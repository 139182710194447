import hoist from 'hoist-non-react-statics'

import {
  ComponentProps,
  UserFormProps,
} from '@features/user/interfaces/UserType'
import { useCompanyList } from '@features/company/hooks/useCompanyList'
import { Option } from '@interfaces/common/SelectType'
import { useRoleGroupList } from '@features/role-group/hooks/useRoleGroupList'

export function withUserForm(Component: React.FC<ComponentProps>) {
  function WithUserForm(props: UserFormProps) {
    const { data: companyList } = useCompanyList()
    const { data: roleGroupList } = useRoleGroupList()
    const companyOptions: Option[] = companyList.map(company => ({
      value: company.id,
      text: company.code,
    }))

    const roleGroupOptions: Option[] = roleGroupList.map(roleGroup => ({
      value: roleGroup.id,
      text: roleGroup.code,
    }))

    const pageProps = {
      companyOptions,
      roleGroupOptions,
      ...props,
    }
    return <Component {...pageProps} />
  }

  hoist(WithUserForm, Component)

  WithUserForm.displayName = `WithUserForm(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUserForm
}
