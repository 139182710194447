import { useState, useMemo, useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import cx from 'classnames'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider, useWatch } from 'react-hook-form'

import { blankFormProducts, generateYearOptions } from '@lib/constants'
import { SelectField } from '@components/fieldV2/SelectField'
import { useCompanyList } from '@features/company/hooks/useCompanyList'
import { useActivityList } from '@features/activity/hooks/useActivityList'
import { useTankProductList } from '@features/tank/hooks/useTankProductList'
import { Option } from '@interfaces/common/SelectType'

import { TankListForm } from './TankListForm'
import styles from './index.module.scss'

const schema = yup.object().shape({
  workRequestCompany: yup
    .string()
    .required('Company work request is a required field'),
  workRequestYear: yup
    .string()
    .required('Year work request is a required field'),
  product: yup.string().required('Product Name is a required field'),
  activity: yup.string().required('Activity is a required field'),
  fromCompany: yup.string().required('Company from is a required field'),
  toCompany: yup.string().required('Company to is a required field'),
})

export interface WorkOrderRequestNumberFormType {
  id?: number
  workRequestCompany: string
  workRequestYear: string
  product: string
  activity: string
  fromCompany: string
  toCompany: string
  fromTanks: string[]
  toTanks: string[]
}

export interface WorkOrderRequestNumberFormPropsType {
  initialValues: WorkOrderRequestNumberFormType
  editMode?: boolean
  productOptions: Option[]
  handleSubmit: (form: WorkOrderRequestNumberFormType) => void
  handleClose: () => void
}

export function WorkOrderRequestNumberForm({
  initialValues,
  editMode = false,
  productOptions,
  handleSubmit,
  handleClose,
}: WorkOrderRequestNumberFormPropsType) {
  const methods = useForm<WorkOrderRequestNumberFormType>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })
  const [selectedProduct, setSelectedProduct] = useState(initialValues.product)
  const isBlankForm = blankFormProducts.includes(selectedProduct)

  const { isSubmitting } = methods.formState
  const [product, fromCompany, toCompany, fromTanks, toTanks] = useWatch({
    control: methods.control,
    name: ['product', 'fromCompany', 'toCompany', 'fromTanks', 'toTanks'],
  })

  const { data: companyList } = useCompanyList()
  const workRequestYearOptions = generateYearOptions()
  const companyOptions: Option[] = companyList.map(company => ({
    value: company.code,
    text: company.code,
  }))
  const companyFromToOptions: Option[] = companyOptions.filter(
    row => !(isBlankForm && row.value === 'MTT')
  )

  const fromCompanyIsVessel = fromCompany === 'VESSEL'
  const toCompanyIsVessel = toCompany === 'VESSEL'

  const { data: activityList } = useActivityList({
    productCode: selectedProduct,
  })
  const { data: tankProductList } = useTankProductList({
    productCode: selectedProduct,
  })

  const activityOptions = activityList.map(row => ({
    text: row.description,
    value: row.code,
  }))
  const tankProductOptions = tankProductList.map(row => ({
    text: row.tankCode,
    value: row.tankCode,
  }))

  useEffect(() => {
    methods.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <div className={cx(styles.w50, styles.inputGroup)}>
          <strong>Company work request :</strong>
          <SelectField
            className={styles.inputItem}
            name='workRequestCompany'
            options={companyOptions}
            placeholder='select company work request'
            disabled={editMode}
          />
        </div>
        <div className={cx(styles.w50, styles.inputGroup)}>
          <strong>Year work request :</strong>
          <SelectField
            className={styles.inputItem}
            name='workRequestYear'
            options={workRequestYearOptions}
            placeholder='select year work request'
            disabled={editMode}
          />
        </div>
        <div className={cx(styles.w50, styles.inputGroup)}>
          <strong>Product name:</strong>
          <SelectField
            className={styles.inputItem}
            name='product'
            options={productOptions}
            placeholder='select product name'
            disabled={editMode}
            onChange={e => {
              setSelectedProduct(e.target.value as string)
              const values = methods.getValues()

              methods.reset({
                ...values,
                product: e.target.value as string,
                activity: '',
                fromCompany: '',
                toCompany: '',
                fromTanks: [],
                toTanks: [],
              })
            }}
          />
        </div>
        <div className={cx(styles.w50, styles.inputGroup)}>
          <strong>Activity :</strong>
          <SelectField
            className={styles.inputItem}
            name='activity'
            options={activityOptions}
            placeholder='select activity'
            disabled={editMode || !product}
          />
        </div>

        <TankListForm
          name='fromTanks'
          companyOptions={companyFromToOptions}
          text='from'
          tankProductOptions={tankProductOptions}
          tanks={fromTanks as string[]}
          editMode={editMode}
          showSelectField={
            fromCompany === 'MTT' || (isBlankForm && !fromCompanyIsVessel)
          }
          disabled={!product}
          disableAddTank={
            (!product && fromCompany === 'MTT') ||
            (fromCompany === 'MTT' &&
              toCompany === 'MTT' &&
              fromTanks.length === 1)
          }
          handleCompanyChange={e => {
            const values = methods.getValues()
            methods.reset({
              ...values,
              fromCompany: e.target.value,
              fromTanks: [],
            })
          }}
        />

        <ChevronRight className={styles.arrow} />

        <TankListForm
          name='toTanks'
          companyOptions={companyFromToOptions}
          text='to'
          tankProductOptions={tankProductOptions}
          tanks={toTanks as string[]}
          editMode={editMode}
          showSelectField={
            toCompany === 'MTT' || (isBlankForm && !toCompanyIsVessel)
          }
          disabled={!product}
          disableAddTank={
            (!product && toCompany === 'MTT') ||
            (fromCompany === 'MTT' &&
              toCompany === 'MTT' &&
              toTanks.length === 1)
          }
          handleCompanyChange={e => {
            const values = methods.getValues()
            methods.reset({
              ...values,
              toCompany: e.target.value,
              toTanks: [],
            })
          }}
        />

        <div className={styles.btnGroup}>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={16} />
            ) : editMode ? (
              'Modify'
            ) : (
              'Add'
            )}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Close
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
