import { Expose, Type, Transform } from 'class-transformer'

export type ManualDipParameterKey =
  | 'densityInVac'
  | 'level'
  | 'liquidTemperature'
  | 'molecularWeight'
  | 'vaporPressure'
  | 'vaporTemperature'

class ConfigType {
  state: boolean

  @Transform(({ value }) => (value !== null ? String(value) : ''))
  value: string

  @Expose({ name: 'key_map_parameter' }) keyMapParameter: number
}

export class ManualDipParameter {
  @Expose({ name: 'density_in_vac' })
  @Type(() => ConfigType)
  densityInVac: ConfigType

  @Type(() => ConfigType)
  level: ConfigType

  @Expose({ name: 'liquid_temperature' })
  @Type(() => ConfigType)
  liquidTemperature: ConfigType

  @Expose({ name: 'molecular_weight' })
  @Type(() => ConfigType)
  molecularWeight: ConfigType

  @Expose({ name: 'vapor_pressure' })
  @Type(() => ConfigType)
  vaporPressure: ConfigType

  @Expose({ name: 'vapor_temperature' })
  @Type(() => ConfigType)
  vaporTemperature: ConfigType
}

class ManualDipType {
  @Expose({ name: 'parameters' })
  @Type(() => ManualDipParameter)
  parameter: ManualDipParameter

  state: boolean
}

class ManualDip {
  @Type(() => ManualDipType)
  start: ManualDipType

  @Type(() => ManualDipType)
  stop: ManualDipType

  @Expose({ name: 'stop_lessline' })
  @Type(() => ManualDipType)
  stopLessline: ManualDipType

  state: boolean
}

export class ConfigDeliveryReport {
  @Expose({ name: 'density_in_vac' })
  @Type(() => ConfigType)
  densityInVac: ConfigType

  @Expose({ name: 'manual_dip' })
  @Type(() => ManualDip)
  manualDip: ManualDip

  @Expose({ name: 'molecular_weight' })
  @Type(() => ConfigType)
  molecularWeight: ConfigType

  @Expose({ name: 'product_purity' })
  @Type(() => ConfigType)
  productPurity: ConfigType

  @Expose({ name: 'vessel_load' })
  @Type(() => ConfigType)
  vesselLoad: ConfigType

  @Expose({ name: 'goal_seek' })
  @Type(() => ConfigType)
  goalSeek: ConfigType
}
