import { memo } from 'react'
import { DateTime } from 'luxon'

import { Datetime } from '@components/Datetime'
import { ActionFormPropsType } from '@features/tracking-work/interfaces/MeterReportType'
import styles from './index.module.scss'

export const ActionForm = memo(
  ({ className, title, value, disabled, setDatetime }: ActionFormPropsType) => {
    function handleDateTimeChange(datetime: string) {
      setDatetime(datetime)
    }

    return (
      <div className={className}>
        <div>
          <strong>{title}</strong>
        </div>
        <Datetime
          className={styles.datetime}
          datetime={value}
          onChange={handleDateTimeChange}
          disabled={disabled}
          max={DateTime.now().toISO({ includeOffset: false })}
        />
      </div>
    )
  }
)
