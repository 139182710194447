import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useAuthentication } from '@features/authentication/hooks/useAuthentication'
import { useProfile } from '@features/profile/hooks/useProfile'
import { getQueryString } from '@lib/utils'
import { Loading } from '@components/Loading'

const automaticLogoutPaths = ['/auth/setup-account', '/auth/reset-password']

export function withUnauthenticatedGuard(
  Component: React.FC<FunctionComponentType>
) {
  function WithUnauthenticatedGuard(props: FunctionComponentType) {
    const navigate = useNavigate()
    const { refetch: fetchProfile } = useProfile()
    const [searchParams] = useSearchParams()
    const { isAuthenticated } = useAuthentication()
    const { pathname } = useLocation()
    const { signOut } = useAuthentication()

    const redirectUrl = searchParams.get('redirectTo') ?? '/'

    const isAutoLogoutPath = automaticLogoutPaths.includes(pathname)

    useEffect(() => {
      if (!isAuthenticated) return

      if (isAutoLogoutPath) {
        signOut()
      } else {
        fetchProfile &&
          fetchProfile().then(() => {
            const queryParams = getQueryString(searchParams, {
              excludes: ['redirectTo'],
            })

            navigate({
              pathname: redirectUrl,
              search: queryParams ? `?${queryParams}` : '',
            })
          })
      }
    }, [
      isAuthenticated,
      navigate,
      fetchProfile,
      redirectUrl,
      signOut,
      isAutoLogoutPath,
      searchParams,
    ])

    if (isAuthenticated) return <Loading />
    return <Component {...props} />
  }

  hoist(WithUnauthenticatedGuard, Component)

  WithUnauthenticatedGuard.displayName = `withUnauthenticatedGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUnauthenticatedGuard
}
