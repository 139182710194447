import { useFormContext, useController } from 'react-hook-form'

import { SelectType } from '@interfaces/common/SelectType'
import { Select } from '@components/Select'

export function SelectField({
  name = '',
  label,
  placeholder,
  options,
  disabled,
  defaultValue,
  ...props
}: SelectType) {
  const { control } = useFormContext()
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control, defaultValue })

  return (
    <Select
      options={options}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      error={invalid}
      helperText={error?.message}
      {...field}
      {...props}
    />
  )
}
