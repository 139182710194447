import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    padding: '10px 14px',
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  textRight: {
    textAlign: 'right',
  },
  mb: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 500,
  },
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: 15,
  },
  imageWrap: {
    width: 90,
    position: 'absolute',
    left: 0,
    top: -10,
  },
  box: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE0EB',
    padding: 10,
    marginTop: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section1: {
    width: '49%',
    fontWeight: 500,
  },
  colorRed: {
    color: 'red',
  },
  tag: {
    width: '20%',
    textAlign: 'right',
  },
  label: {
    width: '30%',
    textAlign: 'right',
  },
  value: {
    width: '15%',
    textAlign: 'left',
  },
  unit: {
    width: '30%',
    textAlign: 'left',
  },
  informationHeader: {
    width: '22%',
    fontWeight: 500,
    textAlign: 'right',
  },
  informationValue: {
    width: '73%',
    display: 'flex',
    flexDirection: 'row',
  },
  informationHeader2: {
    width: '5%',
    fontWeight: 500,
    textAlign: 'right',
  },
  informationValue2: {
    width: '20.5%',
    display: 'flex',
    flexDirection: 'row',
  },
  informationValue3: {
    width: '37.5%',
    display: 'flex',
    flexDirection: 'row',
  },
  signatureGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 350,
  },
  signature: {
    width: '30%',
    borderTopWidth: 1,
    borderTopColor: '#000',
    paddingTop: 10,
    textAlign: 'center',
    fontWeight: 500,
  },
  symbol: {
    position: 'relative',
  },
  lower: {
    position: 'absolute',
    left: 19,
    bottom: -3,
    fontSize: 6,
  },
  upper: {
    position: 'absolute',
    left: 32,
    top: -2,
    fontSize: 6,
  },
})
