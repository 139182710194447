import { useField } from 'formik'
import { TextField, FormControl } from '@material-ui/core'

interface InputFieldType extends React.HTMLProps<HTMLInputElement> {
  name: string
  multiline?: boolean
  rows?: number
  InputProps?: any
}

export function InputField({
  className,
  name,
  label,
  type = 'text',
  multiline = false,
  rows,
  disabled,
  InputProps,
}: InputFieldType) {
  const [field, meta] = useField(name)
  const error = meta.error && meta.touched

  return (
    <FormControl className={className}>
      {label && (
        <div className='label'>
          <strong>{label}</strong>
        </div>
      )}
      <TextField
        {...field}
        type={type}
        size='small'
        variant='outlined'
        multiline={multiline}
        rows={rows}
        rowsMax={rows}
        error={!!error}
        disabled={disabled}
        InputProps={InputProps}
      />
      {!!error && <div className='error-message'>{meta.error}</div>}
    </FormControl>
  )
}
