import { memo } from 'react'
import { TextField } from '@material-ui/core'
import cx from 'classnames'

import { Box } from '@features/tracking-work/components/Box'
import { PurityCorrectionPropsType } from '@features/tracking-work/interfaces/PurityCorrectionType'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

export const PurityCorrection = memo(
  ({ disabled, purity, hideAction, setPurity }: PurityCorrectionPropsType) => {
    return (
      <Box
        className={cx({ [styles.disabled]: disabled })}
        title='Purity Correction'
      >
        <div className={styles.inputGroup}>
          <label>Product Purity:</label>
          <TextField
            className={styles.purityInput}
            type='number'
            size='small'
            variant='outlined'
            value={purity}
            onChange={e => setPurity(e.target.value)}
            disabled={disabled || hideAction}
          />
        </div>
      </Box>
    )
  }
)
