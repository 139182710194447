import { Expose, Type, Transform } from 'class-transformer'

import { addComma } from '@lib/utils'
import { ProductMovementType } from './ProductMovementDetailType'

export interface MonthlyProps {
  product: string
  periodFrom: string
  periodTo: string
  date: string

  stockLastMonth1: string
  totalInputThisMonth1: string
  totalOutputThisMonth1: string
  stockThisMonth1: string

  stockLastMonth2: string
  totalInputThisMonth2: string
  totalOutputThisMonth2: string
  stockThisMonth2: string

  stockLastMonth3: string
  stockThisMonth3: string

  stockLastMonth4: string
  stockThisMonth4: string

  lossGainByMass: string
  lossGainTotalThroughputByMass: string
  totalThroughputThisMonthByMass: string
  totalThroughputByMass: string

  lossGainByVolume: string
  lossGainTotalThroughputByVolume: string
  totalThroughputThisMonthByVolume: string
  totalThroughputByVolume: string

  diThroughputOfThisMonth1: string
  diThroughputOfThisMonth2: string
  diThroughputYearToDate1: string
  diThroughputYearToDate2: string
  deThroughputOfThisMonth1: string
  deThroughputOfThisMonth2: string
  deThroughputYearToDate1: string
  deThroughputYearToDate2: string
  imThroughputOfThisMonth1: string
  imThroughputOfThisMonth2: string
  imThroughputYearToDate1: string
  imThroughputYearToDate2: string
  exThroughputOfThisMonth1: string
  exThroughputOfThisMonth2: string
  exThroughputYearToDate1: string
  exThroughputYearToDate2: string
  soThroughputOfThisMonth1: string
  soThroughputOfThisMonth2: string
  soThroughputYearToDate1: string
  soThroughputYearToDate2: string
  reThroughputOfThisMonth1: string
  reThroughputOfThisMonth2: string
  reThroughputYearToDate1: string
  reThroughputYearToDate2: string
  flThroughputOfThisMonth1: string
  flThroughputOfThisMonth2: string
  flThroughputYearToDate1: string
  flThroughputYearToDate2: string
  itInThroughputOfThisMonth1: string
  itInThroughputOfThisMonth2: string
  itInThroughputYearToDate1: string
  itInThroughputYearToDate2: string
  itOutThroughputOfThisMonth1: string
  itOutThroughputOfThisMonth2: string
  itOutThroughputYearToDate1: string
  itOutThroughputYearToDate2: string
}

export class MonthlyType {
  @Expose()
  @Type(() => SummaryType)
  summary: SummaryType

  @Expose()
  @Transform(({ value }) => value ?? [])
  @Type(() => ProductMovementType)
  movement: ProductMovementType[]
}

class SummaryType {
  @Expose({ name: 'spm_report_type' }) type: string

  @Expose({ name: 'spm_product_name' }) product: string

  @Expose({ name: 'spm_st_datetime' }) startDate: string

  @Expose({ name: 'spm_en_datetime' }) endDate: string

  @Expose({ name: 'delivery_stock_last_month_account_1' })
  @Transform(({ value }) => addComma(value))
  stockLastMonth1: string

  @Expose({ name: 'delivery_total_input_this_month_account_1' })
  @Transform(({ value }) => addComma(value))
  totalInputThisMonth1: string

  @Expose({ name: 'delivery_total_output_this_month_account_1' })
  @Transform(({ value }) => addComma(value))
  totalOutputThisMonth1: string

  @Expose({ name: 'delivery_stock_this_month_account_1' })
  @Transform(({ value }) => addComma(value))
  stockThisMonth1: string

  @Expose({ name: 'delivery_stock_last_month_account_2' })
  @Transform(({ value }) => addComma(value))
  stockLastMonth2: string

  @Expose({ name: 'delivery_total_input_this_month_account_2' })
  @Transform(({ value }) => addComma(value))
  totalInputThisMonth2: string

  @Expose({ name: 'delivery_total_output_this_month_account_2' })
  @Transform(({ value }) => addComma(value))
  totalOutputThisMonth2: string

  @Expose({ name: 'delivery_stock_this_month_account_2' })
  @Transform(({ value }) => addComma(value))
  stockThisMonth2: string

  @Expose({ name: 'daily_stock_last_month_actual_1' })
  @Transform(({ value }) => addComma(value))
  stockLastMonth3: string

  @Expose({ name: 'daily_stock_this_month_actual_1' })
  @Transform(({ value }) => addComma(value))
  stockThisMonth3: string

  @Expose({ name: 'daily_stock_last_month_actual_2' })
  @Transform(({ value }) => addComma(value))
  stockLastMonth4: string

  @Expose({ name: 'daily_stock_this_month_actual_2' })
  @Transform(({ value }) => addComma(value))
  stockThisMonth4: string

  @Expose({ name: 'spm_mass_loss_gain' })
  @Transform(({ value }) => addComma(value))
  lossGainByMass: string

  @Expose({ name: 'spm_mass_loss_gain_total_throughput' })
  @Transform(({ value }) => addComma(value))
  lossGainTotalThroughputByMass: string

  @Expose({ name: 'spm_mass_total_throughput_this_month' })
  @Transform(({ value }) => addComma(value))
  totalThroughputThisMonthByMass: string

  @Expose({ name: 'spm_mass_total_throughput' })
  @Transform(({ value }) => addComma(value))
  totalThroughputByMass: string

  @Expose({ name: 'spm_volumn_loss_gain' })
  @Transform(({ value }) => addComma(value))
  lossGainByVolume: string

  @Expose({ name: 'spm_volumn_loss_gain_total_throughput' })
  @Transform(({ value }) => addComma(value))
  lossGainTotalThroughputByVolume: string

  @Expose({ name: 'spm_volumn_total_throughput_this_month' })
  @Transform(({ value }) => addComma(value))
  totalThroughputThisMonthByVolume: string

  @Expose({ name: 'spm_volumn_total_throughput' })
  @Transform(({ value }) => addComma(value))
  totalThroughputByVolume: string

  @Expose({ name: 'category_di_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  diThroughputOfThisMonth1: string

  @Expose({ name: 'category_di_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  diThroughputOfThisMonth2: string

  @Expose({ name: 'category_di_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  diThroughputYearToDate1: string

  @Expose({ name: 'category_di_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  diThroughputYearToDate2: string

  @Expose({ name: 'category_de_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  deThroughputOfThisMonth1: string

  @Expose({ name: 'category_de_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  deThroughputOfThisMonth2: string

  @Expose({ name: 'category_de_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  deThroughputYearToDate1: string

  @Expose({ name: 'category_de_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  deThroughputYearToDate2: string

  @Expose({ name: 'category_im_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  imThroughputOfThisMonth1: string

  @Expose({ name: 'category_im_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  imThroughputOfThisMonth2: string

  @Expose({ name: 'category_im_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  imThroughputYearToDate1: string

  @Expose({ name: 'category_im_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  imThroughputYearToDate2: string

  @Expose({ name: 'category_ex_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  exThroughputOfThisMonth1: string

  @Expose({ name: 'category_ex_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  exThroughputOfThisMonth2: string

  @Expose({ name: 'category_ex_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  exThroughputYearToDate1: string

  @Expose({ name: 'category_ex_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  exThroughputYearToDate2: string

  @Expose({ name: 'category_so_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  soThroughputOfThisMonth1: string

  @Expose({ name: 'category_so_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  soThroughputOfThisMonth2: string

  @Expose({ name: 'category_so_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  soThroughputYearToDate1: string

  @Expose({ name: 'category_so_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  soThroughputYearToDate2: string

  @Expose({ name: 'category_re_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  reThroughputOfThisMonth1: string

  @Expose({ name: 'category_re_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  reThroughputOfThisMonth2: string

  @Expose({ name: 'category_re_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  reThroughputYearToDate1: string

  @Expose({ name: 'category_re_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  reThroughputYearToDate2: string

  @Expose({ name: 'category_fl_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  flThroughputOfThisMonth1: string

  @Expose({ name: 'category_fl_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  flThroughputOfThisMonth2: string

  @Expose({ name: 'category_fl_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  flThroughputYearToDate1: string

  @Expose({ name: 'category_fl_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  flThroughputYearToDate2: string

  @Expose({ name: 'category_it_in_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  itInThroughputOfThisMonth1: string

  @Expose({ name: 'category_it_in_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  itInThroughputOfThisMonth2: string

  @Expose({ name: 'category_it_in_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  itInThroughputYearToDate1: string

  @Expose({ name: 'category_it_in_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  itInThroughputYearToDate2: string

  @Expose({ name: 'category_it_out_throughput_this_month_1' })
  @Transform(({ value }) => addComma(value))
  itOutThroughputOfThisMonth1: string

  @Expose({ name: 'category_it_out_throughput_this_month_2' })
  @Transform(({ value }) => addComma(value))
  itOutThroughputOfThisMonth2: string

  @Expose({ name: 'category_it_out_throughput_year_to_date_1' })
  @Transform(({ value }) => addComma(value))
  itOutThroughputYearToDate1: string

  @Expose({ name: 'category_it_out_throughput_year_to_date_2' })
  @Transform(({ value }) => addComma(value))
  itOutThroughputYearToDate2: string
}
