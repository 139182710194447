import { Button, TextField, InputAdornment } from '@material-ui/core'
import { Add, Search } from '@material-ui/icons'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { UserPageType } from '@features/user/interfaces/UserType'
import { PermissionGuard } from '@lib/permissions'
import { USER_SETTING } from '@lib/constants'

import styles from './index.module.scss'

export function UserPage({
  data,
  columns,
  loading,
  handleCreate,
}: UserPageType) {
  return (
    <Container>
      <Breadcrumbs />
      <div className={styles.group}>
        <PermissionGuard page={USER_SETTING} mode='write'>
          <Button
            className={styles.add_btn}
            startIcon={<Add />}
            variant='contained'
            color='primary'
            onClick={handleCreate}
          >
            User
          </Button>
        </PermissionGuard>

        {/* <TextField
          type='search'
          size='small'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
        /> */}
      </div>

      <Table
        data={data}
        columns={columns}
        loading={loading}
        className={styles.table}
      />
    </Container>
  )
}
