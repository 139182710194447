import { Page, View, Text, Image } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import logo from '@assets/images/SCGC_Logo_Full Color_Clear_Space.png'
import {
  SummaryReportProps,
  DeliveryProps,
} from '@features/pdf/interfaces/DeliveryType'
import sign from '@assets/images/sign_Chanud.png'
import styles from './styles'

function Signature() {
  return (
    <View style={styles.signatureContainer}>
      <View style={styles.imageWrapper}>
      <Image src={sign} />
        </View>
      
      <View style={styles.signature} />
      <Text>Map Ta Phut Tank Terminal Co., Ltd.</Text>
      <Text>Date: {DateTime.now().toFormat('dd/LL/yyyy')}</Text>
    </View>
  )
}

function fixDecimal(value: number) {
  const [number, decimal] = value.toFixed(3).split('.')

  return `${number.toLocaleString()}.${decimal}`
}

function SummaryReport({
  title,
  tonInAir,
  tonInVac,
  volume15,
  volume30,
  gross,
}: SummaryReportProps) {
  return (
    <View style={styles.row}>
      <View style={styles.summaryHeader}>
        <Text>{title}</Text>
      </View>
      <View style={styles.summaryValueContainer}>
        {gross && (
          <View style={styles.summaryValueGroup}>
            <Text style={styles.summaryValue}>{gross}</Text>
            <Text style={{ ...styles.summaryValue, ...styles.strong }}>
              Volume @ OBSD (Liters)
            </Text>
          </View>
        )}
        <View style={styles.summaryValueGroup}>
          <Text style={styles.summaryValue}>{volume15}</Text>
          <Text style={{ ...styles.summaryValue, ...styles.strong }}>
            Volume @ 15 °C (Liters)
          </Text>
        </View>
        <View style={styles.summaryValueGroup}>
          <Text style={styles.summaryValue}>{volume30}</Text>
          <Text style={{ ...styles.summaryValue, ...styles.strong }}>
            Volume @ 30 °C (Liters)
          </Text>
        </View>
        <View style={styles.summaryValueGroup}>
          <Text style={styles.summaryValue}>{tonInAir}</Text>
          <Text style={{ ...styles.summaryValue, ...styles.strong }}>
            Metric Ton (in air)
          </Text>
        </View>
        <View style={styles.summaryValueGroup}>
          <Text style={styles.summaryValue}>{tonInVac}</Text>
          <Text style={{ ...styles.summaryValue, ...styles.strong }}>
            Metric Ton (in vac)
          </Text>
        </View>
      </View>
    </View>
  )
}

export function Delivery({
  companyName,
  reports,
  workRequestNo,
  capacity,
  product,
  categories,
  fromCompany,
  toCompany,
  note,
  fromTank,
  toTank,
  startDatetime,
  endDatetime,
  tankNo,
  shipmentNo,
  portOfLoading,
  summaryAtgReport,
}: DeliveryProps) {
  const value = reports.find(row => row.keyAttribute === 'grossObsdVol')
  const gross = value
    ? (Number(value.stopValue) - Number(value.startValue)).toLocaleString()
    : '-'

  function transformData(number: number, decimalPoint: number): string {
    if (decimalPoint === 0) return number.toLocaleString()

    const temp = number.toFixed(decimalPoint)
    const [frist, last] = temp.split('.')

    return `${Number(frist).toLocaleString()}.${last}`
  }

  return (
    <Page size='A4' style={styles.page}>
      <View style={styles.headerGroup}>
        <View style={styles.imageWrapper}>
          <Image src={logo} />
        </View>
        <View style={styles.companyName}>
          <Text>{companyName}</Text>
          <Text>DELIVERY REPORT</Text>
        </View>
        <View>
          <Text>PS-F-0018-007</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>WORK REQUEST NO</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{workRequestNo}</Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>PRODUCT</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{product}</Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>SHIPMENT NO.</Text>
            <Text>:</Text>
          </View>

          <View style={styles.informationValue}>
            <Text>{shipmentNo}</Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>CATEGORIES</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{categories}</Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>PORT OF LOADING</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{portOfLoading}</Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>FROM</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{fromCompany}</Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TANK/VESSEL NA</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{fromTank}</Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TO</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{toCompany}</Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TANK/VESSEL NA</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{toTank}</Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>START DATE</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>
              {startDatetime
                ? DateTime.fromISO(startDatetime).toFormat('dd/LL/yyyy')
                : '-'}
            </Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>FINISH DATE</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>
              {endDatetime
                ? DateTime.fromISO(endDatetime).toFormat('dd/LL/yyyy')
                : '-'}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TIME</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>
              {startDatetime
                ? DateTime.fromISO(startDatetime).toFormat('HH:mm')
                : '-'}
            </Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TIME</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>
              {endDatetime
                ? DateTime.fromISO(endDatetime).toFormat('HH:mm')
                : '-'}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.line} />

      <View style={styles.row}>
        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>TANK NO.</Text>
            <Text>:</Text>
          </View>

          <View style={[styles.informationValue, styles.main]}>
            <Text>{tankNo}</Text>
          </View>
        </View>

        <View style={styles.information}>
          <View style={styles.informationHeader}>
            <Text>CAPACITY</Text>
            <Text>:</Text>
          </View>

          <View style={styles.informationValue}>
            <Text>
              {capacity ? (
                <>
                  {capacity.toLocaleString()}{' '}
                  <Text style={styles.strong}>Liters</Text>
                </>
              ) : (
                '-'
              )}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.line} />

      <View style={styles.row}>
        <View style={styles.reportHeader} />
        <View style={styles.reportValue}>
          <Text style={styles.strong}>START</Text>
        </View>
        <View style={styles.reportValue}>
          <Text style={styles.strong}>STOP</Text>
        </View>
      </View>

      {reports.map(report => (
        <View style={styles.row} key={report.sort}>
          <View style={styles.reportHeader}>
            <Text>{report.topic}</Text>
          </View>
          <View
            style={{
              ...styles.reportValue,
              ...(report.topicType === 'C' && styles.main),
            }}
          >
            <Text>
              {report.topicType !== 'H'
                ? transformData(Number(report.startValue), report.decimalPoint)
                : ''}
            </Text>
          </View>
          <View
            style={{
              ...styles.reportValue,
              ...(report.topicType === 'C' && styles.main),
            }}
          >
            <Text>
              {report.topicType !== 'H'
                ? transformData(Number(report.stopValue), report.decimalPoint)
                : ''}
            </Text>
          </View>
        </View>
      ))}

      <View style={styles.line} />

      <SummaryReport
        title='Meter Line Adjustment'
        tonInAir={
          summaryAtgReport?.meterMetricTonsInAir
            ? fixDecimal(summaryAtgReport.meterMetricTonsInAir)
            : '-'
        }
        tonInVac={
          summaryAtgReport?.meterMetricTonsInVac
            ? fixDecimal(summaryAtgReport.meterMetricTonsInVac)
            : '-'
        }
        volume15={
          summaryAtgReport?.meterVolume15C
            ? summaryAtgReport.meterVolume15C.toLocaleString()
            : '-'
        }
        volume30={
          summaryAtgReport?.meterVolume30C
            ? summaryAtgReport.meterVolume30C.toLocaleString()
            : '-'
        }
      />

      <View style={styles.line} />

      <SummaryReport
        title='Less pipe Line Adjustment'
        tonInAir={
          summaryAtgReport?.lessLineMetricTonsInAir
            ? fixDecimal(summaryAtgReport.lessLineMetricTonsInAir)
            : '-'
        }
        tonInVac={
          summaryAtgReport?.lessLineMetricTonsInVac
            ? fixDecimal(summaryAtgReport.lessLineMetricTonsInVac)
            : '-'
        }
        volume15={
          summaryAtgReport?.lessLineVolume15C
            ? summaryAtgReport.lessLineVolume15C.toLocaleString()
            : '-'
        }
        volume30={
          summaryAtgReport?.lessLineVolume30C
            ? summaryAtgReport.lessLineVolume30C.toLocaleString()
            : '-'
        }
      />

      <View style={styles.line} />

      <SummaryReport
        title='Final Difference'
        gross={gross}
        tonInAir={
          summaryAtgReport?.finalMetricTonsInAir
            ? fixDecimal(summaryAtgReport.finalMetricTonsInAir)
            : '-'
        }
        tonInVac={
          summaryAtgReport?.finalMetricTonsInVac
            ? fixDecimal(summaryAtgReport.finalMetricTonsInVac)
            : '-'
        }
        volume15={
          summaryAtgReport?.finalVolume15C
            ? summaryAtgReport.finalVolume15C.toLocaleString()
            : '-'
        }
        volume30={
          summaryAtgReport?.finalVolume30C
            ? summaryAtgReport.finalVolume30C.toLocaleString()
            : '-'
        }
      />

      <View style={styles.noteContainer}>
        <Text style={styles.strong}>Note:</Text>
        <Text style={styles.note}>{note}</Text>
      </View>

      <Signature />
    </Page>
  )
}
