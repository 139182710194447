import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { Tank } from '@features/tank/interfaces/TankType'
import { TankProduct } from '@features/tank/interfaces/TankProductType'
import { TankProductListPropsType } from '@features/tank/hooks/useTankProductList'

export class TankClient {
  constructor(private client: AxiosInstance) {}

  async getTankList(): Promise<Tank[]> {
    const response = await this.client.get('/api/tanks')

    return response.data.map((tank: any) =>
      plainToClass(Tank, tank, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getTankProductList({
    productCode,
  }: TankProductListPropsType): Promise<TankProduct[]> {
    let query = ''
    if (productCode) query = `?product_code=${productCode}`

    const response = await this.client.get(`/api/tanks_products${query}`)

    return response.data.map((tank: any) =>
      plainToClass(TankProduct, tank, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getTankListByCompany(): Promise<Tank[]> {
    const response = await this.client.get('/api/tanks_company_tanks')

    return response.data.map((tank: any) =>
      plainToClass(Tank, tank, {
        excludeExtraneousValues: true,
      })
    )
  }
}
