import { DateTime } from 'luxon'

import { Option } from '@interfaces/common/SelectType'

export const reportRoles: Option[] = [
  {
    value: 'operation_admin',
    text: 'Operation Admin',
  },
  {
    value: 'boardman_admin',
    text: 'Boardman Admin',
  },
  {
    value: 'user',
    text: 'User',
  },
]

export const monthOptions: Option[] = Array.from({ length: 13 }, (_, i) => ({
  value: i === 0 ? 'all' : i,
  text: i === 0 ? 'All month' : `${i}`,
}))

export const workStatusOptions = [
  { text: 'All status', value: 'all' },
  { text: 'Not start', value: 'Not Start' },
  { text: 'On process', value: 'On Process' },
  { text: 'Complete', value: 'Complete' },
]

export const verifyStatusOptions = [
  { text: 'All verify status', value: 'all' },
  { text: 'Wait submit work', value: 'Wait Submit Work' },
  { text: 'Verifying', value: 'Verifying' },
  { text: 'Approve', value: 'Approve' },
]

export const metricTonsInAirKeys = [
  'totalMetricTonInAir',
  'totalMetricToninAir15c',
  'metricTonsInAirAt15c',
]
export const metricTonsInVacKeys = [
  'totalMetricTonInVac',
  'totalMetricToninVac15c',
  'metricTonsInVacAt15c',
]
export const volume15Keys = ['liquidVaporTotalVolume15cLiters', 'volumeAt15c']
export const volume30Keys = ['liquidVaporTotalVolume30cLiters', 'volumeAt30c']

export function generateYearOptions(): Option[] {
  return Array.from({ length: 5 }, (_, i) => {
    const year = Number(DateTime.now().minus({ years: i }).toFormat('yyyy'))

    return { value: year, text: `${year}` }
  })
}

export const COMPANY_SETTING = 'Company Setup Page'
export const ROLE_GROUP_SETTING = 'Role Group Setup Page'
export const USER_SETTING = 'User Setup Page'
export const PRODUCT_SETTING = 'Product Setup Page'
export const TANK_SETTING = 'Tank Setup Page'
export const DAILY_REPORT_CONFIG_SETTING = 'Daily Report Config Setup Page'
export const INVENTORY_DAILY_REPORT = 'Inventory Daily Report Page'
export const SUMMARY_DAILY_REPORT = 'Summary Daily Report Page'
export const TRACKING_WORK = 'Tracking Work Page'
export const BATCH_DOCUMENT_TRACKING = 'Batch Document Tracking Page'
export const DRAFT_CALCULATION_PAGE = 'Draft Calculation Page'
export const MONTHLY_REPORT = 'Monthly Report Page'

export const SETTING_PERMISSIONS = [
  COMPANY_SETTING,
  ROLE_GROUP_SETTING,
  USER_SETTING,
  PRODUCT_SETTING,
  TANK_SETTING,
  DAILY_REPORT_CONFIG_SETTING,
]

export const DAILY_PERMISSIONS = [INVENTORY_DAILY_REPORT, SUMMARY_DAILY_REPORT]

export const DELIVERY_PERMISSIONS = [
  TRACKING_WORK,
  BATCH_DOCUMENT_TRACKING,
  DRAFT_CALCULATION_PAGE,
]

export const MONTHLY_PERMISSIONS = [MONTHLY_REPORT]

export const blankFormProducts = ['PYGAS', 'MIXED-C4-BST', 'MIXED-C4-SCG', 'C4-LPG-RAFFINATE-2', 'C4-RAFFINATE-1-HIB', 'PYROLYSIS-GASOLINE-C9-MOC']
