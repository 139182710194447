import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { UserType, UserFormType } from '@features/user/interfaces/UserType'

interface GetUserPropsType {
  id: string
}

export class UserClient {
  constructor(private client: AxiosInstance) {}

  async getUserList(): Promise<UserType[]> {
    const response = await this.client.get('/api/users')

    return response.data.map((user: any) =>
      plainToClass(UserType, user, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getUser({ id }: GetUserPropsType): Promise<UserType> {
    const response = await this.client.get(`/api/users/${id}`)

    return plainToClass(UserType, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async createUser({
    companyId,
    roleGroupId,
    firstName,
    lastName,
    username,
    password,
    confirmPassword,
    active,
  }: UserFormType): Promise<void> {
    await this.client.post('/api/users', {
      company_id: companyId,
      role_group_id: roleGroupId,
      first_name: firstName,
      last_name: lastName,
      is_active: active === 'true',
      username,
      password,
      confirm_password: confirmPassword,
    })
  }

  async editUser({
    id,
    companyId,
    roleGroupId,
    firstName,
    lastName,
    username,
    password,
    confirmPassword,
    active,
  }: UserFormType): Promise<void> {
    await this.client.patch(`/api/users/${id}`, {
      company_id: companyId,
      role_group_id: roleGroupId,
      first_name: firstName,
      last_name: lastName,
      is_active: active === 'true',
      username,
      ...(password && { password }),
      ...(password && { confirm_password: confirmPassword }),
    })
  }
}
