import hoist from 'hoist-non-react-statics'
import { Button } from '@material-ui/core'
import { DateTime } from 'luxon'

import { AdminPageProps } from '@features/monthly-report/interfaces/AdminType'
import { MonthlyReportType } from '@features/monthly-report/interfaces/MonthlyReportType'
import { useAdminMonthlyReport } from '@features/monthly-report/hooks/useAdminMonthlyReport'
import { PreviewButton } from '@features/monthly-report/components/PreviewButton'

import styles from './index.module.scss'

export function withAdminPage(Component: React.FC<AdminPageProps>) {
  function WithAdminPage() {
    const {
      data,
      isLoading,
      queryParams,
      modalName,
      payload,
      isSubmitting,
      handleOpenApproveModal,
      handleApprove,
      handleOpenRejectModal,
      handleReject,
      closeModal,
    } = useAdminMonthlyReport()

    const columns = [
      {
        Header: DateTime.local(
          Number(queryParams.year),
          Number(queryParams.month)
        ).toFormat('LLLL yyyy'),
        id: 'date',
        columns: [
          {
            Header: 'Product',
            accessor: 'product',
          },
          {
            Header: 'File Name',
            accessor: 'name',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ record }: { record: MonthlyReportType }) => (
              <div className={styles.textCenter}>{record.status}</div>
            ),
          },
          {
            Header: '',
            id: 'preview',
            Cell: ({ record }: { record: MonthlyReportType }) => (
              <PreviewButton
                version={record.name}
                id={record.id}
                year={record.year}
                month={record.month}
              />
            ),
          },
          {
            Header: '',
            id: 'action',
            Cell: ({ record }: { record: MonthlyReportType }) => (
              <div className={styles.actionWrapper}>
                {record.status === 'Complete' ? (
                  <Button
                    className={styles.reject}
                    onClick={() => handleOpenRejectModal(record)}
                  >
                    Reject
                  </Button>
                ) : (
                  <Button
                    className={styles.approve}
                    onClick={() => handleOpenApproveModal(record)}
                  >
                    Approve
                  </Button>
                )}
              </div>
            ),
          },
        ],
      },
    ]

    const pageProps = {
      columns,
      data,
      modalName,
      payload,
      isLoading,
      isSubmitting,
      handleApprove,
      handleReject,
      closeModal,
    }

    return <Component {...pageProps} />
  }

  hoist(WithAdminPage, Component)

  WithAdminPage.displayName = `WithAdminPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithAdminPage
}
