import { useProfile } from '@features/profile/hooks/useProfile'
import { isAdmin, isBoardman } from '@lib/permissions'

import { AdminTrackingWork } from './admin'
import { TrackingWork as BoardmanTrackingWork } from './boardman'

export function TrackingWorkPage() {
  const { data } = useProfile()

  if (isAdmin(data?.roleGroup.reportRole || '')) return <AdminTrackingWork />

  if (isBoardman(data?.roleGroup.reportRole || ''))
    return <BoardmanTrackingWork />

  return null
}
