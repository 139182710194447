import { useState } from 'react'
import { TextField, IconButton } from '@material-ui/core'
import { AddCircleOutlined, RemoveCircleOutlined } from '@material-ui/icons'
import { useFormContext } from 'react-hook-form'

import { SelectField } from '@components/fieldV2/SelectField'
import { Select } from '@components/Select'
import { Option } from '@interfaces/common/SelectType'
import styles from './index.module.scss'

interface TankListType {
  name: string
  companyOptions: Option[]
  text: string
  tankProductOptions: Option[]
  tanks: string[]
  editMode: boolean
  disabled: boolean
  disableAddTank: boolean
  showSelectField: boolean
  handleCompanyChange: (e: any) => void
}

export function TankListForm({
  name,
  companyOptions,
  text,
  editMode,
  tanks,
  tankProductOptions,
  disabled,
  disableAddTank,
  showSelectField,
  handleCompanyChange,
}: TankListType) {
  const [tankInput, setTankInput] = useState('')
  const [tankSelect, setTankSelect] = useState('')
  const { reset, getValues, setValue } = useFormContext()

  return (
    <div className={styles.tankList}>
      <div className={styles.inputGroup}>
        <strong>Company {text} :</strong>
        <SelectField
          className={styles.inputItem}
          name={`${text}Company`}
          options={companyOptions}
          placeholder={`select company ${text}`}
          onChange={handleCompanyChange}
          disabled={editMode || disabled}
        />
      </div>
      <div className={styles.inputGroup}>
        <strong>Add tank {text} :</strong>
        {showSelectField ? (
          <Select
            className={styles.inputItem}
            value={tankSelect}
            options={tankProductOptions}
            onChange={e => setTankSelect(e.target.value as string)}
            placeholder={`select tank ${text}`}
            disabled={disableAddTank || disabled}
          />
        ) : (
          <TextField
            className={styles.inputItem}
            type='text'
            size='small'
            variant='outlined'
            value={tankInput}
            onChange={e => setTankInput(e.target.value)}
            disabled={disabled}
          />
        )}
        <IconButton
          color='secondary'
          onClick={() => {
            if (showSelectField) {
              setValue(name, [...tanks, tankSelect])
            } else {
              setValue(name, [...tanks, tankInput])
              setTankInput('')
            }
          }}
          disabled={disableAddTank || disabled}
        >
          <AddCircleOutlined />
        </IconButton>
      </div>
      <div className={styles.header}>List tank {text}</div>
      {tanks.map((row, index) => (
        <div className={styles.tankItem} key={`${row}-${index}`}>
          <span>{row}</span>
          <IconButton
            color='secondary'
            size='small'
            onClick={() => {
              const form = getValues()

              reset({
                ...form,
                [name]: tanks.filter((_, i) => i !== index),
              })
            }}
          >
            <RemoveCircleOutlined />
          </IconButton>
        </div>
      ))}
    </div>
  )
}
