import { BATCH_DOCUMENT_TRACKING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { BatchDocumentTracking } from './BatchDocumentTracking'
import { withBatchDocumentTracking } from './withBatchDocumentTracking'

const ConnectedBatchDocumentTracking = withPermissionGuard(
  withBatchDocumentTracking(BatchDocumentTracking),
  BATCH_DOCUMENT_TRACKING,
  'read'
)

export { ConnectedBatchDocumentTracking as BatchDocumentTracking }
