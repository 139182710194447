import { memo } from 'react'
import { Button } from '@material-ui/core'
import { DateTime } from 'luxon'

import { Datetime } from '@components/Datetime'
import { ActionFormPropsType } from '@features/tracking-work/interfaces/ATGReportType'

import styles from './index.module.scss'

export const ActionForm = memo(
  ({
    className,
    title,
    value,
    disabled,
    hideAction,
    isDateChange,
    handleCalculation,
    setDatetime,
  }: ActionFormPropsType) => {
    function handleDateTimeChange(datetime: string) {
      if (datetime) setDatetime(datetime, 'select')
    }

    function handleCalCurrentDate() {
      const currentDate = DateTime.now()
        .minus({ minutes: 2 })
        .toISO({ includeOffset: false })
      setDatetime(currentDate, 'current')
      handleCalculation(null)
    }

    function handleCalDefineDate() {
      if (value) handleCalculation(value)
    }

    return (
      <div className={className}>
        <div>
          <strong>{title}</strong>
        </div>
        {!hideAction && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleCalCurrentDate}
            disabled={disabled}
          >
            Cal. Current Date
          </Button>
        )}

        <Datetime
          className={styles.datetime}
          datetime={value || ''}
          onChange={handleDateTimeChange}
          disabled={disabled || hideAction}
          max={DateTime.now().toISO({ includeOffset: false })}
        />

        {!hideAction && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleCalDefineDate}
            disabled={disabled || !isDateChange}
          >
            Cal. Define Date
          </Button>
        )}
      </div>
    )
  }
)
