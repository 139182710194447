import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { AuthStateListenerType } from '@lib/clients/ClientType'
import { AccessTokenType } from '@lib/clients/interfaces/AuthClientType'
import { SignInForm } from '@features/authentication/interfaces/SignInType'
import { Profile } from '@features/profile/interfaces/ProfileType'

export class AuthClient {
  constructor(
    private client: AxiosInstance,
    private authStateListener: AuthStateListenerType[] = []
  ) {}

  notifyAuthStateListener(token: AccessTokenType) {
    this.authStateListener.forEach(listener => listener(token))
  }

  get accessToken(): AccessTokenType {
    return localStorage.getItem('act')
  }

  set accessToken(token: AccessTokenType) {
    if (typeof token === 'undefined' || token === null) {
      localStorage.removeItem('act')
      return
    }

    localStorage.setItem('act', token)
  }

  get refreshToken() {
    return localStorage.getItem('rft')
  }

  set refreshToken(token) {
    if (typeof token === 'undefined' || token === null) {
      localStorage.removeItem('rft')
      return
    }

    localStorage.setItem('rft', token)
  }

  async refreshAccessToken() {
    try {
      const response = await this.client.post(
        `/api/auth/refresh?refresh_token=${this.refreshToken}`
      )
      this.accessToken = response.data.access_token
      this.refreshToken = response.data.refresh_token
    } catch (error) {
      this.accessToken = null
      this.refreshToken = null
      this.notifyAuthStateListener(this.accessToken)
    }
  }

  async signIn({ username, password }: SignInForm): Promise<void> {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)

    const { data } = await this.client.post('/api/auth/token', formData)

    this.accessToken = data.access_token
    this.refreshToken = data.refresh_token
  }

  signOut() {
    this.clearToken()
  }

  async getProfile(): Promise<Profile> {
    const response = await this.client.get('/api/auth/users/me/')

    return plainToClass(Profile, response.data, {
      excludeExtraneousValues: true,
    })
  }

  clearToken() {
    this.accessToken = null
    this.refreshToken = null
  }
}
