import { Button, IconButton } from '@material-ui/core'
import { Print } from '@material-ui/icons'

import { ActionFormPropsType } from '@features/tracking-work/interfaces/ManualDipType'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

export function ActionForm({ disabled, handleSubmit }: ActionFormPropsType) {
  return (
    <div className={styles.manualDipAction}>
      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        disabled={disabled}
      >
        Cal
      </Button>
    </div>
  )
}
