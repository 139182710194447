import cx from 'classnames'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { Loading } from '@components/Loading'

import { VesselLoad } from './VesselLoad'
import { ShipReceived } from './ShipReceived'
import { MeterReport } from './MeterReport'
import styles from './index.module.scss'

export function MeterMode() {
  const { isMeterLoading } = useDeliveryReport()
  return (
    <div className={cx('mt', styles.container)}>
      {isMeterLoading ? (
        <Loading />
      ) : (
        <>
          <VesselLoad />
          <ShipReceived />
          <MeterReport className={styles.meterReportContainer} />
        </>
      )}
    </div>
  )
}
