import { Expose } from 'class-transformer'
import { DateTime } from 'luxon'

export interface DailyReportPageType {
  data: DailyReport[]
  loading: boolean
  customer?: string
  selectedDate: string
  date: string
  verifyDates: string[]
  companyId?: number
  editable: boolean
  isError: boolean
  handleSelectedDateChange: (value: DateTime | null) => void
  handleSearch: () => void
  handleSelectVerifyDate: (value: string) => void
  handleExportFile: () => void
}

export interface DataType {
  value: string
  adjust?: boolean
}

type RocKey = 'tankNo' | 'productionName'
export type RocType = Record<RocKey, DataType>

export type ParameterKey =
  | 'levelMasterMm'
  | 'levelSlaveMm'
  | 'liquidTempC'
  | 'vaporTempC'
  | 'den15oCInVacKgsLiter'
  | 'den15oCInAirKgsLiter'
  | 'vaporPressureKgsCm2g'
  | 'mw'
export type ParameterType = Record<ParameterKey, DataType>

export type QuanityValueKey =
  | 'volLiters15oC'
  | 'volLiters30oC'
  | 'massTonsInAir'
  | 'massTonsInVac'
type QuanityValueType = Record<QuanityValueKey, DataType>
type QuanityKey = 'alltank' | 'usable' | 'avaliablespace'
export type QuanityType = Record<QuanityKey, QuanityValueType>
type ReportStatusType = 'Normal' | 'Verify'

export class DailyReport {
  @Expose() date: string

  @Expose() reportStatus: ReportStatusType

  @Expose() roc: RocType

  @Expose() parameter: ParameterType

  @Expose() quantities: QuanityType
}
