import { useFormContext, useWatch } from 'react-hook-form'
import cx from 'classnames'

import { InputField } from '@components/fieldV2/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { ManualDipComponentType } from '@features/tracking-work/interfaces/ManualDipType'
import { useProfile } from '@features/profile/hooks/useProfile'
import { isBoardman } from '@lib/permissions'
import {
  CalculateMode,
  CalculateType,
} from '@lib/clients/collections/ATGReportClient'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

import { ActionForm } from './ActionForm'

export function ManualDip({
  className,
  disableStartForm,
  disableLessLineForm,
  disableStopForm,
  disableAll,
  showLevel,
  showLiquidTemperature,
  showVaporTemperature,
  showVaporPressure,
  showDensityInVac,
  showMolecularWeight,
  isSubmitting,
  hideAction,
  handleCalStartForm,
  handleCalLessLineForm,
  handleCalStopForm,
  handleManualFormChange,
}: ManualDipComponentType) {
  const { setValue, control } = useFormContext()
  const { data: profile } = useProfile()

  const level = useWatch({
    control,
    name: 'level',
  })

  const liquidTemperature = useWatch({
    control,
    name: 'liquidTemperature',
  })

  const vaporTemperature = useWatch({
    control,
    name: 'vaporTemperature',
  })

  const vaporPressure = useWatch({
    control,
    name: 'vaporPressure',
  })

  const densityInVac = useWatch({
    control,
    name: 'densityInVac',
  })

  const molecularWeight = useWatch({
    control,
    name: 'molecularWeight',
  })

  function handleChange(
    name: string,
    value: string,
    decimalPoint: number,
    type: CalculateType,
    mode: CalculateMode
  ) {
    const number = value.split('.')

    if (decimalPoint && number.length > 1)
      setValue(name, `${number[0]}.${number[1].slice(0, decimalPoint)}`)
    else setValue(name, number[0])

    if (isBoardman(profile?.roleGroup.reportRole || ''))
      handleManualFormChange(type, mode)
  }

  return (
    <Box
      className={cx(className, { [styles.disabled]: disableAll })}
      title='Manual Dip / Verify (Calculation)'
    >
      <form className={styles.manualDipContent}>
        <div />
        <div
          className={cx(styles.manualDipHeader, {
            [styles.disabled]: disableStartForm,
          })}
        >
          START (ATG)
        </div>
        <div
          className={cx(styles.manualDipHeader, {
            [styles.disabled]: disableLessLineForm,
          })}
        >
          STOP (Less-Line)
        </div>
        <div
          className={cx(styles.manualDipHeader, {
            [styles.disabled]: disableStopForm,
          })}
        >
          STOP (ATG)
        </div>

        {showLevel && (
          <>
            <div className={styles.manualDipLabel}>Level (mm.) :</div>
            <InputField
              className={cx({
                conflict:
                  level?.startValue &&
                  Number(level?.startValue) !== level?.oldStartValue,
              })}
              type='number'
              name='level.startValue'
              handleChange={e =>
                handleChange(
                  'level.startValue',
                  e.target.value,
                  level.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  level?.stopLesslineValue &&
                  Number(level?.stopLesslineValue) !==
                    level?.oldStopLesslineValue,
              })}
              type='number'
              name='level.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'level.stopLesslineValue',
                  e.target.value,
                  level.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  level?.stopValue &&
                  Number(level?.stopValue) !== level?.oldStopValue,
              })}
              type='number'
              name='level.stopValue'
              handleChange={e =>
                handleChange(
                  'level.stopValue',
                  e.target.value,
                  level.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {showLiquidTemperature && (
          <>
            <div className={styles.manualDipLabel}>
              Liquid Temp. (&#8451;) :
            </div>
            <InputField
              className={cx({
                conflict:
                  liquidTemperature?.startValue &&
                  Number(liquidTemperature?.startValue) !==
                    liquidTemperature?.oldStartValue,
              })}
              type='number'
              name='liquidTemperature.startValue'
              handleChange={e =>
                handleChange(
                  'liquidTemperature.startValue',
                  e.target.value,
                  liquidTemperature.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  liquidTemperature?.stopLesslineValue &&
                  Number(liquidTemperature?.stopLesslineValue) !==
                    liquidTemperature?.oldStopLesslineValue,
              })}
              type='number'
              name='liquidTemperature.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'liquidTemperature.stopLesslineValue',
                  e.target.value,
                  liquidTemperature.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  liquidTemperature?.stopValue &&
                  Number(liquidTemperature?.stopValue) !==
                    liquidTemperature?.oldStopValue,
              })}
              type='number'
              name='liquidTemperature.stopValue'
              handleChange={e =>
                handleChange(
                  'liquidTemperature.stopValue',
                  e.target.value,
                  liquidTemperature.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {showVaporTemperature && (
          <>
            <div className={styles.manualDipLabel}>Vapor Temp. (&#8451;) :</div>
            <InputField
              className={cx({
                conflict:
                  vaporTemperature?.startValue &&
                  Number(vaporTemperature?.startValue) !==
                    vaporTemperature?.oldStartValue,
              })}
              type='number'
              name='vaporTemperature.startValue'
              handleChange={e =>
                handleChange(
                  'vaporTemperature.startValue',
                  e.target.value,
                  vaporTemperature.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  vaporTemperature?.stopLesslineValue &&
                  Number(vaporTemperature?.stopLesslineValue) !==
                    vaporTemperature?.oldStopLesslineValue,
              })}
              type='number'
              name='vaporTemperature.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'vaporTemperature.stopLesslineValue',
                  e.target.value,
                  vaporTemperature.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  vaporTemperature?.stopValue &&
                  Number(vaporTemperature?.stopValue) !==
                    vaporTemperature?.oldStopValue,
              })}
              type='number'
              name='vaporTemperature.stopValue'
              handleChange={e =>
                handleChange(
                  'vaporTemperature.stopValue',
                  e.target.value,
                  vaporTemperature.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {showVaporPressure && (
          <>
            <div className={styles.manualDipLabel}>
              Vapor Pressure (Kg/cm2, mm/H2O) :
            </div>
            <InputField
              className={cx({
                conflict:
                  vaporPressure?.startValue &&
                  Number(vaporPressure?.startValue) !==
                    vaporPressure?.oldStartValue,
              })}
              type='number'
              name='vaporPressure.startValue'
              handleChange={e =>
                handleChange(
                  'vaporPressure.startValue',
                  e.target.value,
                  vaporPressure.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  vaporPressure?.stopLesslineValue &&
                  Number(vaporPressure?.stopLesslineValue) !==
                    vaporPressure?.oldStopLesslineValue,
              })}
              type='number'
              name='vaporPressure.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'vaporPressure.stopLesslineValue',
                  e.target.value,
                  vaporPressure.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  vaporPressure?.stopValue &&
                  Number(vaporPressure?.stopValue) !==
                    vaporPressure?.oldStopValue,
              })}
              type='number'
              name='vaporPressure.stopValue'
              handleChange={e =>
                handleChange(
                  'vaporPressure.stopValue',
                  e.target.value,
                  vaporPressure.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {showDensityInVac && (
          <>
            <div className={styles.manualDipLabel}>Density @15c in VAC :</div>
            <InputField
              className={cx({
                conflict:
                  densityInVac?.startValue &&
                  Number(densityInVac?.startValue) !==
                    densityInVac?.oldStartValue,
              })}
              type='number'
              name='densityInVac.startValue'
              handleChange={e =>
                handleChange(
                  'densityInVac.startValue',
                  e.target.value,
                  densityInVac.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  densityInVac?.stopLesslineValue &&
                  Number(densityInVac?.stopLesslineValue) !==
                    densityInVac?.oldStopLesslineValue,
              })}
              type='number'
              name='densityInVac.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'densityInVac.stopLesslineValue',
                  e.target.value,
                  densityInVac.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  densityInVac?.stopValue &&
                  Number(densityInVac?.stopValue) !==
                    densityInVac?.oldStopValue,
              })}
              type='number'
              name='densityInVac.stopValue'
              handleChange={e =>
                handleChange(
                  'densityInVac.stopValue',
                  e.target.value,
                  densityInVac.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {showMolecularWeight && (
          <>
            <div className={styles.manualDipLabel}>Molecular Weight :</div>
            <InputField
              className={cx({
                conflict:
                  molecularWeight?.startValue &&
                  Number(molecularWeight?.startValue) !==
                    molecularWeight?.oldStartValue,
              })}
              type='number'
              name='molecularWeight.startValue'
              handleChange={e =>
                handleChange(
                  'molecularWeight.startValue',
                  e.target.value,
                  molecularWeight.decimalPoint,
                  'start',
                  'normal'
                )
              }
              disabled={disableStartForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  molecularWeight?.stopLesslineValue &&
                  Number(molecularWeight?.stopLesslineValue) !==
                    molecularWeight?.oldStopLesslineValue,
              })}
              type='number'
              name='molecularWeight.stopLesslineValue'
              handleChange={e =>
                handleChange(
                  'molecularWeight.stopLesslineValue',
                  e.target.value,
                  molecularWeight.decimalPoint,
                  'stop',
                  'less_line'
                )
              }
              disabled={disableLessLineForm || hideAction}
            />
            <InputField
              className={cx({
                conflict:
                  molecularWeight?.stopValue &&
                  Number(molecularWeight?.stopValue) !==
                    molecularWeight?.oldStopValue,
              })}
              type='number'
              name='molecularWeight.stopValue'
              handleChange={e =>
                handleChange(
                  'molecularWeight.stopValue',
                  e.target.value,
                  molecularWeight.decimalPoint,
                  'stop',
                  'normal'
                )
              }
              disabled={disableStopForm || hideAction}
            />
          </>
        )}

        {!hideAction && <div />}
        {!hideAction && (
          <ActionForm
            handleSubmit={handleCalStartForm}
            disabled={disableStartForm || isSubmitting}
          />
        )}
        {!hideAction && (
          <ActionForm
            handleSubmit={handleCalLessLineForm}
            disabled={disableLessLineForm || isSubmitting}
          />
        )}
        {!hideAction && (
          <ActionForm
            handleSubmit={handleCalStopForm}
            disabled={disableStopForm || isSubmitting}
          />
        )}
      </form>
    </Box>
  )
}
