import { IconButton } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { DateTime } from 'luxon'
import cx from 'classnames'

import styles from './index.module.scss'

interface DatetimePropsType {
  className?: string
  datetime: string
  disabled: boolean
  max?: string
  onChange: (value: string) => void
}

export function Datetime({
  className,
  datetime,
  disabled,
  max = '',
  onChange,
}: DatetimePropsType) {
  return (
    <div className={cx(className, styles.container)}>
      {datetime
        ? DateTime.fromISO(datetime).toFormat('dd/LL/yyyy HH:mm')
        : 'dd/mm/yyyy --:--'}
      <IconButton color='primary'>
        <CalendarToday />
      </IconButton>

      <input
        value={datetime}
        type='datetime-local'
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        max={max}
      />
    </div>
  )
}
