import { FormProvider } from 'react-hook-form'
import hoist from 'hoist-non-react-statics'

import {
  MeterReportPropsType,
  MeterReportType,
} from '@features/tracking-work/interfaces/MeterReportType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

export function withMeterReport(Component: React.FC<MeterReportType>) {
  function WithMeterReport(props: MeterReportPropsType) {
    const {
      meterReport,
      meterMethods,
      meterSummary,
      hideAction,
      handleMeterSaveStartAndSaveChecklist,
      handleMeterSaveStopLesslineAndSaveChecklist,
      handleMeterSaveStopAndSaveChecklist,
    } = useDeliveryReport()

    function handleSaveStart() {
      const { start } = meterMethods.getValues()
      handleMeterSaveStartAndSaveChecklist(start)
    }
    function handleSaveLessLine() {
      const { stopLessline } = meterMethods.getValues()
      handleMeterSaveStopLesslineAndSaveChecklist(stopLessline)
    }
    function handleSaveStop() {
      const { stop } = meterMethods.getValues()
      handleMeterSaveStopAndSaveChecklist(stop)
    }

    const pageProps = {
      ...props,
      meterRunId: meterReport?.meterRunId || '',
      summary: meterSummary,
      hideAction,
      handleSaveStart,
      handleSaveLessLine,
      handleSaveStop,
    }
    return (
      <FormProvider {...meterMethods}>
        <Component {...pageProps} />
      </FormProvider>
    )
  }

  hoist(WithMeterReport, Component)

  WithMeterReport.displayName = `WithMeterReport(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithMeterReport
}
