import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import hoist from 'hoist-non-react-statics'
import cx from 'classnames'

import {
  AdminTrackingWorkType,
  TrackingWork,
} from '@features/tracking-work/interfaces/TrackingWorkType'
import { useTrackingWork } from '@features/tracking-work/hooks/useTrackingWork'
import { useTrackingWorkList } from '@features/tracking-work/hooks/useTrackingWorkList'
import { useRejectTrackingWorkList } from '@features/tracking-work/hooks/useRejectTrackingWorkList'
import { useModal } from '@features/tracking-work/hooks/useModal'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useProductList } from '@features/product/hooks/useProductList'
import { Option } from '@interfaces/common/SelectType'
import { generateYearOptions, TRACKING_WORK } from '@lib/constants'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'
import { usePermissionGuard } from '@lib/permissions'

const columns = [
  {
    Header: 'Work Request No',
    accessor: 'workNo',
    Cell: ({ record }: { record: TrackingWork }) => (
      <Link to={`/tracking-work/${record.id}/calculate`}>
        {record.workRequestID}
      </Link>
    ),
  },
  {
    Header: 'Tank No',
    accessor: 'tankNo',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Activity',
    accessor: 'activity',
  },
  {
    Header: 'Work Status',
    Cell: ({ record }: { record: TrackingWork }) => (
      <div
        className={cx({
          [styles.notStart]: record.workStatus === 'Not Start',
          [styles.onProcess]: record.workStatus === 'On Process',
          [styles.complete]: record.workStatus === 'Complete',
          [styles.reject]: record.status === 'reject',
        })}
      >
        {record.status ? 'Reject' : record.workStatus}
      </div>
    ),
  },
]

export function withAdminTrackingWork(
  Component: React.FC<AdminTrackingWorkType>
) {
  function WithAdminTrackingWork() {
    const { success, error } = useAlert()
    const [selectedModify, setSelectedModify] = useState<number>()
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const { modal, payload, openModal, closeModal } = useModal()
    const {
      data: trackingWorkList,
      isLoading: isTrackingWorkListLoading,
      refetch: refetchTrackingWorkList,
      queryParam: trackingWorkParam,
      rejectTrackingWork,
      addWorkRequest,
      handleSearch,
      handlePaginationChange,
      reviseWork,
      copyWork,
    } = useTrackingWorkList()
    const {
      data: trackingWork,
      isLoading: isLoadingTrackingWork,
      updateWorkRequest,
    } = useTrackingWork({
      id: selectedModify,
    })
    const {
      data: rejectTrackingWorkList,
      isLoading: isRejectTrackingWorkListLoading,
      queryParam: rejectParam,
      refetch: refetchRejectTrackingWorkList,
      recoverTrackingWork,
      handleRejectSearch,
      handleRejectPaginationChange,
    } = useRejectTrackingWorkList()
    const { data: productList } = useProductList()
    const yearOptions: Option[] = generateYearOptions()
    const productOptions = productList.map(row => ({
      text: row.name,
      value: row.code,
    }))
    const editable = usePermissionGuard(TRACKING_WORK, 'write')

    const trackingWorkColumns = editable
      ? [
          ...columns,
          {
            Header: 'Verify Status',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div
                className={cx({
                  [styles.waitSubmitWork]:
                    record.verifyStatus === 'Wait Submit Work',
                  [styles.verifying]: record.verifyStatus === 'Verifying',
                  [styles.approve]: record.verifyStatus === 'Approve',
                })}
              >
                {record.verifyStatus}
              </div>
            ),
          },
          {
            Header: 'Modify Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.modifyWork}>
                <Button
                  onClick={() => handleOpenModifyWorkModal(record)}
                  disabled={record.verifyStatus === 'Approve'}
                >
                  Modify
                </Button>
              </div>
            ),
          },
          {
            Header: 'Reject Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.rejectWork}>
                <Button onClick={() => handleOpenRejectModal(record)}>
                  Reject
                </Button>
              </div>
            ),
          },
          {
            Header: 'Revise Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.revise}>
                <Button
                  onClick={() => handleOpenReviseWorkModal(record)}
                  disabled={!record.canRevise}
                >
                  Revise
                </Button>
              </div>
            ),
          },
          {
            Header: 'Copy work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.modifyWork}>
                <Button
                  onClick={() => handleOpenCopyModal(record)}
                  disabled={!record.canCopy}
                >
                  Copy
                </Button>
              </div>
            ),
          },
        ]
      : [
          ...columns,
          {
            Header: 'Verify Status',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div
                className={cx({
                  [styles.waitSubmitWork]:
                    record.verifyStatus === 'Wait Submit Work',
                  [styles.verifying]: record.verifyStatus === 'Verifying',
                  [styles.approve]: record.verifyStatus === 'Approve',
                })}
              >
                {record.verifyStatus}
              </div>
            ),
          },
        ]

    const rejectTrackingWorkColumns = editable
      ? [
          {
            Header: 'Work Request No',
            accessor: 'workRequestID',
          },
          ...columns.slice(1),
          {
            Header: 'Recover Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.recoverWork}>
                <Button onClick={() => handleOpenRecoverModal(record)}>
                  Recover
                </Button>
              </div>
            ),
          },
        ]
      : columns

    function handleOpenRejectModal(form: TrackingWork) {
      openModal({
        modalName: 'reject',
        message: `Are you sure you want to reject ${form.workRequestID}`,
        payload: form,
      })
    }

    function handleOpenRecoverModal(form: TrackingWork) {
      openModal({
        modalName: 'recover',
        message: `Are you sure you want to recover ${form.workRequestID}`,
        payload: form,
      })
    }

    function handleOpenAddWorkModal() {
      openModal({ modalName: 'add-work' })
    }

    function handleOpenModifyWorkModal(value: TrackingWork) {
      openModal({ modalName: 'modify-work' })
      setSelectedModify(value.id)
    }

    function handleOpenReviseWorkModal(value: TrackingWork) {
      openModal({ modalName: 'revise-work', payload: value })
    }

    function handleOpenCopyModal(value: TrackingWork) {
      openModal({ modalName: 'copy-work', payload: value })
    }

    async function handleReject() {
      let isError = false

      await rejectTrackingWork({ id: payload.id }).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        refetchRejectTrackingWorkList()
        success({ message: 'reject success' })
      }

      closeModal()
    }

    async function handleCopyWork() {
      setSubmitting(true)
      let isError = false
      await copyWork(payload.id).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        closeModal()
        success({ message: 'Copy work successful.' })
      }
      setSubmitting(false)
    }

    async function handleRecover() {
      let isError = false

      await recoverTrackingWork({ id: payload.id }).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        refetchRejectTrackingWorkList()
        success({ message: 'Recover successful.' })
      }

      closeModal()
    }

    async function handleAddWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await addWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        closeModal()
        success({ message: 'Add work successful.' })
      }
    }

    async function handleModifyWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await updateWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        closeModal()
        success({ message: 'Modify work successful.' })
      }
    }

    async function handleReviseWork() {
      setSubmitting(true)
      let isError = false

      await reviseWork(payload.id).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        closeModal()
        refetchTrackingWorkList()
        success({ message: 'Revise successful.' })
      }
      setSubmitting(false)
    }

    const pageProps = {
      trackingWorkList,
      rejectTrackingWorkList,
      productOptions,
      yearOptions,
      isTrackingWorkListLoading,
      isRejectTrackingWorkListLoading,
      modal,
      payload: payload as TrackingWork,
      trackingWorkColumns,
      rejectTrackingWorkColumns,
      modifyWork: {
        id: trackingWork?.id,
        workRequestCompany: trackingWork?.workRequestCompany || '',
        workRequestYear: trackingWork?.workRequestYear || '',
        product: trackingWork?.product || '',
        activity: trackingWork?.activity || '',
        fromCompany: trackingWork?.fromCompany || '',
        toCompany: trackingWork?.toCompany || '',
        fromTanks: trackingWork?.fromTanks || [],
        toTanks: trackingWork?.toTanks || [],
      },
      isLoadingTrackingWork,
      trackingWorkParam,
      rejectParam,
      isSubmitting,
      handleSearch,
      handleRejectSearch,
      handleReject,
      handleRecover,
      handleCloseModal: closeModal,
      handleOpenAddWorkModal,
      handleAddWork,
      handleModifyWork,
      handlePaginationChange,
      handleRejectPaginationChange,
      handleReviseWork,
      handleOpenCopyModal,
      handleCopyWork,
    }

    return <Component {...pageProps} />
  }

  hoist(WithAdminTrackingWork, Component)

  WithAdminTrackingWork.displayName = `WithAdminTrackingWork(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithAdminTrackingWork
}
