import { Type, Expose, Transform } from 'class-transformer'

import { Level } from './LevelType'
import { CalculationDetailType } from './CalculationDetailType'

export interface ATGReportPropsType {
  className?: string
}

export interface InformationPropsType {
  retrieveLastSave: () => void
}

export interface OperationPropsType extends Level {
  className: string
}

export interface ActionFormPropsType {
  className: string
  title: string
  value: string | null
  disabled: boolean
  hideAction: boolean
  isDateChange: boolean
  handleCalculation: (datetime: string | null) => void
  setDatetime: (datetime: string, type: string) => void
}

export class ATGReport {
  capacity: number

  @Type(() => Level)
  level: Level

  @Type(() => CalculationDetailType)
  reports: CalculationDetailType[]

  @Expose({ name: 'start_date' }) startDatetime: string

  @Expose({ name: 'start_state' }) startState: boolean

  @Expose({ name: 'stop_lessline_date' }) stopLesslineDatetime: string

  @Expose({ name: 'stop_lessline_state' }) stopLesslineState: true

  @Expose({ name: 'stop_date' }) stopDatetime: string

  @Expose({ name: 'stop_state' }) stopState: true

  @Expose({ name: 'summary_report' })
  @Type(() => SummaryReport)
  summaryReport: SummaryReport
}

type SummaryValueKey = 'volume15' | 'volume30' | 'tonInAir' | 'tonInVac'
export type SummaryValueType = Record<SummaryValueKey, number>

export class SummaryReport {
  @Expose({ name: 'vessel_load_liters_15_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  vesselVolume15: string

  @Expose({ name: 'vessel_load_liters_30_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  vesselVolume30: string

  @Expose({ name: 'vessel_load_ton_in_air' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  vesselTonInAir: string

  @Expose({ name: 'vessel_load_ton_in_vac' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  vesselTonInVac: string

  @Expose({ name: 'final_metric_tons_in_air' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  finalTonInAir: string

  @Expose({ name: 'final_metric_tons_in_vac' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  finalTonInVac: string

  @Expose({ name: 'final_volume_15_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  finalVolume15: string

  @Expose({ name: 'final_volume_30_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  finalVolume30: string

  @Expose({ name: 'less_line_metric_tons_in_air' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  lesslineTonInAir: string

  @Expose({ name: 'less_line_metric_tons_in_vac' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  lesslineTonInVac: string

  @Expose({ name: 'less_line_volume_15_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  lesslineVolume15: string

  @Expose({ name: 'less_line_volume_30_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  lesslineVolume30: string

  @Expose({ name: 'meter_metric_tons_in_air' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  meterTonInAir: string

  @Expose({ name: 'meter_metric_tons_in_vac' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  meterTonInVac: string

  @Expose({ name: 'meter_volume_15_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  meterVolume15: string

  @Expose({ name: 'meter_volume_30_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  meterVolume30: string

  @Expose({ name: 'total_metric_tons_in_air' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  totalTonInAir: string

  @Expose({ name: 'total_metric_tons_in_vac' })
  @Transform(({ value }) => (value || value === 0 ? value.toFixed(3) : ''))
  totalTonInVac: string

  @Expose({ name: 'total_volume_15_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  totalVolume15: string

  @Expose({ name: 'total_volume_30_c' })
  @Transform(({ value }) => (value || value === 0 ? String(value) : ''))
  totalVolume30: string
}
