import { Expose } from 'class-transformer'
import { DateTime } from 'luxon'

import { Option } from '@interfaces/common/SelectType'
import { ParameterType, QuanityType } from './DailyReportType'

export interface GetSummaryDailyReportProps {
  productCode: string | null
  tankCode: string | null
  startDate: string
  endDate: string
}

export interface SummaryDailyReportPageType {
  data: SummaryDailyReport[]
  loading: boolean
  selectedStartDate: string | null
  selectedEndDate: string | null
  selectedProductCode: string
  selectedTankCode: string | null
  tankOptions: Option[]
  handleTankChange: (value: string) => void
  handleStartDateChange: (value: DateTime | null) => void
  handleEndDateChange: (value: DateTime | null) => void
  handleSearch: () => void
  handleExportFile: () => void
}

export class SummaryDailyReport {
  @Expose()
  date: string

  @Expose()
  parameter: ParameterType

  @Expose()
  quantities: QuanityType
}
