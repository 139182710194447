import { AppBar, Toolbar, Snackbar } from '@material-ui/core'
import {
  CheckCircleOutline,
  WarningOutlined,
  InfoOutlined,
} from '@material-ui/icons'
import cx from 'classnames'

import { useAlert } from '@lib/useAlert'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useProfile } from '@features/profile/hooks/useProfile'
import { Loading } from '@components/Loading'
import { Menu } from './Menu'
import { UserInfo } from './UserInfo'
import styles from './index.module.scss'

export function AppLayout({ children }: FunctionComponentType) {
  const { open, message, type, handleClose } = useAlert()
  const { isLoading } = useProfile()

  return (
    <div className={styles.container}>
      <AppBar className={styles.appBar} position='static'>
        <Toolbar>
          <div className={styles.title}>MTT iReport Terminal Connect</div>
          <UserInfo className={styles.userInfo} />
        </Toolbar>
        <Menu />
      </AppBar>
      {isLoading ? <Loading /> : children}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={cx(styles[type], styles.alert)}>
          {type === 'success' && <CheckCircleOutline />}
          {type === 'info' && <InfoOutlined />}
          {type === 'error' && <WarningOutlined />}
          {message}
        </div>
      </Snackbar>
    </div>
  )
}
