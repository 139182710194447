import { useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { CompanyFormProps } from '@features/company/interfaces/CompanyType'
import { InputField } from '@components/fieldV2/InputField'
import { TextAreaField } from '@components/fieldV2/TextAreaField'
import { RadioGroupField } from '@components/fieldV2/RadioGroupField'
import { Loading } from '@components/Loading'

import styles from './index.module.scss'

const schema = yup.object().shape({
  code: yup.string().required('Company code is a required field'),
  name: yup.string().required('Company name is a required field'),
  description: yup.string().required('Description is a required field'),
  phoneNumber: yup.string().required('Phone number is a required field'),
  active: yup.string().required('Active is a required field'),
})

export function CompanyForm({
  initialValues,
  loading = false,
  onSubmit,
}: CompanyFormProps) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })

  const { isSubmitting, errors } = methods.formState

  useEffect(() => {
    methods.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  if (loading) return <Loading />

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <RadioGroupField
          name='active'
          label='Status'
          radioGroups={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
        />
        <InputField
          className='mt'
          name='code'
          label='Company Code'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          name='name'
          label='Company Name'
          disabled={isSubmitting}
        />
        <TextAreaField
          className='mt'
          name='description'
          label='Description'
          rows={6}
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          name='phoneNumber'
          label='Phone Number'
          disabled={isSubmitting}
        />
        <Button
          className='mt'
          variant='contained'
          color='primary'
          type='submit'
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={28} /> : 'Save'}
        </Button>
      </form>
    </FormProvider>
  )
}
