import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useUserList() {
  const client = useAppClient()
  const { data, isLoading } = useQuery('users', () =>
    client?.user.getUserList().then(response => response)
  )

  return {
    data,
    isLoading,
  }
}
