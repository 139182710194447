import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { FormProvider } from 'react-hook-form'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { ShipReceivedPropsType } from '@features/tracking-work/interfaces/ShipReceiveType'

export function withShipReceived(Component: React.FC<ShipReceivedPropsType>) {
  function WithShipReceived() {
    const {
      meterShipMethods,
      meterReport,
      isSubmitting,
      isMeterSubmitting,
      hideAction,
    } = useDeliveryReport()

    return (
      <FormProvider {...meterShipMethods}>
        <Component disabled={isSubmitting || isMeterSubmitting || hideAction} />
      </FormProvider>
    )
  }

  hoist(WithShipReceived, Component)

  WithShipReceived.displayName = `WithShipReceived(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithShipReceived
}
