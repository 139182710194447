import { Expose } from 'class-transformer'

export class Activity {
  @Expose() id: number

  @Expose({ name: 'activity_code' }) code: string

  @Expose({ name: 'activity_desc' }) description: string

  @Expose({ name: 'is_active' }) active: boolean
}
