export const PATH_NAME = {
  home: 'Home',
  'role-group': 'Role Group',
  company: 'Company',
  user: 'User',
  create: 'Create',
  edit: 'Edit',
  'inventory-daily-report': 'Inventory Daily Report',
  'summary-daily-report': 'Summary Daily Report',
  calculate: 'Calculate',
  'config-daily-report': 'Daily Report Config',
  'tracking-work': 'Tracking Work Request No. ',
  meter: 'Meter',
  'batch-document-tracking': 'Batch document tracking',
  draft: 'Draft',
}
