import { SentimentVeryDissatisfied } from '@material-ui/icons'
import { Container } from '@components/Container'

import styles from './index.module.scss'

export function Error404Page() {
  return (
    <Container>
      <div className={styles.container}>
        <SentimentVeryDissatisfied className={styles.icon} />
        <div>404</div>
        <div>Page not found</div>
      </div>
    </Container>
  )
}
