import { Page, View, Text } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import { CategoryGroup } from '@features/pdf/pages/MonthlyReport/components/CategoryGroup'
import { ProductMovementDetailProps } from '@features/pdf/interfaces/ProductMovementDetailType'
import styles from './styles'

interface RowProps {
  orderNo: string
  shipmentNo: string
  activity: string
  fromPlace: string
  fromTankNo: string
  toPlace: string
  toTankNo: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  column1: string
  column2: string
  remark: string
}

function Header({ type }: { type: string }) {
  return (
    <View
      style={[styles.row, styles.borderT, styles.borderL, styles.borderR]}
      fixed
    >
      <View
        style={[styles.col14, styles.row, styles.justifyCenter, styles.borderR]}
      >
        <Text>OrderNo</Text>
      </View>
      <View
        style={[styles.col8, styles.row, styles.justifyCenter, styles.borderR]}
      >
        <Text>ShipmentNo</Text>
      </View>
      <View
        style={[styles.col3, styles.row, styles.justifyCenter, styles.borderR]}
      >
        <Text>Cat</Text>
      </View>
      <View style={[styles.col10, styles.textCenter, styles.borderR]}>
        <Text style={styles.p}>From</Text>
        <View style={[styles.row, styles.borderT, styles.p]}>
          <Text style={{ flex: 1 }}>Place</Text>
          <Text style={{ flex: 1 }}>Tank No</Text>
        </View>
      </View>
      <View style={[styles.col10, styles.textCenter, styles.borderR]}>
        <Text style={styles.p}>To</Text>
        <View style={[styles.row, styles.borderT, styles.p]}>
          <Text style={{ flex: 1 }}>Place</Text>
          <Text style={{ flex: 1 }}>Tank No</Text>
        </View>
      </View>
      <View
        style={[
          styles.col12,
          styles.textCenter,
          styles.borderR,
          { display: 'flex', justifyContent: 'center' },
        ]}
      >
        <Text>Start</Text>
        <Text>Date / Time</Text>
      </View>
      <View
        style={[
          styles.col12,
          styles.textCenter,
          styles.borderR,
          { display: 'flex', justifyContent: 'center' },
        ]}
      >
        <Text>Finish</Text>
        <Text>Date / Time</Text>
      </View>
      <View
        style={[
          styles.col8,
          styles.textCenter,
          styles.borderR,
          { display: 'flex', justifyContent: 'center' },
        ]}
      >
        <Text>{type === 'LIQUID' ? 'Volume@30C' : 'Qty in Vac'}</Text>
        <Text>{type === 'LIQUID' ? 'Liters' : 'Tons'}</Text>
      </View>
      <View
        style={[
          styles.col8,
          styles.textCenter,
          styles.borderR,
          { display: 'flex', justifyContent: 'center' },
        ]}
      >
        <Text>Qty in Air</Text>
        <Text>Tons</Text>
      </View>
      <View style={[styles.col15, styles.row, styles.justifyCenter]}>
        <Text>Remark</Text>
      </View>
    </View>
  )
}

function Row({
  orderNo,
  shipmentNo,
  activity,
  fromPlace,
  fromTankNo,
  toPlace,
  toTankNo,
  startDate,
  startTime,
  endDate,
  endTime,
  column1,
  column2,
  remark,
}: RowProps) {
  return (
    <View style={[styles.row, styles.borderT]} wrap={false}>
      <View
        style={[
          styles.col14,
          styles.row,
          styles.justifyCenter,
          styles.borderR,
          styles.p,
        ]}
      >
        <Text>{orderNo}</Text>
      </View>
      <View
        style={[
          styles.col8,
          styles.row,
          styles.justifyCenter,
          styles.borderR,
          styles.p,
        ]}
      >
        <Text>{shipmentNo}</Text>
      </View>
      <View
        style={[
          styles.col3,
          styles.row,
          styles.justifyCenter,
          styles.borderR,
          styles.p,
        ]}
      >
        <Text>{activity}</Text>
      </View>
      <View style={[styles.col10, styles.row]}>
        <View
          style={[
            { flex: 1, height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{fromPlace}</Text>
        </View>
        <View
          style={[
            { flex: 1, height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{fromTankNo}</Text>
        </View>
      </View>
      <View style={[styles.col10, styles.row]}>
        <View
          style={[
            { flex: 1, height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{toPlace}</Text>
        </View>
        <View
          style={[
            { flex: 1, height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{toTankNo}</Text>
        </View>
      </View>
      <View style={[styles.row, styles.col12]}>
        <View
          style={[
            { width: '50%', height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{startDate}</Text>
        </View>
        <View
          style={[
            { width: '50%', height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{startTime}</Text>
        </View>
      </View>
      <View style={[styles.row, styles.col12]}>
        <View
          style={[
            { width: '50%', height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{endDate}</Text>
        </View>
        <View
          style={[
            { width: '50%', height: '100%' },
            styles.textCenter,
            styles.borderR,
            styles.p,
          ]}
        >
          <Text>{endTime}</Text>
        </View>
      </View>
      <View style={[styles.col8, styles.textRight, styles.borderR, styles.p]}>
        <Text>{column1}</Text>
      </View>
      <View style={[styles.col8, styles.textRight, styles.borderR, styles.p]}>
        <Text>{column2}</Text>
      </View>
      <View style={[styles.col15, styles.textLeft, styles.p]}>
        <Text>{remark}</Text>
      </View>
    </View>
  )
}

export function ProductMovementDetail({
  type,
  date,
  product,
  data,
}: ProductMovementDetailProps) {
  return (
    <Page size='A4' style={styles.page} orientation='landscape'>
      <View style={styles.headerGroup} fixed>
        <Text>Product Movement Detail as of {date}</Text>
        <Text>{product}</Text>
      </View>
      <Header type={type} />
      <View style={[styles.borderR, styles.borderB, styles.borderL]}>
        {data.map((row, index) => (
          <Row
            key={index}
            orderNo={row.orderNo}
            shipmentNo={row.shipmentNo}
            activity={row.activity}
            fromPlace={row.fromPlace}
            fromTankNo={row.fromTankNo}
            toPlace={row.toPlace}
            toTankNo={row.toTankNo}
            startDate={
              row.startDate
                ? DateTime.fromISO(row.startDate).toFormat('dd-LLL-yyyy')
                : '-'
            }
            startTime={row.startTime ?? '-'}
            endDate={
              row.endDate
                ? DateTime.fromISO(row.endDate).toFormat('dd-LLL-yyyy')
                : '-'
            }
            endTime={row.endTime ?? '-'}
            column1={row.column1}
            column2={row.column2}
            remark={row.remark}
          />
        ))}
      </View>
      <CategoryGroup />
    </Page>
  )
}
