import { COMPANY_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { withCompanyEditPage } from './withCompanyEditPage'
import { CompanyEditPage } from './CompanyEditPage'

const ConnectedCompanyEditPage = withPermissionGuard(
  withCompanyEditPage(CompanyEditPage),
  COMPANY_SETTING,
  'write'
)

export { ConnectedCompanyEditPage as CompanyEditPage }
