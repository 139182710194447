import { useFormContext } from 'react-hook-form'

import { InputField } from '@components/fieldV2/InputField'
import { MeterFormPropsType } from '@features/tracking-work/interfaces/MeterReportType'
import styles from '@features/tracking-work/pages/MeterMode/index.module.scss'

export function MeterForm({
  label,
  meterKey,
  disabled,
  stopLesslineState,
  stopState,
  handleOnChange,
}: MeterFormPropsType) {
  const { setValue } = useFormContext()

  function handleChange(name: string, value: string) {
    const decimalPoint = 3
    const number = value.split('.')
    handleOnChange(false)
    if (decimalPoint && number.length > 1)
      setValue(name, `${number[0]}.${number[1].slice(0, decimalPoint)}`)
    else setValue(name, number[0])
  }

  return (
    <>
      <div className={styles.meterReportCalculationLabel}>{label}</div>
      <InputField
        name={`start.${meterKey}`}
        disabled={disabled}
        handleChange={e => handleChange(`start.${meterKey}`, e.target.value)}
      />
      <InputField
        name={`stopLessline.${meterKey}`}
        disabled={disabled || !stopLesslineState}
        handleChange={e =>
          handleChange(`stopLessline.${meterKey}`, e.target.value)
        }
      />
      <InputField
        name={`stop.${meterKey}`}
        disabled={disabled || !stopState}
        handleChange={e => handleChange(`stop.${meterKey}`, e.target.value)}
      />
    </>
  )
}
