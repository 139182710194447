import cx from 'classnames'
import { Button } from '@material-ui/core'

import { PermissionGuard } from '@lib/permissions'
import { TRACKING_WORK } from '@lib/constants'
import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { ConfirmModal } from '@components/ConfirmModal'
import { AdminTrackingWorkType } from '@features/tracking-work/interfaces/TrackingWorkType'
import { TrackingWorkTable } from '@features/tracking-work/components/TrackingWorkTable'
import { AddWorkModal } from '@features/tracking-work/components/AddWorkModal'
import { ModifyWorkModal } from '@features/tracking-work/components/ModifyWorkModal'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'

import { AdminSearch } from './AdminSearch'
import { RejectSearch } from './RejectSearch'

export function AdminTrackingWork({
  trackingWorkList,
  rejectTrackingWorkList,
  productOptions,
  yearOptions,
  isTrackingWorkListLoading,
  isRejectTrackingWorkListLoading,
  modal,
  payload,
  trackingWorkColumns,
  rejectTrackingWorkColumns,
  modifyWork,
  isLoadingTrackingWork,
  trackingWorkParam,
  rejectParam,
  isSubmitting,
  handleSearch,
  handleRejectSearch,
  handleReject,
  handleRecover,
  handleCloseModal,
  handleOpenAddWorkModal,
  handleAddWork,
  handleModifyWork,
  handlePaginationChange,
  handleRejectPaginationChange,
  handleReviseWork,
  handleCopyWork,
}: AdminTrackingWorkType) {
  return (
    <Container>
      <Breadcrumbs />

      <AdminSearch
        className='mb'
        initialValues={trackingWorkParam}
        yearOptions={yearOptions}
        handleSubmit={handleSearch}
      />
      <PermissionGuard page={TRACKING_WORK} mode='write'>
        <Button
          className='mb'
          variant='contained'
          color='secondary'
          onClick={handleOpenAddWorkModal}
        >
          Add Work
        </Button>
      </PermissionGuard>
      <TrackingWorkTable
        data={trackingWorkList?.items || []}
        columns={trackingWorkColumns}
        loading={isTrackingWorkListLoading}
        page={trackingWorkParam.page}
        count={trackingWorkParam.count}
        onPaginationChange={handlePaginationChange}
      />

      <RejectSearch
        className={cx('mb', styles.rejectSearch)}
        yearOptions={yearOptions}
        initialValues={rejectParam}
        handleSubmit={handleRejectSearch}
      />
      <TrackingWorkTable
        data={rejectTrackingWorkList?.items || []}
        columns={rejectTrackingWorkColumns}
        loading={isRejectTrackingWorkListLoading}
        page={rejectParam.page}
        count={rejectParam.count}
        onPaginationChange={handleRejectPaginationChange}
      />
      <ConfirmModal
        open={modal === 'reject'}
        handleConfirm={handleReject}
        handleClose={handleCloseModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to reject{' '}
            <span>{payload?.workRequestID}</span> ?
          </strong>
        </div>
      </ConfirmModal>
      <ConfirmModal
        open={modal === 'recover'}
        handleConfirm={handleRecover}
        handleClose={handleCloseModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to recover{' '}
            <span>{payload?.workRequestID}</span> ?
          </strong>
        </div>
      </ConfirmModal>
      <AddWorkModal
        open={modal === 'add-work'}
        productOptions={productOptions}
        initialValues={{
          workRequestCompany: '',
          workRequestYear: '',
          product: '',
          activity: '',
          fromCompany: '',
          toCompany: '',
          fromTanks: [],
          toTanks: [],
        }}
        handleSubmit={handleAddWork}
        handleClose={handleCloseModal}
      />

      <ModifyWorkModal
        isLoading={isLoadingTrackingWork}
        open={modal === 'modify-work'}
        productOptions={productOptions}
        initialValues={modifyWork}
        handleSubmit={handleModifyWork}
        handleClose={handleCloseModal}
      />

      <ConfirmModal
        open={modal === 'copy-work'}
        isSubmitting={isSubmitting}
        handleConfirm={handleCopyWork}
        handleClose={handleCloseModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to copy <span>{payload?.workRequestID}</span>{' '}
            ?
          </strong>
        </div>
      </ConfirmModal>

      <ConfirmModal
        open={modal === 'revise-work'}
        isSubmitting={isSubmitting}
        handleConfirm={handleReviseWork}
        handleClose={handleCloseModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to revise{' '}
            <span>{payload?.workRequestID}</span> ?
          </strong>
        </div>
      </ConfirmModal>
    </Container>
  )
}
