import hoist from 'hoist-non-react-statics'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

export function withDashboardPage(Component: React.FC<FunctionComponentType>) {
  function WithDashboardPage(props: FunctionComponentType) {
    return <Component {...props} />
  }

  hoist(WithDashboardPage, Component)

  WithDashboardPage.displayName = `WithDashboardPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithDashboardPage
}
