import React, { useMemo } from 'react'
import hoist from 'hoist-non-react-statics'
import { Navigate } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useProfile } from '@features/profile/hooks/useProfile'

type ModeType = 'read' | 'write'

export function isAdmin(role: string) {
  return role === 'operation_admin'
}

export function isBoardman(role: string) {
  return role === 'boardman_admin'
}

export function usePermissionGuard(page: string, mode: ModeType) {
  const { data } = useProfile()

  return useMemo(() => {
    return (
      data?.roleGroup?.screens.some(row => {
        const hasPermission = row[mode]

        return row.screen.name.replace(/[^\w]/g, ' ') === page && hasPermission
      }) || false
    )
  }, [data, page, mode])
}

export const PermissionGuard = React.memo(
  ({ children, page, mode, ...props }: any) => {
    const hasPermission = usePermissionGuard(page, mode)

    if (!hasPermission) return null
    return React.cloneElement(children, props)
  }
)

export function withPermissionGuard(
  Component: React.FC<FunctionComponentType>,
  page: string,
  mode: ModeType
) {
  function WithPermissionGuard({ ...props }) {
    const hasPermission = usePermissionGuard(page, mode)

    if (!hasPermission) {
      return <Navigate to='/' />
    }

    return <Component {...props} />
  }

  hoist(WithPermissionGuard, Component)

  WithPermissionGuard.displayName = `withPermissionGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithPermissionGuard
}
