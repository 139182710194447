import { Column } from 'react-table'
import { Expose } from 'class-transformer'

import { CompanyType } from '@features/company/interfaces/CompanyType'
import { RoleGroupType } from '@features/role-group/interfaces/RoleGroupType'
import { Option } from '@interfaces/common/SelectType'

export class UserType {
  @Expose() id: number

  @Expose({ name: 'is_active' }) active: boolean

  @Expose({ name: 'first_name' }) firstName: string

  @Expose({ name: 'last_name' }) lastName: string

  @Expose() username: string

  @Expose() company: CompanyType

  @Expose({ name: 'role_group' }) roleGroup: RoleGroupType

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

export interface UserFormType {
  id?: string
  companyId: string | undefined
  roleGroupId: string | undefined
  firstName: string
  lastName: string
  username: string
  password: string
  confirmPassword: string
  active: string
}

export interface UserFormProps {
  initialValues: UserFormType
  loading?: boolean
  handleSubmit: (form: UserFormType) => Promise<void>
}

export interface ComponentProps extends UserFormProps {
  companyOptions: Option[]
  roleGroupOptions: Option[]
}

export interface UserPageType {
  data: UserType[]
  columns: Column[]
  loading: boolean
  handleCreate: () => void
}

export interface UserCreatePageType {
  initialValues: UserFormType
  handleSubmit: (form: UserFormType) => Promise<void>
}
