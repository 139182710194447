import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

interface UseCompanyProps {
  id: string
}

export function useCompany({ id }: UseCompanyProps) {
  const client = useAppClient()
  const { data, isLoading } = useQuery(['companies', id], () =>
    client?.company.getCompany({ id }).then(response => response)
  )

  return {
    data,
    isLoading,
  }
}
