import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTankList } from '@features/tank/hooks/useTankList'
import { Tank } from '@features/tank/interfaces/TankType'
import {
  ConfigDailyReportForm,
  ConfigDailyReportPageType,
} from '@features/config-daily-report/interfaces/ConfigDailyReportType'
import { useCompany } from '@features/company/hooks/useCompany'
import { useCompanyList } from '@features/company/hooks/useCompanyList'
import { useReportHeaderList } from '@features/report-header/hooks/useReportHeaderList'
import { ReportHeader } from '@features/report-header/interfaces/ReportHeaderType'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

const validationSchema = yup.object().shape({
  company: yup.object().required(),
})

export function withConfigDailyReportPage(
  Component: React.FC<ConfigDailyReportPageType>
) {
  function WithConfigDailyReportPage() {
    const { success, error } = useAlert()
    const client = useAppClient()
    const {
      values,
      isSubmitting,
      handleSubmit,
      setFieldValue,
      setValues,
    } = useFormik({
      initialValues: {
        company: undefined,
        tanks: [],
        reportHeaders: [],
      } as ConfigDailyReportForm,
      onSubmit: async form => {
        let isError = false
        await client?.report.editConfigDailyReport(form).catch(e => {
          isError = true

          error({ message: getErrorMessage(e) })
        })

        if (!isError) success({ message: 'update success' })
      },
      validationSchema,
    })
    const { data: tanks, isLoading: loadingTanks } = useTankList()
    const {
      parameters,
      quantity,
      isLoading: loadingReportHeaders,
    } = useReportHeaderList()
    const { data: companyList } = useCompanyList()
    const { data: company } = useCompany({ id: String(values?.company?.id) })

    const companyOptions = companyList.map(row => ({
      text: row.code,
      value: row.id,
    }))

    function handleCompanyChange(id: string) {
      setFieldValue(
        'company',
        companyList.find(row => row.id === +id)
      )
    }

    function handleSelectTank(data: Tank, checked: boolean) {
      if (checked) {
        setFieldValue('tanks', [...values.tanks, { id: data.id }])
      } else {
        setFieldValue(
          'tanks',
          values.tanks.filter(row => row.id !== data.id)
        )
      }
    }

    function handleSelectReportHeader(data: ReportHeader, checked: boolean) {
      if (checked) {
        setFieldValue('reportHeaders', [
          ...values.reportHeaders,
          { id: data.id },
        ])
      } else {
        setFieldValue(
          'reportHeaders',
          values.reportHeaders.filter(row => row.id !== data.id)
        )
      }
    }

    const pageProps = {
      formValues: values,
      companyOptions,
      tanks,
      parameters,
      quantity,
      loadingTanks,
      loadingReportHeaders,
      isSubmitting,
      handleCompanyChange,
      handleSelectTank,
      handleSelectReportHeader,
      onSubmit: handleSubmit,
    }

    useEffect(() => {
      setValues({
        ...values,
        tanks: company?.tanks.map(row => ({ id: row.id })) || [],
        reportHeaders:
          company?.reportHeaders.map(row => ({ id: row.id })) || [],
      })
    }, [company]) // eslint-disable-line react-hooks/exhaustive-deps

    return <Component {...pageProps} />
  }

  hoist(WithConfigDailyReportPage, Component)

  WithConfigDailyReportPage.displayName = `WithConfigDailyReportPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithConfigDailyReportPage
}
