import { Box } from '@features/tracking-work/components/Box'
import { InputField } from '@components/fieldV2/InputField'

import styles from '@features/tracking-work/pages/MeterMode/index.module.scss'
import { ShipReceivedPropsType } from '@features/tracking-work/interfaces/ShipReceiveType'

export function ShipReceived({ disabled }: ShipReceivedPropsType) {
  return (
    <Box title='Ship Received'>
      <div className={styles.boxContent}>
        <div className={styles.header}>
          <strong>Value (Fill in)</strong>
        </div>
        <div />

        <InputField
          type='number'
          className={styles.vesselInput}
          name='tonInAir'
          disabled={disabled}
        />
        <div>Tons in air</div>

        <InputField
          type='number'
          className={styles.vesselInput}
          name='tonInVac'
          disabled={disabled}
        />
        <div>Tons in vac</div>

        <InputField
          type='number'
          className={styles.vesselInput}
          name='volume15'
          disabled={disabled}
        />
        <div>Liters @ 15&#8451;</div>

        <InputField
          type='number'
          className={styles.vesselInput}
          name='volume30'
          disabled={disabled}
        />
        <div>Liters @ 30&#8451;</div>
      </div>
    </Box>
  )
}
