import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover, Button } from '@material-ui/core'
import { SupervisedUserCircle } from '@material-ui/icons'

import { useAuthentication } from '@features/authentication/hooks/useAuthentication'
import { useProfile } from '@features/profile/hooks/useProfile'

import styles from './index.module.scss'

interface UserInfoType {
  className: string
}

export function UserInfo({ className }: UserInfoType) {
  const navigate = useNavigate()
  const { signOut } = useAuthentication()
  const { data } = useProfile()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'setup-popover' : undefined

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function navigateTo(path: string) {
    navigate(path)
    handleClose()
  }

  return (
    <>
      <Button
        className={className}
        onClick={handleClick}
        color='inherit'
        endIcon={<SupervisedUserCircle />}
      >
        {data?.fullName}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ className: styles.paper }}
      >
        <div className={styles.subMenu}>
          <div className={styles.subMenuItem} onClick={() => signOut()}>
            Logout
          </div>
        </div>
      </Popover>
    </>
  )
}
