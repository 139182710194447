import { withPermissionGuard } from '@lib/permissions'
import { ROLE_GROUP_SETTING } from '@lib/constants'

import { RoleGroupPage } from './RoleGroupPage'
import { withRoleGroupPage } from './withRoleGroupPage'

const ConnectedRoleGroupPage = withPermissionGuard(
  withRoleGroupPage(RoleGroupPage),
  ROLE_GROUP_SETTING,
  'read'
)

export { ConnectedRoleGroupPage as RoleGroupPage }
