import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    padding: '10px 14px',
    fontFamily: 'Roboto',
    fontSize: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  borderL: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
  },
  borderR: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
  },
  borderB: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  borderT: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  headerGroup: {
    fontSize: 10,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  col15: {
    width: '15%',
    height: '100%',
  },
  col14: {
    width: '14%',
    height: '100%',
  },
  col12: {
    width: '12%',
    height: '100%',
  },
  col10: {
    width: '10%',
    height: '100%',
  },
  col8: {
    width: '8%',
    height: '100%',
  },
  col5: {
    width: '5%',
    height: '100%',
  },
  col3: {
    width: '3%',
    height: '100%',
  },
  p: {
    padding: 1,
  },
})
