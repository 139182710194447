import { withPermissionGuard } from '@lib/permissions'
import { COMPANY_SETTING } from '@lib/constants'

import { withCompanyCreatePage } from './withCompanyCreatePage'
import { CompanyCreatePage } from './CompanyCreatePage'

const ConnectedCompanyCreatePage = withPermissionGuard(
  withCompanyCreatePage(CompanyCreatePage),
  COMPANY_SETTING,
  'write'
)

export { ConnectedCompanyCreatePage as CompanyCreatePage }
