import { useRoutes, Navigate, Outlet } from 'react-router-dom'

import { AppLayout } from '@components/AppLayout'
import { AuthLayout } from '@features/authentication/components/AuthLayout'
import { authenticationRoutes } from '@features/authentication/authenticationRoutes'
import { dashboardRoutes } from '@features/dashboard/dashboardRoutes'
import { roleGroupRoutes } from '@features/role-group/roleGroupRoutes'
import { companyRoutes } from '@features/company/companyRoutes'
import { userRoutes } from '@features/user/userRoutes'
import { reportRoutes } from '@features/report/reportRoutes'
import { configDailyReportRoutes } from '@features/config-daily-report/configDailyReportRoutes'
import { trackingWorkRoutes } from '@features/tracking-work/trackingWorkRoutes'
import { batchDocumentTrackingRoutes } from '@features/batch-document-tracking/batchDocumentTrackingRoutes'
import { configDraftCalculationRoutes } from '@features/draft-calculation/configDraftCalculationRoutes'
import { monthlyReportRoutes } from '@features/monthly-report/monthlyReportRoutes'
import { Error404Page } from '@features/error/pages/Error404Page'

export function App() {
  const routes = useRoutes([
    {
      path: '',
      element: (
        <AppLayout>
          <Outlet />
        </AppLayout>
      ),
      children: [
        ...dashboardRoutes,
        ...roleGroupRoutes,
        ...companyRoutes,
        ...userRoutes,
        ...reportRoutes,
        ...configDailyReportRoutes,
        ...trackingWorkRoutes,
        ...batchDocumentTrackingRoutes,
        ...configDraftCalculationRoutes,
        ...monthlyReportRoutes,
      ],
    },
    {
      path: 'auth',
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [...authenticationRoutes],
    },
    {
      path: '*',
      element: (
        <AppLayout>
          <Error404Page />
        </AppLayout>
      ),
    },
  ])

  return routes
}
