import { USER_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { UserCreatePage } from './UserCreatePage'
import { withUserCreatePage } from './withUserCreatePage'

const ConnectedUserCreatePage = withPermissionGuard(
  withUserCreatePage(UserCreatePage),
  USER_SETTING,
  'write'
)
export { ConnectedUserCreatePage as UserCreatePage }
