import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover, Button } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import { PermissionGuard } from '@lib/permissions'
import { INVENTORY_DAILY_REPORT, SUMMARY_DAILY_REPORT } from '@lib/constants'

import styles from './index.module.scss'

export function ReportMenu() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'report-popover' : undefined

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function navigateTo(path: string) {
    navigate(path)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        Daily Report
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ className: styles.paper }}
      >
        <div className={styles.subMenu}>
          <PermissionGuard page={INVENTORY_DAILY_REPORT} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/inventory-daily-report')}
            >
              Inventory Daily Report
            </div>
          </PermissionGuard>
          <PermissionGuard page={SUMMARY_DAILY_REPORT} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/summary-daily-report')}
            >
              Summary Daily Report
            </div>
          </PermissionGuard>
        </div>
      </Popover>
    </>
  )
}
