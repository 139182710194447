import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import hoist from 'hoist-non-react-statics'
import { DateTime } from 'luxon'

import { SummaryDailyReportPageType } from '@features/report/interfaces/SummaryDailyReportType'
import { useSummaryDailyReportList } from '@features/report/hooks/useSummaryDailyReportList'
import { useTankProductList } from '@features/tank/hooks/useTankProductList'
import { API_URL } from '@lib/config'
import { useAppClient } from '@lib/useAppClient'

export function withSummaryDailyReportPage(
  Component: React.FC<SummaryDailyReportPageType>
) {
  function WithSummaryDailyReportPage() {
    const navigate = useNavigate()
    const client = useAppClient()
    const [searchParams] = useSearchParams()
    const startDate =
      searchParams.get('start') || DateTime.now().toFormat('yyyy-LL-dd')
    const endDate =
      searchParams.get('end') || DateTime.now().toFormat('yyyy-LL-dd')
    const tankCode = searchParams.get('tc')
    const productCode = searchParams.get('p')
    const [selectedTankCode, setSelectedTankCode] = useState<string | null>(
      tankCode
    )
    const [selectedProductCode, setSelectedProductCode] = useState<
      string | null
    >(productCode)
    const [selectedStartDate, setSelectedStartDate] = useState<string>(
      startDate
    )
    const [selectedEndDate, setSelectedEndDate] = useState<string>(endDate)
    const { data: tankList } = useTankProductList()
    const { data, isLoading } = useSummaryDailyReportList({
      tankCode,
      productCode,
      startDate,
      endDate,
    })

    const tankOptions = tankList.map(tank => ({
      text: tank.tankCode,
      value: tank.tankCode,
    }))

    function handleTankChange(value: string) {
      setSelectedTankCode(value)
      setSelectedProductCode(
        tankList.find(row => row.tankCode === value)?.productCode || null
      )
    }

    function handleStartDateChange(value: DateTime | null) {
      if (value) setSelectedStartDate(value.toFormat('yyyy-LL-dd'))
    }

    function handleEndDateChange(value: DateTime | null) {
      if (value) setSelectedEndDate(value.toFormat('yyyy-LL-dd'))
    }

    async function handleExportFile() {
      window.open(
        `${API_URL}/api/reports/summary_daily_reports/export?product_code=${productCode}&tank_code=${tankCode}&start_date=${startDate}&end_date=${endDate}&token=${client?.auth.accessToken}`
      )
    }

    function handleSearch() {
      const queries = []

      if (selectedTankCode) queries.push(`tc=${selectedTankCode}`)
      if (selectedProductCode) queries.push(`p=${selectedProductCode}`)
      if (selectedStartDate) queries.push(`start=${selectedStartDate}`)
      if (selectedEndDate) queries.push(`end=${selectedEndDate}`)

      navigate({
        pathname: '/summary-daily-report',
        search: `?${queries.join('&')}`,
      })
    }

    const pageProps = {
      data,
      loading: isLoading,
      selectedStartDate,
      selectedEndDate,
      selectedProductCode: selectedProductCode || '-',
      selectedTankCode,
      tankOptions,
      handleTankChange,
      handleStartDateChange,
      handleEndDateChange,
      handleSearch,
      handleExportFile,
    }

    return <Component {...pageProps} />
  }

  hoist(WithSummaryDailyReportPage, Component)

  WithSummaryDailyReportPage.displayName = `WithSummaryDailyReportPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithSummaryDailyReportPage
}
