import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { UserSearchForm } from '@features/monthly-report/components/UserSearchForm'
import { UserPageProps } from '@features/monthly-report/interfaces/UserType'

export function UserPage({ columns, data, isLoading }: UserPageProps) {
  return (
    <Container>
      <UserSearchForm />

      <Table columns={columns} data={data} loading={isLoading} />
    </Container>
  )
}
