import { useNavigate, Link } from 'react-router-dom'
import hoist from 'hoist-non-react-statics'
import cx from 'classnames'

import { UserType, UserPageType } from '@features/user/interfaces/UserType'
import { useUserList } from '@features/user/hooks/useUserList'
import { usePermissionGuard } from '@lib/permissions'
import { USER_SETTING } from '@lib/constants'

import styles from './index.module.scss'

interface CellType {
  record: UserType
}

const initialColumns = [
  {
    accessor: 'company',
    Header: 'Company Code',
    Cell: ({ record }: CellType) => record?.company?.code || '',
  },
  {
    accessor: 'roleGroup',
    Header: 'Role Group Code',
    Cell: ({ record }: CellType) => record?.roleGroup?.code || '',
  },
  {
    accessor: 'username',
    Header: 'Username',
    Cell: ({ record }: CellType) => record.username,
  },
  {
    accessor: 'fullName',
    Header: 'Full Name',
    Cell: ({ record }: CellType) => record.fullName,
  },
  {
    accessor: 'active',
    Header: 'Active Status',
    Cell: ({ record }: CellType) => (
      <div
        className={cx(styles.status, {
          [styles.active]: record.active,
        })}
      >
        {record.active ? 'Active' : 'Inactive'}
      </div>
    ),
  },
]

export function withUserPage(Component: React.FC<UserPageType>) {
  function WithUserPage() {
    const navigate = useNavigate()
    const { data: userList, isLoading } = useUserList()
    const editable = usePermissionGuard(USER_SETTING, 'write')

    const columns = editable
      ? [
          ...initialColumns,
          {
            accessor: 'action',
            Header: '',
            Cell: ({ record }: CellType) => (
              <Link to={`/user/${record.id}/edit`}>Edit</Link>
            ),
          },
        ]
      : initialColumns

    function handleCreate() {
      navigate('/user/create')
    }

    const pageProps = {
      columns,
      data: userList || [],
      loading: isLoading,
      handleCreate,
    }

    return <Component {...pageProps} />
  }

  hoist(WithUserPage, Component)

  WithUserPage.displayName = `WithUserPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUserPage
}
