import hoist from 'hoist-non-react-statics'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { ATGModeProps } from '@features/tracking-work/interfaces/ATGModeType'

export function withATGMode(Component: React.FC<ATGModeProps>) {
  function WithATGMode() {
    const { data } = useDeliveryReport()

    const pageProps = {
      isBlankForm: data.isBlankForm,
    }

    return <Component {...pageProps} />
  }

  hoist(WithATGMode, Component)

  WithATGMode.displayName = `WithATGMode(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithATGMode
}
