import { memo } from 'react'
import cx from 'classnames'

import { InputField } from '@components/fieldV2/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { VesselLoadType } from '@features/tracking-work/interfaces/MeterReportType'

import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

export const VesselLoad = memo(
  ({ summary, hasLessline, disabled }: VesselLoadType) => {
    return (
      <Box
        className={cx({ [styles.disabled]: !hasLessline })}
        title='Vessel Load / Dis. Quantities (Less-Line Mode)'
      >
        <div className={styles.vesselLoadForm}>
          <div className='text-center'>
            <strong>Value (Fill in)</strong>
          </div>
          <div className='text-center'>
            <strong>Difference</strong>
          </div>

          <InputField type='number' name='tonInAir' disabled={disabled} />
          <div className={styles.difference}>
            <div className='text-center'>{summary.difference.tonInAir}</div>
            <div>Tons in air</div>
          </div>

          <InputField type='number' name='tonInVac' disabled={disabled} />
          <div className={styles.difference}>
            <div className='text-center'>{summary.difference.tonInVac}</div>
            <div>Tons in vac</div>
          </div>

          <InputField type='number' name='volume15' disabled={disabled} />
          <div className={styles.difference}>
            <div className='text-center'>{summary.difference.volume15}</div>
            <div>Liters @ 15&#8451;</div>
          </div>

          <InputField type='number' name='volume30' disabled={disabled} />
          <div className={styles.difference}>
            <div className='text-center'>{summary.difference.volume30}</div>
            <div>Liters @ 30&#8451;</div>
          </div>
        </div>
      </Box>
    )
  }
)
