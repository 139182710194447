import { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { UseFormReturn } from 'react-hook-form'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useProfile } from '@features/profile/hooks/useProfile'
import { DeliveryReport } from '@features/tracking-work/interfaces/DeliveryReportType'
import {
  CalculationDetailType,
  CalculationFormType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { Level } from '@features/tracking-work/interfaces/LevelType'
import { ConfigDeliveryReport } from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import { VesselLoadForm } from '@features/tracking-work/interfaces/VesselLoadType'
import { ManualDipForm } from '@features/tracking-work/interfaces/ManualDipType'
import { useAppClient } from '@lib/useAppClient'
import { isAdmin, isBoardman } from '@lib/permissions'
import {
  CalculateMode,
  CalculateType,
} from '@lib/clients/collections/ATGReportClient'

import { useDraftATGReport } from './useDraftATGReport'

interface DeliverReportResponse {
  data: DeliveryReport
  isLoading: boolean
  selectedTankCode: string
  readOnly: boolean
  hideAction: boolean
  onTankChange: (value: string) => void

  isAtgLoading: boolean
  atgLevel?: Level
  atgReports: CalculationDetailType[] | []
  disableAtgStopLesslineForm: boolean
  disableAtgStopForm: boolean
  isAtgSubmitting: boolean
  atgStartDatetime: string | null
  atgStopLesslineDatetime: string | null
  atgStopDatetime: string | null
  atgCapacity: number
  atgConfig?: ConfigDeliveryReport
  loadingAtgConfig: boolean
  atgPurity: string
  atgManualDipMethods: UseFormReturn<ManualDipForm>
  atgMethods: UseFormReturn<CalculationFormType>
  atgVesselLoadMethods: UseFormReturn<VesselLoadForm>
  isStartDateChange: boolean
  isStopDateChange: boolean
  isLesslineDateChange: boolean
  canSaveStart: boolean
  canSaveStop: boolean
  canSaveLessline: boolean
  modal: string
  handleAtgCalculationStart: (datetime: string | null) => void
  handleAtgCalculationLessLine: (datetime: string | null) => void
  handleAtgCalculationStop: (datetime: string | null) => void
  handleAtgSaveStart: (form: CalculationFormType) => void
  handleAtgSaveLessLine: (form: CalculationFormType) => void
  handleAtgSaveStop: (form: CalculationFormType) => void
  handleAtgStartDatetimeChange: (datetime: string, type: string) => void
  handleAtgStopLesslineDatetimeChange: (datetime: string, type: string) => void
  handleAtgStopDatetimeChange: (datetime: string, type: string) => void
  handleAtgManualDipStartCal: () => void
  handleAtgManualDipStopLesslineCal: () => void
  handleAtgManualDipStopCal: () => void
  setAtgPurity: (value: string) => void
  handleManualFormChange: (type: CalculateType, mode: CalculateMode) => void
  handleConfirmSaveStart: () => Promise<void>
  handleConfirmSaveLessLine: () => Promise<void>
  handleConfirmSaveStop: () => Promise<void>
  closeModal: () => void
}

interface DraftDeliveryReportProviderType extends FunctionComponentType {
  id: string
}

const DeliveryReportContext = createContext<any>({})

export function DraftDeliveryReportProvider({
  children,
  id,
}: DraftDeliveryReportProviderType) {
  const client = useAppClient()
  const queryKey = ['delivery-report', id]
  const [selectedTankCode, setSelectedTankCode] = useState('')
  const { data: profile } = useProfile()
  const { data, isLoading } = useQuery(queryKey, () =>
    client?.draft.getDeliveryReport({ id }).then(res => {
      if (!selectedTankCode)
        setSelectedTankCode(() => (res.tanks.length ? res.tanks[0].code : ''))
      return res
    })
  )

  const isWaitSubmitWork = data?.verifyStatus === 'Wait Submit Work'
  const isApprove = data?.verifyStatus === 'Approve'
  const roleBoardman = isBoardman(profile?.roleGroup.reportRole || '')
  const roleAdmin = isAdmin(profile?.roleGroup.reportRole || '')

  const hideAction =
    (roleBoardman && !isWaitSubmitWork) || (roleAdmin && isApprove)
  const readOnly = roleBoardman || isApprove

  const {
    isLoading: isAtgLoading,
    level: atgLevel,
    reports: atgReports,
    disableStopLesslineForm: disableAtgStopLesslineForm,
    disableStopForm: disableAtgStopForm,
    isSubmitting: isAtgSubmitting,
    startDatetime: atgStartDatetime,
    stopLesslineDatetime: atgStopLesslineDatetime,
    stopDatetime: atgStopDatetime,
    capacity: atgCapacity,
    config: atgConfig,
    loadingConfig: loadingAtgConfig,
    purity: atgPurity,
    manualDipMethods: atgManualDipMethods,
    methods: atgMethods,
    vesselLoadMethods: atgVesselLoadMethods,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    modal,
    handleCalculationStart: handleAtgCalculationStart,
    handleCalculationLessLine: handleAtgCalculationLessLine,
    handleCalculationStop: handleAtgCalculationStop,
    handleSaveStart: handleAtgSaveStart,
    handleSaveLessLine: handleAtgSaveLessLine,
    handleSaveStop: handleAtgSaveStop,
    handleStartDatetimeChange: handleAtgStartDatetimeChange,
    handleStopLesslineDatetimeChange: handleAtgStopLesslineDatetimeChange,
    handleStopDatetimeChange: handleAtgStopDatetimeChange,
    handleManualDipStartCal: handleAtgManualDipStartCal,
    handleManualDipStopLesslineCal: handleAtgManualDipStopLesslineCal,
    handleManualDipStopCal: handleAtgManualDipStopCal,
    setPurity: setAtgPurity,
    handleManualFormChange,
    handleConfirmSaveStart,
    handleConfirmSaveLessLine,
    handleConfirmSaveStop,
    closeModal,
  } = useDraftATGReport({ deliveryId: id, tankCode: selectedTankCode })

  const value = {
    data,
    isLoading,
    selectedTankCode,
    readOnly,
    hideAction,
    onTankChange: setSelectedTankCode,

    isAtgLoading,
    atgLevel,
    atgReports,
    disableAtgStopLesslineForm,
    disableAtgStopForm,
    isAtgSubmitting,
    atgStartDatetime,
    atgStopLesslineDatetime,
    atgStopDatetime,
    atgCapacity,
    atgConfig,
    loadingAtgConfig,
    atgPurity,
    atgManualDipMethods,
    atgMethods,
    atgVesselLoadMethods,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    modal,
    handleAtgCalculationStart,
    handleAtgCalculationLessLine,
    handleAtgCalculationStop,
    handleAtgSaveStart,
    handleAtgSaveLessLine,
    handleAtgSaveStop,
    handleAtgStartDatetimeChange,
    handleAtgStopLesslineDatetimeChange,
    handleAtgStopDatetimeChange,
    handleAtgManualDipStartCal,
    handleAtgManualDipStopLesslineCal,
    handleAtgManualDipStopCal,
    setAtgPurity,
    handleManualFormChange,
    handleConfirmSaveStart,
    handleConfirmSaveLessLine,
    handleConfirmSaveStop,
    closeModal,
  }

  return (
    <DeliveryReportContext.Provider value={value}>
      {children}
    </DeliveryReportContext.Provider>
  )
}

export function useDraftDeliveryReport(): DeliverReportResponse {
  return useContext(DeliveryReportContext)
}
