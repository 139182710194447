import { View, Text } from '@react-pdf/renderer'

import styles from '@features/pdf/pages/MonthlyReport/styles'

export function Note() {
  return (
    <View style={styles.mt}>
      <Text style={styles.strong}>Note:</Text>
      <View>
        <Text>* Actual stock measure at 0:00 hr at the end of month</Text>
      </View>
    </View>
  )
}
