import hoist from 'hoist-non-react-statics'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { WaitSubmitWorkPageProps } from '@features/tracking-work/interfaces/WaitSubmitWorkType'
import { useModal } from '@features/tracking-work/hooks/useModal'

export function withWaitSubmitWorkPage(
  Component: React.FC<WaitSubmitWorkPageProps>
) {
  function WithWaitSubmitWorkPage() {
    const {
      data,
      isLoading,
      atgCapacity,
      hideAction,
      isSubmitting,
      checkSaveStart,
      checkSaveStop,
      checkSaveLessline,
      handleSaveBlankForm,
      handleAtgSaveChecklist,
      handleMeterSaveChecklist,
      submitWork,
      canSubmit,
      handleSaveAll,
    } = useDeliveryReport()

    const { modal, closeModal, openModal } = useModal()

    function openConfirmSubmitWorkModal() {
      openModal({
        modalName: 'submit-work',
      })
    }

    function handleSubmitWork() {
      submitWork()
      closeModal()
    }

    function handleSaveChecklist() {
      if (data.isBlankForm) handleSaveBlankForm()
      else handleAtgSaveChecklist()
    }

    async function saveAll(meterMode: boolean){
      handleSaveAll(meterMode)
    }

    const pageProps = {
      data,
      modal,
      loading: isLoading,
      capacity: atgCapacity,
      hideAction,
      isSubmitting,
      checkSaveStart,
      checkSaveStop,
      checkSaveLessline,
      handleSaveAtg: handleSaveChecklist,
      handleSaveMeter: handleMeterSaveChecklist,
      openConfirmSubmitWorkModal,
      handleSubmitWork,
      closeModal,
      canSubmit,
      handleSaveAll: saveAll,
    }

    return <Component {...pageProps} />
  }

  hoist(WithWaitSubmitWorkPage, Component)

  WithWaitSubmitWorkPage.displayName = `WithWaitSubmitWorkPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithWaitSubmitWorkPage
}
