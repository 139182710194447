import { memo, useEffect } from 'react'

import { FormProvider } from 'react-hook-form'

import {
  CalculationDetailType,
  CalculationFormPropsType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

import { CalculationDetailAction } from './CalculationDetailAction'
import { CalculationDetailInput } from './CalculationDetailInput'

export const CalculationForm = memo(
  ({
    reports,
    disableStopLesslineForm,
    disableStopForm,
    isSubmitting,
    readOnly,
    hideAction,
  }: CalculationFormPropsType) => {
    const {
      data,
      atgMethods,
      selectedTankCode,
      modal,
      handleAtgSaveStart,
      handleAtgSaveLessLine,
      handleAtgSaveStop,
      handleConfirmSaveStart,
      handleConfirmSaveLessLine,
      handleConfirmSaveStop,
      closeModal,
    } = useDraftDeliveryReport()

    useEffect(() => {
      atgMethods.reset({ list: reports })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports])

    return (
      <FormProvider {...atgMethods}>
        <form>
          {reports.map((row: CalculationDetailType, index: number) => (
            <CalculationDetailInput
              key={index}
              data={row}
              index={index}
              disableStopLesslineForm={disableStopLesslineForm}
              disableStopForm={disableStopForm}
              readOnly={readOnly}
            />
          ))}

          <div className={styles.atgReportCalculationDetailLabel} />
          <CalculationDetailAction
            disabled={isSubmitting}
            title='Save Start (ATG)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='start'
            mode='normal'
            modal={modal}
            handleSave={() => handleAtgSaveStart(atgMethods.getValues())}
            handleConfirm={handleConfirmSaveStart}
            closeModal={closeModal}
          />
          <CalculationDetailAction
            disabled={disableStopLesslineForm || isSubmitting}
            title='Save Stop (Less-Line)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='stop'
            mode='less_line'
            modal={modal}
            handleSave={() => handleAtgSaveLessLine(atgMethods.getValues())}
            handleConfirm={handleConfirmSaveLessLine}
            closeModal={closeModal}
          />
          <CalculationDetailAction
            disabled={disableStopForm || isSubmitting}
            title='Save Stop (ATG)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='stop'
            mode='normal'
            modal={modal}
            handleSave={() => handleAtgSaveStop(atgMethods.getValues())}
            handleConfirm={handleConfirmSaveStop}
            closeModal={closeModal}
          />
        </form>
      </FormProvider>
    )
  }
)
