import { Button } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Link, useParams } from 'react-router-dom'
import cx from 'classnames'

import { CheckListType } from '@features/tracking-work/interfaces/CheckListType'
import { Box } from '@features/tracking-work/components/Box'
import styles from './index.module.scss'

interface CheckListPropsType {
  className: string
  mode: 'ATG' | 'Meter'
  checkList: CheckListType[]
  active: boolean
  disabled?: boolean
  hideAction: boolean
  handleSaveChecklist: () => void
}

export function CheckList({
  className,
  mode,
  checkList,
  active,
  disabled,
  hideAction,
  handleSaveChecklist,
}: CheckListPropsType) {
  const { id } = useParams()

  const to =
    mode === 'ATG'
      ? `/tracking-work/${id}/calculate`
      : `/tracking-work/${id}/calculate/meter`

  return (
    <Box className={className} title={`Check list (${mode} Mode)`}>
      <div className={cx(styles.actionGroup, { [styles.disabled]: disabled })}>
        <Link to={to}>
          <Button variant='contained' color='primary' disabled={disabled}>
            {mode} Mode
          </Button>
        </Link>

        {!hideAction && (
          <Button
            variant='contained'
            className={styles.disabledBtn}
            onClick={handleSaveChecklist}
            disabled={active || disabled}
          >
            Save
          </Button>
        )}
      </div>

      <div className={styles.checkList}>
        {checkList.map(row => (
          <div className={styles.item} key={row.id}>
            <div className={styles.name}>{row.name} :</div>
            <div className={styles.status}>
              <label>{row.necessity ? 'Mandatory' : 'Optional'}</label>
              {row.active && <Check />}
            </div>
          </div>
        ))}
      </div>
    </Box>
  )
}
