import { useWatch } from 'react-hook-form'
import { Button } from '@material-ui/core'
import cx from 'classnames'

import { Loading } from '@components/Loading'
import { Box } from '@features/tracking-work/components/Box'
import { ActionForm } from '@features/tracking-work/components/ActionForm'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { PurityCorrection } from '@features/tracking-work/pages/ATGMode/PurityCorrection'
import { GoalSeek } from '@features/tracking-work/pages/ATGMode/GoalSeek'
import { ProrateDensity } from '@features/tracking-work/pages/ATGMode/ProrateDensity'
import { ProrateMW } from '@features/tracking-work/pages/ATGMode/ProrateMW'
import { VesselLoad } from '@features/tracking-work/pages/ATGMode/VesselLoad'
import { ManualDip } from '@features/tracking-work/pages/ATGMode/ManualDip'
import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

import { Operation } from './Operation'
import { CalculationForm } from './CalculationForm'
import { Summary } from './Summary'
import { Note } from './Note'

export function ATGReport() {
  const {
    isAtgLoading,
    atgLevel,
    atgStartDatetime,
    atgStopDatetime,
    atgStopLesslineDatetime,
    atgReports,
    disableAtgStopLesslineForm,
    disableAtgStopForm,
    isAtgSubmitting,
    atgConfig,
    atgMethods,
    meterSummary,
    atgVesselLoadMethods,
    isSubmitting,
    readOnly,
    hideAction,
    data,
    loadingAtgConfig,
    summaryMethods,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    handleAtgCalculationStart,
    handleAtgCalculationLessLine,
    handleAtgCalculationStop,
    handleAtgStartDatetimeChange,
    handleAtgStopLesslineDatetimeChange,
    handleAtgStopDatetimeChange,
    atgRetrieveLastSave,
  } = useDeliveryReport()

  const reports = useWatch({
    name: 'list',
    control: atgMethods.control,
  })
  const vesselLoads = useWatch({
    name: ['volume30', 'volume15', 'tonInAir', 'tonInVac'],
    control: atgVesselLoadMethods.control,
  })

  const hasMeter = !!data?.meterChecklist.length
  const hasVesselLoad = !!atgConfig?.vesselLoad?.state

  return (
    <div className={cx('mt', styles.container)}>
      {loadingAtgConfig || isAtgLoading ? (
        <Loading />
      ) : (
        <>       
          <Operation
              className={styles.reportOperation}
              pl={atgLevel?.pl || 0}
              ph={atgLevel?.ph || 0}
              hh={atgLevel?.hh || 0}
              ll={atgLevel?.ll || 0}
              beforeStop={atgLevel?.beforeStop || 0}
              beforeStopFiveMinutes={atgLevel?.beforeStopFiveMinutes || 0}
            />   
          <ManualDip className={styles.manualDip} />
        </>
      )}
      <Box className={styles.atgReport} title='ATG Mode Report'>
        {loadingAtgConfig || isAtgLoading ? (
          <Loading />
        ) : (
          <div className={styles.atgReportContent}>
            {!hideAction && (
              <div className={styles.retrieveBtn}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={atgRetrieveLastSave}
                  disabled={isAtgSubmitting || isSubmitting}
                >
                  Retrieve Last Save
                </Button>
              </div>
            )}
            <Note note={data.note || ''} />
            
            <ActionForm
              className={styles.atgReportActionForm}
              title='START (ATG)'
              value={atgStartDatetime}
              handleCalculation={handleAtgCalculationStart}
              setDatetime={handleAtgStartDatetimeChange}
              disabled={isAtgSubmitting || isSubmitting}
              hideAction={hideAction}
              isDateChange={isStartDateChange}
            />
            <ActionForm
              className={styles.atgReportActionForm}
              title='STOP (Less-Line)'
              value={atgStopLesslineDatetime}
              handleCalculation={handleAtgCalculationLessLine}
              setDatetime={handleAtgStopLesslineDatetimeChange}
              disabled={
                isAtgSubmitting || isSubmitting || disableAtgStopLesslineForm
              }
              hideAction={hideAction}
              isDateChange={isLesslineDateChange}
            />
            <ActionForm
              className={styles.atgReportActionForm}
              title='STOP(ATG)'
              value={atgStopDatetime}
              handleCalculation={handleAtgCalculationStop}
              setDatetime={handleAtgStopDatetimeChange}
              disabled={isAtgSubmitting || isSubmitting || disableAtgStopForm}
              hideAction={hideAction}
              isDateChange={isStopDateChange}
            />

            <CalculationForm
              reports={atgReports}
              disableStopLesslineForm={disableAtgStopLesslineForm}
              disableStopForm={disableAtgStopForm}
              isSubmitting={isAtgSubmitting || isSubmitting}
              readOnly={readOnly}
              hideAction={hideAction}
              canSaveStart={canSaveStart}
              canSaveLessline={canSaveLessline}
              canSaveStop={canSaveStop}
            />

            

            <Summary
              disabled={data.verifyStatus === 'Approve'}
              summaryMethods={summaryMethods}
              meterLineAdjustment={meterSummary.finalSummary}
              reports={reports || []}
              vesselLoads={vesselLoads}
              disableVesselLoad={!atgConfig?.vesselLoad?.state}
              canCalTotal={!!atgConfig?.manualDip?.stop?.state}
              isSubmitting={isSubmitting}
              hasMeter={hasMeter}
              hasVesselLoad={hasVesselLoad}
            />
          </div>
        )}
      </Box>
      {loadingAtgConfig || isAtgLoading ? (
        <Loading />
      ) : (
        <>
          <PurityCorrection />
          <GoalSeek />
          <ProrateDensity />
          <ProrateMW />
          <VesselLoad />
        </>
      )}
    </div>
  )
}
