import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import { useProfile } from '@features/profile/hooks/useProfile'
import {
  SETTING_PERMISSIONS,
  DAILY_PERMISSIONS,
  DELIVERY_PERMISSIONS,
  MONTHLY_PERMISSIONS,
} from '@lib/constants'

import { SetupMenu } from './SetupMenu'
import { ReportMenu } from './ReportMenu'
import { DeliveryReportMenu } from './DeliveryReportMenu'
import styles from './index.module.scss'

export function Menu() {
  const { data } = useProfile()
  const navigate = useNavigate()

  function checkPermission(permissions: string[]) {
    return data?.roleGroup?.screens.some(row => {
      return (
        permissions.includes(row.screen.name.replace(/[^\w]/g, ' ')) && row.read
      )
    })
  }

  return (
    <ul className={styles.menu}>
      {checkPermission(DAILY_PERMISSIONS) && (
        <li>
          <ReportMenu />
        </li>
      )}
      {checkPermission(DELIVERY_PERMISSIONS) && (
        <li>
          <DeliveryReportMenu />
        </li>
      )}
      {checkPermission(MONTHLY_PERMISSIONS) && (
        <li>
          <Button onClick={() => navigate('/monthly-report')}>
            Monthly Report
          </Button>
        </li>
      )}
      {checkPermission(SETTING_PERMISSIONS) && (
        <li>
          <SetupMenu />
        </li>
      )}
    </ul>
  )
}
