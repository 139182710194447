import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'
import { Screen } from '@lib/clients/interfaces/Screen'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

interface ScreenListContextType {
  data: Screen[]
  isLoading: boolean
}

const ScreenListContext = createContext<ScreenListContextType>({
  data: [],
  isLoading: false,
})

export function ScreenListProvider({ children }: FunctionComponentType) {
  const client = useAppClient()
  const { data, isLoading } = useQuery('screens', () =>
    client?.screen.getScreenList().then(response => response)
  )

  const value = {
    data: data || [],
    isLoading,
  }

  return (
    <ScreenListContext.Provider value={value}>
      {children}
    </ScreenListContext.Provider>
  )
}

export function useScreenList() {
  return useContext(ScreenListContext)
}
