import { Expose, Transform } from 'class-transformer'

export interface GoalSeekProps {
  level: string
  total: string
  disabled: boolean
  isSubmitting: boolean
  hideAction: boolean
  handleSubmit: (form: GoalSeekFormType) => void
}

export interface GoalSeekFormType {
  inputTarget: string
}

export class GoalSeekType {
  @Expose({ name: 'final_level_stop' })
  @Transform(({ value }) => (value ? String(value) : '0'))
  levelStop: string

  @Expose({ name: 'estimate_summary_total_by_atg' })
  @Transform(({ value }) => (value ? String(value) : '0'))
  total: string
}
