import styles from './index.module.scss'

interface NoteProps {
  note: string
}

export function Note({ note }: NoteProps) {
  return (
    <>
      <div className={styles.noteHeader}>Note</div>
      <textarea className={styles.noteDetail} value={note} disabled />
    </>
  )
}
