import { memo } from 'react'
import { Column } from 'react-table'

import { Table } from '@components/Table'

interface PermissionTableType {
  className: string
  columns: Column[]
  data: any[]
  loading?: boolean
}

export const PermissionTable = memo(
  ({ className, columns, data, loading }: PermissionTableType) => {
    return (
      <Table
        className={className}
        columns={columns}
        data={data}
        loading={loading}
      />
    )
  }
)
