import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox, CircularProgress } from '@material-ui/core'
import * as yup from 'yup'

import {
  ComponentProps,
  RoleGroupScreenType,
} from '@features/role-group/interfaces/RoleGroupType'
import { PermissionTable } from '@features/role-group/components/PermissionTable'
import { RadioGroupField } from '@components/fieldV2/RadioGroupField'
import { InputField } from '@components/fieldV2/InputField'
import { SelectField } from '@components/fieldV2/SelectField'
import { Loading } from '@components/Loading'

import styles from './index.module.scss'

const schema = yup.object().shape({
  code: yup.string().required('Role group code is a required field'),
  name: yup.string().required('Role group name is a required field'),
  reportRole: yup.string().required('Report Role is a required field'),
})

export function RoleGroupForm({
  initialValues,
  screens,
  reportRoles,
  screenLoading,
  loading = false,
  handleSubmit,
}: ComponentProps) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })
  const { isSubmitting } = methods.formState

  const columns = [
    { accessor: 'name', Header: 'Screen Name' },
    {
      accessor: 'write',
      Header: 'Write',
      Cell: ({ record, row }: any) => {
        const form = methods.getValues()

        return (
          <Checkbox
            color='primary'
            checked={!!form.screens[row.index]?.write}
            disabled={isSubmitting}
            onChange={e => {
              methods.reset({
                ...form,
                screens: form.screens.map((screen: RoleGroupScreenType) =>
                  screen.id === record.id
                    ? {
                        ...form.screens[row.index],
                        write: e.target.checked,
                        read: e.target.checked || form.screens[row.index]?.read,
                      }
                    : screen
                ),
              })
            }}
          />
        )
      },
    },
    {
      accessor: 'read',
      Header: 'Read',
      Cell: ({ row, record }: any) => {
        const form = methods.getValues()
        return (
          <Checkbox
            color='primary'
            checked={!!form.screens[row.index]?.read}
            disabled={isSubmitting}
            onChange={e => {
              methods.reset({
                ...form,
                screens: form.screens.map((screen: RoleGroupScreenType) =>
                  screen.id === record.id
                    ? {
                        ...form.screens[row.index],
                        read: e.target.checked,
                      }
                    : screen
                ),
              })
            }}
          />
        )
      },
    },
  ]

  useEffect(() => {
    methods.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <FormProvider {...methods}>
      {loading ? (
        <Loading />
      ) : (
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <RadioGroupField
            name='active'
            label='Status'
            radioGroups={[
              { label: 'Active', value: 'true' },
              { label: 'Inactive', value: 'false' },
            ]}
          />
          <InputField
            className='mt'
            name='code'
            label='Role Group Code'
            disabled={isSubmitting}
          />
          <InputField
            className='mt'
            name='name'
            label='Role Group Name'
            disabled={isSubmitting}
          />
          <SelectField
            className='mt'
            name='reportRole'
            label='Report Role'
            options={reportRoles}
            placeholder='Select Report Role'
            disabled={isSubmitting}
          />
          <PermissionTable
            className='mt'
            columns={columns}
            data={screens}
            loading={screenLoading}
          />
          <Button
            className='mt'
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={28} /> : 'Save'}
          </Button>
        </form>
      )}
    </FormProvider>
  )
}
