import { Outlet, useLocation } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'

import { CalculationInformation } from '@features/tracking-work/components/CalculationInformation'
import { ChecklistGroup } from '@features/tracking-work/components/ChecklistGroup'
import { VerifyingPageProps } from '@features/tracking-work/interfaces/VerifyingType'

import { useState } from 'react'
import styles from './index.module.scss'
import { VerifyingModal } from './VerifyingModal'


const theme = createTheme({
  palette: {
    primary: { main: '#4caf50', light: '#4caf50E6', contrastText: '#FEFEFE'},
    secondary: {
      main: '#FFCE40', contrastText: '#000000'
    },
  },
})

export function VerifyingPage({
  data,
  modal,
  loading,
  capacity,
  note,
  isSubmitting,
  hideAction,
  checkSaveStart,
  checkSaveLessline,
  checkSaveStop,
  handleSaveAtg,
  handleSaveMeter,
  handleVerifying,
  openConfirmVerifyModal,
  closeModal,
  canSubmit,
  handleSaveAll,
}: VerifyingPageProps) {
  const { pathname } = useLocation()
  const isMeterMode = pathname.includes('meter')
  const canApprove = data?.canApprove
  return (
    <Container>
      <Breadcrumbs />
      <div className={styles.submitGroup}>
        <ThemeProvider theme={theme}>
          <Button
          color='secondary'
          variant='contained'
          onClick={(e) => handleSaveAll(isMeterMode)}
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={!canSubmit || isSubmitting || !canApprove}
            onClick={openConfirmVerifyModal}
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'Verifying'}
          </Button>
        </ThemeProvider>
      </div>

      {loading && !data ? (
        <Loading />
      ) : (
        <>
          <CalculationInformation
            workRequestNo={data?.workRequestNo || '-'}
            product={data?.product || '-'}
            categories={data?.activity || '-'}
            portOfLoading={data?.portOfLoading || '-'}
            fromCompany={data?.fromCompany || '-'}
            fromTank={data?.fromTanks.join(',') || '-'}
            toCompany={data?.toCompany || '-'}
            toTank={data?.toTanks.join(',') || '-'}
            capacity={capacity}
          />

          <ChecklistGroup
            atgChecklist={data?.atgChecklist || []}
            meterChecklist={data?.meterChecklist || []}
            isMeterMode={isMeterMode}
            hideAction={hideAction}
            handleSaveAtg={handleSaveAtg}
            handleSaveMeter={handleSaveMeter}
          />
          <Outlet />
        </>
      )}

      <VerifyingModal
        portOfLoading={data?.portOfLoading || '-'}
        note={note}
        open={modal === 'verifying'}
        disableShipmentNo={data?.edition === 'revised'}
        checkSaveStart={checkSaveStart}
        checkSaveLessline={checkSaveLessline}
        checkSaveStop={checkSaveStop}
        handleConfirm={handleVerifying}
        handleClose={closeModal}
      />
    </Container>
  )
}
