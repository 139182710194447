import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useReportHeaderList() {
  const client = useAppClient()
  const { data, isLoading } = useQuery('report-headers', () =>
    client?.reportHeader.getReportHeaderList().then(response => response)
  )

  return {
    parameters: data?.parameters,
    quantity: data?.quantity,
    isLoading,
  }
}
