import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

interface UseRoleGroupType {
  id: string
}

export function useRoleGroup({ id }: UseRoleGroupType) {
  const client = useAppClient()
  const { data, isLoading } = useQuery('role-group', () =>
    client?.roleGroup.getRoleGroup({ id }).then(response => response)
  )

  return {
    data,
    isLoading,
  }
}
