import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

interface UseHeaderReportType {
  companyId?: number
}

export function useHeaderReport({ companyId }: UseHeaderReportType) {
  const client = useAppClient()
  const { data, isLoading } = useQuery(['header-report', companyId], () =>
    client?.report.getHeaderDailyReport({ companyId }).then(res => res)
  )

  return {
    data,
    isLoading,
  }
}
