import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { SearchForm } from '@features/batch-document-tracking/components/SearchForm'
import { BatchDocumentTrackingProps } from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'

export function BatchDocumentTracking({
  data,
  columns,
  searchParam,
  isLoading,
  total,
  handleSearch,
  handlePaginationChange,
}: BatchDocumentTrackingProps) {
  return (
    <Container>
      <Breadcrumbs />
      <SearchForm initialSearchForm={searchParam} onSearch={handleSearch} />
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        page={searchParam.page}
        count={Math.ceil(total / searchParam.size)}
        onPaginationChange={handlePaginationChange}
      />
    </Container>
  )
}
