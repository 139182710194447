import { Expose, Type } from 'class-transformer'

class ParameterValueType {
  tag: string

  value: number

  unit: string
}

class ParameterType {
  @Expose({ name: 'level_master' })
  @Type(() => ParameterValueType)
  levelMaster: ParameterValueType

  @Expose({ name: 'level_slave' })
  @Type(() => ParameterValueType)
  levelSlave: ParameterValueType

  @Expose({ name: 'liquid_temperature' })
  @Type(() => ParameterValueType)
  liquidTemperature: ParameterValueType

  @Expose({ name: 'vapor_temperature' })
  @Type(() => ParameterValueType)
  vaporTemperature: ParameterValueType

  @Expose({ name: 'vapor_pressure' })
  @Type(() => ParameterValueType)
  vaporPressure: ParameterValueType
}

export class PrintGaugeType {
  @Expose({ name: 'tank_no' }) tankCode: string

  @Expose()
  @Type(() => ParameterType)
  parameters: ParameterType

  @Expose({ name: 'work_number' }) workNumber: string

  @Expose({ name: 'company_name' }) companyName: string

  @Expose() time: string

  @Expose() product: string

  @Expose({ name: 'batch_period' }) batchPeriod: string

  @Expose() activity: string

  @Expose({ name: 'from_tank' }) fromTank: string

  @Expose({ name: 'to_tank' }) toTank: string
}

export interface PrintGaugeProps {
  tankCode: string
  parameters?: ParameterType
  workNumber: string
  companyName: string
  time: string
  product: string
  batchPeriod: string
  activity: string
  fromTank: string
  toTank: string
}
