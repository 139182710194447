export function getHeader(text: string) {
  const index = text.indexOf('(')

  if (index === -1) return text

  const header = text.slice(0, index).trim()
  const unit = text.slice(index)

  return (
    <span>
      {header}
      <br />
      {unit}
    </span>
  )
}
