import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { DeliveryReport } from '@features/tracking-work/interfaces/DeliveryReportType'
import { ConfigDeliveryReport } from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import { PrintGaugeType } from '@features/pdf/interfaces/PrintGaugeType'
import { VerifyingFormType } from '@features/tracking-work/interfaces/VerifyingType'
import { getQueryParams } from '@lib/utils'
import { blankFormProducts } from '@lib/constants'

import { CalculateMode, CalculateType } from './ATGReportClient'

interface DeliveryReportParams {
  id: string
}

interface ConfigDeliveryReportParams {
  deliveryId: string
  tankId: string
}

interface SubmitWorkParams {
  deliveryId: string
}

const MODE = {
  start: 'START',
  less_line: 'Less-Line',
  stop: 'STOP',
}

interface PrintGaugeParams {
  deliveryId: number | string
  tankCode: string
  type: CalculateType
  mode: CalculateMode
}

interface VerifyWorkParams {
  deliveryId: string
  form: VerifyingFormType
}

export class DeliveryReportClient {
  constructor(private client: AxiosInstance) {}

  transformData(data: any) {
    return {
      ...data,
      tanks: data.tanks.map((row: any) => ({
        id: row.id,
        code: row.tankNo,
        shipmentNo: row.shipmentNumber,
      })),
      atgChecklist: data.tanks.flatMap((tank: any) => {
        return tank.atg_checklists.map((row: any) => {
          const type = row.type as CalculateType
          const mode = row.mode === 'less_line' ? MODE.less_line : MODE[type]
          return {
            id: row.id,
            name: `${tank.tankNo} (${mode})`,
            active: row.status,
            necessity: row.necessity,
            type,
            mode: row.mode,
            tankCode: tank.tankNo,
          }
        })
      }),
      meterChecklist: data.tanks.flatMap((tank: any) => {
        return tank.meter_checklists.map((row: any) => {
          const type = row.type as CalculateType
          const mode = row.mode === 'less_line' ? MODE.less_line : MODE[type]
          return {
            id: row.id,
            name: `${mode}`,
            active: row.status,
            necessity: row.necessity,
            type,
            mode: row.mode,
            tankCode: tank.tankNo,
          }
        })
      }),
      hasMeterLessline: data.tanks.some((tank: any) => {
        return tank.meter_checklists.some(
          (row: any) => row.type === 'stop' && row.mode === 'less_line'
        )
      }),
      isBlankForm: blankFormProducts.includes(data.product),
    }
  }

  async getDeliveryReport({
    id,
  }: DeliveryReportParams): Promise<DeliveryReport> {
    const response = await this.client.get(`/api/reports/delivery/${id}`)

    const data = this.transformData(response.data)

    return plainToClass(DeliveryReport, data, {
      excludeExtraneousValues: true,
    })
  }

  async getConfigDeliveryReport({
    deliveryId,
    tankId,
  }: ConfigDeliveryReportParams): Promise<ConfigDeliveryReport> {
    if (!deliveryId || !tankId) return plainToClass(ConfigDeliveryReport, {})
    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankId}/config`
    )

    return plainToClass(ConfigDeliveryReport, response.data)
  }

  async submitWork({ deliveryId }: SubmitWorkParams) {
    await this.client.patch(`/api/reports/delivery/${deliveryId}/submit`)
  }

  async verifyWork({ deliveryId, form }: VerifyWorkParams) {
    const query = getQueryParams({
      year: form.year,
      month: form.month,
    })

    const response = await this.client.patch(
      `/api/reports/delivery/${deliveryId}/verify?${query}`,
      {
        port_of_loading: form.portOfLoading,
        note: form.note,
      }
    )

    const data = this.transformData(response.data)

    return plainToClass(DeliveryReport, data, {
      excludeExtraneousValues: true,
    })
  }

  async getPrintGauge({ deliveryId, tankCode, type, mode }: PrintGaugeParams) {
    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/gauge?type=${type}&mode=${mode}`
    )

    return plainToClass(PrintGaugeType, response.data)
  }
}
