import { FC, SVGProps } from 'react'
import TankSVG from './tank.svg'
import LogoSVG from './LogoSCGC.svg'

export interface IconProps {
  height?: number
  width?: number
  className?: string
  viewBox?: string
}

const Icon = (SvgComponent: FC<SVGProps<SVGSVGElement>>): FC<IconProps> => {
  return ({ height = 20, width = 20, viewBox = '', className }: IconProps) => {
    const svgProps = {
      height,
      width,
      viewBox,
      className,
    }
    return <SvgComponent {...svgProps} />
  }
}

export const TankIcon = Icon(TankSVG)
export const LogoIcon = Icon(LogoSVG)
