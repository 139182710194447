import { ROLE_GROUP_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { RoleGroupCreatePage } from './RoleGroupCreatePage'
import { withRoleGroupCreatePage } from './withRoleGroupCreatePage'

const ConnectedRoleGroupCreatePage = withPermissionGuard(
  withRoleGroupCreatePage(RoleGroupCreatePage),
  ROLE_GROUP_SETTING,
  'write'
)
export { ConnectedRoleGroupCreatePage as RoleGroupCreatePage }
