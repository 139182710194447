import { memo } from 'react'
import cx from 'classnames'

import { TableReport } from '@components/TableReport'
import { SummaryDailyReport } from '@features/report/interfaces/SummaryDailyReportType'
import { useHeaderReport } from '@features/report/hooks/useHeaderReport'
import { useProfile } from '@features/profile/hooks/useProfile'
import { getHeader } from '@lib/report'
import { SummaryDailyTablePropsType } from './interfaces'
import styles from './index.module.scss'

export const SummaryDailyTable = memo(
  ({ data, loading }: SummaryDailyTablePropsType) => {
    const { data: profile } = useProfile()
    const { data: headerReport } = useHeaderReport({
      companyId: profile?.company?.id,
    })

    const columns = [
      {
        Header: <div className={styles.box}>Date</div>,
        accessor: 'date',
      },
      {
        Header: <div className={cx(styles.box, styles.allTank)}>All tank</div>,
        id: 'all-tank',
        columns: headerReport?.alltank.map(row => ({
          Header: (
            <div className={cx(styles.box, styles.allTank, styles.noWrap)}>
              {getHeader(row.name)}
            </div>
          ),
          accessor: `allTank_${row.key}`,
          Cell: ({ record }: { record: SummaryDailyReport }) =>
            record.quantities.alltank[row.key]?.value,
        })),
      },
      {
        Header: (
          <div className={cx(styles.box, styles.usable)}>
            Usable (All tank - Dead stock)
          </div>
        ),
        id: 'usable-all-tank-dead-stock',
        columns: headerReport?.usable.map(row => ({
          Header: (
            <div className={cx(styles.box, styles.noWrap, styles.usable)}>
              {getHeader(row.name)}
            </div>
          ),
          accessor: `usable_${row.key}`,
          Cell: ({ record }: { record: SummaryDailyReport }) =>
            record.quantities.usable[row.key]?.value,
        })),
      },
      {
        Header: (
          <div className={cx(styles.box, styles.available)}>
            Available space (Max Capacity - All tank)
          </div>
        ),
        id: 'available-space',
        columns: headerReport?.avaliablespace.map(row => ({
          Header: (
            <div className={cx(styles.box, styles.noWrap, styles.available)}>
              {getHeader(row.name)}
            </div>
          ),
          accessor: `avaliable_${row.key}`,
          Cell: ({ record }: { record: SummaryDailyReport }) =>
            record.quantities.avaliablespace[row.key]?.value,
        })),
      },
      {
        Header: (
          <div className={cx(styles.box, styles.setParameter)}>
            Set Parameter
          </div>
        ),
        id: 'parameter',
        columns: headerReport?.parameter.map(row => ({
          Header: (
            <div
              className={cx(
                styles.box,
                styles.noWrap,
                styles.h56,
                styles.setParameter
              )}
            >
              {getHeader(row.name)}
            </div>
          ),
          accessor: row.key,
          Cell: ({ record }: { record: SummaryDailyReport }) =>
            record.parameter[row.key]?.value,
        })),
      },
    ]

    return (
      <TableReport
        columns={columns}
        data={data}
        loading={loading}
        type='summary'
      />
    )
  }
)
