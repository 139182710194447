import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { Screen } from '@lib/clients/interfaces/Screen'

export class ScreenClient {
  constructor(private client: AxiosInstance) {}

  async getScreenList(): Promise<Screen[]> {
    const response = await this.client.get('/api/screens')
    return response.data.map((screen: any) =>
      plainToClass(Screen, screen, {
        excludeExtraneousValues: true,
      })
    )
  }
}
