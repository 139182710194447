import { useMemo } from 'react'
import hoist from 'hoist-non-react-statics'
import { useNavigate, Link } from 'react-router-dom'
import cx from 'classnames'

import {
  CompanyPageProps,
  CellType,
} from '@features/company/interfaces/CompanyType'
import { useCompanyList } from '@features/company/hooks/useCompanyList'
import { usePermissionGuard } from '@lib/permissions'
import { COMPANY_SETTING } from '@lib/constants'

import styles from './index.module.scss'

const initialColumns = [
  { accessor: 'code', Header: 'Company Code' },
  { accessor: 'name', Header: 'Company Name' },
  {
    accessor: 'active',
    Header: 'Active Status',
    Cell: ({ record }: CellType) => (
      <div
        className={cx(styles.status, {
          [styles.active]: record.active,
        })}
      >
        {record.active ? 'Active' : 'Inactive'}
      </div>
    ),
  },
]

export function withCompanyPage(Component: React.FC<CompanyPageProps>) {
  function WithCompanyPage() {
    const { data: companyList, isLoading } = useCompanyList()
    const editable = usePermissionGuard(COMPANY_SETTING, 'write')
    const navigate = useNavigate()

    const columns = editable
      ? [
          ...initialColumns,
          {
            accessor: 'action',
            Header: '',
            Cell: ({ record }: CellType) => {
              return <Link to={`/company/${record.id}/edit`}>Edit</Link>
            },
          },
        ]
      : initialColumns

    function handleCreate() {
      navigate('/company/create')
    }

    const data = useMemo(
      () =>
        companyList.map(company => ({
          ...company,
          action: '',
        })),
      [companyList]
    )

    const pageProps = {
      loading: isLoading,
      columns,
      data,
      handleCreate,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCompanyPage, Component)

  WithCompanyPage.displayName = `WithCompanyPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCompanyPage
}
