import { Outlet } from 'react-router-dom'

import { DraftCalculationPage } from './pages/DraftCalculationPage'
import { WrapCalculationPage } from './pages/WrapCalculationPage'
import { ATGMode } from './pages/ATGMode'

export const configDraftCalculationRoutes = [
  {
    path: 'draft',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <DraftCalculationPage />,
      },
      {
        path: ':id/calculate',
        element: <WrapCalculationPage />,
        children: [
          {
            path: '',
            element: <ATGMode />,
          },
        ],
      },
    ],
  },
]
