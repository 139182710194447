import { useField } from 'formik'
import { FormControl } from '@material-ui/core'

import { SelectType } from '@interfaces/common/SelectType'
import { Select } from '@components/Select'

export function SelectField({
  name = '',
  label,
  placeholder,
  options,
  disabled,
  ...props
}: SelectType) {
  const [field, meta] = useField(name)
  const error = Boolean(meta.error && meta.touched)

  return (
    <Select
      options={options}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      error
      helperText={meta.error || ''}
      {...field}
      {...props}
    />
  )
}
