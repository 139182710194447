import hoist from 'hoist-non-react-statics'

import {
  SignInForm,
  SignInPageType,
} from '@features/authentication/interfaces/SignInType'
import { useAuthentication } from '@features/authentication/hooks/useAuthentication'

export function withSignInPage(Component: React.FC<SignInPageType>) {
  function WithSignInPage() {
    const { signIn, error } = useAuthentication()

    async function onSignIn(form: SignInForm) {
      await signIn(form)
    }

    const pageProps = {
      error,
      onSignIn,
    }

    return <Component {...pageProps} />
  }

  hoist(WithSignInPage, Component)

  WithSignInPage.displayName = `WithSignInPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithSignInPage
}
