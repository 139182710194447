import { Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Select } from '@components/Select'
import { Loading } from '@components/Loading'
import { Container } from '@components/Container'
import { Panel } from '@components/Panel'
import { ConfigDailyReportPageType } from '@features/config-daily-report/interfaces/ConfigDailyReportType'

import styles from './index.module.scss'

export function ConfigDailyReportPage({
  formValues,
  companyOptions,
  tanks,
  parameters,
  quantity,
  loadingTanks,
  loadingReportHeaders,
  isSubmitting,
  handleCompanyChange,
  handleSelectTank,
  handleSelectReportHeader,
  onSubmit,
}: ConfigDailyReportPageType) {
  return (
    <Container>
      <Breadcrumbs />
      <form className={styles.formWrapper} onSubmit={onSubmit}>
        <div className={styles.header}>
          <Select
            className={styles.select}
            value={formValues?.company?.id}
            options={companyOptions}
            label='Company Code'
            placeholder='Select Company Code'
            onChange={event =>
              handleCompanyChange(event.target.value as string)
            }
            disabled={isSubmitting}
          />
          <Button
            className='mt'
            type='submit'
            color='primary'
            variant='contained'
            disabled={isSubmitting}
          >
            Save
          </Button>
        </div>
        <Panel header='Tank Owner'>
          <Grid container spacing={2}>
            {loadingTanks ? (
              <Loading />
            ) : (
              tanks.map(tank => (
                <Grid item xs={4} sm={2} key={tank.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(_, checked) =>
                          handleSelectTank(tank, checked)
                        }
                        checked={
                          formValues.tanks.findIndex(
                            row => row.id === tank.id
                          ) !== -1
                        }
                        disabled={isSubmitting}
                      />
                    }
                    label={tank.code}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Panel>

        <Panel className='mt' header='Set of Parameter'>
          <Grid container spacing={2}>
            {loadingReportHeaders ? (
              <Loading />
            ) : (
              parameters?.map(parameter => (
                <Grid item xs={4} sm={3} key={parameter.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(_, checked) =>
                          handleSelectReportHeader(parameter, checked)
                        }
                        checked={
                          formValues.reportHeaders.findIndex(
                            row => row.id === parameter.id
                          ) !== -1
                        }
                        disabled={isSubmitting}
                      />
                    }
                    label={parameter.description}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Panel>

        <Panel className='mt' header='Quantity: All Tank'>
          <Grid container spacing={2}>
            {loadingReportHeaders ? (
              <Loading />
            ) : (
              quantity?.alltanks.map(alltank => (
                <Grid item xs={4} sm={3} key={alltank.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(_, checked) =>
                          handleSelectReportHeader(alltank, checked)
                        }
                        checked={
                          formValues.reportHeaders.findIndex(
                            row => row.id === alltank.id
                          ) !== -1
                        }
                        disabled={isSubmitting}
                      />
                    }
                    label={alltank.description}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Panel>

        <Panel className='mt' header='Quantity: Usable (All Tank - Dead Stock)'>
          <Grid container spacing={2}>
            {loadingReportHeaders ? (
              <Loading />
            ) : (
              quantity?.usables.map(usable => (
                <Grid item xs={4} sm={3} key={usable.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(_, checked) =>
                          handleSelectReportHeader(usable, checked)
                        }
                        checked={
                          formValues.reportHeaders.findIndex(
                            row => row.id === usable.id
                          ) !== -1
                        }
                        disabled={isSubmitting}
                      />
                    }
                    label={usable.description}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Panel>

        <Panel
          className='mt'
          header='Quantity: Avaliable Space (Max Capacity - All Tank)'
        >
          <Grid container spacing={2}>
            {loadingReportHeaders ? (
              <Loading />
            ) : (
              quantity?.avaliablespaces.map(avaliablespace => (
                <Grid item xs={4} sm={3} key={avaliablespace.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(_, checked) =>
                          handleSelectReportHeader(avaliablespace, checked)
                        }
                        checked={
                          formValues.reportHeaders.findIndex(
                            row => row.id === avaliablespace.id
                          ) !== -1
                        }
                        disabled={isSubmitting}
                      />
                    }
                    label={avaliablespace.description}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Panel>
      </form>
    </Container>
  )
}
