import { FormControl } from '@material-ui/core'
import { useFormContext, useController } from 'react-hook-form'

import styles from './index.module.scss'

interface TextAreaFieldProps {
  className?: string
  name: string
  label?: string
  disabled?: boolean
  defaultValue?: string
  rows?: number
  handleChange?: (e: any) => void
}

export function TextAreaField({
  className,
  label,
  name,
  defaultValue,
  disabled,
  rows = 3,
  handleChange,
}: TextAreaFieldProps) {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control, defaultValue })
  const { invalid, error } = fieldState

  return (
    <FormControl className={className}>
      {label && (
        <div className='label'>
          <strong>{label}</strong>
        </div>
      )}
      <textarea
        className={styles.textarea}
        {...field}
        value={field.value ?? ''}
        rows={rows}
        disabled={disabled}
        onChange={e => {
          field.onChange(e)
          handleChange && handleChange(e)
        }}
      />
      {invalid && <div className={styles.error}>{error?.message}</div>}
    </FormControl>
  )
}
