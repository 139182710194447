import hoist from 'hoist-non-react-statics'

import {
  RoleGroupFormProps,
  ComponentProps,
} from '@features/role-group/interfaces/RoleGroupType'
import { useScreenList } from '@features/screen/hooks/useScreenList'
import { reportRoles } from '@lib/constants'

export function withRoleGroupForm(Component: React.FC<ComponentProps>) {
  function WithRoleGroupForm(props: RoleGroupFormProps) {
    const { data: screens, isLoading } = useScreenList()

    const pageProps = {
      screens,
      reportRoles,
      screenLoading: isLoading,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithRoleGroupForm, Component)

  WithRoleGroupForm.displayName = `WithRoleGroupForm(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithRoleGroupForm
}
