import { useState } from 'react'
import hoist from 'hoist-non-react-statics'
import { DateTime } from 'luxon'

import {
  BatchDocumentTrackingProps,
  SearchFormType,
} from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'
import { useBatchDocumentTrackingList } from '@features/batch-document-tracking/hooks/useBatchDocumentTrckingList'
import { ExportPdfButton } from '@features/batch-document-tracking/components/ExportPdfButton'

export function withBatchDocumentTracking(
  Component: React.FC<BatchDocumentTrackingProps>
) {
  function WithBatchDocumentTracking() {
    const [searchParam, setSearchParam] = useState<SearchFormType>({
      product: 'all',
      tank: 'all',
      activity: 'all',
      month: DateTime.now().toFormat('M'),
      year: DateTime.now().toFormat('yyyy'),
      page: 1,
      size: 10,
    })

    const { data, total, isLoading } = useBatchDocumentTrackingList(searchParam)

    const columns = [
      { accessor: 'workRequestID', Header: 'Work ID' },
      { accessor: 'tankNo', Header: 'Tank' },
      { accessor: 'product', Header: 'Product' },
      { accessor: 'activity', Header: 'Activity' },
      {
        accessor: 'startDates',
        Header: 'Start date',
        Cell: ({ record }: { record: any }) => (
          <div>
            {record.startDates.map((startDate: string) => (
              <div>{startDate}</div>
            ))}
          </div>
        ),
      },
      {
        accessor: 'endDates',
        Header: 'End date',
        Cell: ({ record }: { record: any }) => (
          <div>
            {record.endDates.map((endDate: string) => (
              <div>{endDate}</div>
            ))}
          </div>
        ),
      },
      {
        accessor: 'action',
        Header: '',
        Cell: ({ record }: { record: any }) => (
          <ExportPdfButton deliveryId={record.id} />
        ),
      },
    ]

    function handleSearch(form: SearchFormType) {
      setSearchParam({ ...form, page: 1 })
    }

    function handlePaginationChange(page: number) {
      setSearchParam(prev => ({
        ...prev,
        page,
      }))
    }

    const pageProps = {
      data,
      columns,
      searchParam,
      isLoading,
      total,
      handleSearch,
      handlePaginationChange,
    }

    return <Component {...pageProps} />
  }

  hoist(WithBatchDocumentTracking, Component)

  WithBatchDocumentTracking.displayName = `WithBatchDocumentTracking(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithBatchDocumentTracking
}
