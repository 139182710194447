import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

import { Profile } from '../interfaces/ProfileType'

interface ProfileContextType {
  data?: Profile
  isLoading: boolean
  refetch?: () => Promise<any>
}

const ProfileContext = createContext<ProfileContextType>({
  isLoading: false,
})

export function ProfileProvider({ children }: FunctionComponentType) {
  const client = useAppClient()
  const { data, isLoading, refetch } = useQuery('profile', () =>
    client?.auth.getProfile().then(res => res)
  )

  const value = {
    data,
    isLoading,
    refetch,
  }

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export function useProfile() {
  return useContext(ProfileContext)
}
