import { Expose } from 'class-transformer'

export interface QuantityType {
  alltanks: ReportHeader[]
  usables: ReportHeader[]
  avaliablespaces: ReportHeader[]
}

export interface ReportHeaderResponse {
  parameters: ReportHeader[]
  quantity: QuantityType
}

export class ReportHeader {
  @Expose() id: number

  @Expose({ name: 'header_group' }) group: 'parameter' | 'quantities'

  @Expose({ name: 'header_type' }) type:
    | 'alltank'
    | 'usable'
    | 'avaliablespace'
    | 'parameter'

  @Expose({ name: 'header_column' }) name: string

  @Expose({ name: 'header_column_desc' }) description: string
}
