import { memo } from 'react'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'

import { InputField } from '@components/fieldV2/InputField'
import { CalculationDetailType } from '@features/tracking-work/interfaces/CalculationDetailType'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

interface CalculationDetailInputPropsType {
  data: CalculationDetailType
  index: number
  disableStopLesslineForm: boolean
  disableStopForm: boolean
  readOnly: boolean
}

export const CalculationDetailInput = memo(
  ({
    data,
    index,
    disableStopLesslineForm,
    disableStopForm,
    readOnly,
  }: CalculationDetailInputPropsType) => {
    const { setValue } = useFormContext()

    if (data.topicType === 'H')
      return (
        <div className={styles.atgReportCalculationDetailHeader}>
          {data.topic}
        </div>
      )

    if (data.topicType === 'F')
      return (
        <>
          <div className={styles.atgReportCalculationDetailLabel}>
            {data.topic}
          </div>
          <div>{data.startValue}</div>
          <div>{data.stopLesslineValue}</div>
          <div>{data.stopValue}</div>
        </>
      )

    return (
      <>
        <div
          className={cx(styles.atgReportCalculationDetailLabel, {
            [styles.calculate]: data.topicType === 'C',
          })}
        >
          {data.topic}
        </div>

        <InputField
          className={cx({ [styles.InputCalculate]: data.topicType === 'C' })}
          type='number'
          name={`list.${index}.startValue`}
          disabled={data.topicType === 'C' || readOnly}
          handleChange={(e: any) => {
            const number = e.target.value.split('.')

            if (data.decimalPoint && number.length > 1)
              setValue(
                `list.${index}.startValue`,
                `${number[0]}.${number[1].slice(0, data.decimalPoint)}`
              )
            else setValue(`list.${index}.startValue`, number[0])
          }}
        />
        <InputField
          className={cx({ [styles.InputCalculate]: data.topicType === 'C' })}
          type='number'
          name={`list.${index}.stopLesslineValue`}
          disabled={
            data.topicType === 'C' || disableStopLesslineForm || readOnly
          }
          handleChange={(e: any) => {
            const number = e.target.value.split('.')

            if (data.decimalPoint && number.length > 1)
              setValue(
                `list.${index}.stopLesslineValue`,
                `${number[0]}.${number[1].slice(0, data.decimalPoint)}`
              )
            else setValue(`list.${index}.stopLesslineValue`, number[0])
          }}
        />
        <InputField
          className={cx({ [styles.InputCalculate]: data.topicType === 'C' })}
          type='number'
          name={`list.${index}.stopValue`}
          disabled={data.topicType === 'C' || disableStopForm || readOnly}
          handleChange={(e: any) => {
            const number = e.target.value.split('.')

            if (data.decimalPoint && number.length > 1)
              setValue(
                `list.${index}.stopValue`,
                `${number[0]}.${number[1].slice(0, data.decimalPoint)}`
              )
            else setValue(`list.${index}.stopValue`, number[0])
          }}
        />
      </>
    )
  }
)
