import hoist from 'hoist-non-react-statics'
import { DateTime } from 'luxon'

import { PrintGaugeProps } from '@features/pdf/interfaces/PrintGaugeType'

export function withPrintGauge(Component: React.FC<PrintGaugeProps>) {
  function WithPrintGauge({
    tankCode,
    parameters,
    workNumber,
    companyName,
    time,
    product,
    batchPeriod,
    activity,
    fromTank,
    toTank,
  }: PrintGaugeProps) {
    const pageProps = {
      tankCode,
      parameters,
      workNumber,
      companyName,
      time,
      product,
      batchPeriod,
      activity,
      fromTank,
      toTank,
    }

    return <Component {...pageProps} />
  }

  hoist(WithPrintGauge, Component)

  WithPrintGauge.displayName = `WithPrintGauge(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithPrintGauge
}
