import { addComma } from '@lib/utils'
import { Expose, Transform } from 'class-transformer'

export interface ProductMovementDetailProps {
  type: string
  date: string
  product: string
  data: ProductMovementType[]
}

export class ProductMovementType {
  @Expose({ name: 'order_no' }) orderNo: string

  @Expose({ name: 'shipment_no' }) shipmentNo: string

  @Expose({ name: 'cat' }) activity: string

  @Expose({ name: 'from_place' }) fromPlace: string

  @Expose({ name: 'from_tankno' }) fromTankNo: string

  @Expose({ name: 'to_place' }) toPlace: string

  @Expose({ name: 'to_tankno' }) toTankNo: string

  @Expose({ name: 'start_date' }) startDate: string

  @Expose({ name: 'start_time' }) startTime: string

  @Expose({ name: 'finish_date' }) endDate: string

  @Expose({ name: 'finish_time' }) endTime: string

  @Expose({ name: 'volume30c' })
  @Transform(({ value }) => addComma(value))
  column1: string

  @Expose({ name: 'qty_in_air' })
  @Transform(({ value }) => addComma(value))
  column2: string

  @Expose() remark: string
}
