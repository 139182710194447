import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'

import { Loading } from '@components/Loading'
import {
  WorkOrderRequestNumberForm,
  WorkOrderRequestNumberFormPropsType,
} from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'

interface ModifyWorkModalType extends WorkOrderRequestNumberFormPropsType {
  open: boolean
  isLoading: boolean
}

export function ModifyWorkModal({
  isLoading,
  open,
  initialValues,
  productOptions,
  handleClose,
  handleSubmit,
}: ModifyWorkModalType) {
  return (
    <Dialog
      className={styles.workModal}
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle className={styles.title}>
        Modify Work Order Request No.
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <WorkOrderRequestNumberForm
            initialValues={initialValues}
            productOptions={productOptions}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            editMode
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
