import cx from 'classnames'

import { Loading } from '@components/Loading'
import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'

import { PurityCorrection } from './PurityCorrection'
import { GoalSeek } from './GoalSeek'
import { ProrateDensity } from './ProrateDensity'
import { ProrateMW } from './ProrateMW'
import { VesselLoad } from './VesselLoad'
import { ManualDip } from './ManualDip'
import { ATGReport } from './ATGReport'

import styles from './index.module.scss'

export function ATGMode() {
  const { loadingAtgConfig, isAtgLoading } = useDraftDeliveryReport()

  return (
    <div className={cx('mt', styles.container)}>
      {loadingAtgConfig || isAtgLoading ? (
        <Loading />
      ) : (
        <>
          <PurityCorrection />
          <GoalSeek />
          <ProrateDensity />
          <ProrateMW />
          <VesselLoad />
          <ManualDip className={styles.manualDip} />
        </>
      )}
      <ATGReport className={styles.atgReport} />
    </div>
  )
}
