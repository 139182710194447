import { Expose, Transform } from 'class-transformer'

export interface MonthlyReportPageProps {
  isAdmin: boolean
}

export class MonthlyReportType {
  @Expose() id: number

  @Expose({ name: 'spm_product_name' }) product: string

  @Expose({ name: 'spm_product_type' }) type: string

  @Expose({ name: 'spm_year' }) year: string

  @Expose({ name: 'spm_month' }) month: string

  @Expose()
  @Transform(({ value }) =>
    value === 'wait_verify' ? 'Wait verify' : 'Complete'
  )
  status: string

  @Expose({ name: 'report_name' }) name: string
}
