import { useState, useCallback, useRef, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import * as yup from 'yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'

import {
  CalculationDetailType,
  CalculationFormType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { Level } from '@features/tracking-work/interfaces/LevelType'
import {
  ConfigDeliveryReport,
  ManualDipParameterKey,
} from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import {
  ManualDipCalculateForm,
  ManualDipForm,
} from '@features/tracking-work/interfaces/ManualDipType'
import { VesselLoadForm } from '@features/tracking-work/interfaces/VesselLoadType'
import { CheckListType } from '@features/tracking-work/interfaces/CheckListType'
import {
  ATGReport,
  SummaryReport,
} from '@features/tracking-work/interfaces/ATGReportType'
import { SummaryFormType } from '@features/tracking-work/interfaces/SummaryType'
import { useAlert } from '@lib/useAlert'
import { transformDateToISOFormat } from '@lib/utils'
import { useAppClient } from '@lib/useAppClient'
import {
  CalculateType,
  CalculateMode,
} from '@lib/clients/collections/ATGReportClient'


interface UseATGReportType {
  deliveryId: string
  tankCode: string
}

interface ATGReportResponse {
  isLoading: boolean
  level?: Level
  reports: CalculationDetailType[] | []
  disableStopLesslineForm: boolean
  disableStopForm: boolean
  isSubmitting: boolean
  startDatetime: string | null
  stopLesslineDatetime: string | null
  stopDatetime: string | null
  capacity: number
  config?: ConfigDeliveryReport
  loadingConfig: boolean
  purity: string
  manualDipMethods: UseFormReturn<ManualDipForm>
  methods: UseFormReturn<CalculationFormType>
  vesselLoadMethods: UseFormReturn<VesselLoadForm>
  summaryReport?: SummaryReport
  summaryMethods: UseFormReturn<SummaryFormType>
  checkSaveStart: boolean
  checkSaveStop: boolean
  checkSaveLessline: boolean
  isStartDateChange: boolean
  isStopDateChange: boolean
  isLesslineDateChange: boolean
  canSaveStart: any
  canSaveStop: any
  canSaveLessline: any
  calculationStart: (datetime: string | null) => Promise<void>
  calculationLessLine: (datetime: string | null) => void
  calculationStop: (datetime: string | null) => void
  handleSaveStart: (form: CalculationFormType) => Promise<void>
  handleSaveLessLine: (form: CalculationFormType) => Promise<void>
  handleSaveStop: (form: CalculationFormType) => Promise<void>
  handleStartDatetimeChange: (datetime: string, type: string) => void
  handleStopLesslineDatetimeChange: (datetime: string, type: string) => void
  handleStopDatetimeChange: (datetime: string, type: string) => void
  manualDipStartCal: () => Promise<void>
  manualDipStopLesslineCal: () => Promise<void>
  manualDipStopCal: () => Promise<void>
  setPurity: (value: string) => void
  retrieveLastSave: () => void
  handleCheckSaveStartChange: () => void
  handleCheckSaveLesslineChange: () => void
  handleCheckSaveStopChange: () => void
  handleManualFormChange: (type: CalculateType, mode: CalculateMode) => void
  calStartAutoSave: (datetime: string | null) => Promise<void>
  canApprove: boolean
}
const validateValue = yup
  .number()
  .typeError('')
  .min(
    yup.ref('constraintMin'),
    v => `must be greater than or equal to ${v.min}`
  )
  .max(yup.ref('constraintMax'), v => `must be less than or equal to ${v.max}`)

const validateGroupValue = yup.object().shape({
  startValue: validateValue,
  stopLesslineValue: validateValue,
  stopValue: validateValue,
})

const validationSchema = yup.object().shape({
  level: validateGroupValue,
  liquidTemperature: validateGroupValue,
  vaporTemperature: validateGroupValue,
  vaporPressure: validateGroupValue,
  densityInVac: validateGroupValue,
  molecularWeight: validateGroupValue,
})

export function useATGReport({
  deliveryId,
  tankCode,
}: UseATGReportType): ATGReportResponse {
  const client = useAppClient()
  const checkSaveStartRef = useRef(false)
  const checkSaveStopRef = useRef(false)
  const checkSaveLesslineRef = useRef(false)

  const [data, setData] = useState<ATGReport>()
  const [config, setConfig] = useState<ConfigDeliveryReport>()

  const [isLoading, setLoaing] = useState<boolean>(false)

  const [isStartDateChange, setStartDateChange] = useState(false)
  const [isStopDateChange, setStopDateChange] = useState(false)
  const [isLesslineDateChange, setLesslineDateChange] = useState(false)
  
  const canSaveStart = useRef(false)
  const canSaveStop = useRef(false)
  const canSaveLessline = useRef(false)

  const { success, error } = useAlert()
  const [isSubmitting, setSubmitting] = useState(false)
  const [stopState, setStopState] = useState(false)
  const [stopLesslineState, setStopLesslineState] = useState(false)
  const summaryMethods = useForm<SummaryFormType>()

  const [purity, setPurity] = useState('')

  const [canApprove, setCanApprove] = useState(true)


  useEffect(() => {
    initialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryId, tankCode])

  async function initialData() {
    setLoaing(true)
    await Promise.all([getAtgReport(), getConfig()])
    setLoaing(false)
  }

  async function getAtgReport() {
    const res = await client?.atgReport.getCalculateDetail({
      deliveryId,
      tankCode,
    })

    if (res) {
      setStartDatetime(res.startDatetime)
      setStopLesslineDatetime(res.stopLesslineDatetime)
      setStopDatetime(res.stopDatetime)
      setStopState(res.stopState)
      setStopLesslineState(res.stopLesslineState)
      methods.reset({ list: res.reports || [] })

      if (res.summaryReport) {
        vesselLoadMethods.reset({
          volume15: res.summaryReport.vesselVolume15 || '',
          volume30: res.summaryReport.vesselVolume30 || '',
          tonInAir: res.summaryReport.vesselTonInAir || '',
          tonInVac: res.summaryReport.vesselTonInVac || '',
        })

        summaryMethods.reset({
          totalVolume30: res.summaryReport.totalVolume30 || '0',
          totalVolume15: res.summaryReport.totalVolume15 || '0',
          totalTonInAir: res.summaryReport.totalTonInAir || '0.000',
          totalTonInVac: res.summaryReport.totalTonInVac || '0.000',
          meterVolume30: res.summaryReport.meterVolume30 || '0',
          meterVolume15: res.summaryReport.meterVolume15 || '0',
          meterTonInAir: res.summaryReport.meterTonInAir || '0.000',
          meterTonInVac: res.summaryReport.meterTonInVac || '0.000',
          lesslineVolume30: res.summaryReport.lesslineVolume30 || '0',
          lesslineVolume15: res.summaryReport.lesslineVolume15 || '0',
          lesslineTonInAir: res.summaryReport.lesslineTonInAir || '0.000',
          lesslinetonInVac: res.summaryReport.lesslineTonInVac || '0.000',
          finalVolume30: res.summaryReport.finalVolume30 || '0',
          finalVolume15: res.summaryReport.finalVolume15 || '0',
          finalTonInAir: res.summaryReport.finalTonInAir || '0.000',
          finalTonInVac: res.summaryReport.finalTonInVac || '0.000',
        })
      }
    }
    setData(res)
  }

  async function getConfig() {
    const res = await client?.delivery.getConfigDeliveryReport({
      deliveryId,
      tankId: tankCode,
    })
    setConfig(res)
  }

  const methods = useForm<CalculationFormType>({
    defaultValues: { list: [] },
  })

  const manualDipMethods = useForm<ManualDipForm>({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  })

  const vesselLoadMethods = useForm<VesselLoadForm>({
    defaultValues: {
      tonInAir: '',
      tonInVac: '',
      volume15: '',
      volume30: '',
    },
  })

  const [startDatetime, setStartDatetime] = useState<string | null>(null)
  const [stopLesslineDatetime, setStopLesslineDatetime] = useState<
    string | null
  >(null)
  const [stopDatetime, setStopDatetime] = useState<string | null>(null)

  const queryClient = useQueryClient()

  async function handleCalSaveAuto(
    type: CalculateType,
    mode: CalculateMode,
    datetime: string | null,
    successMessage: string
  ) {
    try {
      if (
        datetime &&
        DateTime.fromISO(datetime).toMillis() > DateTime.now().toMillis()
      ) {
        error({ message: 'เวลาไม่ตรงตามเงื่อนไขโปรดเลือกใหม่อีกครั้ง' })
        return
      }

      const res = await client?.atgReport.getCalculate({
        deliveryId,
        tankCode,
        type,
        mode,
        datetime: datetime ?? null,
      })

      if (res) {
        const currentDatetime = transformDateToISOFormat(res.datetime)
        success({ message: successMessage })
        resetForm(type, mode)

        setData((prev: any) => ({
          ...prev,
          reports: res.reports,
        }))

        methods.reset({ list: res.reports })

        if (type === 'start' && mode === 'normal') {
          setStartDatetime(currentDatetime)
          checkSaveStartRef.current = true
          setStartDateChange(false)
          canSaveStart.current = true
        } else if (type === 'stop' && mode === 'less_line') {
          setStopLesslineDatetime(currentDatetime)
          checkSaveLesslineRef.current = true
          setLesslineDateChange(false)
          canSaveLessline.current = true
        } else if (type === 'stop' && mode === 'normal') {
          setStopDatetime(currentDatetime)
          checkSaveStopRef.current = true
          setStopDateChange(false)
          canSaveStop.current = true
        }
      }      
    } catch (e: any) {
      const currentDatetime = transformDateToISOFormat(
        e?.response?.data?.detail.datetime
      )

      if (type === 'start' && mode === 'normal') {
        setStartDatetime(currentDatetime)
      } else if (type === 'stop' && mode === 'less_line') {
        setStopLesslineDatetime(currentDatetime)
      } else if (type === 'stop' && mode === 'normal') {
        setStopDatetime(currentDatetime)
      }

      throw e
    }
  }

  async function handleCalculation(
    type: CalculateType,
    mode: CalculateMode,
    datetime: string | null,
    successMessage: string
  ) {
    try {
      if (
        datetime &&
        DateTime.fromISO(datetime).toMillis() > DateTime.now().toMillis()
      ) {
        error({ message: 'เวลาไม่ตรงตามเงื่อนไขโปรดเลือกใหม่อีกครั้ง' })
        return
      }

      const res = await client?.atgReport.getCalculate({
        deliveryId,
        tankCode,
        type,
        mode,
        datetime: datetime ?? null,
      })

      if (res) {
        const currentDatetime = transformDateToISOFormat(res.datetime)
        success({ message: successMessage })
        resetForm(type, mode)

        setData((prev: any) => ({
          ...prev,
          reports: res.reports,
        }))

        methods.reset({ list: res.reports })

        if (type === 'start' && mode === 'normal') {
          setStartDatetime(currentDatetime)
          checkSaveStartRef.current = true
          setStartDateChange(false)
          canSaveStart.current = true
        } else if (type === 'stop' && mode === 'less_line') {
          setStopLesslineDatetime(currentDatetime)
          checkSaveLesslineRef.current = true
          setLesslineDateChange(false)
          canSaveLessline.current = true
        } else if (type === 'stop' && mode === 'normal') {
          setStopDatetime(currentDatetime)
          checkSaveStopRef.current = true
          setStopDateChange(false)
          canSaveStop.current = true
        }
      }
    } catch (e: any) {
      const currentDatetime = transformDateToISOFormat(
        e?.response?.data?.detail.datetime
      )

      if (type === 'start' && mode === 'normal') {
        setStartDatetime(currentDatetime)
      } else if (type === 'stop' && mode === 'less_line') {
        setStopLesslineDatetime(currentDatetime)
      } else if (type === 'stop' && mode === 'normal') {
        setStopDatetime(currentDatetime)
      }

      throw e
    }
  }

  const calStartAutoSave = useCallback(
    async (datetime: string | null) => {
      await handleCalSaveAuto(
        'start',
        'normal',
        datetime,
        'Start cal successful.'
      )
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const calculationStart = useCallback(
    async (datetime: string | null) => {
      await handleCalculation(
        'start',
        'normal',
        datetime,
        'Start cal successful.'
      )
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const calculationLessLine = useCallback(
    async (datetime: string | null) => {
      await handleCalculation(
        'stop',
        'less_line',
        datetime,
        'Stop lessline cal successful.'
      )
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const calculationStop = useCallback(
    async (datetime: string | null) => {
      await handleCalculation(
        'stop',
        'normal',
        datetime,
        'Stop cal successful.'
      )
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )

  async function saveSuccess() {
    await getConfig()
    const res = await client?.atgReport.getCalculateDetail({
      deliveryId,
      tankCode,
    })
    if (res) {
      setStopState(res.stopState)
      setStopLesslineState(res.stopLesslineState)
    }
  }

  function updateChecklistStatus(type: CalculateType, mode: CalculateMode) {
    queryClient.setQueryData(
      ['delivery-report', deliveryId],
      (oldData: any) => {
        return {
          ...oldData,
          atgChecklist: oldData.atgChecklist.map((row: CheckListType) => ({
            ...row,
            active:
              (row.type === type &&
                row.mode === mode &&
                row.tankCode === tankCode) ||
              row.active,
          })),
        }
      }
    )
  }

  async function handleSave(
    form: CalculationDetailType[],
    manualForm: ManualDipCalculateForm,
    datetime: string,
    type: CalculateType,
    mode: CalculateMode
  ) {
    setSubmitting(true)
    await client?.atgReport.updateCalculateDetail({
      deliveryId,
      tankCode,
      form,
      manualForm,
      purity,
      type,
      mode,
      datetime,
    })

    setData((oldData: any) => {
      const newData = {
        ...oldData,
        ...(type === 'start' &&
          mode === 'normal' && {
            startDatetime: datetime,
          }),
        ...(type === 'stop' &&
          mode === 'less_line' && {
            stopLesslineDatetime: datetime,
          }),
        ...(type === 'stop' &&
          mode === 'normal' && {
            stopDatetime: datetime,
          }),
      }

      return newData
    })
    updateChecklistStatus(type, mode)
    saveSuccess()
    if (type === 'start' && mode === 'normal') {
      checkSaveStartRef.current = false
    } else if (type === 'stop' && mode === 'less_line') {
      checkSaveLesslineRef.current = false
    } else if (type === 'stop' && mode === 'normal') {
      checkSaveStopRef.current = false
    }

    setSubmitting(false)
  }

  const handleSaveStart = useCallback(
    async ({ list }: CalculationFormType) => {
      if (startDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.startValue || '',
            liquidTemperature: values.liquidTemperature?.startValue || '',
            vaporTemperature: values.vaporTemperature?.startValue || '',
            vaporPressure: values.vaporPressure?.startValue || '',
            densityInVac: values.densityInVac?.startValue || '',
            molecularWeight: values.molecularWeight?.startValue || '',
          },
          startDatetime,
          'start',
          'normal'
        )
      }
    },
    [startDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleSaveLessLine = useCallback(
    async ({ list }: CalculationFormType) => {
      if (stopLesslineDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.stopLesslineValue || '',
            liquidTemperature:
              values.liquidTemperature?.stopLesslineValue || '',
            vaporTemperature: values.vaporTemperature?.stopLesslineValue || '',
            vaporPressure: values.vaporPressure?.stopLesslineValue || '',
            densityInVac: values.densityInVac?.stopLesslineValue || '',
            molecularWeight: values.molecularWeight?.stopLesslineValue || '',
          },
          stopLesslineDatetime,
          'stop',
          'less_line'
        )
      }
    },
    [stopLesslineDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleSaveStop = useCallback(
    async ({ list }: CalculationFormType) => {
      if (stopDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.stopValue || '',
            liquidTemperature: values.liquidTemperature?.stopValue || '',
            vaporTemperature: values.vaporTemperature?.stopValue || '',
            vaporPressure: values.vaporPressure?.stopValue || '',
            densityInVac: values.densityInVac?.stopValue || '',
            molecularWeight: values.molecularWeight?.stopValue || '',
          },
          stopDatetime,
          'stop',
          'normal'
        )
      }
    },
    [stopDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  async function handleManualDipCal(
    form: ManualDipCalculateForm,
    type: CalculateType,
    mode: CalculateMode
  ) {
    const valid = Object.keys(form).every(
      key => key === 'level' || !isZero(form[key as ManualDipParameterKey])
    )

    if (valid) {
      const reports = await client?.atgReport.manualCalculate({
        deliveryId,
        tankCode,
        type,
        mode,
        form,
        purity,
      })

      setData((oldData: any) => {
        const newData = { ...oldData, reports }
        return newData
      })

      methods.setValue('list', reports || [])

      if (type === 'start' && mode === 'normal') {
        setStartDatetime(data?.startDatetime ?? null)
        canSaveStart.current = true
        setStartDateChange(false)
      } else if (type === 'stop' && mode === 'less_line') {
        setStopLesslineDatetime(data?.stopLesslineDatetime ?? null)
        canSaveLessline.current = true
        setLesslineDateChange(false)
      } else if (type === 'stop' && mode === 'normal') {
        setStopDatetime(data?.stopDatetime ?? null)
        canSaveStop.current = true
        setStopDateChange(false)
      }
    } else {
      error({ message: 'ค่าที่กรอกต้องไม่เป็น 0' })
    }
  }

  function isZero(value: string) {
    return value && Number(value) === 0
  }

  async function manualDipStartCal() {
    const values = manualDipMethods.getValues()

    await handleManualDipCal(
      {
        level: values.level?.startValue || '',
        liquidTemperature: values.liquidTemperature?.startValue || '',
        vaporTemperature: values.vaporTemperature?.startValue || '',
        vaporPressure: values.vaporPressure?.startValue || '',
        densityInVac: values.densityInVac?.startValue || '',
        molecularWeight: values.molecularWeight?.startValue || '',
      },
      'start',
      'normal'
    )
  }

  async function manualDipStopLesslineCal() {
    const values = manualDipMethods.getValues()

    await handleManualDipCal(
      {
        level: values.level?.stopLesslineValue || '',
        liquidTemperature: values.liquidTemperature?.stopLesslineValue || '',
        vaporTemperature: values.vaporTemperature?.stopLesslineValue || '',
        vaporPressure: values.vaporPressure?.stopLesslineValue || '',
        densityInVac: values.densityInVac?.stopLesslineValue || '',
        molecularWeight: values.molecularWeight?.stopLesslineValue || '',
      },
      'stop',
      'less_line'
    )
  }

  async function manualDipStopCal() {
    const values = manualDipMethods.getValues()

    await handleManualDipCal(
      {
        level: values.level?.stopValue || '',
        liquidTemperature: values.liquidTemperature?.stopValue || '',
        vaporTemperature: values.vaporTemperature?.stopValue || '',
        vaporPressure: values.vaporPressure?.stopValue || '',
        densityInVac: values.densityInVac?.stopValue || '',
        molecularWeight: values.molecularWeight?.stopValue || '',
      },
      'stop',
      'normal'
    )
  }

  function resetForm(type: CalculateType, mode: CalculateMode) {
    const values = manualDipMethods.getValues()
    const newform = Object.keys(values).reduce((res, key) => {
      return {
        ...res,
        [key]: {
          ...values[key as ManualDipParameterKey],
          ...(type === 'start' &&
            mode === 'normal' && {
              startValue: null,
            }),
          ...(type === 'stop' &&
            mode === 'less_line' && {
              stopLesslineValue: null,
            }),
          ...(type === 'stop' &&
            mode === 'normal' && {
              stopValue: null,
            }),
        },
      }
    }, {})

    manualDipMethods.reset(newform)
  }

  function handleStartDatetimeChange(datetime: string, type: string) {
    setStartDatetime(datetime)
    canSaveStart.current = false
    if (type === 'select') setStartDateChange(true)
  }

  function handleStopDatetimeChange(datetime: string, type: string) {
    setStopDatetime(datetime)
    canSaveStop.current = false
    if (type === 'select') setStopDateChange(true)
  }

  function handleStopLesslineDatetimeChange(datetime: string, type: string) {
    setStopLesslineDatetime(datetime)
    canSaveLessline.current = false
    if (type === 'select') setLesslineDateChange(true)
  }

  function handleCheckSaveStartChange() {
    setCanApprove(false)
    if (!checkSaveStartRef.current) checkSaveStartRef.current = true
  }

  function handleCheckSaveLesslineChange() {
    setCanApprove(false)
    if (!checkSaveLesslineRef.current) checkSaveLesslineRef.current = true
  }

  function handleCheckSaveStopChange() {
    setCanApprove(false)
    if (!checkSaveStopRef.current) checkSaveStopRef.current = true
  }

  function initialManualDipForm() {
    if (config?.manualDip?.start?.parameter && data?.reports) {
      const form = Object.keys(config?.manualDip?.start?.parameter).reduce(
        (res, key) => {
          if (
            config?.manualDip?.start?.parameter[key as ManualDipParameterKey]
              .state
          ) {
            const old = data.reports.find(
              v =>
                v.keyMapParameter ===
                config?.manualDip?.start?.parameter[
                  key as ManualDipParameterKey
                ].keyMapParameter
            )

            return {
              ...res,
              [key]: {
                startValue:
                  config?.manualDip?.start?.parameter[
                    key as ManualDipParameterKey
                  ].value,
                stopLesslineValue:
                  config?.manualDip?.stopLessline?.parameter[
                    key as ManualDipParameterKey
                  ].value,
                stopValue:
                  config?.manualDip?.stop?.parameter[
                    key as ManualDipParameterKey
                  ].value,
                constraintMax: old?.constraintMax,
                constraintMin: old?.constraintMin,
                decimalPoint: old?.decimalPoint,
                oldStartValue: old?.startValue,
                oldStopLesslineValue: old?.stopLesslineValue,
                oldStopValue: old?.stopValue,
              },
            }
          }

          return res
        },
        {}
      )

      manualDipMethods.reset(form)
    }
  }

  const retrieveLastSave = useCallback(() => {
    setPurity(config?.productPurity?.value || '')
    initialManualDipForm()
    getAtgReport()
    canSaveStart.current = true
    canSaveStop.current = true
    canSaveLessline.current = true

    success({
      message: 'Retrieve last save successful.',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, data])

  function handleManualFormChange(type: CalculateType, mode: CalculateMode) {
    if (type === 'start' && mode === 'normal' && canSaveStart) {
      canSaveStart.current = false
    } else if (type === 'stop' && mode === 'normal' && canSaveStop) {
      canSaveStop.current = false
    } else if (type === 'stop' && mode === 'less_line' && canSaveLessline) {
      canSaveLessline.current = false
    }
  }

  const value: ATGReportResponse = {
    isLoading,
    level: data?.level,
    reports: data?.reports || [],
    disableStopLesslineForm: !stopLesslineState,
    disableStopForm: !stopState,
    isSubmitting,
    startDatetime,
    stopLesslineDatetime,
    stopDatetime,
    capacity: data?.capacity || 0,
    config,
    loadingConfig: isLoading,
    vesselLoadMethods,
    purity,
    manualDipMethods,
    methods,
    summaryMethods,
    summaryReport: data?.summaryReport,
    checkSaveStart: checkSaveStartRef.current,
    checkSaveStop: checkSaveStopRef.current,
    checkSaveLessline: checkSaveLesslineRef.current,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    calculationStart,
    calculationLessLine,
    calculationStop,
    handleSaveStart,
    handleSaveLessLine,
    handleSaveStop,
    handleStartDatetimeChange,
    handleStopDatetimeChange,
    handleStopLesslineDatetimeChange,
    manualDipStartCal,
    manualDipStopLesslineCal,
    manualDipStopCal,
    setPurity,
    retrieveLastSave,
    handleCheckSaveStartChange,
    handleCheckSaveLesslineChange,
    handleCheckSaveStopChange,
    handleManualFormChange,
    calStartAutoSave,
    canApprove,
  }

  return value
}
