import { Expose } from 'class-transformer'
import { Column } from 'react-table'

import { Tank } from '@features/tank/interfaces/TankType'
import { ReportHeader } from '@features/report-header/interfaces/ReportHeaderType'

export interface CompanyFormType {
  id?: string
  code: string
  name: string
  description: string
  phoneNumber: string
  active: string
}

interface ColumnType {
  id: number
  code: string
  name: string
  active: boolean
}

export interface CellType {
  record: ColumnType
}

export interface CompanyPageProps {
  data: ColumnType[]
  columns: Column[]
  loading: boolean
  handleCreate: () => void
}

export interface CompanyFormProps {
  initialValues: CompanyFormType
  loading?: boolean
  onSubmit: (form: CompanyFormType) => Promise<void>
}

export class CompanyType {
  @Expose({ name: 'is_active' }) active: boolean

  @Expose() id: number

  @Expose() code: string

  @Expose() name: string

  @Expose() description: string

  @Expose({ name: 'phone_number' }) phoneNumber: string

  @Expose() tanks: Tank[]

  @Expose({ name: 'report_headers' }) reportHeaders: ReportHeader[]
}
