import { useCallback, useState } from 'react'
import hoist from 'hoist-non-react-statics'
import { useParams } from 'react-router-dom'

import {
  GoalSeekFormType,
  GoalSeekProps,
} from '@features/tracking-work/interfaces/GoalSeekType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { useAppClient } from '@lib/useAppClient'
import { getErrorMessage } from '@lib/utils'
import { useAlert } from '@lib/useAlert'

export function withGoalSeek(Component: React.FC<GoalSeekProps>) {
  function WithGoalSeek() {
    const client = useAppClient()
    const { id } = useParams()
    const {
      atgConfig,
      selectedTankCode,
      isSubmitting,
      isAtgSubmitting,
      hideAction,
    } = useDeliveryReport()
    const { error, success } = useAlert()
    const [level, setLevel] = useState<string>('')
    const [total, setTotal] = useState<string>('')

    const handleSubmit = useCallback(async (form: GoalSeekFormType) => {
      let isError = false
      const response = await client?.atgReport
        .goalSeek({
          deliveryId: id || '',
          tankCode: selectedTankCode,
          value: form.inputTarget,
        })
        .catch(e => {
          isError = true
          error({ message: getErrorMessage(e) })
        })
      if (!isError && response) {
        setLevel(response.levelStop || '')
        setTotal(response.total || '')
        success({ message: 'Goal seek successful.' })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pageProps = {
      disabled: !atgConfig?.goalSeek?.state,
      level,
      total,
      isSubmitting: isSubmitting || isAtgSubmitting,
      hideAction,
      handleSubmit,
    }

    return <Component {...pageProps} />
  }

  hoist(WithGoalSeek, Component)

  WithGoalSeek.displayName = `WithGoalSeek(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithGoalSeek
}
