import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useProductList() {
  const client = useAppClient()
  const { data = [] } = useQuery('products', () =>
    client?.product.getProductList().then(response => response)
  )

  return {
    data,
  }
}
