import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { AuthStateListenerType, RetryRequestTaskType } from './ClientType'
import { AccessTokenType } from './interfaces/AuthClientType'
import { AuthClient } from './collections/AuthClient'
import { RoleGroupClient } from './collections/RoleGroupClient'
import { CompanyClient } from './collections/CompanyClient'
import { UserClient } from './collections/UserClient'
import { ScreenClient } from './collections/ScreenClient'
import { ReportClient } from './collections/ReportClient'
import { TankClient } from './collections/TankClient'
import { ReportHeaderClient } from './collections/ReportHeaderClient'
import { ProductClient } from './collections/ProductClient'
import { TrackingWorkClient } from './collections/TrackingWorkClient'
import { ActivityClient } from './collections/ActivityClient'
import { ATGReportClient } from './collections/ATGReportClient'
import { DeliveryReportClient } from './collections/DeliveryReportClient'
import { MeterReportClient } from './collections/MeterReportClient'
import { BatchDocumentTrackingClient } from './collections/BatchDocumentTrackingClient'
import { DraftClient } from './collections/DraftClient'
import { MonthlyClient } from './collections/MonthlyClient'

function injectAuthorizationToken(headers: any, token: string) {
  return { ...headers, Authorization: `Bearer ${token}` }
}

export class Client {
  isRefreshingAccessToken = false

  authStateListener: AuthStateListenerType[] = []

  retryRequestTasks: RetryRequestTaskType[] = []

  constructor(private client: AxiosInstance) {
    this.setupClient()
  }

  public auth = new AuthClient(this.client, this.authStateListener)

  public roleGroup = new RoleGroupClient(this.client)

  public company = new CompanyClient(this.client)

  public user = new UserClient(this.client)

  public screen = new ScreenClient(this.client)

  public report = new ReportClient(this.client)

  public tank = new TankClient(this.client)

  public reportHeader = new ReportHeaderClient(this.client)

  public product = new ProductClient(this.client)

  public trackingWork = new TrackingWorkClient(this.client)

  public activity = new ActivityClient(this.client)

  public atgReport = new ATGReportClient(this.client)

  public delivery = new DeliveryReportClient(this.client)

  public meterReport = new MeterReportClient(this.client)

  public batchReport = new BatchDocumentTrackingClient(this.client)

  public draft = new DraftClient(this.client)

  public monthlyReport = new MonthlyClient(this.client)

  onAuthStateChange(listener: AuthStateListenerType) {
    this.authStateListener.push(listener)

    return () => this.authStateListener.filter(l => listener === l)
  }

  setupClient() {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      if (this.auth.accessToken) {
        return this.configWithAuthorization(config, this.auth.accessToken)
      }

      return config
    })

    this.client.interceptors.response.use(
      response => response,
      error => {
        if (!error.isAxiosError) {
          return Promise.reject(error)
        }

        if (!this.isAccessTokenExpired(error)) {
          return Promise.reject(error)
        }

        if (!this.isRefreshingAccessToken) {
          this.isRefreshingAccessToken = true
          this.auth
            .refreshAccessToken()
            .then(
              this.handleRefreshAccessTokenSuccess.bind(this),
              this.handleRefreshAccessTokenFail.bind(this)
            )
        }

        const retry = this.retry(error)

        return retry
      }
    )
  }

  configWithAuthorization(config: AxiosRequestConfig, token: string) {
    const { headers = {} } = config

    if (headers.Authorization) {
      return config
    }

    return {
      ...config,
      headers: injectAuthorizationToken(headers, token),
    }
  }

  retry(error: any) {
    return new Promise((resolve, reject) => {
      this.retryRequestTasks.push(accessTokenOrError => {
        if (typeof accessTokenOrError !== 'string') {
          reject(accessTokenOrError)
          return
        }

        const config = { ...error.config }
        config.headers = injectAuthorizationToken(
          error.config.headers,
          accessTokenOrError
        )

        resolve(this.client.request(config))
      })
    })
  }

  retryRequestQueues(accessTokenOrError: AccessTokenType) {
    this.retryRequestTasks.forEach(queue => queue(accessTokenOrError))

    this.retryRequestTasks = []
  }

  handleRefreshAccessTokenSuccess() {
    this.isRefreshingAccessToken = false
    this.retryRequestQueues(this.auth.accessToken)
  }

  handleRefreshAccessTokenFail(error: string) {
    this.isRefreshingAccessToken = false
    this.retryRequestQueues(error)
  }

  isAccessTokenExpired(error: any) {
    return (
      error.config &&
      error.config.url !== `/api/auth/token` &&
      error.config.url !==
        `/api/auth/refresh?refresh_token=${this.auth.refreshToken}` &&
      error.response &&
      error.response.status === 401
    )
  }
}
