import { Outlet } from 'react-router-dom'

import { ScreenListProvider } from '@features/screen/hooks/useScreenList'
import { RoleGroupPage } from './pages/RoleGroupPage'
import { RoleGroupCreatePage } from './pages/RoleGroupCreatePage'
import { RoleGroupEditPage } from './pages/RoleGroupEditPage'

export const roleGroupRoutes = [
  {
    path: 'role-group',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <RoleGroupPage />,
      },
      {
        path: 'create',
        element: (
          <ScreenListProvider>
            <RoleGroupCreatePage />
          </ScreenListProvider>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <ScreenListProvider>
            <RoleGroupEditPage />
          </ScreenListProvider>
        ),
      },
    ],
  },
]
