import React, { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useAuthentication } from '@features/authentication/hooks/useAuthentication'
import { getQueryString } from '@lib/utils'

export function withAuthenticatedGuard(
  Component: React.FC<FunctionComponentType>
) {
  function WithAuthenticatedGuard(props: FunctionComponentType) {
    const { isAuthenticated } = useAuthentication()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const redirectTo =
      searchParams.get('redirectTo') ?? encodeURIComponent(pathname)

    useEffect(() => {
      if (isAuthenticated) return

      const queryParams = getQueryString(searchParams)

      navigate({
        pathname: '/auth/sign-in',
        search: `?redirectTo=${redirectTo}&${queryParams}`,
      })
    }, [isAuthenticated, redirectTo, navigate, pathname, searchParams])

    if (!isAuthenticated) return null
    return <Component {...props} />
  }

  hoist(WithAuthenticatedGuard, Component)

  WithAuthenticatedGuard.type = 'AUTH_GUARD'
  WithAuthenticatedGuard.displayName = `withAuthenticatedGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithAuthenticatedGuard
}
