import { useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { ComponentProps } from '@features/user/interfaces/UserType'
import { InputField } from '@components/fieldV2/InputField'
import { RadioGroupField } from '@components/fieldV2/RadioGroupField'
import { SelectField } from '@components/fieldV2/SelectField'
import { Loading } from '@components/Loading'

import styles from './index.module.scss'

const schema = yup.object().shape({
  companyId: yup
    .number()
    .typeError('Company code is a required field')
    .required('Company code is a required field'),
  roleGroupId: yup
    .number()
    .typeError('Role group code is a required field')
    .required('Role group code is a required field'),
  firstName: yup.string().required('First name is a required field'),
  lastName: yup.string().required('Last name is a required field'),
  username: yup.string().email().required('Username is a required field'),
  password: yup.string(),
  confirmPassword: yup.string().when('password', {
    is: (val: string) => val && val.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('password')], 'Both password need to be the same'),
  }),
})

export function UserForm({
  initialValues,
  companyOptions,
  roleGroupOptions,
  loading = false,
  handleSubmit,
}: ComponentProps) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })

  const { isSubmitting } = methods.formState

  useEffect(() => {
    methods.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  if (loading) return <Loading />

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <RadioGroupField
          name='active'
          label='Status'
          radioGroups={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
        />
        <SelectField
          options={companyOptions}
          className='mt'
          name='companyId'
          label='Company Code'
          placeholder='Select Company Code'
          disabled={isSubmitting}
        />
        <SelectField
          options={roleGroupOptions}
          className='mt'
          name='roleGroupId'
          label='Role Group Code'
          placeholder='Select Role Group Code'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          name='firstName'
          label='First Name'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          name='lastName'
          label='Last Name'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          name='username'
          label='Username'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          type='password'
          name='password'
          label='Password'
          disabled={isSubmitting}
        />
        <InputField
          className='mt'
          type='password'
          name='confirmPassword'
          label='Confirm Password'
          disabled={isSubmitting}
        />
        <Button
          className='mt'
          variant='contained'
          color='primary'
          type='submit'
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={28} /> : 'Save'}
        </Button>
      </form>
    </FormProvider>
  )
}
