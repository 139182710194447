import hoist from 'hoist-non-react-statics'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

export function withAppLayout(Component: React.FC<FunctionComponentType>) {
  function WithAppLayout(props: FunctionComponentType) {
    return <Component {...props} />
  }

  hoist(WithAppLayout, Component)

  WithAppLayout.displayName = `withAppLayout(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithAppLayout
}
