import { Expose } from 'class-transformer'

export class TankProduct {
  @Expose() id: number

  @Expose() tankCode: string

  @Expose() typeGroupCode: string

  @Expose() typeCode: string

  @Expose() productCode: string

  @Expose() volPerMM: number

  @Expose() deductVolume: number

  @Expose() referenceHeight: number

  @Expose() appUsable: number

  @Expose() available: number

  @Expose() isActive: boolean
}
