import { ConfirmModal } from '@components/ConfirmModal'
import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { AdminSearchForm } from '@features/monthly-report/components/AdminSearchForm'
import { AdminPageProps } from '@features/monthly-report/interfaces/AdminType'

import styles from './index.module.scss'

export function AdminPage({
  columns,
  data,
  modalName,
  payload,
  isLoading,
  isSubmitting,
  handleApprove,
  handleReject,
  closeModal,
}: AdminPageProps) {
  return (
    <Container>
      <AdminSearchForm />

      <Table columns={columns} data={data} loading={isLoading} />

      <ConfirmModal
        open={modalName === 'approve'}
        isSubmitting={isSubmitting}
        handleConfirm={handleApprove}
        handleClose={closeModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to Approve <span>{payload?.product}</span> ?
          </strong>
        </div>
      </ConfirmModal>

      <ConfirmModal
        open={modalName === 'reject'}
        isSubmitting={isSubmitting}
        handleConfirm={handleReject}
        handleClose={closeModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to Reject <span>{payload?.product}</span> ?
          </strong>
        </div>
      </ConfirmModal>
    </Container>
  )
}
