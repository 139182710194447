import { SUMMARY_DAILY_REPORT } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { SummaryDailyReportPage } from './SummaryDailyReportPage'
import { withSummaryDailyReportPage } from './withSummaryDailyReportPage'

const ConnectedSummaryDailyReportPage = withPermissionGuard(
  withSummaryDailyReportPage(SummaryDailyReportPage),
  SUMMARY_DAILY_REPORT,
  'read'
)

export { ConnectedSummaryDailyReportPage as SummaryDailyReportPage }
