import { createContext, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { MonthlyReportType } from '@features/monthly-report/interfaces/MonthlyReportType'
import { useAppClient } from '@lib/useAppClient'

interface Response {
  data: MonthlyReportType[]
  isLoading: boolean
  queryParams: {
    month: string
    year: string
  }
  setQueryParams: (value: any) => void
}

const UserMonthlyReportContext = createContext<any>({})

export function UserMonthlyReportProvider({ children }: FunctionComponentType) {
  const [searchParams] = useSearchParams()
  const [queryParams, setQueryParams] = useState(() => {
    const currentDatetime = DateTime.now().minus({ months: 1 })

    return {
      year: searchParams.get('year') || currentDatetime.toFormat('yyyy'),
      month: searchParams.get('month') || currentDatetime.toFormat('L'),
    }
  })

  const queryKey = ['user-monthly-report', queryParams]
  const client = useAppClient()
  const { data = [], isLoading } = useQuery(
    queryKey,
    () =>
      client?.monthlyReport
        .getMonthlyReportList({
          year: queryParams.year,
          month: queryParams.month,
        })
        .then(response => {
          return response
        }),
    {
      enabled: queryParams.month !== '' && queryParams.year !== '',
    }
  )

  const value = {
    data,
    isLoading,
    queryParams,
    setQueryParams,
  }

  return (
    <UserMonthlyReportContext.Provider value={value}>
      {children}
    </UserMonthlyReportContext.Provider>
  )
}

export function useUserMonthlyReport(): Response {
  return useContext(UserMonthlyReportContext)
}
