import { Outlet } from 'react-router-dom'

import { DailyReportPage } from './pages/DailyReportPage'
import { SummaryDailyReportPage } from './pages/SummaryDailyReportPage'
import { CalculateDailyReportPage } from './pages/CalculateDailyReportPage'

export const reportRoutes = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: 'inventory-daily-report',
        element: <Outlet />,
        children: [
          { path: '', element: <DailyReportPage /> },
          {
            path: 'calculate',
            element: <CalculateDailyReportPage />,
          },
        ],
      },
      { path: 'summary-daily-report', element: <SummaryDailyReportPage /> },
    ],
  },
]
