import { FormProvider } from 'react-hook-form'
import { Button } from '@material-ui/core'
import { DateTime } from 'luxon'

import { DatetimeField } from '@components/fieldV2/DatetimeField'
import { Box } from '@features/tracking-work/components/Box'
import { BlankFormProps } from '@features/tracking-work/interfaces/BlankFormType'

import styles from './index.module.scss'
import { Summary } from './Summary'
import { Note } from './Note'

export function BlankForm({
  note,
  hideAction,
  methods,
  isAtgSubmitting,
  isSubmitting,
  meterSummary,
  atgRetrieveLastSave,
}: BlankFormProps) {
  return (
    <Box title='ATG Mode Report' className='mt'>
      <FormProvider {...methods}>
        <form className={styles.container}>
          <div className={styles.retrieveSection}>
            {!hideAction ? (
              <Button
                variant='contained'
                color='primary'
                onClick={atgRetrieveLastSave}
                disabled={isAtgSubmitting || isSubmitting}
              >
                Retrieve Last Save
              </Button>
            ) : null}
          </div>
          <div className='text-center'>
            <div>
              <strong>START (ATG)</strong>
            </div>
            <DatetimeField
              name='startDate'
              disabled={hideAction}
              max={DateTime.now().toISO({ includeOffset: false })}
            />
          </div>
          <div className='text-center'>
            <div>
              <strong>STOP (ATG)</strong>
            </div>
            <DatetimeField
              name='endDate'
              disabled={hideAction}
              max={DateTime.now().toISO({ includeOffset: false })}
            />
          </div>

          <Note note={note} />

          <Summary
            meterLineAdjustment={meterSummary.finalSummary}
            disabled={hideAction}
          />
        </form>
      </FormProvider>
    </Box>
  )
}
