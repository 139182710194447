import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'

import {
  RecoverTrackingWorkForm,
  RejectSearchForm,
  RejectTrackingWorkList,
  RejectTrackingWorkListQueryParamType,
} from '@features/tracking-work/interfaces/TrackingWorkType'
import { useAppClient } from '@lib/useAppClient'

interface UseRejectTrackingWorkListResponse {
  data?: RejectTrackingWorkList
  isLoading: boolean
  queryParam: RejectTrackingWorkListQueryParamType
  refetch: () => Promise<any>
  recoverTrackingWork: (form: RecoverTrackingWorkForm) => Promise<void>
  handleRejectSearch: (form: RejectSearchForm) => void
  handleRejectPaginationChange: (page: number) => void
}

export function useRejectTrackingWorkList(): UseRejectTrackingWorkListResponse {
  const client = useAppClient()
  const [
    queryParam,
    setQueryParam,
  ] = useState<RejectTrackingWorkListQueryParamType>({
    workNo: '',
    month: 'all',
    year: DateTime.now().toFormat('yyyy'),
    size: 10,
    page: 1,
    count: 0,
  })
  const { data, isLoading, refetch } = useQuery(
    [
      'reject-tracking-work',
      queryParam.workNo,
      queryParam.month,
      queryParam.year,
      queryParam.page,
      queryParam.size,
    ],
    () =>
      client?.trackingWork
        .getRejectTrackingWorkList({
          workNo: queryParam.workNo,
          month: queryParam.month === 'all' ? '' : queryParam.month,
          year: queryParam.year,
          size: queryParam.size,
          page: queryParam.page,
        })
        .then(response => response)
  )

  async function recoverTrackingWork({ id }: RecoverTrackingWorkForm) {
    await client?.trackingWork.recoverTrackingWork({ id })
  }

  function handleRejectSearch(form: RejectSearchForm) {
    setQueryParam(prev => ({ ...prev, ...form, page: 1 }))
  }

  function handleRejectPaginationChange(page: number) {
    setQueryParam(prev => ({ ...prev, page }))
  }

  useEffect(() => {
    if (data && queryParam.count !== Math.ceil(data.total / data.size))
      setQueryParam(prev => ({
        ...prev,
        count: Math.ceil(data.total / data.size),
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    data,
    isLoading,
    queryParam,
    refetch,
    recoverTrackingWork,
    handleRejectSearch,
    handleRejectPaginationChange,
  }
}
