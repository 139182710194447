import { Outlet, useLocation } from 'react-router-dom'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { CalculationInformation } from '@features/draft-calculation/components/CalculationInformation'
import { CalculationPageProps } from '@features/draft-calculation/interfaces/CalculationType'

export function CalculationPage({
  data,
  loading,
  capacity,
}: CalculationPageProps) {
  const { pathname } = useLocation()
  return (
    <Container>
      <Breadcrumbs />

      {loading && !data ? (
        <Loading />
      ) : (
        <>
          <CalculationInformation
            workRequestNo={data?.workRequestNo || '-'}
            product={data?.product || '-'}
            categories={data?.activity || '-'}
            portOfLoading={data?.portOfLoading || '-'}
            fromCompany={data?.fromCompany || '-'}
            fromTank={data?.fromTanks.join(',') || '-'}
            toCompany={data?.toCompany || '-'}
            toTank={data?.toTanks.join(',') || '-'}
            capacity={capacity}
          />
          <Outlet />
        </>
      )}
    </Container>
  )
}
