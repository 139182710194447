import hoist from 'hoist-non-react-statics'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import {
  VerifyingFormType,
  VerifyingPageProps,
} from '@features/tracking-work/interfaces/VerifyingType'
import { useModal } from '@features/tracking-work/hooks/useModal'

export function withVerifyingPage(Component: React.FC<VerifyingPageProps>) {
  function WithVerifyingPage() {
    const {
      data,
      isLoading,
      atgCapacity,
      isSubmitting,
      hideAction,
      checkSaveStart,
      checkSaveLessline,
      checkSaveStop,
      handleAtgSaveChecklist,
      handleMeterSaveChecklist,
      handleSaveBlankForm,
      verifyingWork,
      canSubmit,
      handleSaveAll,
    } = useDeliveryReport()
    const { modal, closeModal, openModal } = useModal()

    function openConfirmVerifyModal() {
      openModal({
        modalName: 'verifying',
      })
    }

    function handleVerifying(form: VerifyingFormType) {
      verifyingWork({ ...form, note: form.note || '-' })
      closeModal()
    }

    function handleSaveChecklist() {
      if (data.isBlankForm) handleSaveBlankForm()
      else handleAtgSaveChecklist()
    }

    async function saveAll(meterMode: boolean){
      await handleSaveAll(meterMode)
    }

    const pageProps = {
      data,
      modal,
      loading: isLoading,
      capacity: atgCapacity,
      note: data?.note || '',
      isSubmitting,
      hideAction,
      checkSaveStart,
      checkSaveLessline,
      checkSaveStop,
      handleVerifying,
      openConfirmVerifyModal,
      closeModal,
      handleSaveAtg: handleSaveChecklist,
      handleSaveMeter: handleMeterSaveChecklist,
      canSubmit,
      handleSaveAll: saveAll,
    }

    return <Component {...pageProps} />
  }

  hoist(WithVerifyingPage, Component)

  WithVerifyingPage.displayName = `WithVerifyingPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithVerifyingPage
}
