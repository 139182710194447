import { memo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, InputAdornment, TextField } from '@material-ui/core'
import cx from 'classnames'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { InputField } from '@components/fieldV2/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { GoalSeekProps } from '@features/tracking-work/interfaces/GoalSeekType'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

const inputLevelProps = {
  endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
}

const totalProps = {
  endAdornment: <InputAdornment position='end'>tons in air</InputAdornment>,
}

const initialValues = {
  inputTarget: '',
}

const schema = yup.object().shape({
  inputTarget: yup.number().required('this field is required'),
})

export const GoalSeek = memo(
  ({
    disabled,
    level,
    total,
    isSubmitting,
    hideAction,
    handleSubmit,
  }: GoalSeekProps) => {
    const methods = useForm({
      defaultValues: initialValues,
      resolver: yupResolver(schema),
    })

    const { isSubmitting: formSubmitting } = methods.formState

    return (
      <Box
        className={cx({ [styles.disabled]: disabled })}
        title='Goal Seek (for recheck stop level)'
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <div className={styles.inputGroup}>
              <label>Input target :</label>
              <InputField
                className={styles.goalSeekInput}
                type='number'
                name='inputTarget'
                rightComponent={
                  <InputAdornment position='end'>tons in air</InputAdornment>
                }
                disabled={disabled || hideAction}
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Level Stop :</label>
              <TextField
                className={styles.goalSeekInput}
                size='small'
                variant='outlined'
                value={level || ''}
                InputProps={inputLevelProps}
                disabled
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Total (By ATG):</label>
              <TextField
                className={styles.goalSeekInput}
                size='small'
                variant='outlined'
                value={total || ''}
                InputProps={totalProps}
                disabled
              />
            </div>
            {!hideAction && (
              <div className={styles.calBtn}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting || disabled || formSubmitting}
                >
                  Cal
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </Box>
    )
  }
)
