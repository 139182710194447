import { View, Text, Image } from '@react-pdf/renderer'

import logo from '@assets/images/SCGC_Logo_Full Color_Clear_Space.png'
import styles from '@features/pdf/pages/MonthlyReport/styles'

interface HeaderProps {
  product: string
  periodFrom: string
  periodTo: string
}

export function Header({ product, periodFrom, periodTo }: HeaderProps) {
  return (
    <View style={styles.headerGroup}>
      <View
        style={[styles.detailRow, styles.spaceBetween, { marginBottom: 2 }]}
      >
        <View style={styles.detailRow}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
          <Text>MAP TA PHUT TANK TERMINAL CO., LTD</Text>
        </View>
        <Text>PS-F-0007-011</Text>
      </View>
      <View
        style={[
          styles.box,
          styles.p6,
          styles.w100,
          styles.textCenter,
          styles.mb,
        ]}
      >
        <Text style={styles.strong}>SUMMARY OF PRODUCT MOVEMENT</Text>
      </View>
      <View style={[styles.dFlex, styles.flexRow, styles.mt]}>
        <View style={[styles.box, styles.p6, styles.w50, styles.mr]}>
          <Text>
            <Text style={styles.strong}>Product: </Text>
            {product}
          </Text>
        </View>
        <View
          style={[
            styles.box,
            styles.p6,
            styles.w50,
            styles.ml,
            styles.dFlex,
            styles.flexRow,
            styles.spaceBetween,
          ]}
        >
          <Text style={styles.strong}>Period: </Text>
          <Text>{periodFrom}</Text>
          <Text style={styles.strong}>to</Text>
          <Text>{periodTo}</Text>
        </View>
      </View>
    </View>
  )
}
