import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    padding: '10px 14px',
    fontFamily: 'Roboto',
    fontSize: 8,
  },
  headerGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  imageWrapper: {
    width: 90,
    marginLeft: '25px'
  },
  companyName: {
    fontWeight: 700,
    width: 400,
    fontSize: 12,
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
  },
  information: {
    width: '49%',
    display: 'flex',
    flexDirection: 'row',
  },
  informationHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 500,
    flex: 1,
  },
  informationValue: {
    textAlign: 'center',
    flex: 2,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: 4,
  },
  strong: {
    fontWeight: 500,
  },
  reportHeader: {
    fontWeight: 500,
    flex: 2,
  },
  reportValue: {
    flex: 1,
  },
  main: {
    color: '#ff0000',
  },
  summaryHeader: {
    fontWeight: 500,
    flex: 1,
  },
  summaryValueContainer: {
    flex: 1,
  },
  summaryValueGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    width: '100%',
  },
  summaryValue: {
    flex: 1,
  },
  signatureContainer: {
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  signature: {
    borderBottomWidth: 1,
    borderBottomStyle: 'dotted',
    marginBottom: 4,
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  note: {
    marginLeft: 10,
  },
})
