import { Expose } from 'class-transformer'

export class Tank {
  @Expose() id: number

  @Expose({ name: 'tank_code' }) code: string

  @Expose({ name: 'tank_name' }) name: string

  @Expose({ name: 'tank_desc' }) description: string | null

  @Expose({ name: 'is_active' }) active: true
}
