import { useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import {
  useFormContext,
  useForm,
  FormProvider,
  useWatch,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import * as yup from 'yup'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { InputField } from '@components/fieldV2/InputField'

import {
  CalculateDailyReportForm,
  CalculateDailyReport,
  CalculateDailyReportPageType,
} from '@features/report/interfaces/CalculateDailyReportType'
import styles from './index.module.scss'

const schema = yup.object().shape({
  list: yup.array().of(
    yup.object().test(function (row, { path }) {
      const value = Number(row.value)

      if (row.topicType === 'H' || row.topicType === 'F') return true

      if (!value && value !== 0) {
        return this.createError({
          path: `${path}.value`,
          message: 'this field is required',
        })
      }

      return true
    })
  ),
})

interface CalculateDailyReportItemPropsType {
  item: CalculateDailyReport
  index: number
}

function CalculateDailyReportItem({
  item,
  index,
}: CalculateDailyReportItemPropsType) {
  const { setValue } = useFormContext()

  switch (item.topicType) {
    case 'H':
      return (
        <div className={styles.header} key={index}>
          <strong>{item.topic}</strong>
        </div>
      )
    case 'C':
    case 'I':
      return (
        <Grid
          container
          spacing={4}
          key={index}
          className={cx({ [styles.calculate]: item.topicType === 'C' })}
        >
          <Grid item xs={6} className={styles.topic}>
            {item.topic}
          </Grid>
          <Grid item xs={6} className={styles.value}>
            <InputField
              name={`list.${index}.value`}
              type='number'
              handleChange={(e: any) => {
                const number = e.target.value.split('.')

                if (item.decimalPoint && number.length > 1)
                  setValue(
                    `list.${index}.value`,
                    `${number[0]}.${number[1].slice(0, item.decimalPoint)}`
                  )
                else setValue(`list.${index}.value`, number[0])
              }}
            />
          </Grid>
        </Grid>
      )
    default:
      return (
        <Grid container spacing={4} key={index}>
          <Grid item xs={6} className={styles.topic}>
            {item.topic}
          </Grid>
          <Grid item xs={6} className={styles.value}>
            {item.value}
          </Grid>
        </Grid>
      )
  }
}

export function CalculateDailyReportPage({
  data,
  loading,
  productName,
  tankCode,
  date,
  isSubmitting,
  retrieveRecord,
  handleCalculate,
  handleSave,
}: CalculateDailyReportPageType) {
  const methods = useForm<CalculateDailyReportForm>({
    mode: 'onChange',
    defaultValues: { list: [] },
    resolver: yupResolver(schema),
  })
  const list = useWatch({
    control: methods.control,
    name: 'list',
  })

  useEffect(() => {
    methods.reset({
      list: data.map(row => ({
        ...row,
        value:
          row.topicType === 'H' || row.topicType === 'F'
            ? row.value
            : row.value
            ? row.value.replaceAll(',', '')
            : '0',
      })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Container>
      <Breadcrumbs />
      <div className={cx('mb', styles.infomation)}>
        <span>
          <strong>Date: </strong>
          {date}
        </span>
        <span>
          <strong>Product: </strong>
          {productName}
        </span>
        <span>
          <strong>Tank No: </strong>
          {tankCode}
        </span>
      </div>
      <FormProvider {...methods}>
        <form>
          {loading ? (
            <Loading />
          ) : (
            list.map((item: CalculateDailyReport, index: number) => (
              <CalculateDailyReportItem key={index} item={item} index={index} />
            ))
          )}
          <div className={styles.footer}>
            <div>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={loading || isSubmitting}
                onClick={() => handleCalculate(methods.getValues())}
              >
                Calculate
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={loading || isSubmitting}
                onClick={retrieveRecord}
              >
                Retrieve Last record
              </Button>
            </div>

            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={loading || isSubmitting}
              onClick={() => handleSave(methods.getValues())}
            >
              Save (Overwrite)
            </Button>
          </div>
        </form>
      </FormProvider>
    </Container>
  )
}
