import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import hoist from 'hoist-non-react-statics'

import { VesselLoadType } from '@features/tracking-work/interfaces/MeterReportType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

export function withVesselLoad(Component: React.FC<VesselLoadType>) {
  function WithVesselLoad() {
    const {
      meterReport,
      meterSummary,
      meterVesselLoadMethods,
      isSubmitting,
      isMeterSubmitting,
      hideAction,
      meterCanCalVesselLoad,
    } = useDeliveryReport()

    const pageProps = {
      summary: meterSummary,
      hasLessline: meterCanCalVesselLoad,
      disabled:
        isSubmitting ||
        isMeterSubmitting ||
        !meterCanCalVesselLoad ||
        hideAction,
    }
    return (
      <FormProvider {...meterVesselLoadMethods}>
        <Component {...pageProps} />
      </FormProvider>
    )
  }

  hoist(WithVesselLoad, Component)

  WithVesselLoad.displayName = `WithVesselLoad(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithVesselLoad
}
