import { memo } from 'react'
import cx from 'classnames'
import { FormProvider } from 'react-hook-form'

import {
  CalculationDetailType,
  CalculationFormPropsType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'
import { CalculationDetailAction } from './CalculationDetailAction'
import { CalculationDetailInput } from './CalculationDetailInput'



export const CalculationForm = memo(
  ({
    reports,
    disableStopLesslineForm,
    disableStopForm,
    isSubmitting,
    readOnly,
    hideAction,
  }: CalculationFormPropsType) => {
    const {
      data,
      atgMethods,
      selectedTankCode,
      modal,
      handleAtgSaveStartAndSaveChecklist,
      handleAtgSaveLesslineAndSaveChecklist,
      handleAtgSaveStopAndSaveChecklist,
      handleCheckSaveStartChange,
      handleCheckSaveLesslineChange,
      handleCheckSaveStopChange,
      closeModal,
      confirmAtgSaveStartAndSaveChecklist,
      confirmAtgSaveLesslineAndSaveChecklist,
      confirmAtgSaveStopAndSaveChecklist,
    } = useDeliveryReport()
    
    const keepData = reports
    .filter((dt,index) => (dt.topicType === 'H' && index > 0 || index === reports.length-1))
    .map((dt,index,array) => (index === array.length-1?dt.sort:dt.sort-1))

    const handleOnClickMore = (e: any) => {
      
      const allEl = document.querySelectorAll(`.showhide${e.target.dataset.indextopic}`)
      allEl.forEach((el) => {
        if(el.classList.contains(cx(styles.atgHideReport))){
          el.classList.remove(cx(styles.atgHideReport))
          e.target.innerText = "SHOW LESS.."
        }else{
          el.classList.add(cx(styles.atgHideReport))
          e.target.innerText = "SHOW MORE.."
        }
        
      })
    }
    return (
      <FormProvider {...atgMethods}>
        <form>
        <div className={styles.atgReportCalculationDetailLabel} />
        <CalculationDetailAction
            disabled={isSubmitting}
            title='Save Start (ATG)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='start'
            mode='normal'
            modal={modal}
            handleSave={() =>
              handleAtgSaveStartAndSaveChecklist(atgMethods.getValues())
            }
            handleConfirm={confirmAtgSaveStartAndSaveChecklist}
            closeModal={closeModal}
          />

          <CalculationDetailAction
            disabled={disableStopLesslineForm || isSubmitting}
            title='Save Stop (Less-Line)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='stop'
            mode='less_line'
            modal={modal}
            handleSave={() =>
              handleAtgSaveLesslineAndSaveChecklist(atgMethods.getValues())
            }
            handleConfirm={confirmAtgSaveLesslineAndSaveChecklist}
            closeModal={closeModal}
          />
          <CalculationDetailAction
            disabled={disableStopForm || isSubmitting}
            title='Save Stop (ATG)'
            hideAction={hideAction}
            deliveryId={data.id}
            tankCode={selectedTankCode}
            type='stop'
            mode='normal'
            modal={modal}
            handleSave={() =>
              handleAtgSaveStopAndSaveChecklist(atgMethods.getValues())
            }
            handleConfirm={confirmAtgSaveStopAndSaveChecklist}
            closeModal={closeModal}
          />
          {reports.map((row: CalculationDetailType, index: number) => (

            <CalculationDetailInput
              key={index}
              data={row}
              index={index}
              disableStopLesslineForm={disableStopLesslineForm}
              disableStopForm={disableStopForm}
              readOnly={readOnly}
              handleCheckSaveStartChange={handleCheckSaveStartChange}
              handleCheckSaveLesslineChange={handleCheckSaveLesslineChange}
              handleCheckSaveStopChange={handleCheckSaveStopChange}
              handleClick={handleOnClickMore}
              isEndofTopic={keepData.includes(row.sort)}
            />

          ))}
          
          
        </form>
      </FormProvider>
    )
  }
)
