import { USER_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { UserPage } from './UserPage'
import { withUserPage } from './withUserPage'

const ConnectedUserPage = withPermissionGuard(
  withUserPage(UserPage),
  USER_SETTING,
  'read'
)

export { ConnectedUserPage as UserPage }
