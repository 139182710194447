import { useWatch } from 'react-hook-form'

import { Loading } from '@components/Loading'
import { Box } from '@features/tracking-work/components/Box'
import { ATGReportPropsType } from '@features/tracking-work/interfaces/ATGReportType'
import { ActionForm } from '@features/tracking-work/components/ActionForm'
import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

import { Operation } from './Operation'
import { CalculationForm } from './CalculationForm'
import { Summary } from './Summary'

export function ATGReport({ className }: ATGReportPropsType) {
  const {
    isAtgLoading,
    atgLevel,
    atgStartDatetime,
    atgStopDatetime,
    atgStopLesslineDatetime,
    atgReports,
    disableAtgStopLesslineForm,
    disableAtgStopForm,
    isAtgSubmitting,
    atgConfig,
    atgMethods,
    atgVesselLoadMethods,
    readOnly,
    hideAction,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveLessline,
    canSaveStop,
    handleAtgCalculationStart,
    handleAtgCalculationLessLine,
    handleAtgCalculationStop,
    handleAtgStartDatetimeChange,
    handleAtgStopLesslineDatetimeChange,
    handleAtgStopDatetimeChange,
  } = useDraftDeliveryReport()

  const reports = useWatch({
    name: 'list',
    control: atgMethods.control,
  })

  const vesselLoads = useWatch({
    name: ['volume30', 'volume15', 'tonInAir', 'tonInVac'],
    control: atgVesselLoadMethods.control,
  })

  return (
    <Box className={className} title='ATG Mode Report'>
      {isAtgLoading ? (
        <Loading />
      ) : (
        <div className={styles.atgReportContent}>
          <Operation
            className={styles.atgReportOperation}
            pl={atgLevel?.pl || 0}
            ph={atgLevel?.ph || 0}
            hh={atgLevel?.hh || 0}
            ll={atgLevel?.ll || 0}
            beforeStop={atgLevel?.beforeStop || 0}
            beforeStopFiveMinutes={atgLevel?.beforeStopFiveMinutes || 0}
          />
          <ActionForm
            className={styles.atgReportActionForm}
            title='START (ATG)'
            value={atgStartDatetime}
            handleCalculation={handleAtgCalculationStart}
            setDatetime={handleAtgStartDatetimeChange}
            disabled={isAtgSubmitting}
            hideAction={hideAction}
            isDateChange={isStartDateChange}
          />
          <ActionForm
            className={styles.atgReportActionForm}
            title='STOP (Less-Line)'
            value={atgStopLesslineDatetime}
            handleCalculation={handleAtgCalculationLessLine}
            setDatetime={handleAtgStopLesslineDatetimeChange}
            disabled={isAtgSubmitting || disableAtgStopLesslineForm}
            hideAction={hideAction}
            isDateChange={isLesslineDateChange}
          />
          <ActionForm
            className={styles.atgReportActionForm}
            title='STOP(ATG)'
            value={atgStopDatetime}
            handleCalculation={handleAtgCalculationStop}
            setDatetime={handleAtgStopDatetimeChange}
            disabled={isAtgSubmitting || disableAtgStopForm}
            hideAction={hideAction}
            isDateChange={isStopDateChange}
          />

          <CalculationForm
            reports={atgReports}
            disableStopLesslineForm={disableAtgStopLesslineForm}
            disableStopForm={disableAtgStopForm}
            isSubmitting={isAtgSubmitting}
            readOnly={readOnly}
            hideAction={hideAction}
            canSaveStart={canSaveStart}
            canSaveLessline={canSaveLessline}
            canSaveStop={canSaveStop}
          />

          <Summary
            reports={reports || []}
            vesselLoads={vesselLoads}
            disableVesselLoad={!atgConfig?.vesselLoad?.state}
            canCalTotal={!disableAtgStopForm}
          />
        </div>
      )}
    </Box>
  )
}
