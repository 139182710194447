import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAppClient } from '@lib/useAppClient'
import {
  CalculateDailyReport,
  CalculateDailyReportPropsType,
} from '@features/report/interfaces/CalculateDailyReportType'
import { getErrorMessage } from '@lib/utils'
import { useAlert } from '@lib/useAlert'

export function useCalculate({
  tankCode,
  date,
}: CalculateDailyReportPropsType) {
  const queryKey = ['report', 'daily', 'calculate']
  const navigate = useNavigate()
  const client = useAppClient()
  const queryClient = useQueryClient()
  const { error, success } = useAlert()
  const [isSubmitting, setSubmitting] = useState(false)
  const { data = [], isLoading, refetch } = useQuery(queryKey, () =>
    client?.report
      .getCalculateDailyReport({ tankCode, date })
      .then(response => response)
  )

  function retrieveRecord() {
    setSubmitting(true)
    refetch()
      .then(() => success({ message: 'Retrieve last record successful.' }))
      .catch(e => error({ message: getErrorMessage(e) }))
      .finally(() => setSubmitting(false))
  }

  function calculate(form: CalculateDailyReport[]) {
    if (tankCode && date) {
      setSubmitting(true)
      client?.report
        .calculate({
          tankCode,
          date,
          form,
        })
        .then(response => {
          success({ message: 'Calculate successful.' })
          queryClient.setQueryData(queryKey, () => {
            return response
          })
        })
        .catch(e => error({ message: getErrorMessage(e) }))
        .finally(() => setSubmitting(false))
    }
  }

  function update(form: CalculateDailyReport[]) {
    if (tankCode && date) {
      setSubmitting(true)
      client?.report
        .saveOverwrite({ tankCode, date, form })
        .then(() => {
          navigate({
            pathname: '/inventory-daily-report',
            search: `?d=${date}`,
          })
        })
        .catch(e => {
          setSubmitting(false)
          error({ message: getErrorMessage(e) })
        })
    }
  }

  return {
    data,
    loading: isLoading,
    isSubmitting,
    retrieveRecord,
    calculate,
    update,
  }
}
