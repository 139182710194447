import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useDailyReportList({ date = '' }): any {
  const client = useAppClient()
  const { data = [], isLoading, error, isError } = useQuery(
    ['report', 'daily', date],
    () => client?.report.getDailyReports({ date }).then(response => response)
  )

  return {
    data,
    isLoading,
    error,
    isError,
  }
}
