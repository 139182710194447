import hoist from 'hoist-non-react-statics'
import { DateTime } from 'luxon'

import { UserPageProps } from '@features/monthly-report/interfaces/UserType'
import { useUserMonthlyReport } from '@features/monthly-report/hooks/useUserMonthlyReport'
import { ExportButton } from '@features/monthly-report/components/ExportButton'
import { MonthlyReportType } from '@features/monthly-report/interfaces/MonthlyReportType'

export function withUserPage(Component: React.FC<UserPageProps>) {
  function WithUserPage() {
    const { data, queryParams, isLoading } = useUserMonthlyReport()

    const columns = [
      {
        Header: DateTime.local(
          Number(queryParams.year),
          Number(queryParams.month)
        ).toFormat('LLLL yyyy'),
        accessor: 'name',
      },
      {
        Header: '',
        id: 'export',
        Cell: ({ record }: { record: MonthlyReportType }) => (
          <ExportButton
            id={record.id}
            year={record.year}
            month={record.month}
            version={record.name}
          />
        ),
      },
    ]

    const pageProps = {
      columns,
      data,
      isLoading,
    }

    return <Component {...pageProps} />
  }

  hoist(WithUserPage, Component)

  WithUserPage.displayName = `WithUserPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUserPage
}
