import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'

import {
  WorkOrderRequestNumberForm,
  WorkOrderRequestNumberFormPropsType,
} from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'

interface AddWorkModalType extends WorkOrderRequestNumberFormPropsType {
  open: boolean
}

export function AddWorkModal({
  open,
  initialValues,
  productOptions,
  handleClose,
  handleSubmit,
}: AddWorkModalType) {
  return (
    <Dialog
      className={styles.workModal}
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle className={styles.title}>
        Add Work Order Request No.
      </DialogTitle>
      <DialogContent>
        <WorkOrderRequestNumberForm
          initialValues={initialValues}
          productOptions={productOptions}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  )
}
