import hoist from 'hoist-non-react-statics'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

export function withTrackingWorkPage(
  Component: React.FC<FunctionComponentType>
) {
  function WithTrackingWorkPage() {
    return <Component />
  }

  hoist(WithTrackingWorkPage, Component)

  WithTrackingWorkPage.displayName = `WithTrackingWorkPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithTrackingWorkPage
}
