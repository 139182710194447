import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  RoleGroupFormType,
  RoleGroupType,
} from '@features/role-group/interfaces/RoleGroupType'

interface GetRoleGroupType {
  id: string
}

export class RoleGroupClient {
  constructor(private client: AxiosInstance) {}

  async getRoleGroupList(): Promise<RoleGroupType[]> {
    const response = await this.client.get('/api/role_groups')
    return response.data.map((roleGroup: any) =>
      plainToClass(RoleGroupType, roleGroup, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getRoleGroup({ id }: GetRoleGroupType): Promise<RoleGroupType> {
    const response = await this.client.get(`/api/role_groups/${id}`)
    return plainToClass(RoleGroupType, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async createRoleGroup({
    active,
    code,
    name,
    reportRole,
    screens,
  }: RoleGroupFormType): Promise<void> {
    await this.client.post('/api/role_groups', {
      status: active === 'true',
      code,
      name,
      report_role: reportRole,
      screens: screens.map(({ read, write, id: screenId }) => ({
        write,
        read,
        screen_id: screenId,
      })),
    })
  }

  async editRoleGroup({
    id,
    active,
    code,
    name,
    screens,
    reportRole,
  }: RoleGroupFormType) {
    await this.client.patch(`/api/role_groups/${id}`, {
      status: active === 'true',
      code,
      name,
      report_role: reportRole,
      screens: screens.map(({ read, write, id: screenId }) => ({
        write,
        read,
        screen_id: screenId,
      })),
    })
  }
}
