import { Button, TextField, InputAdornment } from '@material-ui/core'
import { Add, Search } from '@material-ui/icons'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Table } from '@components/Table'
import { RoleGroupPageType } from '@features/role-group/interfaces/RoleGroupType'
import { PermissionGuard } from '@lib/permissions'
import { ROLE_GROUP_SETTING } from '@lib/constants'

import styles from './index.module.scss'

export function RoleGroupPage({
  data,
  columns,
  loading,
  handleCreate,
}: RoleGroupPageType) {
  return (
    <Container>
      <Breadcrumbs />
      <div className={styles.group}>
        <PermissionGuard page={ROLE_GROUP_SETTING} mode='write'>
          <Button
            className={styles.add_btn}
            startIcon={<Add />}
            variant='contained'
            color='primary'
            onClick={handleCreate}
          >
            Role Group
          </Button>
        </PermissionGuard>
        {/* <TextField
          type='search'
          size='small'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
        /> */}
      </div>
      <Table
        columns={columns}
        data={data}
        className={styles.table}
        loading={loading}
      />
    </Container>
  )
}
