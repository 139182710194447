import hoist from 'hoist-non-react-statics'
import { FormProvider, useWatch } from 'react-hook-form'

import { VesselLoadType } from '@features/tracking-work/interfaces/VesselLoadType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

export function withVesselLoad(Component: React.FC<VesselLoadType>) {
  function WithVesselLoad() {
    const {
      atgConfig,
      atgMethods,
      atgVesselLoadMethods,
      hideAction,
    } = useDeliveryReport()

    const reports = useWatch({
      control: atgMethods.control,
      name: 'list',
    })

    const pageProps = {
      reports,
      disabled: !atgConfig?.vesselLoad?.state || hideAction,
    }

    return (
      <FormProvider {...atgVesselLoadMethods}>
        <Component {...pageProps} />
      </FormProvider>
    )
  }

  hoist(WithVesselLoad, Component)

  WithVesselLoad.displayName = `WithVesselLoad(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithVesselLoad
}
