import { memo } from 'react'
import { Formik, Form } from 'formik'
import { Button, InputAdornment, TextField } from '@material-ui/core'
import cx from 'classnames'

import { InputField } from '@components/fields/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { ProrateMWType } from '@features/tracking-work/interfaces/ProrateMWType'
import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

const inputProps = {
  endAdornment: <InputAdornment position='end'>g/mol</InputAdornment>,
}

const initialValues = {
  shoreBefore: '',
  shipBefore: '',
}

export const ProrateMW = memo(
  ({
    disabled,
    shoreAfter,
    isSubmitting,
    hideAction,
    handleSubmit,
  }: ProrateMWType) => {
    return (
      <Box
        className={cx({ [styles.disabled]: disabled })}
        title='Prorate MW - For Import '
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting: formSubmitting }) => (
            <Form>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  MW at SHORE BEFORE discharge :
                </label>
                <InputField
                  name='shoreBefore'
                  InputProps={inputProps}
                  disabled={disabled}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  MW at SHIP BEFORE discharge :
                </label>
                <InputField
                  name='shipBefore'
                  InputProps={inputProps}
                  disabled={disabled}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  MW at SHORE AFTER discharge :
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  value={shoreAfter || ''}
                  InputProps={inputProps}
                  disabled
                />
              </div>
              {!hideAction && (
                <div className={styles.calBtn}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isSubmitting || formSubmitting || disabled}
                  >
                    Cal
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    )
  }
)
