import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    padding: '10px 14px',
    fontFamily: 'Roboto',
    fontSize: 8,
  },
  logo: {
    width: 80,
    marginRight: 10,
  },
  imageWrapper: {
    width: 90,
    marginLeft: '40px'
  },
  dFlex: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },

  strong: {
    fontWeight: 700,
  },
  box: {
    borderWidth: 1,
    borderStyle: 'solid',
  },
  p6: {
    padding: 3,
  },
  pl2: {
    paddingLeft: 2,
  },
  pr2: {
    paddingRight: 2,
  },
  h20: {
    height: 20,
  },
  h12: {
    height: 12,
  },
  w100: {
    width: '100%',
  },
  w50: {
    width: '50%',
  },
  w30: {
    width: '30%',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  ml: {
    marginLeft: 1,
  },
  mr: {
    marginRight: 1,
  },
  mt: {
    marginTop: 1,
  },
  mb: {
    marginBottom: 1,
  },
  headerGroup: {
    fontSize: 10,
  },
  detailColumn2: {
    flex: 2,
  },
  detailColumn1: {
    flex: 1,
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderL: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
  },
  borderR: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
  },
  borderB: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  borderT: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
})
