import { Formik, Form } from 'formik'
import { Button } from '@material-ui/core'
import cx from 'classnames'

import { SearchProps } from '@features/draft-calculation/interfaces/DraftType'
import { InputField } from '@components/fields/InputField'
import { SelectField } from '@components/fields/SelectField'
import { monthOptions } from '@lib/constants'

import styles from './index.module.scss'

export function Search({
  className,
  yearOptions,
  initialValues,
  handleSubmit,
}: SearchProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <Form className={cx(className, styles.searchForm)}>
          <strong>Work No:</strong>
          <InputField name='workNo' />
          <strong>Month:</strong>
          <SelectField
            className={styles.select}
            name='month'
            options={monthOptions}
          />
          <strong>Year:</strong>
          <SelectField
            className={styles.select}
            name='year'
            options={yearOptions}
          />

          <Button variant='contained' color='primary' type='submit'>
            Search
          </Button>
        </Form>
      )}
    </Formik>
  )
}
