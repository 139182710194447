import { useTable } from 'react-table'
import cx from 'classnames'
import Pagination from '@material-ui/lab/Pagination'

import { Loading } from '@components/Loading'
import { TableType } from '@interfaces/common/DataTableType'
import styles from './index.module.scss'

export function Table({
  className,
  columns,
  cellProps,
  data,
  loading,
  page = 1,
  count,
  onPaginationChange,
}: TableType) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })
  const isEmpty = rows.length === 0

  return (
    <div className={styles.container}>
      <table
        {...getTableProps()}
        className={cx(styles.table, className)}
        id='table1'
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className={styles.th}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr>
              <td
                className={cx(styles.td, styles.empty)}
                colSpan={headerGroups[headerGroups.length - 1]?.headers.length}
              >
                <Loading />
              </td>
            </tr>
          ) : isEmpty ? (
            <tr>
              <td
                className={cx(styles.td, styles.empty)}
                colSpan={headerGroups[headerGroups.length - 1]?.headers.length}
              >
                <p>ไม่พบข้อมูล</p>
              </td>
            </tr>
          ) : (
            rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className={styles.td}>
                        {cell.render('Cell', {
                          records: rows.map(record => record.original),
                          record: row.original,
                          ...cellProps,
                        })}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          )}
        </tbody>
      </table>
      {count ? (
        <Pagination
          className='mt'
          count={count}
          color='primary'
          page={page}
          onChange={(_, newPage) =>
            onPaginationChange && onPaginationChange(newPage)
          }
        />
      ) : null}
    </div>
  )
}
