import { DRAFT_CALCULATION_PAGE } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { DraftCalculationPage } from './DraftCalculationPage'
import { withDraftCalculationPage } from './withDraftCalculationPage'

const ConnectedDraftCalculationPage = withPermissionGuard(
  withDraftCalculationPage(DraftCalculationPage),
  DRAFT_CALCULATION_PAGE,
  'read'
)

export { ConnectedDraftCalculationPage as DraftCalculationPage }
