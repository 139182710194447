import { Expose } from 'class-transformer'

export class Product {
  @Expose() id: number

  @Expose({ name: 'product_code' }) code: string

  @Expose({ name: 'product_name' }) name: string

  @Expose({ name: 'product_desc' }) description: string

  @Expose({ name: 'is_active' }) active: boolean
}
