import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  AdminSearchForm,
  RecoverTrackingWorkForm,
  RejectSearchForm,
  RejectTrackingWorkForm,
  TrackingWork,
  GetTrackingWorkPropsType,
  TrackingWorkList,
  RejectTrackingWorkList,
  ReviseWorkParam,
} from '@features/tracking-work/interfaces/TrackingWorkType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'

interface CopyWorkParam {
  deliveryId: number
}

export class TrackingWorkClient {
  constructor(private client: AxiosInstance) {}

  getQueries(form: any) {
    const keys = Object.keys(form)
    return keys.reduce((res: string[], key: any) => {
      if (form[key]) {
        return [...res, `${key}=${form[key]}`]
      }

      return res
    }, [])
  }

  async getTrackingWork({
    id,
  }: GetTrackingWorkPropsType): Promise<TrackingWork> {
    if (!id)
      return plainToClass(
        TrackingWork,
        {},
        {
          excludeExtraneousValues: true,
        }
      )

    const response = await this.client.get(`/api/reports/delivery/${id}`)

    return plainToClass(TrackingWork, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async getTrackingWorkList(form: AdminSearchForm): Promise<TrackingWorkList> {
    const queries = this.getQueries(form)
    const response = await this.client.get(
      `/api/reports/delivery?${queries.join('&')}`
    )

    return {
      ...response.data,
      items: response.data.items.map((trackingWork: any) =>
        plainToClass(TrackingWork, trackingWork, {
          excludeExtraneousValues: true,
        })
      ),
    }
  }

  async getRejectTrackingWorkList(
    form: RejectSearchForm
  ): Promise<RejectTrackingWorkList> {
    const queries = this.getQueries(form)
    const response = await this.client.get(
      `/api/reports/delivery/reject?${queries.join('&')}`
    )

    return {
      ...response.data,
      items: response.data.items.map((trackingWork: any) =>
        plainToClass(TrackingWork, trackingWork, {
          excludeExtraneousValues: true,
        })
      ),
    }
  }

  async rejectTrackingWork({ id }: RejectTrackingWorkForm) {
    await this.client.patch(`/api/reports/delivery/${id}/reject`)
  }

  async recoverTrackingWork({ id }: RecoverTrackingWorkForm) {
    await this.client.patch(`/api/reports/delivery/${id}/recover`)
  }

  async updateWorkRequest({ id, ...form }: WorkOrderRequestNumberFormType) {
    const response = await this.client.patch(
      `/api/reports/delivery/${id}`,
      form
    )

    return response.data
  }

  async addWorkRequest(form: WorkOrderRequestNumberFormType) {
    await this.client.post('/api/reports/delivery', form)
  }

  async reviseWork({ deliveryId }: ReviseWorkParam) {
    const response = await this.client.patch(
      `/api/reports/delivery/${deliveryId}/revise`
    )

    return plainToClass(TrackingWork, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async copyWork({ deliveryId }: CopyWorkParam) {
    const response = await this.client.patch(
      `/api/reports/delivery/${deliveryId}/copy`
    )

    return plainToClass(TrackingWork, response.data)
  }
}
