import cx from 'classnames'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import styles from './index.module.scss'

interface ContainerProps extends FunctionComponentType {
  className?: string
}

export function Container({ children, className = '' }: ContainerProps) {
  return <div className={cx(styles.container, className)}>{children}</div>
}
