import { UseFormReturn } from 'react-hook-form'
import { Expose, Transform } from 'class-transformer'
import { Column } from 'react-table'
import { DateTime } from 'luxon'

import { Option } from '@interfaces/common/SelectType'

export class SearchFormType {
  constructor({
    product,
    tank,
    activity,
    month,
    year,
    page,
    size,
  }: {
    product: string
    tank: string
    activity: string
    month: string
    year: string
    page: number
    size: number
  }) {
    this.product = product
    this.tank = tank
    this.activity = activity
    this.month = month
    this.year = year
    this.page = page
    this.size = size
  }

  @Transform(({ value }) => {
    return value === 'all' ? '' : value
  })
  product: string

  @Transform(({ value }) => (value === 'all' ? '' : value))
  tank: string

  @Transform(({ value }) => (value === 'all' ? '' : value))
  activity: string

  @Transform(({ value }) => (value === 'all' ? '' : value))
  month: string

  year: string

  page: number

  size: number
}

export interface SearchFormProps {
  initialSearchForm: SearchFormType
  onSearch: (value: SearchFormType) => void
}

export interface SearchFormComponentProps {
  methods: UseFormReturn<SearchFormType>
  products: Option[]
  tanks: Option[]
  activities: Option[]
  months: Option[]
  years: Option[]
  productCode: string
  handleSearch: (value: SearchFormType) => void
  handleExportExcel: () => void
}

export interface BatchDocumentTrackingProps {
  data: BatchDocumentTracking[]
  columns: Column[]
  searchParam: SearchFormType
  isLoading: boolean
  total: number
  handleSearch: (value: SearchFormType) => void
  handlePaginationChange: (page: number) => void
}

export class BatchDocumentTracking {
  @Expose() id: number

  @Expose({ name: 'work_request_id' }) workRequestID: string

  @Expose() workRequestCompany: string

  @Expose() workRequestYear: number

  @Expose() product: string

  @Expose() activity: string

  @Expose() tankNo: string

  @Expose() workStatus: string

  @Expose() verifyStatus: string

  @Expose({ name: 'start_date' })
  @Transform(({ value }) =>
    value
      ? value
          .split(',')
          .map((row: string) =>
            DateTime.fromISO(row).toFormat('dd/LL/yyyy HH:mm')
          )
      : ['']
  )
  startDates: string[]

  @Expose({ name: 'end_date' })
  @Transform(({ value }) =>
    value
      ? value
          .split(',')
          .map((row: string) =>
            DateTime.fromISO(row).toFormat('dd/LL/yyyy HH:mm')
          )
      : ['']
  )
  endDates: string[]
}
