import hoist from 'hoist-non-react-statics'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CompanyFormProps,
  CompanyFormType,
} from '@features/company/interfaces/CompanyType'
import { useCompany } from '@features/company/hooks/useCompany'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withCompanyEditPage(Component: React.FC<CompanyFormProps>) {
  function WithCompanyEditPage() {
    const { error } = useAlert()
    const client = useAppClient()
    const { id } = useParams()
    const navigate = useNavigate()
    const { data: company, isLoading } = useCompany({ id: id || '' })

    async function onSubmit(form: CompanyFormType) {
      let isError = false
      await client?.company.editCompany(form).catch(e => {
        isError = true

        error({ message: getErrorMessage(e) })
      })

      if (!isError) navigate('/company')
    }

    const pageProps = {
      initialValues: {
        id,
        code: company?.code || '',
        name: company?.name || '',
        description: company?.description || '',
        phoneNumber: company?.phoneNumber || '',
        active: String(!!company?.active),
      },
      loading: isLoading,
      onSubmit,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCompanyEditPage, Component)

  WithCompanyEditPage.displayName = `WithCompanyEditPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCompanyEditPage
}
