import { useNavigate } from 'react-router-dom'

import { useProfile } from '@features/profile/hooks/useProfile'
import { useUserMonthlyReport } from '@features/monthly-report/hooks/useUserMonthlyReport'
import { Select } from '@components/Select'
import { generateYearOptions } from '@lib/constants'

import styles from './index.module.scss'

export function UserSearchForm() {
  const { queryParams, setQueryParams } = useUserMonthlyReport()
  const { data: profile } = useProfile()
  const navigate = useNavigate()

  const yearOptions = generateYearOptions()
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: `${i + 1}`,
    text: `${i + 1}`,
  }))

  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <div className={styles.label}>
          <strong>Customer:</strong>
        </div>
        <div>{profile?.company?.name}</div>
      </div>

      <div className={styles.inputGroup}>
        <div className={styles.label}>
          <strong>Year:</strong>
        </div>
        <Select
          value={queryParams.year}
          options={yearOptions}
          onChange={event => {
            navigate(
              {
                pathname: '/monthly-report',
                search: `?year=${event.target.value}&month=${queryParams.month}`,
              },
              { replace: true }
            )

            setQueryParams((prev: any) => ({
              ...prev,
              year: event.target.value as string,
            }))
          }}
        />
      </div>

      <div className={styles.inputGroup}>
        <div className={styles.label}>
          <strong>Month:</strong>
        </div>
        <Select
          value={queryParams.month}
          options={monthOptions}
          onChange={event => {
            navigate(
              {
                pathname: '/monthly-report',
                search: `?year=${queryParams.year}&month=${event.target.value}`,
              },
              { replace: true }
            )

            setQueryParams((prev: any) => ({
              ...prev,
              month: event.target.value as string,
            }))
          }}
        />
      </div>
    </div>
  )
}
