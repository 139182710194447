import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { FormProvider } from 'react-hook-form'

import {
  ManualDipPropsType,
  ManualDipComponentType,
} from '@features/tracking-work/interfaces/ManualDipType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { ManualDipParameterKey } from '@features/tracking-work/interfaces/ConfigDeliveryReportType'

export function withManualDip(Component: React.FC<ManualDipComponentType>) {
  function WithManualDip(props: ManualDipPropsType) {
    const {
      atgReports,
      atgConfig,
      atgManualDipMethods,
      isAtgSubmitting,
      isSubmitting,
      hideAction,
      handleAtgManualDipStartCal,
      handleAtgManualDipStopLesslineCal,
      handleAtgManualDipStopCal,
      handleManualFormChange,
    } = useDeliveryReport()

    const disableStartForm = !atgConfig?.manualDip?.start?.state
    const disableLessLineForm = !atgConfig?.manualDip?.stopLessline?.state
    const disableStopForm = !atgConfig?.manualDip?.stop?.state
    const disableAll = !atgConfig?.manualDip?.state

    const showLevel = !!atgConfig?.manualDip?.start?.parameter?.level?.state
    const showLiquidTemperature = !!atgConfig?.manualDip?.start?.parameter
      ?.liquidTemperature?.state
    const showVaporTemperature = !!atgConfig?.manualDip?.start?.parameter
      ?.vaporTemperature?.state
    const showVaporPressure = !!atgConfig?.manualDip?.start?.parameter
      ?.vaporPressure?.state
    const showDensityInVac = !!atgConfig?.manualDip?.start?.parameter
      ?.densityInVac?.state
    const showMolecularWeight = !!atgConfig?.manualDip?.start?.parameter
      ?.molecularWeight?.state

    const pageProps = {
      ...props,
      disableStartForm,
      disableLessLineForm,
      disableStopForm,
      disableAll,
      showLevel,
      showLiquidTemperature,
      showVaporTemperature,
      showVaporPressure,
      showDensityInVac,
      showMolecularWeight,
      isSubmitting: isAtgSubmitting || isSubmitting,
      hideAction,
      handleCalStartForm: handleAtgManualDipStartCal,
      handleCalLessLineForm: handleAtgManualDipStopLesslineCal,
      handleCalStopForm: handleAtgManualDipStopCal,
      handleManualFormChange,
    }

    useEffect(() => {
      if (atgConfig?.manualDip?.start?.parameter && atgReports) {
        const form = Object.keys(atgConfig?.manualDip?.start?.parameter).reduce(
          (res, key) => {
            if (
              atgConfig?.manualDip?.start?.parameter[
                key as ManualDipParameterKey
              ].state
            ) {
              const old = atgReports.find(
                v =>
                  v.keyMapParameter ===
                  atgConfig?.manualDip?.start?.parameter[
                    key as ManualDipParameterKey
                  ].keyMapParameter
              )

              return {
                ...res,
                [key]: {
                  startValue:
                    atgConfig?.manualDip?.start?.parameter[
                      key as ManualDipParameterKey
                    ].value,
                  stopLesslineValue:
                    atgConfig?.manualDip?.stopLessline?.parameter[
                      key as ManualDipParameterKey
                    ].value,
                  stopValue:
                    atgConfig?.manualDip?.stop?.parameter[
                      key as ManualDipParameterKey
                    ].value,
                  constraintMax: old?.constraintMax,
                  constraintMin: old?.constraintMin,
                  decimalPoint: old?.decimalPoint,
                  oldStartValue: old?.startValue,
                  oldStopLesslineValue: old?.stopLesslineValue,
                  oldStopValue: old?.stopValue,
                },
              }
            }

            return res
          },
          {}
        )

        atgManualDipMethods.reset(form)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <FormProvider {...atgManualDipMethods}>
        <Component {...pageProps} />
      </FormProvider>
    )
  }

  hoist(WithManualDip, Component)

  WithManualDip.displayName = `WithManualDip(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithManualDip
}
