import hoist from 'hoist-non-react-statics'
import { useNavigate } from 'react-router-dom'

import {
  RoleGroupFormType,
  RoleGroupFormProps,
} from '@features/role-group/interfaces/RoleGroupType'
import { useScreenList } from '@features/screen/hooks/useScreenList'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withRoleGroupCreatePage(
  Component: React.FC<RoleGroupFormProps>
) {
  function WithRoleGroupCreatePage(props: FunctionComponentType) {
    const { error } = useAlert()
    const client = useAppClient()
    const navigate = useNavigate()
    const { data: screenList } = useScreenList()
    const screens = screenList.map(screen => ({
      write: false,
      read: false,
      id: screen.id,
    }))

    async function handleSubmit(form: RoleGroupFormType) {
      let isError = false

      await client?.roleGroup.createRoleGroup(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })
      if (!isError) navigate('/role-group')
    }

    const pageProps = {
      initialValues: {
        code: '',
        name: '',
        reportRole: '',
        screens,
        active: 'true',
      },
      handleSubmit,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithRoleGroupCreatePage, Component)

  WithRoleGroupCreatePage.displayName = `WithRoleGroupCreatePage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithRoleGroupCreatePage
}
