import { Link } from 'react-router-dom'
import { Breadcrumbs as BreadcrumbsMaterial } from '@material-ui/core'

import { BreadcrumbsType } from './interfaces'
import styles from './index.module.scss'

export function Breadcrumbs({ paths }: BreadcrumbsType) {
  return (
    <BreadcrumbsMaterial aria-label='breadcrumb' className={styles.container}>
      {paths.map((path, index) =>
        path.currentPath ? (
          <b key={index}>{path.name}</b>
        ) : (
          <Link key={index} to={path.to} className={styles.link}>
            {path.name}
          </Link>
        )
      )}
    </BreadcrumbsMaterial>
  )
}
