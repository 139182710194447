import { Button } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import cx from 'classnames'

import { DailyTable } from '@components/DailyTable'
import { Container } from '@components/Container'
import { Breadcrumbs } from '@components/Breadcrumbs'
import { DailyReportPageType } from '@features/report/interfaces/DailyReportType'

import styles from './index.module.scss'

export function DailyReportPage({
  data,
  loading,
  customer,
  selectedDate,
  date,
  verifyDates,
  companyId,
  editable,
  isError,
  handleSelectedDateChange,
  handleSearch,
  handleSelectVerifyDate,
  handleExportFile,
}: DailyReportPageType) {
  return (
    <Container>
      <Breadcrumbs />
      {editable && (
        <div className={styles.verifyDates}>
          {verifyDates.map((verifyDate, index) => (
            <Button
              key={index}
              className={cx({
                [styles.active]: date === verifyDate,
              })}
              onClick={() => handleSelectVerifyDate(verifyDate)}
            >
              {verifyDate}
            </Button>
          ))}
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.customer}>
          <strong>Customer: </strong>
          {customer}
        </div>
        <DatePicker
          value={selectedDate}
          onChange={handleSelectedDateChange}
          format='dd/LL/yyyy'
        />
        <Button
          className={styles.selectBtn}
          variant='contained'
          color='primary'
          onClick={handleSearch}
        >
          Search
        </Button>

        <div className={styles.exportGroup}>
          <div>
            <strong>Report Date: </strong>
            {data.length ? `${data[0].date} 00:00` : '-'}
          </div>

          {!isError && (
            <Button
              className={styles.exportBtn}
              variant='contained'
              color='primary'
              onClick={handleExportFile}
            >
              Export
            </Button>
          )}
        </div>
      </div>

      {isError ? (
        <div className={styles.error}>
          <p>ขออภัยในความไม่สะดวก</p>
          <p>Daily Report ของวันที่ {date} อยู่ในระหว่างการพิจารณาข้อมูล</p>
        </div>
      ) : (
        <DailyTable
          data={data}
          date={date}
          loading={loading}
          companyId={companyId}
          editable={editable}
        />
      )}
    </Container>
  )
}
