import { useMemo } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import hoist from 'hoist-non-react-statics'
import cx from 'classnames'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import {
  RoleGroupPageType,
  RoleGroupType,
} from '@features/role-group/interfaces/RoleGroupType'
import { useRoleGroupList } from '@features/role-group/hooks/useRoleGroupList'
import { usePermissionGuard } from '@lib/permissions'
import { ROLE_GROUP_SETTING } from '@lib/constants'

import styles from './index.module.scss'

const initialColumns = [
  { accessor: 'code', Header: 'Role Group Code' },
  { accessor: 'name', Header: 'Role Group Name' },
  {
    accessor: 'status',
    Header: 'Active Status',
    Cell: ({ record }: { record: RoleGroupType }) => (
      <div
        className={cx(styles.status, {
          [styles.active]: record.status,
        })}
      >
        {record.status ? 'Active' : 'Inactive'}
      </div>
    ),
  },
]

export function withRoleGroupPage(Component: React.FC<RoleGroupPageType>) {
  function WithRoleGroupPage(props: FunctionComponentType) {
    const navigate = useNavigate()
    const editable = usePermissionGuard(ROLE_GROUP_SETTING, 'write')
    const { data: roleGroupList, isLoading } = useRoleGroupList()
    const data = useMemo(
      () =>
        roleGroupList.map(roleGroup => ({
          ...roleGroup,
          action: '',
        })),
      [roleGroupList]
    )
    const columns = editable
      ? [
          ...initialColumns,
          {
            accessor: 'action',
            Header: '',
            Cell: ({ record }: { record: RoleGroupType }) => (
              <Link to={`/role-group/${record.id}/edit`}>Edit</Link>
            ),
          },
        ]
      : initialColumns

    function handleCreate() {
      navigate('/role-group/create')
    }

    const pageProps = {
      columns,
      data,
      loading: isLoading,
      handleCreate,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithRoleGroupPage, Component)

  WithRoleGroupPage.displayName = `WithRoleGroupPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithRoleGroupPage
}
