import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useTankListByCompany() {
  const client = useAppClient()
  const { data, isLoading } = useQuery(['tanks-by-company'], () =>
    client?.tank.getTankListByCompany().then(response => response)
  )

  return {
    data: data || [],
    isLoading,
  }
}
