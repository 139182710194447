import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import hoist from 'hoist-non-react-statics'

export function withATGReport(Component: React.FC<FunctionComponentType>) {
  function WithATGReport() {
    return <Component />
  }

  hoist(WithATGReport, Component)

  WithATGReport.displayName = `WithATGReport(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithATGReport
}
