import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { UserForm } from '@features/user/components/UserForm'
import { UserFormProps } from '@features/user/interfaces/UserType'

export function UserEditPage({
  initialValues,
  loading,
  handleSubmit,
}: UserFormProps) {
  return (
    <Container>
      <Breadcrumbs />
      <UserForm
        initialValues={initialValues}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </Container>
  )
}
