import { Button } from '@material-ui/core'

import { PermissionGuard } from '@lib/permissions'
import { DRAFT_CALCULATION_PAGE } from '@lib/constants'
import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { TrackingWorkTable } from '@features/tracking-work/components/TrackingWorkTable'
import { AddWorkModal } from '@features/draft-calculation/components/AddWorkModal'
import { ModifyWorkModal } from '@features/draft-calculation/components/ModifyWorkModal'
import { DraftCalculationPageComponent } from '@features/draft-calculation/interfaces/DraftType'

import { Search } from './Search'

export function DraftCalculationPage({
  draftList,
  productOptions,
  yearOptions,
  loading,
  modal,
  columns,
  modifyWork,
  loadingDraft,
  queryParam,
  handleSearch,
  handleCloseModal,
  handleOpenAddWorkModal,
  handleAddWork,
  handleModifyWork,
  handlePaginationChange,
}: DraftCalculationPageComponent) {
  return (
    <Container>
      <Breadcrumbs />

      <Search
        className='mb'
        initialValues={queryParam}
        yearOptions={yearOptions}
        handleSubmit={handleSearch}
      />
      <PermissionGuard page={DRAFT_CALCULATION_PAGE} mode='write'>
        <Button
          className='mb'
          variant='contained'
          color='secondary'
          onClick={handleOpenAddWorkModal}
        >
          Add Work
        </Button>
      </PermissionGuard>
      <TrackingWorkTable
        data={draftList?.items || []}
        columns={columns}
        loading={loading}
        page={queryParam.page}
        count={queryParam.count}
        onPaginationChange={handlePaginationChange}
      />

      <AddWorkModal
        open={modal === 'add-draft'}
        productOptions={productOptions}
        initialValues={{
          workRequestCompany: '',
          workRequestYear: '',
          product: '',
          activity: '',
          fromCompany: '',
          toCompany: '',
          fromTanks: [],
          toTanks: [],
        }}
        handleSubmit={handleAddWork}
        handleClose={handleCloseModal}
      />

      <ModifyWorkModal
        isLoading={loadingDraft}
        open={modal === 'modify-draft'}
        productOptions={productOptions}
        initialValues={modifyWork}
        handleSubmit={handleModifyWork}
        handleClose={handleCloseModal}
      />
    </Container>
  )
}
