import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { Product } from '@features/product/interfaces/ProductType'

export class ProductClient {
  constructor(private client: AxiosInstance) {}

  async getProductList(): Promise<Product[]> {
    const response = await this.client.get('/api/products')

    return response.data.map((product: any) =>
      plainToClass(Product, product, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getProductListByCompany(): Promise<Product[]> {
    const response = await this.client.get('/api/products_product_tank')

    return response.data.map((product: any) =>
      plainToClass(Product, product, {
        excludeExtraneousValues: true,
      })
    )
  }
}
