import { useState } from 'react'

interface ModalType {
  modalName: string
  message?: string
  payload?: any
}

export function useModal() {
  const [modal, setModal] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [payload, setPayload] = useState<any>()

  function openModal({
    modalName,
    message: newMessage = '',
    payload: newPayload,
  }: ModalType) {
    setModal(modalName)
    setMessage(newMessage)
    setPayload(newPayload)
  }

  function closeModal() {
    setModal('')
    setMessage('')
    setPayload(null)
  }

  return {
    modal,
    message,
    payload,
    openModal,
    closeModal,
  }
}
