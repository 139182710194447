import hoist from 'hoist-non-react-statics'
import { useSearchParams } from 'react-router-dom'

import {
  CalculateDailyReportForm,
  CalculateDailyReportPageType,
} from '@features/report/interfaces/CalculateDailyReportType'
import { useCalculate } from '@features/report/hooks/useCalculate'

export function withCalculateDailyReportPage(
  Component: React.FC<CalculateDailyReportPageType>
) {
  function WithCalculateDailyReport() {
    const [searchParams] = useSearchParams()
    const productName = searchParams.get('p')
    const tankCode = searchParams.get('tc')
    const date = searchParams.get('d')
    const {
      data,
      loading,
      isSubmitting,
      calculate,
      retrieveRecord,
      update,
    } = useCalculate({
      tankCode,
      date,
    })

    function handleCalculate(form: CalculateDailyReportForm) {
      calculate(form.list.filter(row => row.topicType === 'C'))
    }

    function handleSave(form: CalculateDailyReportForm) {
      update(form.list)
    }

    const pageProps = {
      data,
      productName,
      tankCode,
      date,
      loading,
      isSubmitting,
      retrieveRecord,
      handleCalculate,
      handleSave,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCalculateDailyReport, Component)

  WithCalculateDailyReport.displayName = `WithCalculateDailyReport(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCalculateDailyReport
}
