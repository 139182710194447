import { useQuery, useQueryClient } from 'react-query'

import { GetTrackingWorkPropsType } from '@features/tracking-work/interfaces/TrackingWorkType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useAppClient } from '@lib/useAppClient'

export function useTrackingWork({ id }: GetTrackingWorkPropsType) {
  const client = useAppClient()
  const queryClient = useQueryClient()
  const queryKey = ['tracking-work', id]
  const { data, isLoading } = useQuery(queryKey, () =>
    client?.trackingWork
      .getTrackingWork({
        id,
      })
      .then(response => response)
  )

  async function updateWorkRequest(form: WorkOrderRequestNumberFormType) {
    const res = await client?.trackingWork.updateWorkRequest(form)
    queryClient.setQueryData(queryKey, () => {
      return res
    })
  }

  return {
    data,
    isLoading,
    updateWorkRequest,
  }
}
