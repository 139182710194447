import hoist from 'hoist-non-react-statics'
import { useNavigate } from 'react-router-dom'

import { UserFormType, UserFormProps } from '@features/user/interfaces/UserType'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withUserCreatePage(Component: React.FC<UserFormProps>) {
  function WithUserCreatePage(props: FunctionComponentType) {
    const { error } = useAlert()
    const client = useAppClient()
    const navigate = useNavigate()

    async function handleSubmit(form: UserFormType) {
      let isError = false
      await client?.user.createUser(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) navigate('/user')
    }

    const pageProps = {
      initialValues: {
        companyId: '',
        roleGroupId: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        confirmPassword: '',
        active: 'true',
      },
      handleSubmit,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithUserCreatePage, Component)

  WithUserCreatePage.displayName = `WithUserCreatePage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUserCreatePage
}
