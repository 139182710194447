import { useMemo } from 'react'
import cx from 'classnames'

import { Select } from '@components/Select'
import { Box } from '@features/tracking-work/components/Box'
import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'

import styles from './index.module.scss'

interface CalculationInformationType {
  workRequestNo: string
  product: string
  categories: string
  portOfLoading: string
  fromCompany: string
  fromTank: string
  toCompany: string
  toTank: string
  capacity: number
}

export function CalculationInformation({
  workRequestNo,
  product,
  categories,
  portOfLoading,
  fromCompany,
  fromTank,
  toCompany,
  toTank,
  capacity,
}: CalculationInformationType) {
  const { data, selectedTankCode, onTankChange } = useDraftDeliveryReport()

  const shipmentNo = useMemo(
    () =>
      data
        ? data.tanks.find(row => selectedTankCode === row.code)?.shipmentNo
        : '-',
    [selectedTankCode, data]
  )

  const tankOptions = useMemo(
    () =>
      data?.tanks?.map(tank => ({
        text: tank.code,
        value: tank.code,
      })) || [],
    [data?.tanks]
  )

  return (
    <Box title='Information'>
      <div className={styles.infomation}>
        <div className={styles.label}>
          <strong>Work Request No :</strong>
        </div>
        <div className={styles.workRequestNo}>{workRequestNo}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Product :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{product}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Shipment No. :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{shipmentNo}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Categories :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{categories}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Port of loading. :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{portOfLoading}</div>

        <div className={cx('mt', styles.label)}>
          <strong>From :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{fromCompany}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Tank/Vessel NA :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{fromTank}</div>

        <div className={cx('mt', styles.label)}>
          <strong>To :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{toCompany}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Tank/Vessel NA :</strong>
        </div>
        <div className={cx('mt', styles.text)}>{toTank}</div>

        <div className={cx('mt', styles.label)}>
          <strong>Tank No:</strong>
        </div>
        <div className={cx('mt', styles.text)}>
          <Select
            options={tankOptions}
            value={selectedTankCode}
            onChange={e => onTankChange(e.target.value as string)}
          />
        </div>

        <div className={cx('mt', styles.label)}>
          <strong>Capacity:</strong>
        </div>
        <div className={cx('mt', styles.text)}>
          {capacity.toLocaleString()} Liters
        </div>
      </div>
    </Box>
  )
}
