import { USER_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { UserEditPage } from './UserEditPage'
import { withUserEditPage } from './withUserEditPage'

const ConnectedUserEditPage = withPermissionGuard(
  withUserEditPage(UserEditPage),
  USER_SETTING,
  'write'
)
export { ConnectedUserEditPage as UserEditPage }
