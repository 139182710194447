import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from '@material-ui/core'
import cx from 'classnames'

import { InputField } from '@components/fieldV2/InputField'
import { SelectField } from '@components/fieldV2/SelectField'
import { monthOptions, workStatusOptions } from '@lib/constants'
import { SearchGroupProps } from '@features/tracking-work/interfaces/boardman/SearchGroupType'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'

export function SearchGroup({
  className,
  yearOptions,
  initialValues,
  handleSubmit,
}: SearchGroupProps) {
  const methods = useForm({
    defaultValues: initialValues,
  })

  useEffect(() => {
    methods.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <FormProvider {...methods}>
      <form
        className={cx(className, styles.searchForm)}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <strong>Work No:</strong>
        <InputField name='workNo' />
        <strong>Month:</strong>
        <SelectField
          className={styles.select}
          name='month'
          options={monthOptions}
        />
        <strong>Year:</strong>
        <SelectField
          className={styles.select}
          name='year'
          options={yearOptions}
        />
        <strong>Work Status:</strong>
        <SelectField
          className={styles.select}
          name='workStatus'
          options={workStatusOptions}
        />
        <Button variant='contained' color='primary' type='submit'>
          Search
        </Button>
      </form>
    </FormProvider>
  )
}
