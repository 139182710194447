import { FormControl } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'

import styles from './index.module.scss'

interface RadioGroupFieldProps {
  name: string
  label?: string
  defaultValue?: string
  radioGroups: RadioGroupType[]
  error?: string
}

interface RadioGroupType {
  label?: string
  value: any
}

export function RadioGroupField({
  name,
  radioGroups,
  defaultValue = '',
  label = '',
  error,
}: RadioGroupFieldProps) {
  const { control } = useFormContext()
  const { field } = useController({ name, control, defaultValue })

  return (
    <FormControl>
      {label && (
        <div className='label'>
          <strong>{label}</strong>
        </div>
      )}
      <div className={styles.container}>
        {radioGroups.map((radio, index) => (
          <label className={styles.radioContainer} key={index}>
            <input
              type='radio'
              value={radio.value}
              name={name}
              onChange={field.onChange}
              checked={radio.value === field.value}
            />
            <span className={styles.circle1} />
            <span className={styles.circle2} />
            {radio.label}
          </label>
        ))}
      </div>
      {!!error && <div className={styles.error}>{error}</div>}
    </FormControl>
  )
}
