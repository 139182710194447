import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'

import {
  AdminSearchForm,
  RejectTrackingWorkForm,
  TrackingWorkList,
  TrackingWorkListQueryParamType,
} from '@features/tracking-work/interfaces/TrackingWorkType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useAppClient } from '@lib/useAppClient'

interface UseTrackingWorkListResponse {
  data?: TrackingWorkList
  isLoading: boolean
  queryParam: TrackingWorkListQueryParamType
  refetch: () => Promise<any>
  rejectTrackingWork: (form: RejectTrackingWorkForm) => Promise<void>
  addWorkRequest: (form: WorkOrderRequestNumberFormType) => Promise<void>
  handleSearch: (form: AdminSearchForm) => void
  handlePaginationChange: (page: number) => void
  reviseWork: (id: number) => Promise<void>
  copyWork: (id: number) => Promise<void>
}

export function useTrackingWorkList(): UseTrackingWorkListResponse {
  const client = useAppClient()
  const [queryParam, setQueryParam] = useState<TrackingWorkListQueryParamType>({
    workNo: '',
    month: DateTime.now().toFormat('L'),
    year: DateTime.now().toFormat('yyyy'),
    workStatus: 'all',
    verifyStatus: 'all',
    size: 10,
    page: 1,
    count: 0,
  })

  const { data, isLoading, refetch } = useQuery(
    [
      'tracking-work',
      queryParam.workNo,
      queryParam.month,
      queryParam.year,
      queryParam.workStatus,
      queryParam.verifyStatus,
      queryParam.page,
      queryParam.size,
    ],
    () =>
      client?.trackingWork
        .getTrackingWorkList({
          workNo: queryParam.workNo,
          month: queryParam.month === 'all' ? '' : queryParam.month,
          year: queryParam.year,
          workStatus:
            queryParam.workStatus === 'all' ? '' : queryParam.workStatus,
          verifyStatus:
            queryParam.verifyStatus === 'all' ? '' : queryParam.verifyStatus,
          size: queryParam.size,
          page: queryParam.page,
        })
        .then(response => response)
  )

  async function rejectTrackingWork({ id }: RejectTrackingWorkForm) {
    await client?.trackingWork.rejectTrackingWork({ id })
  }

  async function addWorkRequest(form: WorkOrderRequestNumberFormType) {
    await client?.trackingWork.addWorkRequest(form)
  }

  function handleSearch(form: AdminSearchForm) {
    setQueryParam(prev => ({
      ...prev,
      ...form,
      page: 1,
    }))
  }

  function handlePaginationChange(page: number) {
    setQueryParam(prev => ({ ...prev, page }))
  }

  async function reviseWork(deliveryId: number) {
    await client?.trackingWork.reviseWork({ deliveryId })
  }

  async function copyWork(id: number) {
    await client?.trackingWork.copyWork({ deliveryId: id })
  }

  useEffect(() => {
    if (data && queryParam.count !== Math.ceil(data.total / data.size))
      setQueryParam(prev => ({
        ...prev,
        count: Math.ceil(data.total / data.size),
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    data,
    isLoading,
    queryParam,
    refetch,
    rejectTrackingWork,
    addWorkRequest,
    handleSearch,
    handlePaginationChange,
    reviseWork,
    copyWork,
  }
}
