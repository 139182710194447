import { Expose } from 'class-transformer'

export class Level {
  @Expose({ name: 'before_stop' }) beforeStop: number

  @Expose({ name: 'before_stop_five_minutes' }) beforeStopFiveMinutes: number

  hh: number

  ll: number

  ph: number

  pl: number
}
