import {
  CalculateMode,
  CalculateType,
} from '@lib/clients/collections/ATGReportClient'
import { Expose, Transform } from 'class-transformer'

type TopicType = 'H' | 'F' | 'C'

export class CalculationDetailType {
  @Expose()
  constraintMax: number

  @Expose()
  constraintMin: number

  @Expose()
  decimalPoint: number

  @Expose()
  keyMapParameter: number

  @Expose()
  sort: number

  @Expose()
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  startValue: string

  @Expose()
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  stopLesslineValue: string

  @Expose()
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  stopValue: string

  @Expose() topic: string

  @Expose() topicType: TopicType

  @Expose() typeGroupId: number

  @Expose() keyAttribute: string
}

export interface CalculationDetailPropsType {
  data: CalculationDetailType
  index: number
  handleChange: (e: React.ChangeEvent<any>) => void
}

export interface CalculationDetailActionPropsType {
  title: string
  disabled: boolean
  hideAction: boolean
  deliveryId: number
  tankCode: string
  type: CalculateType
  mode: CalculateMode
  modal: string
  handleSave: () => void
  closeModal: () => void
  handleConfirm: () => Promise<void>
}

export interface CalculationFormPropsType {
  reports: CalculationDetailType[]
  disableStopLesslineForm: boolean
  disableStopForm: boolean
  isSubmitting: boolean
  readOnly: boolean
  hideAction: boolean
  canSaveStart: boolean
  canSaveLessline: boolean
  canSaveStop: boolean
}

export interface CalculationFormType {
  list: CalculationDetailType[]
}
