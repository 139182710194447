import hoist from 'hoist-non-react-statics'

import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'
import { CalculationPageProps } from '@features/draft-calculation/interfaces/CalculationType'

export function withCalculationPage(Component: React.FC<CalculationPageProps>) {
  function WithCalculationPage() {
    const { data, isLoading, atgCapacity } = useDraftDeliveryReport()

    const pageProps = {
      data,
      loading: isLoading,
      capacity: atgCapacity,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCalculationPage, Component)

  WithCalculationPage.displayName = `WithCalculationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCalculationPage
}
