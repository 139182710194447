import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'

import { SelectType } from '@interfaces/common/SelectType'
import styles from './index.module.scss'

export function Select({
  placeholder,
  options,
  label,
  className,
  error,
  helperText,
  ...props
}: SelectType) {
  return (
    <FormControl className={className}>
      {label && (
        <div className='label'>
          <strong>{label}</strong>
        </div>
      )}
      <MuiSelect
        {...props}
        className={styles.nativeSelect}
        value={props.value || '-1'}
      >
        {placeholder && (
          <MenuItem value='-1' disabled={!!props.value}>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </MuiSelect>
      {!!error && (
        <FormHelperText className={styles.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
