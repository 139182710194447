import hoist from 'hoist-non-react-statics'

import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { CompletePageProps } from '@features/tracking-work/interfaces/CompleteType'

export function withCompletePage(Component: React.FC<CompletePageProps>) {
  function WithCompletePage() {
    const {
      data,
      isLoading,
      atgCapacity,
      hideAction,
      handleMeterSaveChecklist,
      handleAtgSaveChecklist,
    } = useDeliveryReport()

    const pageProps = {
      data,
      loading: isLoading,
      capacity: atgCapacity,
      hideAction,
      handleSaveAtg: handleAtgSaveChecklist,
      handleSaveMeter: handleMeterSaveChecklist,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCompletePage, Component)

  WithCompletePage.displayName = `WithCompletePage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCompletePage
}
