import { Expose } from 'class-transformer'

import { ParameterKey, QuanityValueKey } from './DailyReportType'

export interface HeaderDailyReport {
  parameter: { key: ParameterKey; name: string }[]
  alltank: { key: QuanityValueKey; name: string }[]
  usable: { key: QuanityValueKey; name: string }[]
  avaliablespace: { key: QuanityValueKey; name: string }[]
}

export interface ReportHeader {
  id: number
  header_group: string
  header_type: 'parameter' | 'alltank' | 'usable' | 'avaliablespace'
  header_column: string
  header_column_desc: string
  is_active: boolean
}

export interface CalculateDailyReportPropsType {
  tankCode: string | null
  date: string | null
}

export interface CalculateDailyReportPageType {
  data: CalculateDailyReport[]
  loading: boolean
  productName: string | null
  tankCode: string | null
  date: string | null
  isSubmitting: boolean
  retrieveRecord: () => void
  handleCalculate: (form: CalculateDailyReportForm) => void
  handleSave: (form: CalculateDailyReportForm) => void
}

export interface CalculateDailyReportForm {
  list: CalculateDailyReport[]
}

export interface DailyParams {
  tankCode: string
  date: string
  form: CalculateDailyReport[]
}

type TopicType = 'H' | 'F' | 'I' | 'C'

export class CalculateDailyReport {
  @Expose() sort: number

  @Expose() typeGroupId: number

  @Expose() topicType: TopicType

  @Expose() topic: string

  @Expose() value: string

  @Expose() decimalPoint: number

  @Expose() constraintMax: number

  @Expose() constraintMin: number

  @Expose() keyMapParameter: number
}
