import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { RoleGroupForm } from '@features/role-group/components/RoleGroupForm'
import { RoleGroupFormProps } from '@features/role-group/interfaces/RoleGroupType'

export function RoleGroupCreatePage({
  initialValues,
  handleSubmit,
}: RoleGroupFormProps) {
  return (
    <Container>
      <Breadcrumbs />
      <RoleGroupForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      />
    </Container>
  )
}
