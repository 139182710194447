import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { CompanyFormProps } from '@features/company/interfaces/CompanyType'
import { CompanyForm } from '@features/company/components/CompanyForm'

export function CompanyCreatePage({
  initialValues,
  onSubmit,
}: CompanyFormProps) {
  return (
    <Container>
      <Breadcrumbs />
      <CompanyForm initialValues={initialValues} onSubmit={onSubmit} />
    </Container>
  )
}
