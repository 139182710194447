import { useParams } from 'react-router-dom'

import { CalculationPage } from '@features/draft-calculation/pages/CalculationPage'
import { DraftDeliveryReportProvider } from '@features/draft-calculation/hooks/useDraftDeliveryReport'

export function WrapCalculationPage() {
  const { id } = useParams()

  return (
    <DraftDeliveryReportProvider id={id || ''}>
      <CalculationPage />
    </DraftDeliveryReportProvider>
  )
}
