import { FormProvider } from 'react-hook-form'
import { Button } from '@material-ui/core'

import { SelectField } from '@components/fieldV2/SelectField'
import { SearchFormComponentProps } from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'
import styles from './index.module.scss'

export function SearchForm({
  methods,
  products,
  tanks,
  activities,
  months,
  years,
  productCode,
  handleSearch,
  handleExportExcel,
}: SearchFormComponentProps) {
  return (
    <FormProvider {...methods}>
      <form
        className={styles.container}
        onSubmit={methods.handleSubmit(handleSearch)}
      >
        <div className={styles.inputGroup}>
          <span>
            <strong>Product:</strong>
          </span>
          <SelectField
            className={styles.productOptions}
            name='product'
            options={products}
          />
          <span>
            <strong>Tank:</strong>
          </span>
          <SelectField
            className={styles.tankOptions}
            name='tank'
            options={tanks}
          />
          <span>
            <strong>Activity:</strong>
          </span>
          <SelectField
            className={styles.activityOptions}
            name='activity'
            options={activities}
            disabled={productCode === 'all'}
          />
          <span>
            <strong>Month:</strong>
          </span>
          <SelectField
            className={styles.monthOptions}
            name='month'
            options={months}
          />
          <span>
            <strong>Year:</strong>
          </span>
          <SelectField
            className={styles.yearOptions}
            name='year'
            options={years}
          />
        </div>
        <div>
          <Button type='submit' variant='contained' color='primary'>
            Search
          </Button>
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={handleExportExcel}
          >
            Export Excel
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
