import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useRoleGroupList() {
  const client = useAppClient()
  const { data, isLoading } = useQuery('role-groups', () =>
    client?.roleGroup.getRoleGroupList().then(response => response)
  )

  return {
    data: data || [],
    isLoading,
  }
}
