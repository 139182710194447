import { DatePicker } from '@material-ui/pickers'
import { Button } from '@material-ui/core'

import { SummaryDailyReportPageType } from '@features/report/interfaces/SummaryDailyReportType'
import { Container } from '@components/Container'
import { Breadcrumbs } from '@components/Breadcrumbs'
import { SummaryDailyTable } from '@components/SummaryDailyTable'
import { Select } from '@components/Select'

import styles from './index.module.scss'

export function SummaryDailyReportPage({
  data,
  loading,
  selectedStartDate,
  selectedEndDate,
  selectedProductCode,
  selectedTankCode,
  tankOptions,
  handleStartDateChange,
  handleEndDateChange,
  handleTankChange,
  handleSearch,
  handleExportFile,
}: SummaryDailyReportPageType) {
  return (
    <Container>
      <Breadcrumbs />
      <div className={styles.header}>
        <strong>Tank Code:</strong>
        <Select
          className={styles.select}
          value={selectedTankCode}
          options={tankOptions}
          placeholder='Select Tank'
          onChange={event => handleTankChange(event.target.value as string)}
        />
        <div className={styles.product}>
          <strong>Product:</strong>
          {selectedProductCode}
        </div>
        <strong>Date:</strong>
        <DatePicker
          value={selectedStartDate}
          format='dd/LL/yyyy'
          onChange={handleStartDateChange}
        />
        <strong>~</strong>
        <DatePicker
          value={selectedEndDate}
          format='dd/LL/yyyy'
          onChange={handleEndDateChange}
        />
        <Button
          className={styles.selectBtn}
          variant='contained'
          color='primary'
          onClick={handleSearch}
        >
          Search
        </Button>
        <Button
          className={styles.exportBtn}
          variant='contained'
          color='primary'
          onClick={handleExportFile}
        >
          Export
        </Button>
      </div>
      <SummaryDailyTable data={data} loading={loading} />
    </Container>
  )
}
