import { memo } from 'react'

import { InputField } from '@components/fieldV2/InputField'

import styles from './index.module.scss'

interface SummaryPropsType {
  meterLineAdjustment: {
    volume15: number
    volume30: number
    tonInAir: number
    tonInVac: number
  }
  disabled: boolean
}

export const Summary = memo(
  ({ meterLineAdjustment, disabled }: SummaryPropsType) => {
    return (
      <>
        <div className={styles.calculationDetailHeader}>Summary</div>
        <div className={styles.calculationDetailLabel}>
          <strong>Meter Line Adjustment</strong>
        </div>
        <div>{meterLineAdjustment.volume15.toLocaleString()}</div>
        <div>Volume @ 15 &#8451; (Liters)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <div>{meterLineAdjustment.volume30.toLocaleString()}</div>
        <div>Volume @ 30 &#8451; (Liters)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <div>{meterLineAdjustment.tonInAir.toLocaleString()}</div>
        <div>Metric ton (in air)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <div>{meterLineAdjustment.tonInVac.toLocaleString()}</div>
        <div>Metric ton (in vac)</div>
        <div />

        <div className={styles.calculationDetailLabel}>
          <strong>Final Difference</strong>
        </div>
        <InputField type='number' name='final.volume15' disabled={disabled} />
        <div>Volume @ 15 &#8451; (Liters)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <InputField type='number' name='final.volume30' disabled={disabled} />
        <div>Volume @ 30 &#8451; (Liters)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <InputField type='number' name='final.tonInAir' disabled={disabled} />
        <div>Metric ton (in air)</div>
        <div />

        <div className={styles.calculationDetailLabel} />
        <InputField type='number' name='final.tonInVac' disabled={disabled} />
        <div>Metric ton (in vac)</div>
        <div />
      </>
    )
  }
)
