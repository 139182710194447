import { useState } from 'react'
import hoist from 'hoist-non-react-statics'
import { Document, pdf } from '@react-pdf/renderer'

import { PrintGauge } from '@features/pdf/pages/PrintGauge'
import { Meter } from '@features/pdf/pages/Meter'
import { Delivery } from '@features/pdf/pages/Delivery'
import { Tank } from '@features/batch-document-tracking/interfaces/BatchDocumentReportType'
import { useAppClient } from '@lib/useAppClient'
import { getErrorMessage } from '@lib/utils'
import { useAlert } from '@lib/useAlert'

interface ExportPdfButtonProps {
  deliveryId: number
}

export interface ExportPdfButtonComponent {
  loading: boolean
  handleExportPdf: () => void
}

export function withExportPdfButton(
  Component: React.FC<ExportPdfButtonComponent>
) {
  function WithExportPdfButton({ deliveryId }: ExportPdfButtonProps) {
    const client = useAppClient()
    const { error } = useAlert()
    const [loading, setLoading] = useState(false)

    async function handleExportPdf() {
      setLoading(true)
      const res = await client?.batchReport.exportPdf(deliveryId).catch(e => {
        setLoading(false)
        error({ message: getErrorMessage(e) })
      })

      if (res) {
        const startDates = res.startDate.split(',')
        const endDates = res.endDate.split(',')

        const blob = await pdf(
          <Document title={`Batch Document Report (${res.requestDate})`}>
            {res.tanks.map((tank: Tank, index: number) => (
              <>
                <Delivery
                  companyName='Map Ta Phut Tank Terminal Co., Ltd.'
                  workRequestNo={res.workRequestNo}
                  product={res.product}
                  categories={res.activity}
                  fromCompany={res.fromCompany}
                  toCompany={res.toCompany}
                  note={res.note || ''}
                  reports={tank.atgReports || []}
                  capacity={tank.capacity}
                  fromTank={res.fromTanks.join(', ')}
                  toTank={res.toTanks.join(', ')}
                  startDatetime={startDates[index]}
                  endDatetime={endDates[index]}
                  tankNo={tank.tankNo}
                  shipmentNo={tank.shipmentNumber}
                  portOfLoading={res.portOfLoading}
                  summaryAtgReport={tank.summaryAtgReport}
                />
                {tank.meterChecklist.length > 1 ? (
                  <Meter
                    shipName={res.shipName}
                    product={res.product}
                    categories={res.activity}
                    tankNo={tank.tankNo}
                    meterChecklist={tank.meterChecklist}
                    summaryMeterReport={tank.summaryMeterReport}
                    summaryAtgReport={tank.summaryAtgReport}
                  />
                ) : null}
                {tank.gauge.start !== null ? (
                  <PrintGauge
                    tankCode={tank.tankNo}
                    parameters={tank.gauge.start.parameter}
                    workNumber={tank.gauge.start.workNumber || ''}
                    companyName={tank.gauge.start.companyName || ''}
                    time={tank.gauge.start.time || ''}
                    product={tank.gauge.start.product || ''}
                    batchPeriod={tank.gauge.start.batchPeriod || ''}
                    activity={tank.gauge.start.activity || ''}
                    fromTank={tank.gauge.start.fromTank || ''}
                    toTank={tank.gauge.start.toTank || ''}
                  />
                ) : null}
                {tank.gauge.stopLessline !== null ? (
                  <PrintGauge
                    tankCode={tank.tankNo}
                    parameters={tank.gauge.stopLessline.parameter}
                    workNumber={tank.gauge.stopLessline.workNumber || ''}
                    companyName={tank.gauge.stopLessline.companyName || ''}
                    time={tank.gauge.stopLessline.time || ''}
                    product={tank.gauge.stopLessline.product || ''}
                    batchPeriod={tank.gauge.stopLessline.batchPeriod || ''}
                    activity={tank.gauge.stopLessline.activity || ''}
                    fromTank={tank.gauge.stopLessline.fromTank || ''}
                    toTank={tank.gauge.stopLessline.toTank || ''}
                  />
                ) : null}
                {tank.gauge.stop !== null ? (
                  <PrintGauge
                    tankCode={tank.tankNo}
                    parameters={tank.gauge.stop.parameter}
                    workNumber={tank.gauge.stop.workNumber || ''}
                    companyName={tank.gauge.stop.companyName || ''}
                    time={tank.gauge.stop.time || ''}
                    product={tank.gauge.stop.product || ''}
                    batchPeriod={tank.gauge.stop.batchPeriod || ''}
                    activity={tank.gauge.stop.activity || ''}
                    fromTank={tank.gauge.stop.fromTank || ''}
                    toTank={tank.gauge.stop.toTank || ''}
                  />
                ) : null}
              </>
            ))}
          </Document>
        ).toBlob()

        const csvURL = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute(
          'download',
          `batch-document-report-${res.requestDate}.pdf`
        )
        tempLink.click()
        setLoading(false)
      }
    }

    const pageProps = {
      loading,
      handleExportPdf,
    }

    return <Component {...pageProps} />
  }

  hoist(WithExportPdfButton, Component)

  WithExportPdfButton.displayName = `WithExportPdfButton(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithExportPdfButton
}
