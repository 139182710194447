import { View, Text } from '@react-pdf/renderer'

import styles from '@features/pdf/pages/MonthlyReport/styles'

export function CategoryGroup() {
  return (
    <View
      fixed
      wrap={false}
      style={[
        styles.borderT,
        styles.borderB,
        styles.borderL,
        styles.borderR,
        styles.p6,
        styles.dFlex,
        styles.flexRow,
        {
          marginTop: 'auto',
        },
      ]}
    >
      <View style={{ width: '20%' }}>
        <Text>CATEGORY DESIGNATION</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text>IM : Import</Text>
        <Text>EX : Export</Text>
      </View>
      <View style={{ width: '25%' }}>
        <Text>SO : Sendout of Customer</Text>
        <Text>RE : Receiving from Customer</Text>
        <Text>DI : Domestic Import</Text>
      </View>
      <View style={{ width: '45%' }}>
        <Text>FL : Fill line / Flare off</Text>
        <Text>IT : Inter transfer between storage tank in MTT tank farm</Text>
        <Text>DE : Domestic Export</Text>
      </View>
    </View>
  )
}
