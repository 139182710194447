import { Expose } from 'class-transformer'

import { CheckListType } from './CheckListType'
import { TankDeliveryReport } from './TankDeliveryReport'

export class DeliveryReport {
  @Expose() id: number

  @Expose({ name: 'work_request_id' }) workRequestNo: string

  @Expose() product: string

  @Expose() fromCompany: string

  @Expose() toCompany: string

  @Expose() tankNo: string

  @Expose() workStatus: string

  @Expose() verifyStatus: string

  @Expose() tanks: TankDeliveryReport[]

  @Expose() atgChecklist: CheckListType[]

  @Expose() meterChecklist: CheckListType[]

  @Expose({ name: 'can_approve' }) canApprove: boolean

  @Expose({ name: 'can_submit' }) canSubmit: boolean

  @Expose() hasMeterLessline: boolean

  @Expose() fromTanks: string[]

  @Expose() toTanks: string[]

  @Expose() activity: string

  @Expose() portOfLoading: string

  @Expose() note: string

  @Expose() edition: string

  @Expose() isBlankForm: boolean
}
