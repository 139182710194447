import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { useProfile } from '@features/profile/hooks/useProfile'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import { isAdmin, isBoardman } from '@lib/permissions'

import { CompletePage } from './CompletePage'
import { VerifyingPage } from './VerifyingPage'
import { WaitSubmitWorkPage } from './WaitSubmitWorkPage'

export function CalculationPage() {
  const { data: profile } = useProfile()
  const { data: deliveryReport } = useDeliveryReport()

  const roleAdmin = isAdmin(profile?.roleGroup.reportRole || '')
  const roleBoardman = isBoardman(profile?.roleGroup.reportRole || '')

  if (
    (roleAdmin || roleBoardman) &&
    deliveryReport?.verifyStatus === 'Wait Submit Work'
  )
    return <WaitSubmitWorkPage />

  if (roleAdmin && deliveryReport?.verifyStatus === 'Verifying')
    return <VerifyingPage />

  if ((roleAdmin || roleBoardman) && deliveryReport?.verifyStatus)
    return <CompletePage />

  return (
    <Container>
      <Breadcrumbs />
      <Loading />
    </Container>
  )
}
