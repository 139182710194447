import { memo } from 'react'
import cx from 'classnames'

import { InputField } from '@components/fieldV2/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { VesselLoadType } from '@features/tracking-work/interfaces/VesselLoadType'
import {
  metricTonsInAirKeys,
  metricTonsInVacKeys,
  volume15Keys,
  volume30Keys,
} from '@lib/constants'

import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

export const VesselLoad = memo(({ disabled, reports }: VesselLoadType) => {
  const metricInAir = reports.find(report =>
    metricTonsInAirKeys.includes(report.keyAttribute)
  )
  const metricInVac = reports.find(report =>
    metricTonsInVacKeys.includes(report.keyAttribute)
  )
  const volume15 = reports.find(report =>
    volume15Keys.includes(report.keyAttribute)
  )
  const volume30 = reports.find(report =>
    volume30Keys.includes(report.keyAttribute)
  )

  const difference = {
    tonInAir: cal(
      Number(metricInAir?.startValue ?? null),
      Number(metricInAir?.stopLesslineValue ?? null),
      Number(metricInAir?.decimalPoint)
    ),
    tonInVac: cal(
      Number(metricInVac?.startValue ?? null),
      Number(metricInVac?.stopLesslineValue ?? null),
      Number(metricInVac?.decimalPoint)
    ),
    volume15: cal(
      Number(volume15?.startValue ?? null),
      Number(volume15?.stopLesslineValue ?? null),
      Number(volume15?.decimalPoint)
    ),
    volume30: cal(
      Number(volume30?.startValue ?? null),
      Number(volume30?.stopLesslineValue ?? null),
      Number(volume30?.decimalPoint)
    ),
  }

  function cal(start: number, stop: number, decimalPoint: number) {
    let ans = 0

    if (disabled) return ans.toFixed(decimalPoint)

    ans = stop - start

    return ans === 0
      ? ans.toFixed(decimalPoint)
      : Number(ans.toFixed(decimalPoint)).toLocaleString()
  }

  return (
    <Box
      className={cx({ [styles.disabled]: disabled })}
      title='Vessel Load / Dis. Quantities (Less-Line Mode)'
    >
      <div className={styles.vesselLoadForm}>
        <div className='text-center'>
          <strong>Value (Fill in)</strong>
        </div>
        <div className='text-center'>
          <strong>Difference</strong>
        </div>

        <InputField
          name='tonInAir'
          type='number'
          className={styles.vesselInput}
          disabled={disabled}
        />
        <div className={styles.difference}>
          <div className='text-center'>{difference.tonInAir}</div>
          <div>Tons in air</div>
        </div>

        <InputField
          name='tonInVac'
          type='number'
          className={styles.vesselInput}
          disabled={disabled}
        />
        <div className={styles.difference}>
          <div className='text-center'>{difference.tonInVac}</div>
          <div>Tons in vac</div>
        </div>

        <InputField
          name='volume15'
          type='number'
          className={styles.vesselInput}
          disabled={disabled}
        />
        <div className={styles.difference}>
          <div className='text-center'>{difference.volume15}</div>
          <div>Liters @ 15&#8451;</div>
        </div>

        <InputField
          name='volume30'
          type='number'
          className={styles.vesselInput}
          disabled={disabled}
        />
        <div className={styles.difference}>
          <div className='text-center'>{difference.volume30}</div>
          <div>Liters @ 30&#8451;</div>
        </div>
      </div>
    </Box>
  )
})
