import { useForm, FormProvider } from 'react-hook-form'
import { Button, CircularProgress } from '@material-ui/core'
import cx from 'classnames'

import { InputField } from '@components/fieldV2/InputField'
import { LogoIcon, TankIcon } from '@components/Icons'
import { SignInPageType } from '@features/authentication/interfaces/SignInType'
import styles from './index.module.scss'

export function SignInPage({ error, onSignIn }: SignInPageType) {
  const methods = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const { isSubmitting } = methods.formState

  return (
    <FormProvider {...methods}>
      <form
        className={styles.container}
        onSubmit={methods.handleSubmit(onSignIn)}
      >
        <LogoIcon width={150} height={80} viewBox='0 0 365 127.53' />
        <div className={styles.title}>
          <TankIcon width={68} height={70} viewBox='0 0 310 310' />
          MTT iReport Terminal Connect
        </div>
        <div className={styles.loginForm}>
          <InputField
            className={styles.inputField}
            name='username'
            label='username'
            disabled={isSubmitting}
          />
          <InputField
            className={cx(styles.inputField, 'mt')}
            name='password'
            label='password'
            type='password'
            disabled={isSubmitting}
          />
          {error && <div className='error-message'>{error}</div>}
          <Button
            className='mt'
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={18} /> : 'Login'}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
