import { useFormContext, useController } from 'react-hook-form'
import { FormControl, IconButton } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { DateTime } from 'luxon'

import styles from './index.module.scss'

interface DatetimeFieldType {
  className?: string
  name: string
  label?: string
  disabled?: boolean
  defaultValue?: string
  max?: string
}

export function DatetimeField({
  className,
  name,
  label,
  defaultValue = '',
  max = '',
  disabled = false,
}: DatetimeFieldType) {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control, defaultValue })
  const { invalid, error } = fieldState

  return (
    <FormControl className={className}>
      {label && (
        <div className='label'>
          <strong>{label}</strong>
        </div>
      )}

      <div className={styles.container}>
        {field.value
          ? DateTime.fromISO(field.value).toFormat('dd/LL/yyyy HH:mm')
          : 'dd/mm/yyyy --:--'}
        <IconButton color='primary'>
          <CalendarToday />
        </IconButton>

        <input {...field} type='datetime-local' disabled={disabled} max={max} />
      </div>
      {invalid && <div className={styles.error}>{error?.message}</div>}
    </FormControl>
  )
}
