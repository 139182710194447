import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { DailyReport } from '@features/report/interfaces/DailyReportType'
import { ConfigDailyReportForm } from '@features/config-daily-report/interfaces/ConfigDailyReportType'
import {
  SummaryDailyReport,
  GetSummaryDailyReportProps,
} from '@features/report/interfaces/SummaryDailyReportType'
import {
  DailyParams,
  CalculateDailyReport,
  CalculateDailyReportPropsType,
  HeaderDailyReport,
  ReportHeader,
} from '@features/report/interfaces/CalculateDailyReportType'

export class ReportClient {
  constructor(private client: AxiosInstance) {}

  async getDailyReports({ date = '' }): Promise<DailyReport[]> {
    const response = await this.client.get(
      `/api/reports/daily_reports?date=${date}`
    )

    return new Promise(resolve => {
      resolve(
        response.data.map((user: any) =>
          plainToClass(DailyReport, user, {
            excludeExtraneousValues: true,
          })
        )
      )
    })
  }

  async getSummaryDailyReports({
    productCode,
    tankCode,
    startDate,
    endDate,
  }: GetSummaryDailyReportProps): Promise<SummaryDailyReport[]> {
    if (!productCode || !tankCode) return []

    const response = await this.client.get(
      `/api/reports/summary_daily_reports?product_code=${productCode}&tank_code=${tankCode}&start_date=${startDate}&end_date=${endDate}`
    )

    return new Promise(resolve => {
      resolve(
        response.data.map((user: any) =>
          plainToClass(SummaryDailyReport, user, {
            excludeExtraneousValues: true,
          })
        )
      )
    })
  }

  async getVerifyDates() {
    const response = await this.client.get(`/api/reports/daily_reports/dates`)

    return response.data
  }

  async getCalculateDailyReport({
    tankCode,
    date,
  }: CalculateDailyReportPropsType): Promise<CalculateDailyReport[]> {
    const response = await this.client.get(
      `/api/reports/daily_report?tank_code=${tankCode}&date=${date}`
    )

    return response.data.map((row: any) => {
      return plainToClass(CalculateDailyReport, row, {
        excludeExtraneousValues: true,
      })
    })
  }

  async getHeaderDailyReport({
    companyId,
  }: {
    companyId?: number
  }): Promise<HeaderDailyReport> {
    const initialValue = {
      parameter: [],
      alltank: [],
      usable: [],
      avaliablespace: [],
    } as HeaderDailyReport

    if (!companyId) return initialValue

    const response = await this.client.get(`/api/companies/${companyId}`)
    const reportHeader = response.data.report_headers.reduce(
      (res: HeaderDailyReport, header: ReportHeader) => {
        return {
          ...res,
          [header.header_type]: [
            ...res[header.header_type],
            { name: header.header_column_desc, key: header.header_column },
          ],
        }
      },
      initialValue
    )

    return reportHeader
  }

  async calculate({
    tankCode,
    date,
    form,
  }: DailyParams): Promise<CalculateDailyReport[]> {
    const response = await this.client.patch(
      `/api/reports/daily_report/re_calculate?tank_code=${tankCode}&date=${date}`,
      form
    )

    return response.data.map((row: any) => {
      return plainToClass(CalculateDailyReport, row, {
        excludeExtraneousValues: true,
      })
    })
  }

  async saveOverwrite({ tankCode, date, form }: DailyParams): Promise<void> {
    await this.client.patch(
      `/api/reports/daily_report?tank_code=${tankCode}&date=${date}`,
      form
    )
  }

  async editConfigDailyReport({
    company,
    tanks,
    reportHeaders,
  }: ConfigDailyReportForm): Promise<void> {
    await this.client.patch(`/api/companies/${company?.id}`, {
      tanks,
      report_headers: reportHeaders,
    })
  }
}
