import { withPermissionGuard } from '@lib/permissions'
import { COMPANY_SETTING } from '@lib/constants'

import { withCompanyPage } from './withCompanyPage'
import { CompanyPage } from './CompanyPage'

const ConnectedCompanyPage = withPermissionGuard(
  withCompanyPage(CompanyPage),
  COMPANY_SETTING,
  'read'
)

export { ConnectedCompanyPage as CompanyPage }
