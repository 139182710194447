import { memo } from 'react'

import { Box } from '@features/tracking-work/components/Box'
import { OperationPropsType } from '@features/tracking-work/interfaces/ATGReportType'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

export const Operation = memo(
  ({
    className,
    pl,
    ph,
    hh,
    ll,
    beforeStop,
    beforeStopFiveMinutes,
  }: OperationPropsType) => {
    return (
      <Box className={className} title='For Operation'>
        <div className={styles.atgReportOperationContent}>
          <label>HH :</label>
          <div>
            <span className={styles.hh}>{hh.toLocaleString()}</span>
          </div>

          <label>PH :</label>
          <div>
            <span className={styles.ph}>{ph.toLocaleString()}</span>
            <span>{beforeStop.toLocaleString()}</span>
            Before Stop
          </div>

          <label>PL :</label>
          <div>
            <span className={styles.pl}>{pl.toLocaleString()}</span>
            <span>{beforeStopFiveMinutes.toLocaleString()}</span>
            (5 min)
          </div>

          <label>LL :</label>
          <div>
            <span className={styles.ll}>{ll.toLocaleString()}</span>
          </div>
        </div>
      </Box>
    )
  }
)
