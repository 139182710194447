import hoist from 'hoist-non-react-statics'
import { useParams, useNavigate } from 'react-router-dom'

import {
  RoleGroupFormType,
  RoleGroupFormProps,
  RoleGroupScreenType,
} from '@features/role-group/interfaces/RoleGroupType'
import { useRoleGroup } from '@features/role-group/hooks/useRoleGroup'
import { useScreenList } from '@features/screen/hooks/useScreenList'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withRoleGroupEditPage(Component: React.FC<RoleGroupFormProps>) {
  function WithRoleGroupEditPage() {
    const { error } = useAlert()
    const client = useAppClient()
    const { id } = useParams()
    const navigate = useNavigate()
    const { data: roleGroup, isLoading } = useRoleGroup({ id: id || '' })

    const roleGroupScreens = roleGroup?.screens?.length
      ? roleGroup?.screens.map(
          (permission): RoleGroupScreenType => ({
            write: permission.write,
            read: permission.read,
            id: permission.screen.id,
          })
        )
      : []

    const { data: screenList } = useScreenList()
    const screens = screenList.map(screen => {
      const roleGroupScreen = roleGroupScreens.find(row => row.id === screen.id)

      if (roleGroupScreen) {
        return {
          write: roleGroupScreen.write,
          read: roleGroupScreen.read,
          id: roleGroupScreen.id,
        }
      }
      return { write: false, read: false, id: screen.id }
    })

    async function handleSubmit(form: RoleGroupFormType) {
      let isError = false
      await client?.roleGroup.editRoleGroup(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) navigate('/role-group')
    }

    const pageProps = {
      initialValues: {
        id,
        code: roleGroup?.code || '',
        name: roleGroup?.name || '',
        reportRole: roleGroup?.reportRole || '',
        screens,
        active: String(!!roleGroup?.status),
      },
      loading: isLoading,
      handleSubmit,
    }

    return <Component {...pageProps} />
  }

  hoist(WithRoleGroupEditPage, Component)

  WithRoleGroupEditPage.displayName = `WithRoleGroupEditPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithRoleGroupEditPage
}
