import { useQuery } from 'react-query'
import { classToPlain } from 'class-transformer'

import { SearchFormType } from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'
import { useAppClient } from '@lib/useAppClient'

export function useBatchDocumentTrackingList(searchForm: SearchFormType) {
  const searchParam = classToPlain(new SearchFormType(searchForm))

  const client = useAppClient()
  const {
    data = { items: [], total: 0, page: 1, size: 0 },
    isLoading,
  } = useQuery(
    [
      'batch-document-tracking',
      'product',
      searchForm.product,
      'tank',
      searchForm.tank,
      'activity',
      searchForm.activity,
      'month',
      searchForm.month,
      'year',
      searchForm.year,
      'page',
      searchForm.page,
    ],
    () =>
      client?.batchReport.getBatchDocumentTrackingList(
        searchParam as SearchFormType
      )
  )

  return {
    data: data.items,
    total: data.total,
    page: data.page,
    size: data.size,
    isLoading,
  }
}
