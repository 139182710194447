import { useMemo } from 'react'
import hoist from 'hoist-non-react-statics'
import { useLocation, useParams } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { BreadcrumbsType, PathnameKey } from './interfaces'
import { PATH_NAME } from './constants'

export function withBreadcrumbs(Component: React.FC<BreadcrumbsType>) {
  function WithBreadcrumbs(props: FunctionComponentType) {
    const location = useLocation()
    const { id } = useParams()
    const paths = useMemo(() => {
      const pathnames = location.pathname
        .split('/')
        .filter(pathname => pathname !== id) as [PathnameKey]

      return pathnames.map((pathname: PathnameKey, index) => {
        if (index === 0) {
          return {
            to: '/',
            name: PATH_NAME.home,
            currentPath: false,
          }
        }

        const lastIndex = pathnames.length - 1
        return {
          to: pathnames.slice(0, index + 1).join('/'),
          name: PATH_NAME[pathname],
          currentPath: index === lastIndex,
        }
      })
    }, [location, id])

    const pageProps = {
      paths,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithBreadcrumbs, Component)

  WithBreadcrumbs.displayName = `WithBreadcrumbs(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithBreadcrumbs
}
