import { Table } from '@components/Table'
import { TableType } from '@interfaces/common/DataTableType'

export function TrackingWorkTable({
  data,
  columns,
  loading,
  page,
  count,
  onPaginationChange,
}: TableType) {
  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
      page={page}
      count={count}
      onPaginationChange={onPaginationChange}
    />
  )
}
