import { Column } from 'react-table'
import { Expose } from 'class-transformer'

import { Option } from '@interfaces/common/SelectType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'

export class TrackingWork {
  @Expose() activity: string

  @Expose() fromCompany: string

  @Expose() fromTanks: string[]

  @Expose() id: number

  @Expose() product: string

  @Expose() requestDate: string

  @Expose() tankNo: string

  @Expose() toCompany: string

  @Expose() toTanks: string[]

  @Expose() verifyStatus: string

  @Expose() workRequestCompany: string

  @Expose({ name: 'work_request_id' }) workRequestID: string

  @Expose() workRequestNo: number

  @Expose() workRequestYear: string

  @Expose() workStatus: string

  @Expose() status: string

  @Expose({ name: 'can_copy' }) canCopy: boolean

  @Expose({ name: 'can_revise' }) canRevise: boolean
}

export interface TrackingWorkList {
  items: TrackingWork[]
  total: number
  page: number
  size: number
}

export interface AdminTrackingWorkType {
  trackingWorkList?: TrackingWorkList
  rejectTrackingWorkList?: RejectTrackingWorkList
  productOptions: Option[]
  yearOptions: Option[]
  isTrackingWorkListLoading: boolean
  isRejectTrackingWorkListLoading: boolean
  modal: string
  payload: TrackingWork
  trackingWorkColumns: Column[]
  rejectTrackingWorkColumns: Column[]
  modifyWork: WorkOrderRequestNumberFormType
  isLoadingTrackingWork: boolean
  trackingWorkParam: TrackingWorkListQueryParamType
  rejectParam: RejectTrackingWorkListQueryParamType
  isSubmitting: boolean
  handleSearch: (form: AdminSearchForm) => void
  handleRejectSearch: (form: RejectSearchForm) => void
  handleReject: () => void
  handleRecover: () => void
  handleCloseModal: () => void
  handleOpenAddWorkModal: () => void
  handleAddWork: (form: WorkOrderRequestNumberFormType) => void
  handleModifyWork: (form: WorkOrderRequestNumberFormType) => void
  handlePaginationChange: (page: number) => void
  handleRejectPaginationChange: (page: number) => void
  handleReviseWork: () => void
  handleCopyWork: () => void
}

export interface AdminSearchType {
  className: string
  yearOptions: Option[]
  initialValues: AdminSearchForm
  handleSubmit: (form: AdminSearchForm) => void
}

export interface AdminSearchForm {
  workNo: string
  month: string
  year: string
  workStatus: string
  verifyStatus: string
  size: number
  page: number
}

export interface TrackingWorkListQueryParamType {
  workNo: string
  month: string
  year: string
  workStatus: string
  verifyStatus: string
  size: number
  page: number
  count: number
}

export interface RejectTrackingWorkForm {
  id: number
}

export interface GetTrackingWorkPropsType {
  id?: number
}

export interface RejectTrackingWorkList {
  items: TrackingWork[]
  total: number
  page: number
  size: number
}

export interface RejectSearchType {
  className: string
  yearOptions: Option[]
  initialValues: RejectSearchForm
  handleSubmit: (form: RejectSearchForm) => void
}

export interface RejectSearchForm {
  workNo: string
  month: string
  year: string
  size: number
  page: number
}

export interface RecoverTrackingWorkForm {
  id: number
}

export interface RejectTrackingWorkListQueryParamType {
  workNo: string
  month: string
  year: string
  size: number
  page: number
  count: number
}

export interface ReviseWorkParam {
  deliveryId: number
}
