import { CircularProgress, IconButton } from '@material-ui/core'
import { InsertDriveFile } from '@material-ui/icons'

import { ExportPdfButtonComponent } from './withExportPdfButton'

export function ExportPdfButton({
  loading,
  handleExportPdf,
}: ExportPdfButtonComponent) {
  return (
    <IconButton onClick={handleExportPdf} disabled={loading}>
      {loading ? <CircularProgress size={20} /> : <InsertDriveFile />}
    </IconButton>
  )
}
