import { CircularProgress } from '@material-ui/core'

import styles from './index.module.scss'

export function Loading() {
  return (
    <div className={styles.loading}>
      <CircularProgress />
    </div>
  )
}
