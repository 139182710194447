import { Outlet, useLocation } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { ConfirmModal } from '@components/ConfirmModal'
import { CalculationInformation } from '@features/tracking-work/components/CalculationInformation'
import { ChecklistGroup } from '@features/tracking-work/components/ChecklistGroup'
import { WaitSubmitWorkPageProps } from '@features/tracking-work/interfaces/WaitSubmitWorkType'

import styles from './index.module.scss'

const theme = createTheme({
  palette: {
    primary: { main: '#4caf50', light: '#4caf50E6', contrastText: '#FEFEFE' },
    secondary: {
      main: '#FFCE40', contrastText: '#000000'
    },
  },
})

export function WaitSubmitWorkPage({
  data,
  modal,
  loading,
  capacity,
  hideAction,
  isSubmitting,
  checkSaveStart,
  checkSaveStop,
  checkSaveLessline,
  handleSaveAtg,
  handleSaveMeter,
  openConfirmSubmitWorkModal,
  handleSubmitWork,
  closeModal,
  canSubmit: cSubmit,
  handleSaveAll,
}: WaitSubmitWorkPageProps) {
  const { pathname } = useLocation()
  const isMeterMode = pathname.includes('meter')
  const canSubmit = data?.canSubmit

  return (
    <Container>
      <Breadcrumbs />
      <div className={styles.submitGroup}>
        <ThemeProvider theme={theme}>
        <Button
          color='secondary'
          variant='contained'
          onClick={(e) => handleSaveAll(isMeterMode)}
          >
            SAVE
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={!canSubmit || isSubmitting || !cSubmit}
            onClick={openConfirmSubmitWorkModal}
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'Submit Work'}
          </Button>
        </ThemeProvider>
      </div>

      {loading && !data ? (
        <Loading />
      ) : (
        <>
          <CalculationInformation
            workRequestNo={data?.workRequestNo || '-'}
            product={data?.product || '-'}
            categories={data?.activity || '-'}
            portOfLoading={data?.portOfLoading || '-'}
            fromCompany={data?.fromCompany || '-'}
            fromTank={data?.fromTanks.join(',') || '-'}
            toCompany={data?.toCompany || '-'}
            toTank={data?.toTanks.join(',') || '-'}
            capacity={capacity}
          />

          <ChecklistGroup
            atgChecklist={data?.atgChecklist || []}
            meterChecklist={data?.meterChecklist || []}
            isMeterMode={isMeterMode}
            hideAction={hideAction}
            handleSaveAtg={handleSaveAtg}
            handleSaveMeter={handleSaveMeter}
          />
          <Outlet />
        </>
      )}

      <ConfirmModal
        open={modal === 'submit-work'}
        handleConfirm={handleSubmitWork}
        handleClose={closeModal}
      >
        <div className={styles.confirmMessage}>
          <strong>
            Are you sure you want to submit <span>{data?.workRequestNo}</span> ?
          </strong>
        </div>
        {checkSaveStart ? (
          <div className='error-message'>* Start(ATG) ยังไม่ได้บันทึก</div>
        ) : null}
        {checkSaveStop ? (
          <div className='error-message'>* Stop(ATG) ยังไม่ได้บันทึก</div>
        ) : null}
        {checkSaveLessline ? (
          <div className='error-message'>* Stop(Less-line) ยังไม่ได้บันทึก</div>
        ) : null}
      </ConfirmModal>
    </Container>
  )
}
