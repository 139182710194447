import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useTankList() {
  const client = useAppClient()
  const { data, isLoading } = useQuery('tanks', () =>
    client?.tank.getTankList().then(response => response)
  )

  return {
    data: data || [],
    isLoading,
  }
}
