import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { Activity } from '@features/activity/interfaces/ActivityType'

export class ActivityClient {
  constructor(private client: AxiosInstance) {}

  async getActivityList({ productCode = '' }): Promise<Activity[]> {
    const response = await this.client.get(
      `/api/activities?product_code=${productCode}`
    )

    return response.data.map((product: any) =>
      plainToClass(Activity, product, {
        excludeExtraneousValues: true,
      })
    )
  }
}
