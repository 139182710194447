import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'

import {
  SearchForm,
  DraftList,
  DraftQueryParamType,
} from '@features/draft-calculation/interfaces/DraftType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useAppClient } from '@lib/useAppClient'

interface UseDraftListResponse {
  data?: DraftList
  isLoading: boolean
  queryParam: DraftQueryParamType
  refetch: () => Promise<any>
  addWorkRequest: (form: WorkOrderRequestNumberFormType) => Promise<void>
  updateWorkRequest: (form: WorkOrderRequestNumberFormType) => Promise<void>
  handleSearch: (form: SearchForm) => void
  handlePaginationChange: (page: number) => void
}

export function useDraftList(): UseDraftListResponse {
  const client = useAppClient()
  const [queryParam, setQueryParam] = useState<DraftQueryParamType>({
    workNo: '',
    month: DateTime.now().toFormat('L'),
    year: DateTime.now().toFormat('yyyy'),
    size: 10,
    page: 1,
    count: 0,
  })

  const { data, isLoading, refetch } = useQuery(
    [
      'tracking-work',
      queryParam.workNo,
      queryParam.month,
      queryParam.year,
      queryParam.page,
      queryParam.size,
    ],
    () =>
      client?.draft
        .getDraftList({
          workNo: queryParam.workNo,
          month: queryParam.month === 'all' ? '' : queryParam.month,
          year: queryParam.year,
          size: queryParam.size,
          page: queryParam.page,
        })
        .then(response => response)
  )

  async function addWorkRequest(form: WorkOrderRequestNumberFormType) {
    await client?.draft.addDraft(form)
  }

  async function updateWorkRequest(form: WorkOrderRequestNumberFormType) {
    await client?.draft.updateDraft(form)
  }

  function handleSearch(form: SearchForm) {
    setQueryParam(prev => ({
      ...prev,
      ...form,
      page: 1,
    }))
  }

  function handlePaginationChange(page: number) {
    setQueryParam(prev => ({ ...prev, page }))
  }

  useEffect(() => {
    if (data && queryParam.count !== Math.ceil(data.total / data.size))
      setQueryParam(prev => ({
        ...prev,
        count: Math.ceil(data.total / data.size),
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    data,
    isLoading,
    queryParam,
    refetch,
    addWorkRequest,
    updateWorkRequest,
    handleSearch,
    handlePaginationChange,
  }
}
