import { withPermissionGuard } from '@lib/permissions'
import { TRACKING_WORK } from '@lib/constants'

import { TrackingWorkPage } from './TrackingWorkPage'
import { withTrackingWorkPage } from './withTrackingWorkPage'

const ConnectedTrackingWorkPage = withPermissionGuard(
  withTrackingWorkPage(TrackingWorkPage),
  TRACKING_WORK,
  'read'
)

export { ConnectedTrackingWorkPage as TrackingWorkPage }
