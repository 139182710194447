import { useFormContext } from 'react-hook-form'

import { InputField } from '@components/fieldV2/InputField'
import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

interface SummaryDetailPropsType {
  title: string
  volume15C: string
  volume30C: string
  tonInAir: string
  tonInVac: string
  disabled?: boolean
  handleTonInVacChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTonInAirChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleVolume30Change?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleVolume15Change?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function SummaryDetail({
  title,
  volume15C,
  volume30C,
  tonInAir,
  tonInVac,
  disabled = false,
  handleTonInVacChange,
  handleTonInAirChange,
  handleVolume30Change,
  handleVolume15Change,
}: SummaryDetailPropsType) {
  return (
    <>
      <div className={styles.atgReportCalculationDetailLabel}>
        <strong>{title}</strong>
      </div>
      <InputField
        type='number'
        name={volume15C}
        disabled={disabled}
        handleChange={handleVolume15Change}
      />
      <div>Volume @ 15 &#8451; (Liters)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <InputField
        type='number'
        name={volume30C}
        disabled={disabled}
        handleChange={handleVolume30Change}
      />
      <div>Volume @ 30 &#8451; (Liters)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <InputField
        type='number'
        name={tonInAir}
        disabled={disabled}
        handleChange={handleTonInAirChange}
      />
      <div>Metric ton (in air)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <InputField
        type='number'
        name={tonInVac}
        disabled={disabled}
        handleChange={handleTonInVacChange}
      />
      <div>Metric ton (in vac)</div>
      <div />
    </>
  )
}
