import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { ATGReport } from '@features/tracking-work/interfaces/ATGReportType'
import { CalculationDetailType } from '@features/tracking-work/interfaces/CalculationDetailType'
import { ManualDipCalculateForm } from '@features/tracking-work/interfaces/ManualDipType'
import { ProrateDensityForm } from '@features/tracking-work/interfaces/ProrateDensityType'
import { ProrateMWForm } from '@features/tracking-work/interfaces/ProrateMWType'
import { getQueryParams } from '@lib/utils'
import { GoalSeekType } from '@features/tracking-work/interfaces/GoalSeekType'

export type CalculateType = 'start' | 'stop'
export type CalculateMode = 'normal' | 'less_line'

interface GetCalculateDetailParams {
  deliveryId: number | string
  tankCode: string
}

interface UpdateCalculateDetailParams extends GetCalculateDetailParams {
  form: CalculationDetailType[]
  manualForm: ManualDipCalculateForm
  purity: string
  type: CalculateType
  mode: CalculateMode
  datetime: string
}

interface GetCalculateParams extends GetCalculateDetailParams {
  type: CalculateType
  mode: CalculateMode
  datetime: string | null
}

interface ManualDipCalculateParams extends GetCalculateDetailParams {
  type: CalculateType
  mode: CalculateMode
  form: ManualDipCalculateForm
  purity: string
}

interface CalculateProrateDensityParams extends GetCalculateDetailParams {
  form: ProrateDensityForm
}

interface CalculateProrateMolecularWeightParams
  extends GetCalculateDetailParams {
  form: ProrateMWForm
}

interface UpdateChecklist extends GetCalculateDetailParams {
  form: {
    vessel_load_ton_in_air?: number
    vessel_load_ton_in_vac?: number
    vessel_load_liters_15_c?: number
    vessel_load_liters_30_c?: number
    total_volume_30_c?: number
    total_volume_15_c?: number
    total_metric_tons_in_air?: number
    total_metric_tons_in_vac?: number
    meter_volume_30_c?: number
    meter_volume_15_c?: number
    meter_metric_tons_in_air?: number
    meter_metric_tons_in_vac?: number
    less_line_volume_30_c?: number
    less_line_volume_15_c?: number
    less_line_metric_tons_in_air?: number
    less_line_metric_tons_in_vac?: number
    final_volume_30_c?: number
    final_volume_15_c?: number
    final_metric_tons_in_air?: number
    final_metric_tons_in_vac?: number
    start_date?: string
    end_date?: string
  }
}

interface GoalSeekParams {
  deliveryId: string
  tankCode: string
  value: string
}

interface CalculateResponse {
  datetime: string
  reports: CalculationDetailType[]
}

export class ATGReportClient {
  constructor(private client: AxiosInstance) {}

  async getCalculateDetail({
    deliveryId,
    tankCode,
  }: GetCalculateDetailParams): Promise<ATGReport> {
    if (!deliveryId || !tankCode) return plainToClass(ATGReport, {})

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/reports`
    )

    return plainToClass(ATGReport, response.data)
  }

  async getCalculate({
    deliveryId,
    tankCode,
    type,
    mode,
    datetime,
  }: GetCalculateParams): Promise<CalculateResponse> {
    const queryParams = getQueryParams({
      type,
      mode,
      datetime,
    })

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/calculate?${queryParams}`
    )

    return {
      datetime: response.data.datetime,
      reports: response.data.reports.map((row: any) =>
        plainToClass(CalculationDetailType, row)
      ),
    }
  }

  async manualCalculate({
    deliveryId,
    tankCode,
    type,
    mode,
    form,
    purity,
  }: ManualDipCalculateParams): Promise<CalculationDetailType[]> {
    const queryParams = getQueryParams({
      type,
      mode,
      ...(form.level && { level: form.level }),
      ...(form.liquidTemperature && {
        liquid_temperature: form.liquidTemperature,
      }),
      ...(form.vaporTemperature && {
        vapor_temperature: form.vaporTemperature,
      }),
      ...(form.vaporPressure && { vapor_pressure: form.vaporPressure }),
      ...(form.densityInVac && { density_in_vac: form.densityInVac }),
      ...(form.molecularWeight && { molecular_weight: form.molecularWeight }),
      ...(purity && { product_purity: purity }),
    })

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/manual_calculate?${queryParams}`
    )

    return response.data.map((row: any) =>
      plainToClass(CalculationDetailType, row)
    )
  }

  async calculateProrateDensity({
    form,
    deliveryId,
    tankCode,
  }: CalculateProrateDensityParams): Promise<number> {
    const queryParams = getQueryParams({
      density_shore_before: form.shoreBefore,
      density_ship_before: form.shipBefore,
    })

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/density_in_vac_calculate?${queryParams}`
    )

    return response.data.value
  }

  async calculateProrateMolecularWeight({
    form,
    deliveryId,
    tankCode,
  }: CalculateProrateMolecularWeightParams): Promise<number> {
    const queryParams = getQueryParams({
      molecular_weight_shore_before: form.shoreBefore,
      molecular_weight_ship_before: form.shipBefore,
    })

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/molecular_weight_calculate?${queryParams}`
    )

    return response.data.value
  }

  async updateCalculateDetail({
    form,
    manualForm,
    purity,
    deliveryId,
    tankCode,
    type,
    mode,
    datetime,
  }: UpdateCalculateDetailParams): Promise<void> {
    await this.client.patch(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/reports?type=${type}&mode=${mode}`,
      {
        datetime,
        reports: form,
        manual_parameter: {
          ...(purity && { product_purity: Number(purity) }),
          ...(manualForm.level && { level: Number(manualForm.level) }),
          ...(manualForm.liquidTemperature && {
            liquid_temperature: Number(manualForm.liquidTemperature),
          }),
          ...(manualForm.vaporTemperature && {
            vapor_temperature: Number(manualForm.vaporTemperature),
          }),
          ...(manualForm.vaporPressure && {
            vapor_pressure: Number(manualForm.vaporPressure),
          }),
          ...(manualForm.densityInVac && {
            density_in_vac: Number(manualForm.densityInVac),
          }),
          ...(manualForm.molecularWeight && {
            molecular_weight: Number(manualForm.molecularWeight),
          }),
        },
      }
    )
  }

  async updateChecklist({ deliveryId, tankCode, form }: UpdateChecklist) {
    await this.client.patch(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/atg_checklists`,
      form
    )
  }

  async goalSeek({
    deliveryId,
    tankCode,
    value,
  }: GoalSeekParams): Promise<GoalSeekType> {
    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/goal_seek?delivery_report_id=${deliveryId}&tank_code=${tankCode}&value=${value}`
    )

    return plainToClass(GoalSeekType, response.data)
  }
}
