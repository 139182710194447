import cx from 'classnames'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import styles from './index.module.scss'

interface BoxType extends FunctionComponentType {
  title: string
  className?: string
}

export function Box({ title, className, children }: BoxType) {
  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
