import hoist from 'hoist-non-react-statics'
import { useParams } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { DeliveryReportProvider } from '@features/tracking-work/hooks/useDeliveryReport'

export function withCalculationPage(
  Component: React.FC<FunctionComponentType>
) {
  function WithCalculationPage() {
    const { id } = useParams()

    return (
      <DeliveryReportProvider id={id || ''}>
        <Component />
      </DeliveryReportProvider>
    )
  }

  hoist(WithCalculationPage, Component)

  WithCalculationPage.displayName = `WithCalculationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCalculationPage
}
