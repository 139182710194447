import { Expose, Type, Transform } from 'class-transformer'

import { CalculationDetailType } from '@features/tracking-work/interfaces/CalculationDetailType'
import {
  CalculateMode,
  CalculateType,
} from '@lib/clients/collections/ATGReportClient'

export class SummaryMeterReport {
  @Expose({ name: 'vessel_load_ton_in_air' }) vesselLoadTonInAir: number

  @Expose({ name: 'vessel_load_ton_in_vac' }) vesselLoadTonInVac: number

  @Expose({ name: 'vessel_load_liters_15_c' }) vesselLoadLiters15C: number

  @Expose({ name: 'vessel_load_liters_30_c' }) vesselLoadLiters30C: number

  @Expose({ name: 'ship_received_ton_in_air' }) shipReceivedTonInAir: number

  @Expose({ name: 'ship_received_ton_in_vac' }) shipReceivedTonInVac: number

  @Expose({ name: 'ship_received_liters_15_c' })
  shipReceivedLiters15C: number

  @Expose({ name: 'ship_received_liters_30_c' })
  shipReceivedLiters30C: number

  @Expose({ name: 'measurment_volume_30_c' }) measurmentVolume30C: number

  @Expose({ name: 'measurment_volume_15_c' }) measurmentVolume15C: number

  @Expose({ name: 'measurment_metric_tons_in_air' })
  measurmentMetricTonsInAir: number

  @Expose({ name: 'measurment_metric_tons_in_vac' })
  measurmentMetricTonsInVac: number

  @Expose({ name: 'less_line_volume_30_c' }) lessLineVolume30C: number

  @Expose({ name: 'less_line_volume_15_c' }) lessLineVolume15C: number

  @Expose({ name: 'less_line_metric_tons_in_air' })
  lessLineMetricTonsInAir: number

  @Expose({ name: 'less_line_metric_tons_in_vac' })
  lessLineMetricTonsInVac: number

  @Expose({ name: 'final_volume_30_c' }) finalVolume30C: number

  @Expose({ name: 'final_volume_15_c' }) finalVolume15C: number

  @Expose({ name: 'final_metric_tons_in_air' }) finalMetricTonsInAir: number

  @Expose({ name: 'final_metric_tons_in_vac' }) finalMetricTonsInVac: number
}

export class SummaryAtgReport {
  @Expose({ name: 'vessel_load_ton_in_air' }) vesselLoadTonInAir: number

  @Expose({ name: 'vessel_load_ton_in_vac' }) vesselLoadTonInVac: number

  @Expose({ name: 'vessel_load_liters_15_c' }) vesselLoadLiters15C: number

  @Expose({ name: 'vessel_load_liters_30_c' }) vesselLoadLiters30C: number

  @Expose({ name: 'total_volume_30_c' }) totalVolume30C: number

  @Expose({ name: 'total_volume_15_c' }) totalVolume15C: number

  @Expose({ name: 'total_metric_tons_in_air' }) totalMetricTonsInAir: number

  @Expose({ name: 'total_metric_tons_in_vac' }) totalMetricTonsInVac: number

  @Expose({ name: 'meter_volume_30_c' }) meterVolume30C: number

  @Expose({ name: 'meter_volume_15_c' }) meterVolume15C: number

  @Expose({ name: 'meter_metric_tons_in_air' }) meterMetricTonsInAir: number

  @Expose({ name: 'meter_metric_tons_in_vac' }) meterMetricTonsInVac: number

  @Expose({ name: 'less_line_volume_30_c' }) lessLineVolume30C: number

  @Expose({ name: 'less_line_volume_15_c' }) lessLineVolume15C: number

  @Expose({ name: 'less_line_metric_tons_in_air' })
  lessLineMetricTonsInAir: number

  @Expose({ name: 'less_line_metric_tons_in_vac' })
  lessLineMetricTonsInVac: number

  @Expose({ name: 'final_volume_30_c' }) finalVolume30C: number

  @Expose({ name: 'final_volume_15_c' }) finalVolume15C: number

  @Expose({ name: 'final_metric_tons_in_air' }) finalMetricTonsInAir: number

  @Expose({ name: 'final_metric_tons_in_vac' }) finalMetricTonsInVac: number
}

class ParameterValue {
  @Expose() tag: string

  @Expose() value: number

  @Expose() unit: string
}

class Parameter {
  @Expose({ name: 'level_master' })
  @Type(() => ParameterValue)
  levelMaster: ParameterValue

  @Expose({ name: 'level_slave' })
  @Type(() => ParameterValue)
  levelSlave: ParameterValue

  @Expose({ name: 'liquid_temperature' })
  @Type(() => ParameterValue)
  liquidTemperature: ParameterValue

  @Expose({ name: 'vapor_temperature' })
  @Type(() => ParameterValue)
  vaporTemperature: ParameterValue

  @Expose({ name: 'vapor_pressure' })
  @Type(() => ParameterValue)
  vaporPressure: ParameterValue
}

class GaugeValue {
  @Expose() tankNo: string

  @Expose({ name: 'parameters' })
  @Type(() => Parameter)
  parameter: Parameter

  @Expose({ name: 'work_number' }) workNumber: string

  @Expose({ name: 'company_name' }) companyName: string

  @Expose({ name: 'time' }) time: string

  @Expose({ name: 'product' }) product: string

  @Expose({ name: 'batch_period' }) batchPeriod: string

  @Expose({ name: 'activity' }) activity: string

  @Expose({ name: 'from_tank' }) fromTank: string

  @Expose({ name: 'to_tank' }) toTank: string
}

class Gauge {
  @Expose()
  @Type(() => GaugeValue)
  start: GaugeValue

  @Expose()
  @Type(() => GaugeValue)
  stop: GaugeValue

  @Expose({ name: 'stop_lessline' })
  @Type(() => GaugeValue)
  stopLessline: GaugeValue
}

class MeterChecklistReport {
  @Expose() time: string

  @Expose({ name: 'gross_volume' }) volume30: number

  @Expose({ name: 'net_volume' }) volume15: number

  @Expose({ name: 'mass_in_vac' }) tonInVac: number

  @Expose({ name: 'mass_in_air' }) tonInAir: number
}

export class MeterChecklist {
  @Expose() mode: CalculateMode

  @Expose() necessity: boolean

  @Expose()
  @Type(() => MeterChecklistReport)
  report: MeterChecklistReport

  @Expose() status: boolean

  @Expose() type: CalculateType
}

export class Tank {
  @Expose() tankNo: string

  @Expose() capacity: number

  @Expose() shipmentNumber: string

  @Expose({ name: 'atg_reports' })
  @Type(() => CalculationDetailType)
  atgReports: CalculationDetailType[]

  @Expose({ name: 'summary_meter_report' })
  @Type(() => SummaryMeterReport)
  summaryMeterReport: SummaryMeterReport

  @Expose({ name: 'summary_atg_report' })
  @Type(() => SummaryAtgReport)
  summaryAtgReport: SummaryAtgReport

  @Expose({ name: 'meter_checklists' })
  @Type(() => MeterChecklist)
  meterChecklist: MeterChecklist[]

  @Expose()
  @Type(() => Gauge)
  gauge: Gauge
}

export class BatchDocumentReport {
  @Expose() id: number

  @Expose() requestDate: string

  @Expose() workRequestCompany: string

  @Expose({ name: 'work_request_id' }) workRequestNo: string

  @Expose() workRequestYear: number

  @Expose() product: string

  @Expose() activity: string

  @Expose() fromCompany: string

  @Expose() toCompany: string

  @Expose({ name: 'start_date' })
  @Transform(({ value }) => value || '')
  startDate: string

  @Expose({ name: 'end_date' })
  @Transform(({ value }) => value || '')
  endDate: string

  @Expose() note: string

  @Expose() portOfLoading: string

  @Expose() fromTanks: string[]

  @Expose() toTanks: string[]

  @Expose()
  @Type(() => Tank)
  tanks: Tank[]

  @Expose({ name: 'ship_name' }) shipName: string
}
