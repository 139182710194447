import { Page, View, Text, Image } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import scgImg from '@assets/images/SCGC_Logo_Full Color_Clear_Space.png'
import signature from '@assets/images/sign_Chanud.png'
import { MeterProps } from '@features/pdf/interfaces/MeterType'
import styles from './styles'

export function Meter({
  shipName,
  meterChecklist,
  product,
  categories,
  tankNo,
  summaryMeterReport,
  summaryAtgReport,
}: MeterProps) {
  const isPropaneImport =
    product.toLowerCase() === 'propane' && categories.toLowerCase() === 'import'
  const isBenzeneExport =
    product.toLowerCase() === 'benzene' && categories.toLowerCase() === 'export'
  const isMixC4Export =
    product.toLowerCase() === 'mixed-c4-scg' &&
    categories.toLowerCase() === 'export'
  const meterNo = isPropaneImport
    ? 'FT-4601A'
    : isBenzeneExport
    ? 'FT-3203A'
    : isMixC4Export
    ? 'FT-4001A'
    : '-'
  const start = meterChecklist.find(v => v.type === 'start')
  const stop = meterChecklist.find(
    v => v.type === 'stop' && v.mode === 'normal'
  )

  const difference = isPropaneImport
    ? {
        volume15:
          summaryAtgReport.finalVolume15C -
          summaryMeterReport.shipReceivedLiters15C,
        volume30:
          summaryAtgReport.finalVolume30C -
          summaryMeterReport.shipReceivedLiters30C,
        tonInVac:
          summaryAtgReport.finalMetricTonsInVac -
          summaryMeterReport.shipReceivedTonInVac,
        tonInAir:
          summaryAtgReport.finalMetricTonsInAir -
          summaryMeterReport.shipReceivedTonInAir,
      }
    : isBenzeneExport || isMixC4Export
    ? {
        volume15:
          summaryMeterReport.shipReceivedLiters15C -
          Math.abs(summaryAtgReport.finalVolume15C),
        volume30:
          summaryMeterReport.shipReceivedLiters30C -
          Math.abs(summaryAtgReport.finalVolume30C),
        tonInVac:
          summaryMeterReport.shipReceivedTonInVac -
          Math.abs(summaryAtgReport.finalMetricTonsInVac),
        tonInAir:
          summaryMeterReport.shipReceivedTonInAir -
          Math.abs(summaryAtgReport.finalMetricTonsInAir),
      }
    : {
        volume15: '-',
        volume30: '-',
        tonInVac: '-',
        tonInAir: '-',
      }

  const percentage = isPropaneImport
    ? {
        volume15: calPercentage(
          difference.volume15,
          summaryMeterReport.shipReceivedLiters15C
        ),
        volume30: calPercentage(
          difference.volume30,
          summaryMeterReport.shipReceivedLiters30C
        ),
        tonInVac: calPercentage(
          difference.tonInVac,
          summaryMeterReport.shipReceivedTonInVac
        ),
        tonInAir: calPercentage(
          difference.tonInAir,
          summaryMeterReport.shipReceivedTonInAir
        ),
      }
    : isBenzeneExport || isMixC4Export
    ? {
        volume15: calPercentage(
          difference.volume15,
          Math.abs(summaryAtgReport.finalVolume15C)
        ),
        volume30: calPercentage(
          difference.volume30,
          Math.abs(summaryAtgReport.finalVolume30C)
        ),
        tonInVac: calPercentage(
          difference.tonInVac,
          Math.abs(summaryAtgReport.finalMetricTonsInVac)
        ),
        tonInAir: calPercentage(
          difference.tonInAir,
          Math.abs(summaryAtgReport.finalMetricTonsInAir)
        ),
      }
    : {
        volume15: '-',
        volume30: '-',
        tonInVac: '-',
        tonInAir: '-',
      }

  function calPercentage(d: number | string, s: number) {
    if (s === 0) return '-'

    return typeof d === 'number' ? `${((d / s) * 100).toFixed(2)}%` : '-'
  }

  function numberFormat(d: number | string | undefined) {
    return typeof d === 'number' ? d.toLocaleString() : '-'
  }

  return (
    <Page size='A4' style={styles.page}>
      <View style={styles.titleContainer}>
        <View style={styles.imageWrap}>
          <Image src={scgImg} />
        </View>

        <Text style={styles.title}>OMNI REPORT</Text>
      </View>

      <View style={styles.informationGroup}>
        <Text style={styles.informationLabel}>Ship Name:</Text>
        <Text>{shipName}</Text>

        <Text style={styles.informationLabel}>Loading Date:</Text>
        <Text>
          {DateTime.fromISO(stop?.report?.time || '').toFormat('dd MMMM yyyy')}
        </Text>
      </View>

      <Text style={styles.header}>METERING QUANTITY</Text>

      <View style={styles.tableContainer}>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Meter No.</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.textCenter}>{meterNo}</Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.textCenter}>{meterNo}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Status</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.headTable}>Initial</Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.headTable}>Final</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Tank No.</Text>
          </View>
          <View style={{ width: '66%', padding: 4 }}>
            <Text style={styles.textCenter}>{tankNo}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Net Std.Vol @ 15 Deg.C, liters</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.textCenter}>
              {numberFormat(start?.report?.volume15)}
            </Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.textCenter}>
              {numberFormat(stop?.report?.volume15)}
            </Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Net Std.Vol @ 30 Deg.C, liters</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.textCenter}>
              {numberFormat(start?.report?.volume30)}
            </Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.textCenter}>
              {numberFormat(stop?.report?.volume30)}
            </Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Metric Ton in vacuum</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.textCenter}>
              {numberFormat(start?.report?.tonInVac)}
            </Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.textCenter}>
              {numberFormat(stop?.report?.tonInVac)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.meteringTopic}>
            <Text style={styles.textLeft}>Metric Ton in air</Text>
          </View>
          <View style={[styles.borderRight, styles.meteringDetail]}>
            <Text style={styles.textCenter}>
              {numberFormat(start?.report?.tonInAir)}
            </Text>
          </View>
          <View style={styles.meteringDetail}>
            <Text style={styles.textCenter}>
              {numberFormat(stop?.report?.tonInAir)}
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.header}>COMPARISON</Text>
      <View style={styles.tableContainer}>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={[styles.comparisonTopic, styles.borderRight]} />
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Meter</Text>
            <Text style={styles.headTable}>Delivery</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Less Line Content</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Total Meter Delivery</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Shore tank Delivery</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Total</Text>
            <Text style={styles.headTable}>Delivery</Text>
            <Text style={styles.headTable}>Meter + Shore Tank</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Ship</Text>
            <Text style={styles.headTable}>Received</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.headTable}>Difference</Text>
          </View>
          <View style={styles.comparisonDetail}>
            <Text style={styles.headTable}>Percentage</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={[styles.comparisonTopic, styles.borderRight]}>
            <Text style={styles.textLeft}>Net Std.Vol @ 15 Deg.C,liters</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.measurmentVolume15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.lessLineVolume15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.finalVolume15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.totalVolume15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.finalVolume15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.shipReceivedLiters15C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(difference.volume15)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail]}>
            <Text style={styles.textCenter}>{percentage.volume15}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={[styles.comparisonTopic, styles.borderRight]}>
            <Text style={styles.textLeft}>Net Std.Vol @ 30 Deg.C,liters</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.measurmentVolume30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.lessLineVolume30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.finalVolume30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.totalVolume30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.finalVolume30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.shipReceivedLiters30C)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(difference.volume30)}
            </Text>
          </View>
          <View style={styles.comparisonDetail}>
            <Text style={styles.textCenter}>{percentage.volume30}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderBottom]}>
          <View style={[styles.comparisonTopic, styles.borderRight]}>
            <Text style={styles.textLeft}>Metric Tons in vacuum</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.measurmentMetricTonsInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.lessLineMetricTonsInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.finalMetricTonsInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.totalMetricTonsInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.finalMetricTonsInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.shipReceivedTonInVac)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(difference.tonInVac)}
            </Text>
          </View>
          <View style={styles.comparisonDetail}>
            <Text style={styles.textCenter}>{percentage.tonInVac}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.comparisonTopic, styles.borderRight]}>
            <Text style={styles.textLeft}>Metric Tons in air</Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.measurmentMetricTonsInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.lessLineMetricTonsInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.finalMetricTonsInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.totalMetricTonsInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryAtgReport?.finalMetricTonsInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(summaryMeterReport?.shipReceivedTonInAir)}
            </Text>
          </View>
          <View style={[styles.comparisonDetail, styles.borderRight]}>
            <Text style={styles.textCenter}>
              {numberFormat(difference.tonInAir)}
            </Text>
          </View>
          <View style={styles.comparisonDetail}>
            <Text style={styles.textCenter}>{percentage.tonInAir}</Text>
          </View>
        </View>
      </View>
      <View style={styles.signContainer}>
        <View style={styles.signatureImage}>
          <Image src={signature} />
        </View>
        <Text style={styles.sign}>Map Ta Phut Tank Terminal</Text>
      </View>
    </Page>
  )
}
