import { AdminMonthlyReportProvider } from '@features/monthly-report/hooks/useAdminMonthlyReport'
import { UserMonthlyReportProvider } from '@features/monthly-report/hooks/useUserMonthlyReport'
import { MonthlyReportPageProps } from '@features/monthly-report/interfaces/MonthlyReportType'
import { AdminPage } from '@features/monthly-report/pages/admin'
import { UserPage } from '@features/monthly-report/pages/user'

export function MonthlyReportPage({ isAdmin }: MonthlyReportPageProps) {
  if (isAdmin)
    return (
      <AdminMonthlyReportProvider>
        <AdminPage />
      </AdminMonthlyReportProvider>
    )

  return (
    <UserMonthlyReportProvider>
      <UserPage />
    </UserMonthlyReportProvider>
  )
}
