import { createContext, useContext, useEffect, useState, Ref } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { UseFormReturn, useForm } from 'react-hook-form'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useProfile } from '@features/profile/hooks/useProfile'
import { DeliveryReport } from '@features/tracking-work/interfaces/DeliveryReportType'
import {
  MeterFormType,
  MeterReport,
  MeterValueType,
  ShipFormType,
  SummaryFormType,
  VesselLoadFormType,
} from '@features/tracking-work/interfaces/MeterReportType'
import {
  CalculationDetailType,
  CalculationFormType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { Level } from '@features/tracking-work/interfaces/LevelType'
import { ConfigDeliveryReport } from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import {
  VesselLoadForm,
  VesselLoadKey,
} from '@features/tracking-work/interfaces/VesselLoadType'
import { ManualDipForm } from '@features/tracking-work/interfaces/ManualDipType'
import { VerifyingFormType } from '@features/tracking-work/interfaces/VerifyingType'
import { SummaryFormType as ATGSummaryFormType } from '@features/tracking-work/interfaces/SummaryType'
import { BlankFormType } from '@features/tracking-work/interfaces/BlankFormType'
import { SummaryReport } from '@features/tracking-work/interfaces/ATGReportType'
import { useAlert } from '@lib/useAlert'
import { useAppClient } from '@lib/useAppClient'
import { isAdmin, isBoardman } from '@lib/permissions'
import {
  calDifference,
  calFinal,
  calLessline,
  calTotal,
  getErrorMessage,
} from '@lib/utils'
import {
  CalculateType,
  CalculateMode,
} from '@lib/clients/collections/ATGReportClient'
import {
  metricTonsInAirKeys,
  metricTonsInVacKeys,
  volume15Keys,
  volume30Keys,
} from '@lib/constants'

import { useMeterReport } from './useMeterReport'
import { useATGReport } from './useATGReport'
import { useModal } from './useModal'

interface DeliverReportResponse {
  data: DeliveryReport
  blankFormMethods: UseFormReturn<BlankFormType>
  isLoading: boolean
  selectedTankCode: string
  isSubmitting: boolean
  readOnly: boolean
  hideAction: boolean
  modal: string
  submitWork: () => void
  onTankChange: (value: string) => void
  handleAtgSaveChecklist: () => Promise<void>
  handleMeterSaveChecklist: () => Promise<void>
  verifyingWork: (form: VerifyingFormType) => void
  handleSaveBlankForm: () => void
  closeModal: () => void
  confirmAtgSaveStartAndSaveChecklist: () => Promise<void>
  confirmAtgSaveLesslineAndSaveChecklist: () => Promise<void>
  confirmAtgSaveStopAndSaveChecklist: () => Promise<void>

  summaryMethods: UseFormReturn<ATGSummaryFormType>
  isAtgLoading: boolean
  atgLevel?: Level
  atgReports: CalculationDetailType[]
  disableAtgStopLesslineForm: boolean
  disableAtgStopForm: boolean
  isAtgSubmitting: boolean
  atgStartDatetime: string | null
  atgStopLesslineDatetime: string | null
  atgStopDatetime: string | null
  atgCapacity: number
  atgConfig?: ConfigDeliveryReport
  loadingAtgConfig: boolean
  atgPurity: string
  atgManualDipMethods: UseFormReturn<ManualDipForm>
  atgMethods: UseFormReturn<CalculationFormType>
  atgVesselLoadMethods: UseFormReturn<VesselLoadForm>
  atgSummaryReport: SummaryReport
  checkSaveStart: boolean
  checkSaveStop: boolean
  checkSaveLessline: boolean
  isStartDateChange: boolean
  isStopDateChange: boolean
  isLesslineDateChange: boolean
  canSaveStart: boolean
  canSaveStop: boolean
  canSaveLessline: boolean
  handleAtgCalculationStart: (datetime: string | null) => void
  handleAtgCalculationLessLine: (datetime: string | null) => void
  handleAtgCalculationStop: (datetime: string | null) => void
  handleAtgSaveStartAndSaveChecklist: (
    form: CalculationFormType
  ) => Promise<void>
  handleAtgSaveLesslineAndSaveChecklist: (
    form: CalculationFormType
  ) => Promise<void>
  handleAtgSaveStopAndSaveChecklist: (
    form: CalculationFormType
  ) => Promise<void>
  handleAtgStartDatetimeChange: (datetime: string, type: string) => void
  handleAtgStopLesslineDatetimeChange: (datetime: string, type: string) => void
  handleAtgStopDatetimeChange: (datetime: string, type: string) => void
  handleAtgManualDipStartCal: () => Promise<void>
  handleAtgManualDipStopLesslineCal: () => Promise<void>
  handleAtgManualDipStopCal: () => Promise<void>
  setAtgPurity: (value: string) => void
  atgRetrieveLastSave: () => void
  handleCheckSaveStartChange: () => void
  handleCheckSaveLesslineChange: () => void
  handleCheckSaveStopChange: () => void
  handleManualFormChange: (type: CalculateType, mode: CalculateMode) => void

  isMeterLoading: boolean
  isMeterSubmitting: boolean
  meterCanCalVesselLoad: boolean
  meterReport: MeterReport
  meterMethods: UseFormReturn<MeterFormType>
  meterVesselLoadMethods: UseFormReturn<VesselLoadFormType>
  meterShipMethods: UseFormReturn<ShipFormType>
  meterStartDatetime: string
  meterStopLesslineDatetime: string
  meterStopDatetime: string
  meterSummary: SummaryFormType
  setMeterStartDatetime: (datetime: string) => void
  setMeterStopLesslineDatetime: (datetime: string) => void
  setMeterStopDatetime: (datetime: string) => void
  handleMeterSaveStartAndSaveChecklist: (form: MeterValueType) => Promise<void>
  handleMeterSaveStopLesslineAndSaveChecklist: (form: MeterValueType) => void
  handleMeterSaveStopAndSaveChecklist: (form: MeterValueType) => void
  handleMeterRetrieveLastSave: () => void

  handleSetSubmitting: (status:boolean) => void
  canSubmit: boolean
  handleSaveAll: (meterMode:boolean) => Promise<void>
}

interface DeliveryReportProviderType extends FunctionComponentType {
  id: string
}

const DeliveryReportContext = createContext<any>({})

const vesselLoadKeyForm = {
  tonInAir: 'vessel_load_ton_in_air',
  tonInVac: 'vessel_load_ton_in_vac',
  volume15: 'vessel_load_liters_15_c',
  volume30: 'vessel_load_liters_30_c',
}

export function DeliveryReportProvider({
  children,
  id,
}: DeliveryReportProviderType) {
  const client = useAppClient()
  const queryClient = useQueryClient()
  const queryKey = ['delivery-report', id]
  const [selectedTankCode, setSelectedTankCode] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const { success, error } = useAlert()
  const { data: profile } = useProfile()
  const { modal, payload, openModal, closeModal } = useModal()
  const { data, isLoading } = useQuery(queryKey, () =>
    client?.delivery.getDeliveryReport({ id }).then(res => {
      if (!selectedTankCode)
        setSelectedTankCode(() => (res.tanks.length ? res.tanks[0].code : ''))
      return res
    })
  )
  const [canSubmit, setCanSubmit] = useState(true)
  const isWaitSubmitWork = data?.verifyStatus === 'Wait Submit Work'
  const isApprove = data?.verifyStatus === 'Approve'
  const roleBoardman = isBoardman(profile?.roleGroup.reportRole || '')
  const roleAdmin = isAdmin(profile?.roleGroup.reportRole || '')
  const hideAction =
    (roleBoardman && !isWaitSubmitWork) || (roleAdmin && isApprove)
  const readOnly = roleBoardman || isApprove
  
  const blankFormMethods = useForm<BlankFormType>({
    defaultValues: {
      startDate: '',
      endDate: '',
      final: {
        tonInAir: '0',
        tonInVac: '0',
        volume15: '0',
        volume30: '0',
      },
    },
  })
  
  const {
    isLoading: isAtgLoading,
    level: atgLevel,
    reports: atgReports,
    disableStopLesslineForm: disableAtgStopLesslineForm,
    disableStopForm: disableAtgStopForm,
    isSubmitting: isAtgSubmitting,
    startDatetime: atgStartDatetime,
    stopLesslineDatetime: atgStopLesslineDatetime,
    stopDatetime: atgStopDatetime,
    capacity: atgCapacity,
    config: atgConfig,
    loadingConfig: loadingAtgConfig,
    purity: atgPurity,
    manualDipMethods: atgManualDipMethods,
    methods: atgMethods,
    vesselLoadMethods: atgVesselLoadMethods,
    summaryReport: atgSummaryReport,
    summaryMethods,
    checkSaveStart,
    checkSaveStop,
    checkSaveLessline,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    calculationStart: atgCalculationStart,
    calculationLessLine: atgCalculationLessLine,
    calculationStop: atgCalculationStop,
    handleSaveStart: handleAtgSaveStart,
    handleSaveLessLine: handleAtgSaveLessLine,
    handleSaveStop: handleAtgSaveStop,
    handleStartDatetimeChange: handleAtgStartDatetimeChange,
    handleStopLesslineDatetimeChange: handleAtgStopLesslineDatetimeChange,
    handleStopDatetimeChange: handleAtgStopDatetimeChange,
    manualDipStartCal: atgManualDipStartCal,
    manualDipStopLesslineCal: atgManualDipStopLesslineCal,
    manualDipStopCal: atgManualDipStopCal,
    setPurity: setAtgPurity,
    retrieveLastSave: atgRetrieveLastSave,
    handleCheckSaveStartChange,
    handleCheckSaveLesslineChange,
    handleCheckSaveStopChange,
    handleManualFormChange,
    canApprove: canApproveATG,
  } = useATGReport({ deliveryId: id, tankCode: selectedTankCode })
  useEffect(() => {
    handleSetSubmitting(canApproveATG)
  },[canApproveATG])

  const {
    isLoading: isMeterLoading,
    isSubmitting: isMeterSubmitting,
    canCalVesselLoad: meterCanCalVesselLoad,
    data: meterReport,
    methods: meterMethods,
    vesselLoadMethods: meterVesselLoadMethods,
    shipMethods: meterShipMethods,
    startDatetime: meterStartDatetime,
    stopLesslineDatetime: meterStopLesslineDatetime,
    stopDatetime: meterStopDatetime,
    summary: meterSummary,
    setStartDatetime: setMeterStartDatetime,
    setStopLesslineDatetime: setMeterStopLesslineDatetime,
    setStopDatetime: setMeterStopDatetime,
    saveStart: meterSaveStart,
    saveStopLessline: meterSaveStopLessline,
    saveStop: meterSaveStop,
    handleRetrieveLastSave: handleMeterRetrieveLastSave,
  } = useMeterReport({
    deliveryId: id,
    tankCode: selectedTankCode,
    hasLessline: !!data?.hasMeterLessline,
  })

  async function submitWork() {
    try {
      setSubmitting(true)
      await client?.delivery.submitWork({ deliveryId: id })
      queryClient.setQueryData(queryKey, (oldData: any) => {
        return {
          ...oldData,
          canSubmit: false,
          canApprove: true,
          verifyStatus: 'Verifying',
        }
      })
      success({ message: 'Submit work successful' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function confirmAtgSaveStartAndSaveChecklist() {
    await atgSaveStartAndSaveChecklist(payload)
    closeModal()
  }

  async function confirmAtgSaveLesslineAndSaveChecklist() {
    await atgSaveLesslineAndSaveChecklist(payload)
    closeModal()
  }

  async function confirmAtgSaveStopAndSaveChecklist() {
    await atgSaveStopAndSaveChecklist(payload)
    closeModal()
  }

  async function atgSaveStartAndSaveChecklist(form: CalculationFormType) {
    try {
      setSubmitting(true)
      await handleAtgSaveStart(form)
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save start successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function atgSaveLesslineAndSaveChecklist(form: CalculationFormType) {
    try {
      setSubmitting(true)
      await handleAtgSaveLessLine(form)
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save stop lessline successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function atgSaveStopAndSaveChecklist(form: CalculationFormType) {
    try {
      setSubmitting(true)
      await handleAtgSaveStop(form)
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save stop successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function handleAtgSaveStartAndSaveChecklist(form: CalculationFormType) {    
    if (atgStartDatetime && canSaveStart) {
      await atgSaveStartAndSaveChecklist(form)
    } else if (atgStartDatetime && !canSaveStart) {
      openModal({
        modalName: 'start_normal',
        payload: form,
      })
    } else {
      error({ message: 'Please select start datetime.' })
    }
  }

  async function handleAtgSaveLesslineAndSaveChecklist(
    form: CalculationFormType
  ) {
    if (atgStopLesslineDatetime && canSaveLessline) {
      await atgSaveLesslineAndSaveChecklist(form)
    } else if (atgStopLesslineDatetime && !canSaveLessline) {
      openModal({
        modalName: 'stop_less_line',
        payload: form,
      })
    } else {
      error({ message: 'Please select stop lessline datetime.' })
    }
  }

  async function handleAtgSaveStopAndSaveChecklist(form: CalculationFormType) {
    if (atgStopDatetime && canSaveStop) {
      await atgSaveStopAndSaveChecklist(form)
    } else if (atgStopDatetime && !canSaveStop) {
      openModal({
        modalName: 'stop_normal',
        payload: form,
      })
    } else {
      error({ message: 'Please select stop datetime.' })
    }
  }

  function generateChecklistForm() {
    const vessel = atgVesselLoadMethods.getValues()
    const form = summaryMethods.getValues()
    const hasVesselLoad = !!atgConfig?.vesselLoad?.state

    const vesselLoad = Object.keys(vessel).reduce((res, key) => {
      if (vessel[key as VesselLoadKey])
        return {
          ...res,
          [vesselLoadKeyForm[key as VesselLoadKey]]: Number(
            vessel[key as VesselLoadKey]
          ),
        }
      return res
    }, {})

    return {
      ...(hasVesselLoad && vesselLoad),

      total_volume_30_c: Number(form.totalVolume30),
      total_volume_15_c: Number(form.totalVolume15),
      total_metric_tons_in_air: Number(form.totalTonInAir),
      total_metric_tons_in_vac: Number(form.totalTonInVac),

      meter_volume_30_c: Number(form.meterVolume30),
      meter_volume_15_c: Number(form.meterVolume15),
      meter_metric_tons_in_air: Number(form.meterTonInAir),
      meter_metric_tons_in_vac: Number(form.meterTonInVac),

      less_line_volume_30_c: Number(form.lesslineVolume30),
      less_line_volume_15_c: Number(form.lesslineVolume15),
      less_line_metric_tons_in_air: Number(form.lesslineTonInAir),
      less_line_metric_tons_in_vac: Number(form.lesslinetonInVac),

      final_volume_30_c: Number(form.finalVolume30),
      final_volume_15_c: Number(form.finalVolume15),
      final_metric_tons_in_air: Number(form.finalTonInAir),
      final_metric_tons_in_vac: Number(form.finalTonInVac),
    }
  }

  async function checkCanSubmit() {
    const res = await client?.delivery.getDeliveryReport({ id })
    if (res) {
      queryClient.setQueryData(queryKey, (oldData: any) => {
        return {
          ...oldData,
          canSubmit: res.canSubmit,
        }
      })
    }
  }

  async function atgSaveChecklist() {
    if (data?.isBlankForm) {
      await saveBlankForm()
    } else {
      const form = generateChecklistForm()
      await client?.atgReport.updateChecklist({
        deliveryId: id,
        tankCode: selectedTankCode,
        form,
      })
    }
  }

  async function handleAtgSaveChecklist() {
    try {
      setSubmitting(true)
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save checklist successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function meterSaveChecklist() {
    await client?.meterReport.updateMeterChecklist({
      deliveryId: id,
      tankCode: selectedTankCode,
      form: {
        summary: meterSummary,
        vessel: meterVesselLoadMethods.getValues(),
        ship: meterShipMethods.getValues(),
      },
    })

    const summary = summaryMethods.getValues()
    summaryMethods.reset({
      ...summary,
      meterVolume30: String(meterSummary.finalSummary.volume30),
      meterVolume15: String(meterSummary.finalSummary.volume15),
      meterTonInAir: meterSummary.finalSummary.tonInAir.toFixed(3),
      meterTonInVac: meterSummary.finalSummary.tonInVac.toFixed(3),
      finalVolume30: calFinal(
        Number(meterSummary.finalSummary.volume30),
        Number(summary.lesslineVolume30),
        Number(summary.totalVolume30),
        0,
        true
      ),
      finalVolume15: calFinal(
        Number(meterSummary.finalSummary.volume15),
        Number(summary.lesslineVolume15),
        Number(summary.totalVolume15),
        0,
        true
      ),
      finalTonInAir: calFinal(
        Number(meterSummary.finalSummary.tonInAir),
        Number(summary.lesslineTonInAir),
        Number(summary.totalTonInAir),
        3,
        true
      ),
      finalTonInVac: calFinal(
        Number(meterSummary.finalSummary.tonInVac),
        Number(summary.lesslinetonInVac),
        Number(summary.totalTonInVac),
        3,
        true
      ),
    })
  }

  async function handleMeterSaveChecklist() {
    try {
      setSubmitting(true)
      await meterSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save checklist successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  async function handleMeterSaveStartAndSaveChecklist(form: MeterValueType) {
    try {
      setSubmitting(true)
      await meterSaveStart(form)
      await meterSaveChecklist()
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save start successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleMeterSaveStopLesslineAndSaveChecklist(
    form: MeterValueType
  ) {
    try {
      setSubmitting(true)
      await meterSaveStopLessline(form)
      await meterSaveChecklist()
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save stop lessline successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleMeterSaveStopAndSaveChecklist(form: MeterValueType) {
    try {
      setSubmitting(true)
      await meterSaveStop(form)
      await meterSaveChecklist()
      await atgSaveChecklist()
      await checkCanSubmit()
      success({ message: 'Save stop successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  function verifyingWork(form: VerifyingFormType) {
    setSubmitting(true)
    client?.delivery
      .verifyWork({
        deliveryId: id,
        form,
      })
      .then(res => {
        success({ message: `Verify ${data?.workRequestNo} successful.` })
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return res
        })
      })
      .catch(e => error({ message: getErrorMessage(e) }))
      .finally(() => setSubmitting(false))
  }

  async function saveBlankForm() {
    const form = blankFormMethods.getValues()
    const hasMeter = !!data?.meterChecklist.length
    const meter = meterSummary.finalSummary

    await client?.atgReport.updateChecklist({
      deliveryId: id,
      tankCode: selectedTankCode,
      form: {
        ...(hasMeter && {
          meter_volume_30_c: meter.volume30,
          meter_volume_15_c: meter.volume15,
          meter_metric_tons_in_air: meter.tonInAir,
          meter_metric_tons_in_vac: meter.tonInVac,
        }),
        final_volume_30_c: Number(form.final.volume30),
        final_volume_15_c: Number(form.final.volume15),
        final_metric_tons_in_air: Number(form.final.tonInAir),
        final_metric_tons_in_vac: Number(form.final.tonInVac),
        ...(form.startDate && { start_date: form.startDate }),
        ...(form.endDate && { end_date: form.endDate }),
      },
    })
  }

  async function handleSaveBlankForm() {
    try {
      setSubmitting(true)
      await saveBlankForm()
      await checkCanSubmit()
      success({ message: 'Save checklist successful.' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
    }
  }

  function calSummary() {
    const form = summaryMethods.getValues()
    const canCalTotal = !!atgStartDatetime && !!atgStopDatetime
    const disableVesselLoad = !atgConfig?.vesselLoad?.state
    const hasMeter = !!data?.meterChecklist.length
    const hasVesselLoad = !!atgConfig?.vesselLoad?.state
    const canCalFinal = hasMeter || hasVesselLoad || canCalTotal

    const { list: reports } = atgMethods.getValues()

    const metricInAir = reports.find(report =>
      metricTonsInAirKeys.includes(report.keyAttribute)
    )
    const metricInVac = reports.find(report =>
      metricTonsInVacKeys.includes(report.keyAttribute)
    )
    const volume15 = reports.find(report =>
      volume15Keys.includes(report.keyAttribute)
    )
    const volume30 = reports.find(report =>
      volume30Keys.includes(report.keyAttribute)
    )

    const {
      volume30: vesselVolume30,
      volume15: vesselVolume15,
      tonInAir: vesselTonInAir,
      tonInVac: vesselTonInVac,
    } = atgVesselLoadMethods.getValues()

    const meterLineAdjustment = meterSummary.finalSummary

    const totalVolume30 = calTotal(
      Number(volume30?.startValue ?? null),
      Number(volume30?.stopValue ?? null),
      0,
      canCalTotal
    )
    const totalVolume15 = calTotal(
      Number(volume15?.startValue ?? null),
      Number(volume15?.stopValue ?? null),
      0,
      canCalTotal
    )
    const totalTonInAir = calTotal(
      Number(metricInAir?.startValue ?? null),
      Number(metricInAir?.stopValue ?? null),
      Number(metricInAir?.decimalPoint ?? null),
      canCalTotal
    )
    const totalTonInVac = calTotal(
      Number(metricInVac?.startValue ?? null),
      Number(metricInVac?.stopValue ?? null),
      Number(metricInVac?.decimalPoint ?? null),
      canCalTotal
    )

    const meterVolume30 = String(meterLineAdjustment.volume30)
    const meterVolume15 = String(meterLineAdjustment.volume15)
    const meterTonInAir = String(meterLineAdjustment.tonInAir)
    const meterTonInVac = String(meterLineAdjustment.tonInVac)

    const difference = {
      tonInAir: calDifference(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopLesslineValue ?? null),
        Number(metricInAir?.decimalPoint),
        disableVesselLoad
      ),
      tonInVac: calDifference(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopLesslineValue ?? null),
        Number(metricInVac?.decimalPoint),
        disableVesselLoad
      ),
      volume15: calDifference(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopLesslineValue ?? null),
        Number(volume15?.decimalPoint),
        disableVesselLoad
      ),
      volume30: calDifference(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopLesslineValue ?? null),
        Number(volume30?.decimalPoint),
        disableVesselLoad
      ),
    }

    const lesslineVolume30 = calLessline(difference.volume30, vesselVolume30, 0)
    const lesslineVolume15 = calLessline(difference.volume15, vesselVolume15, 0)
    const lesslineTonInAir = calLessline(
      difference.tonInAir,
      vesselTonInAir,
      Number(metricInAir?.decimalPoint ?? null)
    )
    const lesslinetonInVac = calLessline(
      difference.tonInVac,
      vesselTonInVac,
      Number(metricInVac?.decimalPoint ?? null)
    )

    summaryMethods.reset({
      ...form,
      totalVolume30,
      totalVolume15,
      totalTonInAir,
      totalTonInVac,
      meterVolume30,
      meterVolume15,
      meterTonInAir,
      meterTonInVac,
      lesslineVolume30,
      lesslineVolume15,
      lesslineTonInAir,
      lesslinetonInVac,
      finalVolume30: calFinal(
        Number(meterVolume30),
        Number(lesslineVolume30),
        Number(totalVolume30),
        0,
        canCalFinal
      ),
      finalVolume15: calFinal(
        Number(meterVolume15),
        Number(lesslineVolume15),
        Number(totalVolume15),
        0,
        canCalFinal
      ),
      finalTonInAir: calFinal(
        Number(meterTonInAir),
        Number(lesslineTonInAir),
        Number(totalTonInAir),
        Number(metricInAir?.decimalPoint ?? null),
        canCalFinal
      ),
      finalTonInVac: calFinal(
        Number(meterTonInVac),
        Number(lesslinetonInVac),
        Number(totalTonInVac),
        Number(metricInVac?.decimalPoint ?? null),
        canCalFinal
      ),
    })

  }


  async function handleAtgCalculationStart(datetime: string | null) {
    try {
      setSubmitting(true)
      await atgCalculationStart(datetime)
      calSummary()
      await handleAtgSaveStartAndSaveChecklist(atgMethods.getValues())
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleAtgCalculationLessLine(datetime: string | null) {
    try {
      setSubmitting(true)
      await atgCalculationLessLine(datetime)
      calSummary()
      await handleAtgSaveLesslineAndSaveChecklist(atgMethods.getValues())
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleAtgCalculationStop(datetime: string | null) {
    try {
      setSubmitting(true)
      await atgCalculationStop(datetime)
      calSummary()
      await handleAtgSaveStopAndSaveChecklist(atgMethods.getValues())
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleAtgManualDipStartCal() {
    try {
      setSubmitting(true)
      await atgManualDipStartCal()
      calSummary()
      await handleAtgSaveStartAndSaveChecklist(atgMethods.getValues())
      success({ message: 'Start cal success' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleAtgManualDipStopLesslineCal() {
    try {
      setSubmitting(true)
      await atgManualDipStopLesslineCal()
      calSummary()
      await handleAtgSaveLesslineAndSaveChecklist(atgMethods.getValues())
      success({ message: 'Stop lessline cal success' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  async function handleAtgManualDipStopCal() {
    try {
      setSubmitting(true)
      await atgManualDipStopCal()
      calSummary()
      await handleAtgSaveStopAndSaveChecklist(atgMethods.getValues())
      success({ message: 'Stop cal success' })
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      handleSetSubmitting(true)
    }
  }

  function handleSetSubmitting(status:boolean){
    setCanSubmit(status)
  }

  async function handleSaveAll(meterMode:boolean){
    const hasMeter = !!data?.meterChecklist.length
    setSubmitting(true)
    if(hasMeter && meterMode){
      const {start,stop,stopLessline} = meterMethods.getValues()
      try{
        /// Meter //////
        await meterSaveChecklist()
        await meterSaveStart(start)
        if(data?.hasMeterLessline){
          meterSaveStopLessline(stopLessline)
        }  
        meterSaveStop(stop)
        await checkCanSubmit()
        success({ message: 'Save success' })
      } catch (e){
        error({ message: getErrorMessage(e) })
      } finally {
        setSubmitting(false)
        handleSetSubmitting(true)
      }
    }else{
      try{
        await atgSaveChecklist()
        await handleAtgSaveStart(atgMethods.getValues())
        if(!disableAtgStopLesslineForm){
          await handleAtgSaveLessLine(atgMethods.getValues())
        }
        await handleAtgSaveStop(atgMethods.getValues())
        await checkCanSubmit()
        success({ message: 'Save success' })
      } catch (e){
        error({ message: getErrorMessage(e) })
      } finally {
        setSubmitting(false)
        handleSetSubmitting(true)
      }
    }
  }

  useEffect(() => {
    if (
      atgSummaryReport &&
      atgStartDatetime &&
      atgStopDatetime &&
      data &&
      data.isBlankForm
    )
      blankFormMethods.reset({
        startDate: atgStartDatetime,
        endDate: atgStopDatetime,
        final: {
          volume15: atgSummaryReport?.finalVolume15,
          volume30: atgSummaryReport?.finalVolume30,
          tonInAir: atgSummaryReport?.finalTonInAir,
          tonInVac: atgSummaryReport?.finalTonInVac,
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atgSummaryReport, atgStartDatetime, atgStopDatetime, data?.isBlankForm])

  const value = {
    data,
    isLoading,
    selectedTankCode,
    isSubmitting,
    readOnly,
    hideAction,
    blankFormMethods,
    modal,
    submitWork,
    onTankChange: setSelectedTankCode,
    handleAtgSaveChecklist,
    verifyingWork,
    handleSaveBlankForm,
    closeModal,
    confirmAtgSaveStartAndSaveChecklist,
    confirmAtgSaveLesslineAndSaveChecklist,
    confirmAtgSaveStopAndSaveChecklist,

    isAtgLoading,
    atgLevel,
    atgReports,
    disableAtgStopLesslineForm,
    disableAtgStopForm,
    isAtgSubmitting,
    atgStartDatetime,
    atgStopLesslineDatetime,
    atgStopDatetime,
    atgCapacity,
    atgConfig,
    atgSummaryReport,
    loadingAtgConfig,
    atgPurity,
    atgManualDipMethods,
    atgMethods,
    atgVesselLoadMethods,
    summaryMethods,
    checkSaveStart,
    checkSaveStop,
    checkSaveLessline,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    handleAtgCalculationStart,
    handleAtgCalculationLessLine,
    handleAtgCalculationStop,
    handleAtgSaveStartAndSaveChecklist,
    handleAtgSaveLesslineAndSaveChecklist,
    handleAtgSaveStopAndSaveChecklist,
    handleAtgStartDatetimeChange,
    handleAtgStopLesslineDatetimeChange,
    handleAtgStopDatetimeChange,
    handleAtgManualDipStartCal,
    handleAtgManualDipStopLesslineCal,
    handleAtgManualDipStopCal,
    setAtgPurity,
    atgRetrieveLastSave,
    handleCheckSaveStartChange,
    handleCheckSaveLesslineChange,
    handleCheckSaveStopChange,
    handleManualFormChange,

    isMeterLoading,
    isMeterSubmitting,
    meterCanCalVesselLoad,
    meterReport,
    meterMethods,
    meterVesselLoadMethods,
    meterShipMethods,
    meterStartDatetime,
    meterStopLesslineDatetime,
    meterStopDatetime,
    meterSummary,
    setMeterStartDatetime,
    setMeterStopLesslineDatetime,
    setMeterStopDatetime,
    handleMeterSaveStartAndSaveChecklist,
    handleMeterSaveStopLesslineAndSaveChecklist,
    handleMeterSaveStopAndSaveChecklist,
    handleMeterRetrieveLastSave,
    handleMeterSaveChecklist,

    handleSetSubmitting,
    canSubmit,
    handleSaveAll,
  }

  return (
    <DeliveryReportContext.Provider value={value}>
      {children}
    </DeliveryReportContext.Provider>
  )
}

export function useDeliveryReport(): DeliverReportResponse {
  return useContext(DeliveryReportContext)
}
