import { useState } from 'react'
import hoist from 'hoist-non-react-statics'
import { DateTime } from 'luxon'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { DailyReportPageType } from '@features/report/interfaces/DailyReportType'
import { useDailyReportList } from '@features/report/hooks/useDailyReportList'
import { useVerifyDate } from '@features/report/hooks/useVerifyDate'
import { useProfile } from '@features/profile/hooks/useProfile'
import { API_URL } from '@lib/config'
import { useAppClient } from '@lib/useAppClient'
import { usePermissionGuard } from '@lib/permissions'
import { INVENTORY_DAILY_REPORT } from '@lib/constants'

export function withDailyReportPage(Component: React.FC<DailyReportPageType>) {
  function WithDailyReportPage() {
    const navigate = useNavigate()

    const { data: verifyDates } = useVerifyDate()
    const client = useAppClient()
    const [searchParams] = useSearchParams()
    const date = searchParams.get('d') || DateTime.now().toFormat('yyyy-LL-dd')
    const [selectedDate, setSelectedDate] = useState<string>(date)
    const { data: profile } = useProfile()
    const editable = usePermissionGuard(INVENTORY_DAILY_REPORT, 'write')

    const { data: reports, isLoading, isError } = useDailyReportList({
      date,
    })

    function handleSelectedDateChange(value: DateTime | null) {
      if (value) setSelectedDate(value.toFormat('yyyy-LL-dd'))
    }

    function handleSearch() {
      navigate({
        pathname: '/inventory-daily-report',
        search: `?d=${selectedDate}`,
      })
    }

    function handleSelectVerifyDate(value: string) {
      setSelectedDate(value)
      navigate({
        pathname: '/inventory-daily-report',
        search: `?d=${value}`,
      })
    }

    async function handleExportFile() {
      const exportReport = `${API_URL}/api/reports/daily_reports/export?date=${date}&token=${client?.auth.accessToken}`
      window.open(exportReport)
    }

    const pageProps = {
      data: reports,
      loading: isLoading,
      customer: profile?.company?.code,
      selectedDate,
      date,
      verifyDates,
      companyId: profile?.company?.id,
      editable,
      isError,
      handleSelectedDateChange,
      handleSearch,
      handleSelectVerifyDate,
      handleExportFile,
    }

    return <Component {...pageProps} />
  }

  hoist(WithDailyReportPage, Component)

  WithDailyReportPage.displayName = `WithDailyReportPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithDailyReportPage
}
