import { memo } from 'react'

import { SummaryPropsType } from '@features/draft-calculation/interfaces/SummaryType'
import { Loading } from '@components/Loading'
import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'
import { calDifference, calLessline, calTotal } from '@lib/utils'
import {
  metricTonsInAirKeys,
  metricTonsInVacKeys,
  volume15Keys,
  volume30Keys,
} from '@lib/constants'

import { SummaryDetail } from './SummaryDetail'

export const Summary = memo(
  ({
    reports,
    vesselLoads,
    disableVesselLoad,
    canCalTotal,
  }: SummaryPropsType) => {
    const [vesselVolume30, vesselVolume15, vesselTonInAir, vesselTonInVac] =
      vesselLoads

    const metricInAir = reports.find(report =>
      metricTonsInAirKeys.includes(report?.keyAttribute)
    )
    const metricInVac = reports.find(report =>
      metricTonsInVacKeys.includes(report?.keyAttribute)
    )
    const volume15 = reports.find(report =>
      volume15Keys.includes(report?.keyAttribute)
    )
    const volume30 = reports.find(report =>
      volume30Keys.includes(report?.keyAttribute)
    )

    const total = {
      tonInAir: calTotal(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopValue ?? null),
        Number(metricInAir?.decimalPoint ?? null),
        canCalTotal
      ),
      tonInVac: calTotal(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopValue ?? null),
        Number(metricInVac?.decimalPoint ?? null),
        canCalTotal
      ),
      volume15: calTotal(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopValue ?? null),
        0,
        canCalTotal
      ),
      volume30: calTotal(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopValue ?? null),
        0,
        canCalTotal
      ),
    }

    const difference = {
      tonInAir: calDifference(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopLesslineValue ?? null),
        Number(metricInAir?.decimalPoint),
        disableVesselLoad
      ),
      tonInVac: calDifference(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopLesslineValue ?? null),
        Number(metricInVac?.decimalPoint),
        disableVesselLoad
      ),
      volume15: calDifference(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopLesslineValue ?? null),
        Number(volume15?.decimalPoint),
        disableVesselLoad
      ),
      volume30: calDifference(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopLesslineValue ?? null),
        Number(volume30?.decimalPoint),
        disableVesselLoad
      ),
    }

    const lessline = {
      tonInAir: calLessline(
        difference.tonInAir,
        vesselTonInAir,
        Number(metricInAir?.decimalPoint ?? null)
      ),
      tonInVac: calLessline(
        difference.tonInVac,
        vesselTonInVac,
        Number(metricInVac?.decimalPoint ?? null)
      ),
      volume15: calLessline(difference.volume15, vesselVolume15, 0),
      volume30: calLessline(difference.volume30, vesselVolume30, 0),
    }

    return (
      <>
        <div className={styles.atgReportCalculationDetailHeader}>Summary</div>
        <SummaryDetail
          title='Total (By ATG)'
          volume15C={total.volume15.toLocaleString()}
          volume30C={total.volume30.toLocaleString()}
          tonInAir={total.tonInAir.toLocaleString()}
          tonInVac={total.tonInVac.toLocaleString()}
        />
        <SummaryDetail
          title='Less Pipe Line Adjustment'
          volume15C={lessline.volume15.toLocaleString()}
          volume30C={lessline.volume30.toLocaleString()}
          tonInAir={lessline.tonInAir.toLocaleString()}
          tonInVac={lessline.tonInVac.toLocaleString()}
        />
      </>
    )
  }
)
