type Option = {
  excludes: string[]
}

export function getQueryString(
  searchParams: URLSearchParams,
  option: Option = { excludes: [] }
) {
  const { excludes = [] } = option

  return Array.from(searchParams)
    .filter((param: any) => !excludes.includes(param[0]))
    .map((param: any) => param.join('='))
    .join('&')
}

export function getErrorMessage(e: any) {
  const errorMessage =
    e?.response?.data?.detail?.message ||
    e?.response?.data?.detail ||
    e?.message
  return typeof errorMessage === 'string'
    ? errorMessage
    : 'Sorry, something went wrong.'
}

export function getQueryParams(form: any) {
  const keys = Object.keys(form)
  return keys
    .reduce((res: string[], key: any) => {
      if (form[key] !== null && form[key] !== undefined && form[key] !== '') {
        return [...res, `${key}=${form[key]}`]
      }

      return res
    }, [])
    .join('&')
}

export function calTotal(
  start: number,
  stop: number,
  decimalPoint: number,
  canCalTotal: boolean
) {
  let ans = 0

  if (canCalTotal) ans = stop - start

  const multiplier = ans < 0 ? -1 : 1

  return decimalPoint === 0
    ? String(Math.ceil(Math.abs(ans)) * multiplier)
    : ans.toFixed(decimalPoint)
}

export function calDifference(
  start: number,
  stop: number,
  decimalPoint: number,
  disableVesselLoad: boolean
) {
  let ans = 0
  if (disableVesselLoad) return ans

  ans = Math.abs(Number((stop - start).toFixed(decimalPoint)))

  return ans
}

export function calLessline(
  difference: number,
  vesselValue: string | null,
  decimalPoint: number
) {
  let ans = 0

  if (vesselValue !== null && Number(vesselValue) < difference)
    ans = difference - Number(vesselValue)

  const multiplier = ans < 0 ? -1 : 1

  return decimalPoint === 0
    ? String(Math.ceil(Math.abs(ans)) * multiplier)
    : ans.toFixed(decimalPoint)
}

export function calFinal(
  meter: number,
  lessline: number,
  totalAtg: number,
  decimalPoint: number,
  canCal: boolean
) {
  let ans = 0

  if (canCal) ans = meter + lessline + totalAtg

  const multiplier = ans < 0 ? -1 : 1

  return decimalPoint === 0
    ? String(Math.ceil(Math.abs(ans)) * multiplier)
    : ans.toFixed(decimalPoint)
}

export function transformDateToISOFormat(value: string) {
  const date = new Date(value)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1)
  const day = String(date.getDate())
  const hour = String(date.getHours())
  const min = String(date.getMinutes())

  return `${year}-${month.length < 2 ? `0${month}` : month}-${
    day.length < 2 ? `0${day}` : day
  }T${hour.length < 2 ? `0${hour}` : hour}:${min.length < 2 ? `0${min}` : min}`
}

export function addComma(value: number | null): string {
  if (value === null) return '-'

  if (String(value).includes('.')) {
    const [number, decimal] = value.toFixed(3).split('.')

    return `${Number(number).toLocaleString()}.${decimal}`
  }

  return value.toLocaleString()
}
