import cx from 'classnames'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import styles from './index.module.scss'

interface PanelType extends FunctionComponentType {
  header: string
  className?: string
}

export function Panel({ children, header, className }: PanelType) {
  return (
    <div className={cx(className, styles.panelContainer)}>
      <div className={styles.panelHeader}>
        <strong>{header}</strong>
      </div>
      <div className={styles.panelContent}>{children}</div>
    </div>
  )
}
