import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

interface UseUserType {
  id: string
}

export function useUser({ id }: UseUserType) {
  const client = useAppClient()
  const { data, isLoading } = useQuery(['users', id], () =>
    client?.user.getUser({ id }).then(response => response)
  )

  return {
    data,
    isLoading,
  }
}
