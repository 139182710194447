import React, { useState } from 'react'
import { FormControl } from '@material-ui/core'
import { useFormContext, useController } from 'react-hook-form'
import cx from 'classnames'

import styles from './index.module.scss'

interface InputFieldType {
  className?: string
  name: string
  label?: string
  type?: string
  disabled?: boolean
  defaultValue?: string
  rightComponent?: React.ReactNode
  handleChange?: (e: any) => void
}

export function InputField({
  className,
  name,
  label,
  type = 'text',
  defaultValue = '',
  disabled = false,
  rightComponent,
  handleChange,
}: InputFieldType) {
  const [focus, setFocus] = useState(false)
  const { control } = useFormContext()
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control, defaultValue })
  return (
    <FormControl className={className}>
      {label && (
        <label className='label'>
          <strong>{label}</strong>
        </label>
      )}

      <div
        className={cx(
          styles.container,
          { [styles.focus]: focus, [styles.disabled]: disabled },
          'input-group'
        )}
      >
        <input
          {...field}
          value={field.value ?? ''}
          type={type}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={e => {
            field.onChange(e)
            handleChange && handleChange(e)
          }}
        />
        {rightComponent}
      </div>

      {invalid && <div className={styles.error}>{error?.message}</div>}
    </FormControl>
  )
}
