import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { CompanyFormProps } from '@features/company/interfaces/CompanyType'
import { CompanyForm } from '@features/company/components/CompanyForm'

export function CompanyEditPage({
  initialValues,
  loading,
  onSubmit,
}: CompanyFormProps) {
  return (
    <Container>
      <Breadcrumbs />
      <CompanyForm
        initialValues={initialValues}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
