import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useCompanyList() {
  const client = useAppClient()
  const { data = [], isLoading } = useQuery('companies', () =>
    client?.company.getCompanyList().then(response => response)
  )

  return {
    data,
    isLoading,
  }
}
