import { INVENTORY_DAILY_REPORT } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { DailyReportPage } from './DailyReportPage'
import { withDailyReportPage } from './withDailyReportPage'

const ConnectedDailyReportPage = withPermissionGuard(
  withDailyReportPage(DailyReportPage),
  INVENTORY_DAILY_REPORT,
  'read'
)

export { ConnectedDailyReportPage as DailyReportPage }
