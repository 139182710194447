import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover, Button } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import {
  COMPANY_SETTING,
  DAILY_REPORT_CONFIG_SETTING,
  PRODUCT_SETTING,
  ROLE_GROUP_SETTING,
  TANK_SETTING,
  USER_SETTING,
} from '@lib/constants'
import { PermissionGuard } from '@lib/permissions'

import styles from './index.module.scss'

export function SetupMenu() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'setup-popover' : undefined

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function navigateTo(path: string) {
    navigate(path)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        Setting
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ className: styles.paper }}
      >
        <div className={styles.subMenu}>
          <PermissionGuard page={COMPANY_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/company')}
            >
              Company
            </div>
          </PermissionGuard>

          <PermissionGuard page={ROLE_GROUP_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/role-group')}
            >
              Role Group
            </div>
          </PermissionGuard>

          <PermissionGuard page={USER_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/user')}
            >
              User
            </div>
          </PermissionGuard>

          {/* <PermissionGuard page={PRODUCT_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/product')}
            >
              Product
            </div>
          </PermissionGuard>

          <PermissionGuard page={TANK_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/tank')}
            >
              Tank
            </div>
          </PermissionGuard> */}

          <PermissionGuard page={DAILY_REPORT_CONFIG_SETTING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/config-daily-report')}
            >
              Daily Report Config
            </div>
          </PermissionGuard>
        </div>
      </Popover>
    </>
  )
}
