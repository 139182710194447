import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import { TrackingWork } from '@features/tracking-work/interfaces/TrackingWorkType'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import {
  DraftList,
  SearchForm,
} from '@features/draft-calculation/interfaces/DraftType'
import { DeliveryReport } from '@features/tracking-work/interfaces/DeliveryReportType'
import { ConfigDeliveryReport } from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import { PrintGaugeType } from '@features/pdf/interfaces/PrintGaugeType'
import { ManualDipCalculateForm } from '@features/tracking-work/interfaces/ManualDipType'
import { ProrateDensityForm } from '@features/tracking-work/interfaces/ProrateDensityType'
import { ProrateMWForm } from '@features/tracking-work/interfaces/ProrateMWType'
import { ATGReport } from '@features/tracking-work/interfaces/ATGReportType'
import { getQueryParams } from '@lib/utils'
import { CalculationDetailType } from '@features/tracking-work/interfaces/CalculationDetailType'
import { GoalSeekType } from '@features/tracking-work/interfaces/GoalSeekType'

import {
  MeterReport,
  MeterValueType,
} from '@features/tracking-work/interfaces/MeterReportType'
import { CalculateMode, CalculateType } from './ATGReportClient'

const MODE = {
  start: 'START',
  less_line: 'Less-Line',
  stop: 'STOP',
}

interface GetCalculateDetailParams {
  deliveryId: number | string
  tankCode: string
}

interface UpdateCalculateDetailParams extends GetCalculateDetailParams {
  form: CalculationDetailType[]
  manualForm: ManualDipCalculateForm
  purity: string
  type: CalculateType
  mode: CalculateMode
  datetime: string
}

interface GetCalculateParams extends GetCalculateDetailParams {
  type: CalculateType
  mode: CalculateMode
  datetime: string | null
}

interface ManualDipCalculateParams extends GetCalculateDetailParams {
  type: CalculateType
  mode: CalculateMode
  form: ManualDipCalculateForm
  purity: string
}

interface CalculateProrateDensityParams extends GetCalculateDetailParams {
  form: ProrateDensityForm
}

interface CalculateProrateMolecularWeightParams
  extends GetCalculateDetailParams {
  form: ProrateMWForm
}

interface GetMeterReport {
  deliveryId: string
  tankCode: string
}

interface UpdateMeterReport extends GetMeterReport {
  form: MeterValueType
  type: CalculateType
  mode: CalculateMode
  datetime: string
}

interface GoalSeekParams {
  deliveryId: string
  tankCode: string
  value: string
}

export class DraftClient {
  constructor(private client: AxiosInstance) {}

  async getDraft({ id }: { id: number }): Promise<TrackingWork> {
    const response = await this.client.get(
      `/api/reports/draft/deliveries/${id}`
    )

    return plainToClass(TrackingWork, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async getDraftList(form: SearchForm): Promise<DraftList> {
    const queries = getQueryParams(form)
    const response = await this.client.get(
      `/api/reports/draft/deliveries?${queries}`
    )

    return {
      ...response.data,
      items: response.data.items.map((trackingWork: any) =>
        plainToClass(TrackingWork, trackingWork, {
          excludeExtraneousValues: true,
        })
      ),
    }
  }

  async addDraft(form: WorkOrderRequestNumberFormType) {
    await this.client.post('/api/reports/draft/deliveries', form)
  }

  async updateDraft({ id, ...form }: WorkOrderRequestNumberFormType) {
    const response = await this.client.patch(
      `/api/reports/draft/deliveries/${id}`,
      form
    )

    return response.data
  }

  async getDeliveryReport({ id }: { id: string }): Promise<DeliveryReport> {
    const response = await this.client.get(
      `/api/reports/draft/deliveries/${id}`
    )

    const data = {
      ...response.data,
      tanks: response.data.tanks.map((row: any) => ({
        id: row.id,
        code: row.tankNo,
        shipmentNo: row.shipmentNumber,
      })),
      atgChecklist: response.data.tanks.flatMap((tank: any) => {
        return tank.atg_checklists.map((row: any) => {
          const type = row.type as CalculateType
          const mode = row.mode === 'less_line' ? MODE.less_line : MODE[type]
          return {
            id: row.id,
            name: `${tank.tankNo} (${mode})`,
            active: row.status,
            necessity: row.necessity,
            type,
            mode: row.mode,
            tankCode: tank.tankNo,
          }
        })
      }),
      meterChecklist: response.data.tanks.flatMap((tank: any) => {
        return tank.meter_checklists.map((row: any) => {
          const type = row.type as CalculateType
          const mode = row.mode === 'less_line' ? MODE.less_line : MODE[type]
          return {
            id: row.id,
            name: `${mode}`,
            active: row.status,
            necessity: row.necessity,
            type,
            mode: row.mode,
            tankCode: tank.tankNo,
          }
        })
      }),
      hasMeterLessline: response.data.tanks.some((tank: any) => {
        return tank.meter_checklists.some(
          (row: any) => row.type === 'stop' && row.mode === 'less_line'
        )
      }),
    }

    return plainToClass(DeliveryReport, data, {
      excludeExtraneousValues: true,
    })
  }

  async getConfigDeliveryReport({
    deliveryId,
    tankId,
  }: {
    deliveryId: string
    tankId: string
  }): Promise<ConfigDeliveryReport> {
    if (!deliveryId || !tankId) return plainToClass(ConfigDeliveryReport, {})
    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankId}/config`
    )

    return plainToClass(ConfigDeliveryReport, response.data)
  }

  async getPrintGauge({
    deliveryId,
    tankCode,
    type,
    mode,
  }: {
    deliveryId: number | string
    tankCode: string
    type: CalculateType
    mode: CalculateMode
  }) {
    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/gauge?type=${type}&mode=${mode}`
    )

    return plainToClass(PrintGaugeType, response.data)
  }

  async getCalculateDetail({
    deliveryId,
    tankCode,
  }: GetCalculateDetailParams): Promise<ATGReport> {
    if (!deliveryId || !tankCode) return plainToClass(ATGReport, {})

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/reports`
    )

    return plainToClass(ATGReport, response.data)
  }

  async getCalculate({
    deliveryId,
    tankCode,
    type,
    mode,
    datetime,
  }: GetCalculateParams) {
    const queryParams = getQueryParams({
      type,
      mode,
      datetime,
    })

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/calculate?${queryParams}`
    )

    return {
      datetime: response.data.datetime,
      reports: response.data.reports.map((row: any) =>
        plainToClass(CalculationDetailType, row)
      ),
    }
  }

  async manualCalculate({
    deliveryId,
    tankCode,
    type,
    mode,
    form,
    purity,
  }: ManualDipCalculateParams) {
    const queryParams = getQueryParams({
      type,
      mode,
      ...(form.level && { level: form.level }),
      ...(form.liquidTemperature && {
        liquid_temperature: form.liquidTemperature,
      }),
      ...(form.vaporTemperature && {
        vapor_temperature: form.vaporTemperature,
      }),
      ...(form.vaporPressure && { vapor_pressure: form.vaporPressure }),
      ...(form.densityInVac && { density_in_vac: form.densityInVac }),
      ...(form.molecularWeight && { molecular_weight: form.molecularWeight }),
      ...(purity && { product_purity: purity }),
    })

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/manual_calculate?${queryParams}`
    )

    return plainToClass(ATGReport, response.data)
  }

  async calculateProrateDensity({
    form,
    deliveryId,
    tankCode,
  }: CalculateProrateDensityParams): Promise<number> {
    const queryParams = getQueryParams({
      density_shore_before: form.shoreBefore,
      density_ship_before: form.shipBefore,
    })

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/density_in_vac_calculate?${queryParams}`
    )

    return response.data.value
  }

  async calculateProrateMolecularWeight({
    form,
    deliveryId,
    tankCode,
  }: CalculateProrateMolecularWeightParams): Promise<number> {
    const queryParams = getQueryParams({
      molecular_weight_shore_before: form.shoreBefore,
      molecular_weight_ship_before: form.shipBefore,
    })

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/molecular_weight_calculate?${queryParams}`
    )

    return response.data.value
  }

  async updateCalculateDetail({
    form,
    manualForm,
    purity,
    deliveryId,
    tankCode,
    type,
    mode,
    datetime,
  }: UpdateCalculateDetailParams): Promise<void> {
    await this.client.patch(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/reports?type=${type}&mode=${mode}`,
      {
        datetime,
        reports: form,
        manual_parameter: {
          ...(purity && { product_purity: Number(purity) }),
          ...(manualForm.level && { level: Number(manualForm.level) }),
          ...(manualForm.liquidTemperature && {
            liquid_temperature: Number(manualForm.liquidTemperature),
          }),
          ...(manualForm.vaporTemperature && {
            vapor_temperature: Number(manualForm.vaporTemperature),
          }),
          ...(manualForm.vaporPressure && {
            vapor_pressure: Number(manualForm.vaporPressure),
          }),
          ...(manualForm.densityInVac && {
            density_in_vac: Number(manualForm.densityInVac),
          }),
          ...(manualForm.molecularWeight && {
            molecular_weight: Number(manualForm.molecularWeight),
          }),
        },
      }
    )
  }

  async getMeterReport({
    deliveryId,
    tankCode,
  }: GetMeterReport): Promise<MeterReport> {
    if (!deliveryId || !tankCode) return plainToClass(MeterReport, {})

    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/meter_reports`
    )

    return plainToClass(MeterReport, response.data)
  }

  async updateMeterReport({
    deliveryId,
    tankCode,
    form,
    type,
    mode,
    datetime,
  }: UpdateMeterReport): Promise<void> {
    await this.client.patch(
      `/api/reports/draft/deliveries/${deliveryId}/tanks/${tankCode}/meter_reports?type=${type}&mode=${mode}`,
      {
        time: datetime,
        gross_volume: form.grossVolume,
        net_volume: form.netVolume,
        mass_in_vac: form.massInVac,
        mass_in_air: form.massInAir,
      }
    )
  }

  async goalSeek({
    deliveryId,
    tankCode,
    value,
  }: GoalSeekParams): Promise<GoalSeekType> {
    const response = await this.client.get(
      `/api/reports/draft/deliveries/${deliveryId}/goal_seek?delivery_report_id=${deliveryId}&tank_code=${tankCode}&value=${value}`
    )

    return plainToClass(GoalSeekType, response.data)
  }
}
