import { Button } from '@material-ui/core'
import { useWatch } from 'react-hook-form'

import { Box } from '@features/tracking-work/components/Box'
import { MeterReportType } from '@features/tracking-work/interfaces/MeterReportType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'
import styles from '@features/tracking-work/pages/MeterMode/index.module.scss'

import { ActionForm } from './ActionForm'
import { MeterForm } from './MeterForm'
import { MeterDetail } from './MeterDetail'

export function MeterReport({
  className,
  summary,
  meterRunId,
  hideAction,
  handleSaveStart,
  handleSaveLessLine,
  handleSaveStop,
}: MeterReportType) {
  const {
    isSubmitting,
    isMeterSubmitting,
    meterStartDatetime,
    meterStopDatetime,
    meterStopLesslineDatetime,
    meterReport,
    setMeterStartDatetime,
    setMeterStopLesslineDatetime,
    setMeterStopDatetime,
    handleMeterRetrieveLastSave,
    handleSetSubmitting,
  } = useDeliveryReport()

  function showData(value: number, decimalPoint: number) {
    if (decimalPoint === 0) return value.toLocaleString()

    const temp = value.toFixed(decimalPoint)
    const [first, last] = temp.split('.')

    return `${Number(first).toLocaleString()}.${last}`
  }

  return (
    <Box className={className} title='Meter Mode Report'>
      <div className={styles.meterReportContent}>
        <div className={styles.meterReportInformation}>
          <div>Meter Run ID : {meterRunId}</div>
          {!hideAction && (
            <Button
              variant='contained'
              color='primary'
              disabled={isSubmitting || isMeterSubmitting}
              onClick={handleMeterRetrieveLastSave}
            >
              Retrieve Last Save
            </Button>
          )}
        </div>

        <ActionForm
          className={styles.meterReportActionForm}
          title='START (Meter)'
          value={meterStartDatetime}
          setDatetime={setMeterStartDatetime}
          disabled={isSubmitting || isMeterSubmitting || hideAction}
        />
        <ActionForm
          className={styles.meterReportActionForm}
          title='STOP (Less-Line)'
          value={meterStopLesslineDatetime}
          setDatetime={setMeterStopLesslineDatetime}
          disabled={
            isSubmitting ||
            isMeterSubmitting ||
            !meterReport?.stopLesslineState ||
            hideAction
          }
        />
        <ActionForm
          className={styles.meterReportActionForm}
          title='STOP(Meter)'
          value={meterStopDatetime}
          setDatetime={setMeterStopDatetime}
          disabled={
            isSubmitting ||
            isMeterSubmitting ||
            !meterReport?.stopState ||
            hideAction
          }
        />

        <div className={styles.meterReportCalculationHeader}>
          Peocess Variable
        </div>

        <MeterForm
          label='Cummulative Net Volume@ 30 Deg °C, Liters'
          meterKey='grossVolume'
          disabled={isSubmitting || isMeterSubmitting || hideAction}
          stopLesslineState={meterReport?.stopLesslineState || hideAction}
          stopState={meterReport?.stopState || hideAction}
          handleOnChange={handleSetSubmitting}
        />
        <MeterForm
          label='Cummulative Net Volume@ 15 Deg °C, Liters'
          meterKey='netVolume'
          disabled={isSubmitting || isMeterSubmitting || hideAction}
          stopLesslineState={meterReport?.stopLesslineState || hideAction}
          stopState={meterReport?.stopState || hideAction}
          handleOnChange={handleSetSubmitting}
        />
        <MeterForm
          label='Cummulative Mass in vac (tons)'
          meterKey='massInVac'
          disabled={isSubmitting || isMeterSubmitting || hideAction}
          stopLesslineState={meterReport?.stopLesslineState || hideAction}
          stopState={meterReport?.stopState || hideAction}
          handleOnChange={handleSetSubmitting}
        />
        <MeterForm
          label='Cummulative Mass in air (tons)'
          meterKey='massInAir'
          disabled={isSubmitting || isMeterSubmitting || hideAction}
          stopLesslineState={meterReport?.stopLesslineState || hideAction}
          stopState={meterReport?.stopState || hideAction}
          handleOnChange={handleSetSubmitting}
        />

        {!hideAction && <div className={styles.meterReportCalculationLabel} />}
        {!hideAction && (
          <Button
            variant='contained'
            className={styles.disabledBtn}
            onClick={handleSaveStart}
            disabled={isSubmitting || isMeterSubmitting}
          >
            Save Start (Meter)
          </Button>
        )}
        {!hideAction && (
          <Button
            variant='contained'
            className={styles.disabledBtn}
            onClick={handleSaveLessLine}
            disabled={
              isSubmitting ||
              isMeterSubmitting ||
              !meterReport?.stopLesslineState
            }
          >
            Save Stop (Less-Line)
          </Button>
        )}
        {!hideAction && (
          <Button
            variant='contained'
            className={styles.disabledBtn}
            onClick={handleSaveStop}
            disabled={
              isSubmitting || isMeterSubmitting || !meterReport?.stopState
            }
          >
            Save Stop (Meter)
          </Button>
        )}

        <MeterDetail
          header='Quantity Received / Delivery (By Meter Measurment)'
          volume30={showData(summary.quantityReceivedDelivery.volume30, 0)}
          volume15={showData(summary.quantityReceivedDelivery.volume15, 0)}
          tonInAir={showData(summary.quantityReceivedDelivery.tonInAir, 3)}
          tonInVac={showData(summary.quantityReceivedDelivery.tonInVac, 3)}
        />

        <MeterDetail
          header='Quantity Less-Line'
          volume30={showData(summary.quantityLessline.volume30, 0)}
          volume15={showData(summary.quantityLessline.volume15, 0)}
          tonInAir={showData(summary.quantityLessline.tonInAir, 3)}
          tonInVac={showData(summary.quantityLessline.tonInVac, 3)}
        />

        <MeterDetail
          header='Quantity Final Summary'
          volume30={showData(summary.finalSummary.volume30, 0)}
          volume15={showData(summary.finalSummary.volume15, 0)}
          tonInAir={showData(summary.finalSummary.tonInAir, 3)}
          tonInVac={showData(summary.finalSummary.tonInVac, 3)}
        />
      </div>
    </Box>
  )
}
