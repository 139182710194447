import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

interface ConfirmModalType extends FunctionComponentType {
  open: boolean
  isSubmitting?: boolean
  handleClose: () => void
  handleConfirm: () => void
}

export function ConfirmModal({
  children,
  open,
  isSubmitting = false,
  handleClose,
  handleConfirm,
}: ConfirmModalType) {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      fullWidth
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancle
        </Button>
        <Button
          onClick={handleConfirm}
          color='primary'
          variant='contained'
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={20} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
