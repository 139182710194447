import cx from 'classnames'

import { CheckList } from '@features/tracking-work/components/CheckList'
import { CheckListType } from '@features/tracking-work/interfaces/CheckListType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

import styles from './index.module.scss'

interface ChecklistGroupPropsType {
  atgChecklist: CheckListType[]
  meterChecklist: CheckListType[]
  isMeterMode: boolean
  hideAction: boolean
  handleSaveMeter: () => void
  handleSaveAtg: () => void
}

export function ChecklistGroup({
  atgChecklist,
  meterChecklist,
  isMeterMode,
  hideAction,
  handleSaveAtg,
  handleSaveMeter,
}: ChecklistGroupPropsType) {
  const hasMeter = !!meterChecklist.length
  return (
    <div className={styles.checkListGroup}>
      <CheckList
        className={cx('mt', styles.checkList, {
          [styles.active]: !isMeterMode,
        })}
        mode='ATG'
        active={isMeterMode}
        hideAction={hideAction}
        checkList={atgChecklist}
        handleSaveChecklist={handleSaveAtg}
      />
      <CheckList
        className={cx('mt', styles.checkList, {
          [styles.active]: isMeterMode && hasMeter,
        })}
        mode='Meter'
        active={!isMeterMode}
        disabled={!hasMeter}
        hideAction={hideAction}
        checkList={meterChecklist}
        handleSaveChecklist={handleSaveMeter}
      />
    </div>
  )
}
