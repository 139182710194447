import { AxiosInstance } from 'axios'

import { MonthlyReportType } from '@features/monthly-report/interfaces/MonthlyReportType'
import { MonthlyType } from '@features/pdf/interfaces/MonthlyType'
import { plainToClass } from 'class-transformer'

interface PreviewParams {
  id: number
}

interface ExportParams {
  id: number
}

interface ApproveParams {
  id: number
}

interface RejectParams {
  id: number
}

interface MonthlyReportListParams {
  year: string
  month: string
}

export class MonthlyClient {
  constructor(private client: AxiosInstance) {}

  async getMonthlyReportList({
    year,
    month,
  }: MonthlyReportListParams): Promise<MonthlyReportType[]> {
    const response = await this.client.get(
      `/api/reports/monthlies?year=${year}&month=${month}`
    )

    return response.data.map((row: any) => plainToClass(MonthlyReportType, row))
  }

  async getPreview({ id }: PreviewParams): Promise<MonthlyType> {
    const response = await this.client.get(`/api/reports/monthlies/${id}`)

    return plainToClass(MonthlyType, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async approve({ id }: ApproveParams): Promise<MonthlyReportType> {
    const response = await this.client.patch(
      `/api/reports/monthlies/${id}/approve`
    )

    return plainToClass(MonthlyReportType, response.data)
  }

  async reject({ id }: RejectParams): Promise<MonthlyReportType> {
    const response = await this.client.patch(
      `/api/reports/monthlies/${id}/reject`
    )

    return plainToClass(MonthlyReportType, response.data)
  }

  async export({ id }: ExportParams): Promise<MonthlyType> {
    const response = await this.client.get(
      `/api/reports/monthlies/${id}/export`
    )

    return plainToClass(MonthlyType, response.data, {
      excludeExtraneousValues: true,
    })
  }
}
