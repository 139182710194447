import { memo, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import {
  SummaryFormType,
  SummaryPropsType,
} from '@features/tracking-work/interfaces/SummaryType'
import { Loading } from '@components/Loading'
import {
  metricTonsInAirKeys,
  metricTonsInVacKeys,
  volume15Keys,
  volume30Keys,
} from '@lib/constants'
import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'
import { calDifference, calFinal, calLessline, calTotal } from '@lib/utils'
import { useProfile } from '@features/profile/hooks/useProfile'
import { isBoardman } from '@lib/permissions'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

import { SummaryDetail } from './SummaryDetail'

export const Summary = memo(
  ({
    disabled,
    isSubmitting,
    summaryMethods,
    meterLineAdjustment,
    reports,
    vesselLoads,
    disableVesselLoad,
    canCalTotal,
    hasMeter,
    hasVesselLoad,
  }: SummaryPropsType) => {
    const [
      vesselVolume30,
      vesselVolume15,
      vesselTonInAir,
      vesselTonInVac,
    ] = vesselLoads

    const { handleSetSubmitting } = useDeliveryReport()

    const [isInitialPage, setInitialPage] = useState(true)
    const { data: profile } = useProfile()
    const roleBoardman = isBoardman(profile?.roleGroup?.reportRole || '')
    const metricInAir = reports.find(report =>
      metricTonsInAirKeys.includes(report.keyAttribute)
    )
    const metricInVac = reports.find(report =>
      metricTonsInVacKeys.includes(report.keyAttribute)
    )
    const volume15 = reports.find(report =>
      volume15Keys.includes(report.keyAttribute)
    )
    const volume30 = reports.find(report =>
      volume30Keys.includes(report.keyAttribute)
    )

    const canCalFinal = hasMeter || hasVesselLoad || canCalTotal

    function handleMeterTonInVacChange(e: React.ChangeEvent<HTMLInputElement>) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalTonInVac: calFinal(
          Number(e.target.value),
          Number(form.lesslinetonInVac),
          Number(form.totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function handleMeterTonInAirChange(e: React.ChangeEvent<HTMLInputElement>) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalTonInAir: calFinal(
          Number(e.target.value),
          Number(form.lesslineTonInAir),
          Number(form.totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function handleMeterVolume30Change(e: React.ChangeEvent<HTMLInputElement>) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalVolume30: calFinal(
          Number(e.target.value),
          Number(form.lesslineVolume30),
          Number(form.totalVolume30),
          0,
          true
        ),
      })
    }

    function handleMeterVolume15Change(e: React.ChangeEvent<HTMLInputElement>) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalVolume15: calFinal(
          Number(e.target.value),
          Number(form.lesslineVolume15),
          Number(form.totalVolume15),
          0,
          true
        ),
      })
    }

    function handleLesslineTonInVacChange(
      e: React.ChangeEvent<HTMLInputElement>
    ) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalTonInVac: calFinal(
          Number(form.meterTonInVac),
          Number(e.target.value),
          Number(form.totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          true
        ),
      })
    }

    function handleLesslineTonInAirChange(
      e: React.ChangeEvent<HTMLInputElement>
    ) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalTonInAir: calFinal(
          Number(form.meterTonInAir),
          Number(e.target.value),
          Number(form.totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          true
        ),
      })
    }

    function handleLesslineVolume30Change(
      e: React.ChangeEvent<HTMLInputElement>
    ) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalVolume30: calFinal(
          Number(form.meterVolume30),
          Number(e.target.value),
          Number(form.totalVolume30),
          0,
          true
        ),
      })
    }

    function handleLesslineVolume15Change(
      e: React.ChangeEvent<HTMLInputElement>
    ) {
      const form = summaryMethods.getValues()
      handleSetSubmitting(false)
      summaryMethods.reset({
        ...form,
        finalVolume15: calFinal(
          Number(form.meterVolume15),
          Number(e.target.value),
          Number(form.totalVolume15),
          0,
          true
        ),
      })
    }

    function calSummaryWhenStopStateChange() {
      const form = summaryMethods.getValues()
      const totalVolume30 = calTotal(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalVolume15 = calTotal(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalTonInAir = calTotal(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopValue ?? null),
        Number(metricInAir?.decimalPoint ?? null),
        canCalTotal
      )
      const totalTonInVac = calTotal(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopValue ?? null),
        Number(metricInVac?.decimalPoint ?? null),
        canCalTotal
      )

      summaryMethods.reset({
        ...form,
        totalVolume30,
        totalVolume15,
        totalTonInAir,
        totalTonInVac,
        finalVolume30: calFinal(
          Number(form.meterVolume30),
          Number(form.lesslineVolume30),
          Number(totalVolume30),
          0,
          canCalFinal
        ),
        finalVolume15: calFinal(
          Number(form.meterVolume15),
          Number(form.lesslineVolume15),
          Number(totalVolume15),
          0,
          canCalFinal
        ),
        finalTonInAir: calFinal(
          Number(form.meterTonInAir),
          Number(form.lesslineTonInAir),
          Number(totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          canCalFinal
        ),
        finalTonInVac: calFinal(
          Number(form.meterTonInVac),
          Number(form.lesslinetonInVac),
          Number(totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function calSummaryWhenStartStateChange() {
      const form = summaryMethods.getValues()
      const totalVolume30 = calTotal(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalVolume15 = calTotal(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalTonInAir = calTotal(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopValue ?? null),
        Number(metricInAir?.decimalPoint ?? null),
        canCalTotal
      )
      const totalTonInVac = calTotal(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopValue ?? null),
        Number(metricInVac?.decimalPoint ?? null),
        canCalTotal
      )

      const difference = {
        tonInAir: calDifference(
          Number(metricInAir?.startValue ?? null),
          Number(metricInAir?.stopLesslineValue ?? null),
          Number(metricInAir?.decimalPoint),
          disableVesselLoad
        ),
        tonInVac: calDifference(
          Number(metricInVac?.startValue ?? null),
          Number(metricInVac?.stopLesslineValue ?? null),
          Number(metricInVac?.decimalPoint),
          disableVesselLoad
        ),
        volume15: calDifference(
          Number(volume15?.startValue ?? null),
          Number(volume15?.stopLesslineValue ?? null),
          Number(volume15?.decimalPoint),
          disableVesselLoad
        ),
        volume30: calDifference(
          Number(volume30?.startValue ?? null),
          Number(volume30?.stopLesslineValue ?? null),
          Number(volume30?.decimalPoint),
          disableVesselLoad
        ),
      }

      const lesslineVolume30 = calLessline(
        difference.volume30,
        vesselVolume30,
        0
      )
      const lesslineVolume15 = calLessline(
        difference.volume15,
        vesselVolume15,
        0
      )
      const lesslineTonInAir = calLessline(
        difference.tonInAir,
        vesselTonInAir,
        Number(metricInAir?.decimalPoint ?? null)
      )
      const lesslinetonInVac = calLessline(
        difference.tonInVac,
        vesselTonInVac,
        Number(metricInVac?.decimalPoint ?? null)
      )

      summaryMethods.reset({
        ...form,
        totalVolume30,
        totalVolume15,
        totalTonInAir,
        totalTonInVac,
        lesslineVolume30,
        lesslineVolume15,
        lesslineTonInAir,
        lesslinetonInVac,
        finalVolume30: calFinal(
          Number(form.meterVolume30),
          Number(lesslineVolume30),
          Number(totalVolume30),
          0,
          canCalFinal
        ),
        finalVolume15: calFinal(
          Number(form.meterVolume15),
          Number(lesslineVolume15),
          Number(totalVolume15),
          0,
          canCalFinal
        ),
        finalTonInAir: calFinal(
          Number(form.meterTonInAir),
          Number(lesslineTonInAir),
          Number(totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          canCalFinal
        ),
        finalTonInVac: calFinal(
          Number(form.meterTonInVac),
          Number(lesslinetonInVac),
          Number(totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function calSummaryWhenStopLesslineStateAndVesselLoadChange() {
      const form = summaryMethods.getValues()
      const difference = {
        tonInAir: calDifference(
          Number(metricInAir?.startValue ?? null),
          Number(metricInAir?.stopLesslineValue ?? null),
          Number(metricInAir?.decimalPoint),
          disableVesselLoad
        ),
        tonInVac: calDifference(
          Number(metricInVac?.startValue ?? null),
          Number(metricInVac?.stopLesslineValue ?? null),
          Number(metricInVac?.decimalPoint),
          disableVesselLoad
        ),
        volume15: calDifference(
          Number(volume15?.startValue ?? null),
          Number(volume15?.stopLesslineValue ?? null),
          Number(volume15?.decimalPoint),
          disableVesselLoad
        ),
        volume30: calDifference(
          Number(volume30?.startValue ?? null),
          Number(volume30?.stopLesslineValue ?? null),
          Number(volume30?.decimalPoint),
          disableVesselLoad
        ),
      }

      const lesslineVolume30 = calLessline(
        difference.volume30,
        vesselVolume30,
        0
      )
      const lesslineVolume15 = calLessline(
        difference.volume15,
        vesselVolume15,
        0
      )
      const lesslineTonInAir = calLessline(
        difference.tonInAir,
        vesselTonInAir,
        Number(metricInAir?.decimalPoint ?? null)
      )
      const lesslinetonInVac = calLessline(
        difference.tonInVac,
        vesselTonInVac,
        Number(metricInVac?.decimalPoint ?? null)
      )

      summaryMethods.reset({
        ...form,
        lesslineVolume30,
        lesslineVolume15,
        lesslineTonInAir,
        lesslinetonInVac,
        finalVolume30: calFinal(
          Number(form.meterVolume30),
          Number(lesslineVolume30),
          Number(form.totalVolume30),
          0,
          canCalFinal
        ),
        finalVolume15: calFinal(
          Number(form.meterVolume15),
          Number(lesslineVolume15),
          Number(form.totalVolume15),
          0,
          canCalFinal
        ),
        finalTonInAir: calFinal(
          Number(form.meterTonInAir),
          Number(lesslineTonInAir),
          Number(form.totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          canCalFinal
        ),
        finalTonInVac: calFinal(
          Number(form.meterTonInVac),
          Number(lesslinetonInVac),
          Number(form.totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function calSummary(form: SummaryFormType) {
      const totalVolume30 = calTotal(
        Number(volume30?.startValue ?? null),
        Number(volume30?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalVolume15 = calTotal(
        Number(volume15?.startValue ?? null),
        Number(volume15?.stopValue ?? null),
        0,
        canCalTotal
      )
      const totalTonInAir = calTotal(
        Number(metricInAir?.startValue ?? null),
        Number(metricInAir?.stopValue ?? null),
        Number(metricInAir?.decimalPoint ?? null),
        canCalTotal
      )
      const totalTonInVac = calTotal(
        Number(metricInVac?.startValue ?? null),
        Number(metricInVac?.stopValue ?? null),
        Number(metricInVac?.decimalPoint ?? null),
        canCalTotal
      )

      const meterVolume30 = String(meterLineAdjustment.volume30)
      const meterVolume15 = String(meterLineAdjustment.volume15)
      const meterTonInAir = String(meterLineAdjustment.tonInAir)
      const meterTonInVac = String(meterLineAdjustment.tonInVac)

      const difference = {
        tonInAir: calDifference(
          Number(metricInAir?.startValue ?? null),
          Number(metricInAir?.stopLesslineValue ?? null),
          Number(metricInAir?.decimalPoint),
          disableVesselLoad
        ),
        tonInVac: calDifference(
          Number(metricInVac?.startValue ?? null),
          Number(metricInVac?.stopLesslineValue ?? null),
          Number(metricInVac?.decimalPoint),
          disableVesselLoad
        ),
        volume15: calDifference(
          Number(volume15?.startValue ?? null),
          Number(volume15?.stopLesslineValue ?? null),
          Number(volume15?.decimalPoint),
          disableVesselLoad
        ),
        volume30: calDifference(
          Number(volume30?.startValue ?? null),
          Number(volume30?.stopLesslineValue ?? null),
          Number(volume30?.decimalPoint),
          disableVesselLoad
        ),
      }

      const lesslineVolume30 = calLessline(
        difference.volume30,
        vesselVolume30,
        0
      )
      const lesslineVolume15 = calLessline(
        difference.volume15,
        vesselVolume15,
        0
      )
      const lesslineTonInAir = calLessline(
        difference.tonInAir,
        vesselTonInAir,
        Number(metricInAir?.decimalPoint ?? null)
      )
      const lesslinetonInVac = calLessline(
        difference.tonInVac,
        vesselTonInVac,
        Number(metricInVac?.decimalPoint ?? null)
      )

      summaryMethods.reset({
        ...form,
        totalVolume30,
        totalVolume15,
        totalTonInAir,
        totalTonInVac,
        meterVolume30,
        meterVolume15,
        meterTonInAir,
        meterTonInVac,
        lesslineVolume30,
        lesslineVolume15,
        lesslineTonInAir,
        lesslinetonInVac,
        finalVolume30: calFinal(
          Number(meterVolume30),
          Number(lesslineVolume30),
          Number(totalVolume30),
          0,
          canCalFinal
        ),
        finalVolume15: calFinal(
          Number(meterVolume15),
          Number(lesslineVolume15),
          Number(totalVolume15),
          0,
          canCalFinal
        ),
        finalTonInAir: calFinal(
          Number(meterTonInAir),
          Number(lesslineTonInAir),
          Number(totalTonInAir),
          Number(metricInAir?.decimalPoint ?? null),
          canCalFinal
        ),
        finalTonInVac: calFinal(
          Number(meterTonInVac),
          Number(lesslinetonInVac),
          Number(totalTonInVac),
          Number(metricInVac?.decimalPoint ?? null),
          canCalFinal
        ),
      })
    }

    function handdleSetSaveFinal(){
      handleSetSubmitting(false)
    }

    useEffect(
      () => {
        const form = summaryMethods.getValues()
        const emptyForm = Object.values(form).every(row => row === '')

        if (isInitialPage) {
          setInitialPage(false)

          if (emptyForm) {
            calSummary(form)
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    useEffect(() => {
      if (!isInitialPage) {
        calSummaryWhenStopLesslineStateAndVesselLoadChange()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      metricInAir?.stopLesslineValue,
      metricInVac?.stopLesslineValue,
      volume15?.stopLesslineValue,
      volume30?.stopLesslineValue,
      vesselVolume30,
      vesselVolume15,
      vesselTonInAir,
      vesselTonInVac,
      disableVesselLoad,
    ])

    useEffect(() => {
      if (!isInitialPage) {
        calSummaryWhenStartStateChange()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      metricInAir?.startValue,
      metricInVac?.startValue,
      volume15?.startValue,
      volume30?.startValue,
    ])

    useEffect(() => {
      if (!isInitialPage) {
        calSummaryWhenStopStateChange()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      metricInAir?.stopValue,
      metricInVac?.stopValue,
      volume15?.stopValue,
      volume30?.stopValue,
    ])

    return (
      <FormProvider {...summaryMethods}>
        <div className={styles.atgReportCalculationDetailHeader}>Summary</div>
        <SummaryDetail
          title='Total (By ATG)'
          volume15C='totalVolume15'
          volume30C='totalVolume30'
          tonInAir='totalTonInAir'
          tonInVac='totalTonInVac'
          disabled
        />
        <SummaryDetail
          title='Meter Line Adjustment'
          volume15C='meterVolume15'
          volume30C='meterVolume30'
          tonInAir='meterTonInAir'
          tonInVac='meterTonInVac'
          disabled={isSubmitting || roleBoardman || disabled}
          handleTonInVacChange={handleMeterTonInVacChange}
          handleTonInAirChange={handleMeterTonInAirChange}
          handleVolume30Change={handleMeterVolume30Change}
          handleVolume15Change={handleMeterVolume15Change}
        />
        <SummaryDetail
          title='Less Pipe Line Adjustment'
          volume15C='lesslineVolume15'
          volume30C='lesslineVolume30'
          tonInAir='lesslineTonInAir'
          tonInVac='lesslinetonInVac'
          disabled={isSubmitting || roleBoardman || disabled}
          handleTonInVacChange={handleLesslineTonInVacChange}
          handleTonInAirChange={handleLesslineTonInAirChange}
          handleVolume30Change={handleLesslineVolume30Change}
          handleVolume15Change={handleLesslineVolume15Change}
        />
        <SummaryDetail
          title='Final Difference'
          volume15C='finalVolume15'
          volume30C='finalVolume30'
          tonInAir='finalTonInAir'
          tonInVac='finalTonInVac'
          disabled={isSubmitting || roleBoardman || disabled}
          handleTonInVacChange={handdleSetSaveFinal}
          handleTonInAirChange={handdleSetSaveFinal}
          handleVolume30Change={handdleSetSaveFinal}
          handleVolume15Change={handdleSetSaveFinal}
        />
      </FormProvider>
    )
  }
)
