import { Page, Text, View, Image } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import { PrintGaugeProps } from '@features/pdf/interfaces/PrintGaugeType'
import logo from '@assets/images/SCGC_Logo_Full Color_Clear_Space.png'
import styles from './styles'

function getUnit(unit: string) {
  switch (unit) {
    case 'degc':
      return <Text>°C</Text>
    case 'mmh2o':
      return <Text>mm H2O</Text>
    case 'kg/cm2':
      return (
        <View style={styles.symbol}>
          <Text>kg/cm</Text>
          <View style={styles.upper}>
            <Text>2</Text>
          </View>
        </View>
      )
    default:
      return <Text>{unit}</Text>
  }
}

export function PrintGauge({
  tankCode,
  parameters,
  workNumber,
  companyName,
  time,
  product,
  batchPeriod,
  activity,
  fromTank,
  toTank,
}: PrintGaugeProps) {
  return (
    <Page size='A4' style={styles.page}>
      <View style={styles.header}>
        <View style={styles.imageWrap}>
          <Image src={logo} />
        </View>
        <Text>{companyName}</Text>
      </View>
      <View style={styles.header}>
        <Text>Print Gauge</Text>
      </View>

      <View style={styles.box}>
        <View style={[styles.row, styles.mb]}>
          <View style={[styles.section1, styles.textRight]}>
            <Text>TANK NO:</Text>
          </View>
          <View style={[styles.section1, styles.colorRed]}>
            <Text>{tankCode}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.section1, styles.textRight]}>
            <Text>PRODUCT:</Text>
          </View>
          <View style={[styles.section1, styles.colorRed]}>
            <Text>{product}</Text>
          </View>
        </View>
      </View>

      <View style={styles.box}>
        <View>
          <Text style={[styles.bold, styles.mb]}>TANK INFORMATION</Text>
        </View>
        {parameters?.levelMaster && (
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.tag}>{parameters?.levelMaster?.tag}</Text>
            <Text style={styles.label}>LEVEL MASTER :</Text>
            <Text style={[styles.value, styles.colorRed]}>
              {typeof parameters?.levelMaster?.value === 'number'
                ? parameters?.levelMaster?.value.toLocaleString()
                : ''}
            </Text>
            <View style={styles.unit}>
              {getUnit(parameters?.levelMaster?.unit || '')}
            </View>
          </View>
        )}
        {parameters?.levelSlave && (
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.tag}>{parameters?.levelSlave?.tag}</Text>
            <Text style={styles.label}>LEVEL SLAVE :</Text>
            <Text style={[styles.value, styles.colorRed]}>
              {typeof parameters?.levelSlave?.value === 'number'
                ? parameters?.levelSlave?.value.toLocaleString()
                : ''}
            </Text>
            <View style={styles.unit}>
              {getUnit(parameters?.levelSlave?.unit || '')}
            </View>
          </View>
        )}
        {parameters?.liquidTemperature && (
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.tag}>{parameters?.liquidTemperature?.tag}</Text>
            <Text style={styles.label}>LIQUID TEMPERATURE :</Text>
            <Text style={[styles.value, styles.colorRed]}>
              {typeof parameters?.liquidTemperature?.value === 'number'
                ? parameters?.liquidTemperature?.value.toLocaleString()
                : ''}
            </Text>
            <View style={styles.unit}>
              {getUnit(parameters?.liquidTemperature?.unit || '')}
            </View>
          </View>
        )}
        {parameters?.vaporTemperature && (
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.tag}>{parameters?.vaporTemperature?.tag}</Text>
            <Text style={styles.label}>VAPOR TEMPERATURE :</Text>
            <Text style={[styles.value, styles.colorRed]}>
              {typeof parameters?.vaporTemperature?.value === 'number'
                ? parameters?.vaporTemperature?.value.toLocaleString()
                : ''}
            </Text>
            <View style={styles.unit}>
              {getUnit(parameters?.vaporTemperature?.unit || '')}
            </View>
          </View>
        )}
        {parameters?.vaporPressure && (
          <View style={styles.row}>
            <Text style={styles.tag}>{parameters?.vaporPressure?.tag}</Text>
            <Text style={styles.label}>VAPOR PRESSURE :</Text>
            <Text style={[styles.value, styles.colorRed]}>
              {typeof parameters?.vaporPressure?.value === 'number'
                ? parameters?.vaporPressure?.value.toLocaleString()
                : ''}
            </Text>
            <View style={styles.unit}>
              {getUnit(parameters?.vaporPressure?.unit || '')}
            </View>
          </View>
        )}
      </View>

      <View style={styles.box}>
        <View>
          <Text style={[styles.bold, styles.mb]}>WORK INFORMATION</Text>
        </View>
        <View style={[styles.row, styles.mb]}>
          <Text style={styles.informationHeader}>WORK NUMBER:</Text>
          <Text style={[styles.informationValue, styles.colorRed]}>
            {workNumber}
          </Text>
        </View>
        <View style={[styles.row, styles.mb]}>
          <Text style={styles.informationHeader}>BATCH PERIOD :</Text>
          <Text
            style={{
              ...styles.informationValue,
              ...styles.colorRed,
              textTransform: 'uppercase',
            }}
          >
            {batchPeriod}
          </Text>
        </View>
        <View style={[styles.row, styles.mb]}>
          <Text style={styles.informationHeader}>ACTIVITY : </Text>
          <Text style={[styles.informationValue, styles.colorRed]}>
            {activity}
          </Text>
        </View>
        <View style={[styles.row, styles.mb]}>
          <Text style={styles.informationHeader}>DATE :</Text>
          <Text style={[styles.informationValue2, styles.colorRed]}>
            {time ? DateTime.fromISO(time).toFormat('dd/LL/yyyy') : '-'}
          </Text>

          <Text style={styles.informationHeader2}>TIME :</Text>
          <Text style={[styles.informationValue3, styles.colorRed]}>
            {time ? DateTime.fromISO(time).toFormat('HH:mm') : '-'}
          </Text>
        </View>
        <View style={[styles.row, styles.mb]}>
          <Text style={styles.informationHeader}>FROM :</Text>
          <Text style={[styles.informationValue2, styles.colorRed]}>
            {fromTank}
          </Text>

          <Text style={styles.informationHeader2}>TO :</Text>
          <Text style={[styles.informationValue3, styles.colorRed]}>
            <Text>{toTank}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.signatureGroup}>
        <Text style={styles.signature}>MTT</Text>
        <Text style={styles.signature}>SURVEYOR</Text>
      </View>
    </Page>
  )
}
