import { useState, useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'

import {
  CalculationDetailType,
  CalculationFormType,
} from '@features/tracking-work/interfaces/CalculationDetailType'
import { Level } from '@features/tracking-work/interfaces/LevelType'
import {
  ConfigDeliveryReport,
  ManualDipParameterKey,
} from '@features/tracking-work/interfaces/ConfigDeliveryReportType'
import {
  ManualDipCalculateForm,
  ManualDipForm,
} from '@features/tracking-work/interfaces/ManualDipType'
import { VesselLoadForm } from '@features/tracking-work/interfaces/VesselLoadType'
import { CheckListType } from '@features/tracking-work/interfaces/CheckListType'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage, transformDateToISOFormat } from '@lib/utils'
import { useAppClient } from '@lib/useAppClient'
import {
  CalculateType,
  CalculateMode,
} from '@lib/clients/collections/ATGReportClient'
import { useModal } from '@features/tracking-work/hooks/useModal'

interface UseDraftATGReportType {
  deliveryId: string
  tankCode: string
}

interface ATGReportResponse {
  isLoading: boolean
  level?: Level
  reports: CalculationDetailType[] | []
  disableStopLesslineForm: boolean
  disableStopForm: boolean
  isSubmitting: boolean
  startDatetime: string | null
  stopLesslineDatetime: string | null
  stopDatetime: string | null
  capacity: number
  config?: ConfigDeliveryReport
  loadingConfig: boolean
  purity: string
  manualDipMethods: UseFormReturn<ManualDipForm>
  methods: UseFormReturn<CalculationFormType>
  vesselLoadMethods: UseFormReturn<VesselLoadForm>
  isStartDateChange: boolean
  isStopDateChange: boolean
  isLesslineDateChange: boolean
  canSaveStart: boolean
  canSaveStop: boolean
  canSaveLessline: boolean
  modal: string
  handleStartDatetimeChange: (datetime: string, type: string) => void
  handleStopLesslineDatetimeChange: (datetime: string, type: string) => void
  handleStopDatetimeChange: (datetime: string, type: string) => void
  handleCalculationStart: (datetime: string | null) => void
  handleCalculationLessLine: (datetime: string | null) => void
  handleCalculationStop: (datetime: string | null) => void
  handleSaveStart: (form: CalculationFormType) => void
  handleSaveLessLine: (form: CalculationFormType) => void
  handleSaveStop: (form: CalculationFormType) => void
  handleManualDipStartCal: () => void
  handleManualDipStopLesslineCal: () => void
  handleManualDipStopCal: () => void
  setPurity: (value: string) => void
  handleManualFormChange: (type: CalculateType, mode: CalculateMode) => void
  handleConfirmSaveStart: () => Promise<void>
  handleConfirmSaveLessLine: () => Promise<void>
  handleConfirmSaveStop: () => Promise<void>
  closeModal: () => void
}
const validateValue = yup
  .number()
  .typeError('')
  .min(
    yup.ref('constraintMin'),
    v => `must be greater than or equal to ${v.min}`
  )
  .max(yup.ref('constraintMax'), v => `must be less than or equal to ${v.max}`)

const validateGroupValue = yup.object().shape({
  startValue: validateValue,
  stopLesslineValue: validateValue,
  stopValue: validateValue,
})

const validationSchema = yup.object().shape({
  level: validateGroupValue,
  liquidTemperature: validateGroupValue,
  vaporTemperature: validateGroupValue,
  vaporPressure: validateGroupValue,
  densityInVac: validateGroupValue,
  molecularWeight: validateGroupValue,
})

export function useDraftATGReport({
  deliveryId,
  tankCode,
}: UseDraftATGReportType): ATGReportResponse {
  const queryKey = ['delivery', deliveryId, 'tanks', tankCode, 'reports']
  const client = useAppClient()
  const { success, error } = useAlert()
  const [isSubmitting, setSubmitting] = useState(false)
  const { modal, payload, openModal, closeModal } = useModal()
  const { data, isLoading, refetch } = useQuery(queryKey, () =>
    client?.draft.getCalculateDetail({ deliveryId, tankCode }).then(res => {
      setStartDatetime(res.startDatetime)
      setStopLesslineDatetime(res.stopLesslineDatetime)
      setStopDatetime(res.stopDatetime)

      if (res.summaryReport) {
        vesselLoadMethods.reset({
          volume15: res.summaryReport.vesselVolume15 || '',
          volume30: res.summaryReport.vesselVolume30 || '',
          tonInAir: res.summaryReport.vesselTonInAir || '',
          tonInVac: res.summaryReport.vesselTonInVac || '',
        })
      }

      return res
    })
  )
  const [purity, setPurity] = useState('')
  const [isStartDateChange, setStartDateChange] = useState(false)
  const [isStopDateChange, setStopDateChange] = useState(false)
  const [isLesslineDateChange, setLesslineDateChange] = useState(false)

  const [canSaveStart, setCanSaveStart] = useState(true)
  const [canSaveStop, setCanSaveStop] = useState(true)
  const [canSaveLessline, setCanSaveLessline] = useState(true)

  const {
    data: config,
    isLoading: loadingConfig,
    refetch: refetchConfig,
  } = useQuery(
    ['delivery-report', deliveryId, 'tanks', tankCode, 'config'],
    () =>
      client?.draft
        .getConfigDeliveryReport({ deliveryId, tankId: tankCode })
        .then(res => res)
  )

  const methods = useForm<CalculationFormType>({
    defaultValues: { list: [] },
  })

  const manualDipMethods = useForm<ManualDipForm>({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  })

  const vesselLoadMethods = useForm<VesselLoadForm>({
    defaultValues: {
      tonInAir: '',
      tonInVac: '',
      volume15: '',
      volume30: '',
    },
  })

  const [startDatetime, setStartDatetime] = useState<string | null>(null)
  const [stopLesslineDatetime, setStopLesslineDatetime] = useState<
    string | null
  >(null)
  const [stopDatetime, setStopDatetime] = useState<string | null>(null)

  const queryClient = useQueryClient()

  function handleCalculation(
    type: CalculateType,
    mode: CalculateMode,
    datetime: string | null,
    successMessage: string
  ) {
    if (
      datetime &&
      DateTime.fromISO(datetime).toMillis() > DateTime.now().toMillis()
    ) {
      error({ message: 'เวลาไม่ตรงตามเงื่อนไขโปรดเลือกใหม่อีกครั้ง' })
      return
    }

    setSubmitting(true)
    client?.draft
      .getCalculate({
        deliveryId,
        tankCode,
        type,
        mode,
        datetime: datetime ?? null,
      })
      .then(res => {
        const currentDatetime = transformDateToISOFormat(res.datetime)
        success({ message: successMessage })
        resetForm(type, mode)
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return { ...oldData, reports: res.reports }
        })

        if (type === 'start' && mode === 'normal') {
          setStartDatetime(currentDatetime)
          setStartDateChange(false)
          setCanSaveStart(true)
        } else if (type === 'stop' && mode === 'less_line') {
          setStopLesslineDatetime(currentDatetime)
          setLesslineDateChange(false)
          setCanSaveLessline(true)
        } else if (type === 'stop' && mode === 'normal') {
          setStopDatetime(currentDatetime)
          setStopDateChange(false)
          setCanSaveStop(true)
        }
      })
      .catch(e => {
        const currentDatetime = transformDateToISOFormat(
          e?.response?.data?.detail.datetime
        )
        error({ message: getErrorMessage(e) })
        if (type === 'start' && mode === 'normal') {
          setStartDatetime(currentDatetime)
        } else if (type === 'stop' && mode === 'less_line') {
          setStopLesslineDatetime(currentDatetime)
        } else if (type === 'stop' && mode === 'normal') {
          setStopDatetime(currentDatetime)
        }
      })
      .finally(() => setSubmitting(false))
  }

  const handleCalculationStart = useCallback(
    (datetime: string | null) => {
      handleCalculation('start', 'normal', datetime, 'Start cal successful.')
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleCalculationLessLine = useCallback(
    (datetime: string | null) => {
      handleCalculation(
        'stop',
        'less_line',
        datetime,
        'Stop lessline cal successful.'
      )
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleCalculationStop = useCallback(
    (datetime: string | null) => {
      handleCalculation('stop', 'normal', datetime, 'Stop cal successful.')
    },
    [tankCode] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function saveSuccess(message: string) {
    refetchConfig()
    refetch()
    success({ message })
  }

  function updateChecklistStatus(type: CalculateType, mode: CalculateMode) {
    queryClient.setQueryData(
      ['delivery-report', deliveryId],
      (oldData: any) => {
        return {
          ...oldData,
          atgChecklist: oldData.atgChecklist.map((row: CheckListType) => ({
            ...row,
            active:
              (row.type === type &&
                row.mode === mode &&
                row.tankCode === tankCode) ||
              row.active,
          })),
        }
      }
    )
  }

  async function handleSave(
    form: CalculationDetailType[],
    manualForm: ManualDipCalculateForm,
    datetime: string,
    type: CalculateType,
    mode: CalculateMode,
    successMessage: string
  ) {
    try {
      setSubmitting(true)
      await client?.draft.updateCalculateDetail({
        deliveryId,
        tankCode,
        form,
        manualForm,
        purity,
        type,
        mode,
        datetime,
      })

      updateChecklistStatus(type, mode)
      saveSuccess(successMessage)
    } catch (e) {
      error({ message: getErrorMessage(e) })
    } finally {
      setSubmitting(false)
      if (modal) closeModal()
    }
  }

  const handleConfirmSaveStart = useCallback(
    async () => {
      if (startDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          payload,
          {
            level: values.level?.startValue || '',
            liquidTemperature: values.liquidTemperature?.startValue || '',
            vaporTemperature: values.vaporTemperature?.startValue || '',
            vaporPressure: values.vaporPressure?.startValue || '',
            densityInVac: values.densityInVac?.startValue || '',
            molecularWeight: values.molecularWeight?.startValue || '',
          },
          startDatetime,
          'start',
          'normal',
          'save start success'
        )
      }
    },
    [payload, startDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleConfirmSaveLessLine = useCallback(
    async () => {
      if (stopLesslineDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          payload,
          {
            level: values.level?.stopLesslineValue || '',
            liquidTemperature:
              values.liquidTemperature?.stopLesslineValue || '',
            vaporTemperature: values.vaporTemperature?.stopLesslineValue || '',
            vaporPressure: values.vaporPressure?.stopLesslineValue || '',
            densityInVac: values.densityInVac?.stopLesslineValue || '',
            molecularWeight: values.molecularWeight?.stopLesslineValue || '',
          },
          stopLesslineDatetime,
          'stop',
          'less_line',
          'save stop lessline success'
        )
      }
    },
    [payload, stopLesslineDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleConfirmSaveStop = useCallback(
    async () => {
      if (stopDatetime) {
        const values = manualDipMethods.getValues()
        await handleSave(
          payload,
          {
            level: values.level?.stopValue || '',
            liquidTemperature: values.liquidTemperature?.stopValue || '',
            vaporTemperature: values.vaporTemperature?.stopValue || '',
            vaporPressure: values.vaporPressure?.stopValue || '',
            densityInVac: values.densityInVac?.stopValue || '',
            molecularWeight: values.molecularWeight?.stopValue || '',
          },
          stopDatetime,
          'stop',
          'normal',
          'save stop success'
        )
      }
    },
    [payload, stopDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSaveStart = useCallback(
    async ({ list }: CalculationFormType) => {
      if (startDatetime && canSaveStart) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.startValue || '',
            liquidTemperature: values.liquidTemperature?.startValue || '',
            vaporTemperature: values.vaporTemperature?.startValue || '',
            vaporPressure: values.vaporPressure?.startValue || '',
            densityInVac: values.densityInVac?.startValue || '',
            molecularWeight: values.molecularWeight?.startValue || '',
          },
          startDatetime,
          'start',
          'normal',
          'save start success'
        )
      } else if (startDatetime && !canSaveStart) {
        openModal({ modalName: 'start_normal', payload: list })
      } else {
        error({ message: 'Please select start datetime.' })
      }
    },
    [startDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleSaveLessLine = useCallback(
    async ({ list }: CalculationFormType) => {
      if (stopLesslineDatetime && canSaveLessline) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.stopLesslineValue || '',
            liquidTemperature:
              values.liquidTemperature?.stopLesslineValue || '',
            vaporTemperature: values.vaporTemperature?.stopLesslineValue || '',
            vaporPressure: values.vaporPressure?.stopLesslineValue || '',
            densityInVac: values.densityInVac?.stopLesslineValue || '',
            molecularWeight: values.molecularWeight?.stopLesslineValue || '',
          },
          stopLesslineDatetime,
          'stop',
          'less_line',
          'save stop lessline success'
        )
      } else if (stopLesslineDatetime && !canSaveLessline) {
        openModal({ modalName: 'stop_less_line', payload: list })
      } else {
        error({ message: 'Please select stop lessline datetime.' })
      }
    },
    [stopLesslineDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSaveStop = useCallback(
    async ({ list }: CalculationFormType) => {
      if (stopDatetime && canSaveStop) {
        const values = manualDipMethods.getValues()
        await handleSave(
          list,
          {
            level: values.level?.stopValue || '',
            liquidTemperature: values.liquidTemperature?.stopValue || '',
            vaporTemperature: values.vaporTemperature?.stopValue || '',
            vaporPressure: values.vaporPressure?.stopValue || '',
            densityInVac: values.densityInVac?.stopValue || '',
            molecularWeight: values.molecularWeight?.stopValue || '',
          },
          stopDatetime,
          'stop',
          'normal',
          'save stop success'
        )
      } else if (stopDatetime && !canSaveStop) {
        openModal({ modalName: 'stop_normal', payload: list })
      } else {
        error({ message: 'Please select stop datetime.' })
      }
    },
    [stopDatetime, purity] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function handleManualDipCal(
    form: ManualDipCalculateForm,
    type: CalculateType,
    mode: CalculateMode,
    successMessage: string
  ) {
    const valid = Object.keys(form).every(
      key => key === 'level' || !isZero(form[key as ManualDipParameterKey])
    )

    if (valid) {
      setSubmitting(true)
      client?.draft
        .manualCalculate({
          deliveryId,
          tankCode,
          type,
          mode,
          form,
          purity,
        })
        .then(reports => {
          success({ message: successMessage })
          queryClient.setQueryData(queryKey, (oldData: any) => {
            return { ...oldData, reports }
          })

          if (type === 'start' && mode === 'normal') {
            setStartDatetime(data?.startDatetime ?? null)
            setCanSaveStart(true)
            setStartDateChange(false)
          } else if (type === 'stop' && mode === 'less_line') {
            setStopLesslineDatetime(data?.stopLesslineDatetime ?? null)
            setCanSaveLessline(true)
            setLesslineDateChange(false)
          } else if (type === 'stop' && mode === 'normal') {
            setStopDatetime(data?.stopDatetime ?? null)
            setCanSaveStop(true)
            setStopDateChange(false)
          }
        })
        .catch(e => error({ message: getErrorMessage(e) }))
        .finally(() => setSubmitting(false))
    } else {
      error({ message: 'ค่าที่กรอกต้องไม่เป็น 0' })
    }
  }

  function isZero(value: string) {
    return value && Number(value) === 0
  }

  function handleManualDipStartCal() {
    const values = manualDipMethods.getValues()

    handleManualDipCal(
      {
        level: values.level?.startValue || '',
        liquidTemperature: values.liquidTemperature?.startValue || '',
        vaporTemperature: values.vaporTemperature?.startValue || '',
        vaporPressure: values.vaporPressure?.startValue || '',
        densityInVac: values.densityInVac?.startValue || '',
        molecularWeight: values.molecularWeight?.startValue || '',
      },
      'start',
      'normal',
      'Start cal successful.'
    )
  }

  function handleManualDipStopLesslineCal() {
    const values = manualDipMethods.getValues()

    handleManualDipCal(
      {
        level: values.level?.stopLesslineValue || '',
        liquidTemperature: values.liquidTemperature?.stopLesslineValue || '',
        vaporTemperature: values.vaporTemperature?.stopLesslineValue || '',
        vaporPressure: values.vaporPressure?.stopLesslineValue || '',
        densityInVac: values.densityInVac?.stopLesslineValue || '',
        molecularWeight: values.molecularWeight?.stopLesslineValue || '',
      },
      'stop',
      'less_line',
      'Stop lessline cal successful.'
    )
  }

  function handleManualDipStopCal() {
    const values = manualDipMethods.getValues()

    handleManualDipCal(
      {
        level: values.level?.stopValue || '',
        liquidTemperature: values.liquidTemperature?.stopValue || '',
        vaporTemperature: values.vaporTemperature?.stopValue || '',
        vaporPressure: values.vaporPressure?.stopValue || '',
        densityInVac: values.densityInVac?.stopValue || '',
        molecularWeight: values.molecularWeight?.stopValue || '',
      },
      'stop',
      'normal',
      'Stop cal successful.'
    )
  }

  function resetForm(type: CalculateType, mode: CalculateMode) {
    const values = manualDipMethods.getValues()
    const newform = Object.keys(values).reduce((res, key) => {
      return {
        ...res,
        [key]: {
          ...values[key as ManualDipParameterKey],
          ...(type === 'start' &&
            mode === 'normal' && {
              startValue: null,
            }),
          ...(type === 'stop' &&
            mode === 'less_line' && {
              stopLesslineValue: null,
            }),
          ...(type === 'stop' &&
            mode === 'normal' && {
              stopValue: null,
            }),
        },
      }
    }, {})

    manualDipMethods.reset(newform)
  }

  function handleStartDatetimeChange(datetime: string, type: string) {
    setStartDatetime(datetime)
    setCanSaveStart(false)
    if (type === 'select') setStartDateChange(true)
  }

  function handleStopDatetimeChange(datetime: string, type: string) {
    setStopDatetime(datetime)
    setCanSaveStop(false)
    if (type === 'select') setStopDateChange(true)
  }

  function handleStopLesslineDatetimeChange(datetime: string, type: string) {
    setStopLesslineDatetime(datetime)
    setCanSaveLessline(false)
    if (type === 'select') setLesslineDateChange(true)
  }

  function handleManualFormChange(type: CalculateType, mode: CalculateMode) {
    if (type === 'start' && mode === 'normal' && canSaveStart) {
      setCanSaveStart(false)
    } else if (type === 'stop' && mode === 'normal' && canSaveStop) {
      setCanSaveStop(false)
    } else if (type === 'stop' && mode === 'less_line' && canSaveLessline) {
      setCanSaveLessline(false)
    }
  }

  const value: ATGReportResponse = {
    isLoading,
    level: data?.level,
    reports: data?.reports || [],
    disableStopLesslineForm: !data?.stopLesslineState,
    disableStopForm: !data?.stopState,
    isSubmitting,
    startDatetime,
    stopLesslineDatetime,
    stopDatetime,
    capacity: data?.capacity || 0,
    config,
    loadingConfig,
    vesselLoadMethods,
    purity,
    manualDipMethods,
    methods,
    isStartDateChange,
    isStopDateChange,
    isLesslineDateChange,
    canSaveStart,
    canSaveStop,
    canSaveLessline,
    modal,
    handleCalculationStart,
    handleCalculationLessLine,
    handleCalculationStop,
    handleSaveStart,
    handleSaveLessLine,
    handleSaveStop,
    handleStartDatetimeChange,
    handleStopDatetimeChange,
    handleStopLesslineDatetimeChange,
    handleManualDipStartCal,
    handleManualDipStopLesslineCal,
    handleManualDipStopCal,
    setPurity,
    handleManualFormChange,
    handleConfirmSaveStart,
    handleConfirmSaveLessLine,
    handleConfirmSaveStop,
    closeModal,
  }

  return value
}
