import { useState } from 'react'
import { Button, CircularProgress, IconButton } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import { pdf, Document } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { useProfile } from '@features/profile/hooks/useProfile'
import { isAdmin } from '@lib/permissions'

import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'
import { CalculationDetailActionPropsType } from '@features/tracking-work/interfaces/CalculationDetailType'
import { PrintGauge } from '@features/pdf/pages/PrintGauge'
import { useAppClient } from '@lib/useAppClient'
import { getErrorMessage } from '@lib/utils'
import { useAlert } from '@lib/useAlert'
import { ConfirmModal } from '@components/ConfirmModal'

export function CalculationDetailAction({
  title,
  disabled,
  hideAction,
  deliveryId,
  tankCode,
  type,
  mode,
  modal,
  handleSave,
  handleConfirm,
  closeModal,
}: CalculationDetailActionPropsType) {
  const client = useAppClient()
  const { error } = useAlert()
  const [loading, setLoading] = useState(false)
  const { data:dataProfile } = useProfile()

  async function downloadPdf() {
    setLoading(true)
    const data = await client?.delivery
      .getPrintGauge({
        deliveryId,
        tankCode,
        type,
        mode,
      })
      .catch(e => {
        error({ message: getErrorMessage(e) })
      })

    if (data) {
      const fileName = `print-gauge-${DateTime.fromISO(data.time).toFormat(
        'dd-LL-yyyy HH:mm'
      )}`

      const blob = await pdf(
        <Document title={fileName}>
          <PrintGauge
            tankCode={tankCode}
            parameters={data?.parameters}
            workNumber={data?.workNumber || ''}
            companyName={data?.companyName || ''}
            time={data?.time || ''}
            product={data?.product || ''}
            batchPeriod={data?.batchPeriod || ''}
            activity={data?.activity || ''}
            fromTank={data?.fromTank || ''}
            toTank={data?.toTank || ''}
          />
        </Document>
      ).toBlob()

      const csvURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', `${fileName}.pdf`)
      tempLink.click()
    }

    setLoading(false)
  }

  return (
    <div className={styles.atgReportCalculationDetailAction}>
      {!hideAction && isAdmin(dataProfile?.roleGroup.reportRole || '') && (
        <Button
          type='button'
          variant='contained'
          className={styles.disabled}
          onClick={handleSave}
          disabled={disabled}
        >
          {title}
        </Button>
      )}
      <IconButton disabled={disabled || loading} onClick={downloadPdf}>
        {loading ? <CircularProgress size={16} /> : <Print />}
      </IconButton>
      <ConfirmModal
        open={modal === `${type}_${mode}`}
        isSubmitting={disabled}
        handleConfirm={handleConfirm}
        handleClose={closeModal}
      >
        <div>
          <p>
            <strong>
              Are you sure you want to <span>{title}</span> ?
            </strong>
          </p>
          <p className='error-message'>
            * Datetime is change or calculation is wrong!!!
          </p>
        </div>
      </ConfirmModal>
    </div>
  )
}
