import { createContext, useContext, useReducer } from 'react'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

const AlertContext = createContext<any>({})

function reducer(state: any, action: any): any {
  switch (action.type) {
    case 'error':
    case 'info':
    case 'success':
      return {
        ...state,
        open: true,
        type: action.type,
        message: action.message,
      }
    case 'close':
      return { ...state, open: false }
    default:
      return state
  }
}

export function AlertProvider({ children }: FunctionComponentType) {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    message: '',
    type: 'success',
  })

  function error({ message = '' }) {
    dispatch({ type: 'error', message })
  }

  function info({ message = '' }) {
    dispatch({ type: 'info', message })
  }

  function success({ message = '' }) {
    dispatch({ type: 'success', message })
  }

  function close() {
    dispatch({ type: 'close' })
  }

  const value = {
    open: state.open,
    message: state.message,
    type: state.type,
    error,
    info,
    success,
    handleClose: close,
  }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export function useAlert() {
  return useContext(AlertContext)
}
