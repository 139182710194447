import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import hoist from 'hoist-non-react-statics'
import cx from 'classnames'

import { TrackingWork } from '@features/tracking-work/interfaces/TrackingWorkType'
import { useTrackingWork } from '@features/tracking-work/hooks/useTrackingWork'
import { useTrackingWorkList } from '@features/tracking-work/hooks/useTrackingWorkList'
import { useModal } from '@features/tracking-work/hooks/useModal'
import styles from '@features/tracking-work/pages/TrackingWorkPage/index.module.scss'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useProductList } from '@features/product/hooks/useProductList'
import { Option } from '@interfaces/common/SelectType'
import { generateYearOptions, TRACKING_WORK } from '@lib/constants'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'
import { usePermissionGuard } from '@lib/permissions'
import { TrackingWorkProps } from '@features/tracking-work/interfaces/boardman/TrackingWorkType'

const initialColumns = [
  {
    Header: 'Work Request No',
    accessor: 'workNo',
    Cell: ({ record }: { record: TrackingWork }) => (
      <Link to={`/tracking-work/${record.id}/calculate`}>
        {record.workRequestID}
      </Link>
    ),
  },
  {
    Header: 'Tank No',
    accessor: 'tankNo',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Activity',
    accessor: 'activity',
  },
  {
    Header: 'Work Status',
    Cell: ({ record }: { record: TrackingWork }) => (
      <div
        className={cx({
          [styles.notStart]: record.workStatus === 'Not Start',
          [styles.onProcess]: record.workStatus === 'On Process',
          [styles.complete]: record.workStatus === 'Complete',
          [styles.reject]: record.status === 'reject',
        })}
      >
        {record.status ? 'Reject' : record.workStatus}
      </div>
    ),
  },
]

export function withTrackingWork(Component: React.FC<TrackingWorkProps>) {
  function WithTrackingWork() {
    const { success, error } = useAlert()
    const [selectedModify, setSelectedModify] = useState<number>()

    const { modal, message, openModal, closeModal } = useModal()
    const {
      data: trackingWorkList,
      isLoading: isTrackingWorkListLoading,
      queryParam,
      refetch: refetchTrackingWorkList,
      addWorkRequest,
      handleSearch,
      handlePaginationChange,
    } = useTrackingWorkList()
    const {
      data: trackingWork,
      isLoading: isLoadingTrackingWork,
      updateWorkRequest,
    } = useTrackingWork({
      id: selectedModify,
    })

    const { data: productList } = useProductList()
    const yearOptions: Option[] = generateYearOptions()
    const productOptions = productList.map(row => ({
      text: row.name,
      value: row.code,
    }))
    const editable = usePermissionGuard(TRACKING_WORK, 'write')

    const columns = editable
      ? [
          ...initialColumns,
          {
            Header: 'Modify Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.modifyWork}>
                <Button
                  onClick={() => handleOpenModifyWorkModal(record)}
                  disabled={record.verifyStatus === 'Approve'}
                >
                  Modify
                </Button>
              </div>
            ),
          },
        ]
      : initialColumns

    function handleOpenAddWorkModal() {
      openModal({ modalName: 'add-work' })
    }

    function handleOpenModifyWorkModal(value: TrackingWork) {
      openModal({ modalName: 'modify-work' })
      setSelectedModify(value.id)
    }

    async function handleAddWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await addWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        closeModal()
        success({ message: 'add work success' })
      }
    }

    async function handleModifyWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await updateWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetchTrackingWorkList()
        closeModal()
        success({ message: 'modify work success' })
      }
    }

    const pageProps = {
      trackingWorkList,
      productOptions,
      yearOptions,
      isTrackingWorkListLoading,
      modal,
      columns,
      modifyWork: {
        id: trackingWork?.id,
        workRequestCompany: trackingWork?.workRequestCompany || '',
        workRequestYear: trackingWork?.workRequestYear || '',
        product: trackingWork?.product || '',
        activity: trackingWork?.activity || '',
        fromCompany: trackingWork?.fromCompany || '',
        toCompany: trackingWork?.toCompany || '',
        fromTanks: trackingWork?.fromTanks || [],
        toTanks: trackingWork?.toTanks || [],
      },
      isLoadingTrackingWork,
      queryParam,
      handleSearch,
      handleCloseModal: closeModal,
      handleOpenAddWorkModal,
      handleAddWork,
      handleModifyWork,
      handlePaginationChange,
    }

    return <Component {...pageProps} />
  }

  hoist(WithTrackingWork, Component)

  WithTrackingWork.displayName = `WithTrackingWork(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithTrackingWork
}
