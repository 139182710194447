import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'
import { GetSummaryDailyReportProps } from '@features/report/interfaces/SummaryDailyReportType'

export function useSummaryDailyReportList({
  productCode,
  tankCode,
  startDate,
  endDate,
}: GetSummaryDailyReportProps) {
  const client = useAppClient()
  const { data, isLoading } = useQuery(
    ['report', 'summary-daily', tankCode, productCode, startDate, endDate],
    () =>
      client?.report
        .getSummaryDailyReports({
          productCode,
          tankCode,
          startDate,
          endDate,
        })
        .then(response => response)
  )

  return {
    data: data || [],
    isLoading,
  }
}
