import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

interface NoteProps {
  note: string
}

export function Note({ note }: NoteProps) {
  return (
    <div className={styles.atgNote}>
      <div className={styles.atgReportNoteHeader}>Note</div>
      <textarea className={styles.atgReportNoteDetail} value={note} disabled />
    </div>
  )
}
