import styles from '@features/draft-calculation/pages/ATGMode/index.module.scss'

interface SummaryDetailPropsType {
  title: string
  volume15C: string
  volume30C: string
  tonInAir: string
  tonInVac: string
}

export function SummaryDetail({
  title,
  volume15C,
  volume30C,
  tonInAir,
  tonInVac,
}: SummaryDetailPropsType) {
  return (
    <>
      <div className={styles.atgReportCalculationDetailLabel}>
        <strong>{title}</strong>
      </div>
      <div>{volume15C}</div>
      <div>Volume @ 15 &#8451; (Liters)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <div>{volume30C}</div>
      <div>Volume @ 30 &#8451; (Liters)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <div>{tonInAir}</div>
      <div>Metric ton (in air)</div>
      <div />

      <div className={styles.atgReportCalculationDetailLabel} />
      <div>{tonInVac}</div>
      <div>Metric ton (in vac)</div>
      <div />
    </>
  )
}
