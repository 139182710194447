import { INVENTORY_DAILY_REPORT } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { CalculateDailyReportPage } from './CalculateDailyReportPage'
import { withCalculateDailyReportPage } from './withCalculateDailyReportPage'

const ConnectedCalculateDailyReport = withPermissionGuard(
  withCalculateDailyReportPage(CalculateDailyReportPage),
  INVENTORY_DAILY_REPORT,
  'write'
)

export { ConnectedCalculateDailyReport as CalculateDailyReportPage }
