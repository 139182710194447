import { useAppClient } from '@lib/useAppClient'
import { useQuery } from 'react-query'

export function useVerifyDate() {
  const client = useAppClient()
  const { data, isLoading } = useQuery('verify-date', () =>
    client?.report.getVerifyDates().then(res => res)
  )

  return {
    data: data || [],
    isLoading,
  }
}
