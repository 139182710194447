import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  BatchDocumentTracking,
  SearchFormType,
} from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'
import { BatchDocumentReport } from '@features/batch-document-tracking/interfaces/BatchDocumentReportType'
import { getQueryParams } from '@lib/utils'

interface BatchDocumentTrackingListResponse {
  total: number
  page: number
  size: number
  items: BatchDocumentTracking[]
}

export class BatchDocumentTrackingClient {
  constructor(private client: AxiosInstance) {}

  async getBatchDocumentTrackingList(
    searchParams: SearchFormType
  ): Promise<BatchDocumentTrackingListResponse> {
    const query = getQueryParams({
      product: searchParams.product,
      all_tanks: searchParams.tank,
      activity: searchParams.activity,
      month: searchParams.month,
      year: searchParams.year,
      page: searchParams.page,
      size: searchParams.size,
    })
    const response = await this.client.get(
      `/api/batch_document_tracking?${query}`
    )

    return {
      ...response.data,
      items: response.data.items.map((row: any) =>
        plainToClass(BatchDocumentTracking, row, {
          excludeExtraneousValues: true,
        })
      ),
    }
  }

  async exportPdf(id: number) {
    const response = await this.client.get(`/api/batch_document_tracking/${id}`)

    return plainToClass(BatchDocumentReport, response.data, {
      excludeExtraneousValues: true,
    })
  }
}
