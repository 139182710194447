import { Expose, Type, Transform } from 'class-transformer'

export interface MeterReportPropsType {
  className?: string
}

export interface MeterReportType extends MeterReportPropsType {
  meterRunId: string
  summary: SummaryFormType
  hideAction: boolean
  handleSaveStart: () => void
  handleSaveLessLine: () => void
  handleSaveStop: () => void
}

export interface ActionFormPropsType {
  className: string
  title: string
  value: string
  disabled: boolean
  setDatetime: (value: string) => void
}

export interface CalculationFormPropsType {
  label: string
  name: string
}

export type MeterValueKey =
  | 'grossVolume'
  | 'netVolume'
  | 'massInAir'
  | 'massInVac'
export type MeterValueType = Record<MeterValueKey, string>

type MeterFormKey = 'start' | 'stopLessline' | 'stop'
export type MeterFormType = Record<MeterFormKey, MeterValueType>

export interface MeterFormPropsType {
  meterKey: string
  label: string
  disabled: boolean
  stopLesslineState: boolean
  stopState: boolean
  handleOnChange: (status:boolean) => void
}

export interface MeterDetailPropsType {
  header: string
  volume30: string
  volume15: string
  tonInAir: string
  tonInVac: string
}

class ReportValue {
  time: string

  @Expose({ name: 'gross_volume' })
  @Transform(({ value }) => String(value))
  grossVolume: string

  @Expose({ name: 'net_volume' })
  @Transform(({ value }) => String(value))
  netVolume: string

  @Expose({ name: 'mass_in_vac' })
  @Transform(({ value }) => String(value))
  massInVac: string

  @Expose({ name: 'mass_in_air' })
  @Transform(({ value }) => String(value))
  massInAir: string
}

class SummaryReport {
  @Expose({ name: 'ship_received_liters_15_c' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  shipVolume15: string

  @Expose({ name: 'ship_received_liters_30_c' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  shipVolume30: string

  @Expose({ name: 'ship_received_ton_in_air' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  shipTonInAir: string

  @Expose({ name: 'ship_received_ton_in_vac' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  shipTonInVac: string

  @Expose({ name: 'vessel_load_liters_15_c' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  vesselVolume15: string

  @Expose({ name: 'vessel_load_liters_30_c' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  vesselVolume30: string

  @Expose({ name: 'vessel_load_ton_in_air' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  vesselTonInAir: string

  @Expose({ name: 'vessel_load_ton_in_vac' })
  @Transform(({ value }) => (value !== null ? String(value) : ''))
  vesselTonInVac: string
}

class ReportType {
  @Type(() => ReportValue)
  start: ReportValue

  @Type(() => ReportValue)
  stop: ReportValue

  @Expose({ name: 'stop_less_line' })
  @Type(() => ReportValue)
  stopLessline: ReportValue

  @Expose({ name: 'summary_report' })
  @Type(() => SummaryReport)
  summaryReport: SummaryReport
}

export class MeterReport {
  @Expose({ name: 'meter_run_id' }) meterRunId: string

  @Expose({ name: 'tank_no' }) tankCode: string

  @Type(() => ReportType) report: ReportType

  @Expose({ name: 'start_state' }) startState: boolean

  @Expose({ name: 'stop_lessline_state' }) stopLesslineState: boolean

  @Expose({ name: 'stop_state' }) stopState: boolean
}

export type ValueKey = 'volume30' | 'volume15' | 'tonInAir' | 'tonInVac'
type SummaryFormKey =
  | 'quantityReceivedDelivery'
  | 'difference'
  | 'quantityLessline'
  | 'finalSummary'

type SummaryValueType = Record<ValueKey, number>
export type SummaryFormType = Record<SummaryFormKey, SummaryValueType>

export type VesselLoadFormType = Record<ValueKey, string>

export interface VesselLoadType {
  summary: SummaryFormType
  disabled: boolean
  hasLessline: boolean
}

export interface ShipFormType {
  volume30: string
  volume15: string
  tonInAir: string
  tonInVac: string
}

export interface ChecklistForm {
  summary: SummaryFormType
  ship: ShipFormType
  vessel: VesselLoadFormType
}
