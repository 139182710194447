import { createContext, useContext } from 'react'

import { Client } from './clients/Client'

interface AppClientProviderType {
  children: React.ReactNode
  client: Client
}

const AppClientContext = createContext<Partial<{ client: Client }>>({})

export function AppClientProvider({ children, client }: AppClientProviderType) {
  const value = {
    client,
  }

  return (
    <AppClientContext.Provider value={value}>
      {children}
    </AppClientContext.Provider>
  )
}

export function useAppClient() {
  return useContext(AppClientContext).client
}
