import { DAILY_REPORT_CONFIG_SETTING } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'

import { ConfigDailyReportPage } from './ConfigDailyReportPage'
import { withConfigDailyReportPage } from './withConfigDailyReportPage'

const ConnectedConfigDailyReportPage = withPermissionGuard(
  withConfigDailyReportPage(ConfigDailyReportPage),
  DAILY_REPORT_CONFIG_SETTING,
  'write'
)

export { ConnectedConfigDailyReportPage as ConfigDailyReportPage }
