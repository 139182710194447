import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import hoist from 'hoist-non-react-statics'

import { TrackingWork } from '@features/tracking-work/interfaces/TrackingWorkType'
import { DraftCalculationPageComponent } from '@features/draft-calculation/interfaces/DraftType'
import { useDraftList } from '@features/draft-calculation/hooks/useDraftList'
import { useModal } from '@features/tracking-work/hooks/useModal'
import { WorkOrderRequestNumberFormType } from '@features/tracking-work/components/WorkOrderRequestNumberForm'
import { useProductList } from '@features/product/hooks/useProductList'
import { Option } from '@interfaces/common/SelectType'
import {
  blankFormProducts,
  DRAFT_CALCULATION_PAGE,
  generateYearOptions,
} from '@lib/constants'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'
import { usePermissionGuard } from '@lib/permissions'
import { useAppClient } from '@lib/useAppClient'

import styles from './index.module.scss'

const initialColumns = [
  {
    Header: 'Work Request No',
    accessor: 'workNo',
    Cell: ({ record }: { record: TrackingWork }) => (
      <Link to={`/draft/${record.id}/calculate`}>{record.workRequestID}</Link>
    ),
  },
  {
    Header: 'Tank No',
    accessor: 'tankNo',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Activity',
    accessor: 'activity',
  },
]

export function withDraftCalculationPage(
  Component: React.FC<DraftCalculationPageComponent>
) {
  function WithDraftCalculationPage() {
    const client = useAppClient()
    const { success, error } = useAlert()
    const [modifyData, setModifyData] = useState<TrackingWork>()
    const [loadingDraft, setLoadingDraft] = useState<boolean>(false)

    const { modal, openModal, closeModal } = useModal()
    const {
      data: draftList,
      isLoading,
      refetch,
      queryParam,
      handleSearch,
      handlePaginationChange,
      addWorkRequest,
      updateWorkRequest,
    } = useDraftList()

    const { data: productList } = useProductList()
    const yearOptions: Option[] = generateYearOptions()
    const productOptions = productList
      .filter(row => !blankFormProducts.includes(row.code))
      .map(row => ({
        text: row.name,
        value: row.code,
      }))
    const editable = usePermissionGuard(DRAFT_CALCULATION_PAGE, 'write')

    const columns = editable
      ? [
          ...initialColumns,
          {
            Header: 'Modify Work',
            Cell: ({ record }: { record: TrackingWork }) => (
              <div className={styles.modifyWork}>
                <Button
                  onClick={() => handleOpenModifyWorkModal(record)}
                  disabled={record.verifyStatus === 'Approve'}
                >
                  Modify
                </Button>
              </div>
            ),
          },
        ]
      : initialColumns

    function handleOpenAddWorkModal() {
      openModal({ modalName: 'add-draft' })
    }

    async function handleOpenModifyWorkModal(value: TrackingWork) {
      setLoadingDraft(true)
      openModal({ modalName: 'modify-draft' })
      const response = await client?.draft.getDraft({ id: value.id })
      setModifyData(response)
      setLoadingDraft(false)
    }

    async function handleAddWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await addWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetch()
        closeModal()
        success({ message: 'Add draft successful.' })
      }
    }

    async function handleModifyWork(form: WorkOrderRequestNumberFormType) {
      let isError = false

      await updateWorkRequest(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) {
        refetch()
        closeModal()
        success({ message: 'Modify draft successful.' })
      }
    }

    const pageProps = {
      draftList,
      productOptions,
      yearOptions,
      loading: isLoading,
      modal,
      columns,
      modifyWork: {
        id: modifyData?.id,
        workRequestCompany: modifyData?.workRequestCompany || '',
        workRequestYear: modifyData?.workRequestYear || '',
        product: modifyData?.product || '',
        activity: modifyData?.activity || '',
        fromCompany: modifyData?.fromCompany || '',
        toCompany: modifyData?.toCompany || '',
        fromTanks: modifyData?.fromTanks || [],
        toTanks: modifyData?.toTanks || [],
      },
      loadingDraft,
      queryParam,
      handleSearch,
      handleCloseModal: closeModal,
      handleOpenAddWorkModal,
      handleOpenModifyWorkModal,
      handleAddWork,
      handleModifyWork,
      handlePaginationChange,
    }

    return <Component {...pageProps} />
  }

  hoist(WithDraftCalculationPage, Component)

  WithDraftCalculationPage.displayName = `WithDraftCalculationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithDraftCalculationPage
}
