import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    padding: '10px 14px',
    fontFamily: 'Roboto',
    fontSize: 8,
  },
  strong: {
    fontWeight: 500,
  },
  center: {
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 30,
    marginBottom: 10,
  },
  imageWrap: {
    width: 90,
    position: 'absolute',
    left: 0,
  },
  title: {
    flex: 1,
    fontWeight: 700,
    fontSize: 12,
    textAlign: 'center',
  },
  informationGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '50%',
  },
  informationLabel: {
    fontWeight: 500,
  },
  header: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: 10,
  },
  tableContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  meteringTopic: {
    flex: 1,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    padding: 4,
  },
  meteringDetail: {
    width: '33%',
    padding: 4,
  },
  comparisonTopic: {
    width: '21%',
    padding: 4,
  },
  comparisonDetail: {
    flex: 1,
    padding: 4,
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  headTable: {
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 500,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    marginLeft: 5,
    textAlign: 'left',
  },
  footerWrap: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 80,
  },
  signContainer: {
    width: 150,
    marginLeft: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signatureImage: {
    width: 100,
  },
  sign: {
    width: '100%',
    borderTopWidth: 1,
    padding: 10,
    textAlign: 'center',
  },
})
