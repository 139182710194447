import hoist from 'hoist-non-react-statics'

import { BlankFormProps } from '@features/tracking-work/interfaces/BlankFormType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

export function withBlankForm(Component: React.FC<BlankFormProps>) {
  function WithBlankForm() {
    const {
      blankFormMethods,
      isAtgSubmitting,
      isSubmitting,
      meterSummary,
      hideAction,
      atgRetrieveLastSave,
      data,
    } = useDeliveryReport()

    const pageProps = {
      note: data.note || '',
      hideAction,
      methods: blankFormMethods,
      meterSummary,
      isAtgSubmitting,
      isSubmitting,
      atgRetrieveLastSave,
    }

    return <Component {...pageProps} />
  }

  hoist(WithBlankForm, Component)

  WithBlankForm.displayName = `WithBlankForm(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithBlankForm
}
