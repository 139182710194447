import { Outlet, useLocation } from 'react-router-dom'

import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { Loading } from '@components/Loading'
import { CalculationInformation } from '@features/tracking-work/components/CalculationInformation'
import { ChecklistGroup } from '@features/tracking-work/components/ChecklistGroup'
import { CompletePageProps } from '@features/tracking-work/interfaces/CompleteType'

export function CompletePage({
  data,
  loading,
  capacity,
  hideAction,
  handleSaveAtg,
  handleSaveMeter,
}: CompletePageProps) {
  const { pathname } = useLocation()
  const isMeterMode = pathname.includes('meter')

  return (
    <Container>
      <Breadcrumbs />

      {loading && !data ? (
        <Loading />
      ) : (
        <>
          <CalculationInformation
            workRequestNo={data?.workRequestNo || '-'}
            product={data?.product || '-'}
            categories={data?.activity || '-'}
            portOfLoading={data?.portOfLoading || '-'}
            fromCompany={data?.fromCompany || '-'}
            fromTank={data?.fromTanks.join(',') || '-'}
            toCompany={data?.toCompany || '-'}
            toTank={data?.toTanks.join(',') || '-'}
            capacity={capacity}
          />

          <ChecklistGroup
            atgChecklist={data?.atgChecklist || []}
            meterChecklist={data?.meterChecklist || []}
            isMeterMode={isMeterMode}
            hideAction={hideAction}
            handleSaveAtg={handleSaveAtg}
            handleSaveMeter={handleSaveMeter}
          />
          <Outlet />
        </>
      )}
    </Container>
  )
}
