import hoist from 'hoist-non-react-statics'
import { useNavigate } from 'react-router-dom'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import {
  CompanyFormProps,
  CompanyFormType,
} from '@features/company/interfaces/CompanyType'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withCompanyCreatePage(Component: React.FC<CompanyFormProps>) {
  function WithCompanyCreatePage(props: FunctionComponentType) {
    const { error } = useAlert()
    const client = useAppClient()
    const navigate = useNavigate()

    async function onSubmit(form: CompanyFormType) {
      let isError = false

      await client?.company.createCompany(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) navigate('/company')
    }

    const pageProps = {
      initialValues: {
        code: '',
        name: '',
        description: '',
        phoneNumber: '',
        active: 'true',
      },
      onSubmit,
      ...props,
    }

    return <Component {...pageProps} />
  }

  hoist(WithCompanyCreatePage, Component)

  WithCompanyCreatePage.displayName = `WithCompanyCreatePage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithCompanyCreatePage
}
