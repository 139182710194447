import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'
import { useForm, useWatch } from 'react-hook-form'

import {
  SearchFormComponentProps,
  SearchFormProps,
  SearchFormType,
} from '@features/batch-document-tracking/interfaces/BatchDocumentTrackingType'
import { useProductListByCompany } from '@features/product/hooks/useProductListByCompany'
import { useTankListByCompany } from '@features/tank/hooks/useTankListByCompany'
import { generateYearOptions, monthOptions } from '@lib/constants'
import { useActivityList } from '@features/activity/hooks/useActivityList'
import { API_URL } from '@lib/config'
import { useAppClient } from '@lib/useAppClient'
import { getQueryParams } from '@lib/utils'

export function withSearchForm(Component: React.FC<SearchFormComponentProps>) {
  function WithSearchForm({ initialSearchForm, onSearch }: SearchFormProps) {
    const client = useAppClient()
    const methods = useForm({
      defaultValues: initialSearchForm,
    })

    const productCode = useWatch({
      control: methods.control,
      name: 'product',
    })

    const { data: productList } = useProductListByCompany()
    const { data: tankProductList } = useTankListByCompany()
    const { data: activityList } = useActivityList({
      productCode: productCode !== 'all' ? productCode : '',
    })

    const products = [
      {
        text: 'All Product',
        value: 'all',
      },
      ...productList.map(v => ({
        text: v.name,
        value: v.code,
      })),
    ]

    const tanks = [
      {
        text: 'All Tank',
        value: 'all',
      },
      ...tankProductList.map(v => ({
        text: v.name,
        value: v.code,
      })),
    ]

    const activities = [
      {
        text: 'All Activity',
        value: 'all',
      },
      ...activityList.map(row => ({
        text: row.description,
        value: row.code,
      })),
    ]

    const months = monthOptions
    const years = generateYearOptions()

    function handleSearch(form: SearchFormType) {
      onSearch(form)
    }

    function handleExportExcel() {
      const form = methods.getValues()
      const queries = getQueryParams({
        token: client?.auth.accessToken,
        product: form.product === 'all' ? '' : form.product,
        all_tanks: form.tank === 'all' ? '' : form.tank,
        activity: form.activity === 'all' ? '' : form.activity,
        month: form.month === 'all' ? '' : form.month,
        year: form.year,
      })

      window.open(
        `${API_URL}/api/batch_document_tracking/delivery_report/export?${queries}`
      )
    }

    const componentProps = {
      methods,
      products,
      tanks,
      activities,
      months,
      years,
      productCode,
      handleSearch,
      handleExportExcel,
    }

    useEffect(() => {
      const form = methods.getValues()

      methods.reset({
        activity: 'all',
        page: 1,
        size: 10,
        tank: form.tank,
        year: form.year,
        month: form.month,
        product: productCode,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])

    useEffect(() => {
      methods.reset(initialSearchForm)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSearchForm])

    return <Component {...componentProps} />
  }

  hoist(WithSearchForm, Component)

  WithSearchForm.displayName = `WithSearchForm(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithSearchForm
}
