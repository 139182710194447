import { Breadcrumbs } from '@components/Breadcrumbs'
import { Container } from '@components/Container'
import { UserForm } from '@features/user/components/UserForm'
import { UserFormProps } from '@features/user/interfaces/UserType'

export function UserCreatePage({ initialValues, handleSubmit }: UserFormProps) {
  return (
    <Container>
      <Breadcrumbs />
      <UserForm initialValues={initialValues} handleSubmit={handleSubmit} />
    </Container>
  )
}
