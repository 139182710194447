import hoist from 'hoist-non-react-statics'
import { useParams, useNavigate } from 'react-router-dom'

import { useAppClient } from '@lib/useAppClient'
import { useUser } from '@features/user/hooks/useUser'
import { UserFormType, UserFormProps } from '@features/user/interfaces/UserType'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withUserEditPage(Component: React.FC<UserFormProps>) {
  function WithUserEditPage() {
    const { error } = useAlert()
    const client = useAppClient()
    const navigate = useNavigate()
    const { id } = useParams()
    const { data: user, isLoading } = useUser({ id: id || '' })

    async function handleSubmit(form: UserFormType) {
      let isError = false

      await client?.user.editUser(form).catch(e => {
        isError = true
        error({ message: getErrorMessage(e) })
      })

      if (!isError) navigate('/user')
    }

    const pageProps = {
      initialValues: {
        id,
        companyId: String(user?.company?.id),
        roleGroupId: String(user?.roleGroup?.id),
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        username: user?.username || '',
        password: '',
        confirmPassword: '',
        active: String(!!user?.active),
      },
      loading: isLoading,
      handleSubmit,
    }

    return <Component {...pageProps} />
  }

  hoist(WithUserEditPage, Component)

  WithUserEditPage.displayName = `WithUserEditPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithUserEditPage
}
