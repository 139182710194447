import { Dialog, DialogContent, Button } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { VerifyingFormType } from '@features/tracking-work/interfaces/VerifyingType'
import { SelectField } from '@components/fieldV2/SelectField'
import { TextAreaField } from '@components/fieldV2/TextAreaField'
import { generateYearOptions } from '@lib/constants'
import styles from './index.module.scss'

interface VerifyingModalProps {
  open: boolean
  disableShipmentNo: boolean
  portOfLoading: string
  note: string
  checkSaveStart: boolean
  checkSaveLessline: boolean
  checkSaveStop: boolean
  handleConfirm: (form: VerifyingFormType) => void
  handleClose: () => void
}

const monthOptions = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  text: `${i + 1}`,
}))

const portOfLoadingOptions = [
  {
    value: '-',
    text: '-',
  },
  {
    value: 'Jetty No.1',
    text: 'Jetty No.1',
  },
  {
    value: 'Jetty No.2',
    text: 'Jetty No.2',
  },
  {
    value: 'Jetty No.3',
    text: 'Jetty No.3',
  },
  {
    value: 'Jetty No.4',
    text: 'Jetty No.4',
  },
]

export function VerifyingModal({
  open,
  disableShipmentNo,
  portOfLoading,
  note,
  checkSaveStart,
  checkSaveLessline,
  checkSaveStop,
  handleConfirm,
  handleClose,
}: VerifyingModalProps) {
  const schema = yup.object().shape({
    ...(!disableShipmentNo && {
      month: yup
        .number()
        .typeError('This field is required.')
        .required('This field is required.'),
      year: yup
        .number()
        .typeError('This field is required.')
        .required('This field is required.'),
    }),
    portOfLoading: yup.string().required('This field is required.'),
    note: yup.string().max(50),
  })

  const methods = useForm<VerifyingFormType>({
    mode: 'all',
    defaultValues: {
      ...(!disableShipmentNo && {
        year: DateTime.now().toFormat('yyyy'),
        month: DateTime.now().toFormat('L'),
      }),
      portOfLoading,
      note,
    },
    resolver: yupResolver(schema),
  })

  const { errors } = methods.formState

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleConfirm)}>
            <div className={styles.header}>
              {disableShipmentNo ? 'Verifyng' : 'Confirm Generate Shipment No'}
            </div>
            <div className={styles.inputGroup}>
              {!disableShipmentNo && (
                <SelectField
                  name='month'
                  label='Month'
                  placeholder='select month'
                  options={monthOptions}
                  helperText={errors.month?.message}
                />
              )}
              {!disableShipmentNo && (
                <SelectField
                  name='year'
                  label='Year'
                  placeholder='select year'
                  options={generateYearOptions()}
                  helperText={errors.year?.message}
                />
              )}
              <SelectField
                name='portOfLoading'
                label='Port of loading'
                placeholder='select port of loading'
                options={portOfLoadingOptions}
                helperText={errors.portOfLoading?.message}
              />
            </div>

            <TextAreaField className={styles.note} name='note' label='Note' />
            {checkSaveStart ? (
              <div className='error-message'>* Start(ATG) ยังไม่ได้บันทึก</div>
            ) : null}
            {checkSaveStop ? (
              <div className='error-message'>* Stop(ATG) ยังไม่ได้บันทึก</div>
            ) : null}
            {checkSaveLessline ? (
              <div className='error-message'>
                * Stop(Less-line) ยังไม่ได้บันทึก
              </div>
            ) : null}
            <Button
              className='mt'
              type='submit'
              color='primary'
              variant='contained'
            >
              Confirm
            </Button>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
