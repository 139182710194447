import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  ChecklistForm,
  MeterReport,
  MeterValueType,
  ValueKey,
} from '@features/tracking-work/interfaces/MeterReportType'
import { CalculateMode, CalculateType } from './ATGReportClient'

interface GetMeterReport {
  deliveryId: string
  tankCode: string
}

interface UpdateMeterReport extends GetMeterReport {
  form: MeterValueType
  type: CalculateType
  mode: CalculateMode
  datetime: string
}

interface UpdateMeterChecklist extends GetMeterReport {
  form: ChecklistForm
}

export class MeterReportClient {
  constructor(private client: AxiosInstance) {}

  async getMeterReport({
    deliveryId,
    tankCode,
  }: GetMeterReport): Promise<MeterReport> {
    if (!deliveryId || !tankCode) return plainToClass(MeterReport, {})

    const response = await this.client.get(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/meter_reports`
    )

    return plainToClass(MeterReport, response.data)
  }

  async updateMeterReport({
    deliveryId,
    tankCode,
    form,
    type,
    mode,
    datetime,
  }: UpdateMeterReport): Promise<void> {
    await this.client.patch(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/meter_reports?type=${type}&mode=${mode}`,
      {
        time: datetime,
        gross_volume: form.grossVolume,
        net_volume: form.netVolume,
        mass_in_vac: form.massInVac,
        mass_in_air: form.massInAir,
      }
    )
  }

  async updateMeterChecklist({
    deliveryId,
    tankCode,
    form,
  }: UpdateMeterChecklist): Promise<void> {
    const {
      vessel,
      ship,
      summary: { quantityReceivedDelivery, quantityLessline, finalSummary },
    } = form

    const isVesselEmpty = Object.keys(vessel).every(
      key => vessel[key as ValueKey] === ''
    )
    const isShipEmpty = Object.keys(ship).every(
      key => ship[key as ValueKey] === ''
    )

    await this.client.patch(
      `/api/reports/delivery/${deliveryId}/tanks/${tankCode}/meter_checklists`,
      {
        ...(!isVesselEmpty && {
          vessel_load_ton_in_air: Number(vessel.tonInAir),
          vessel_load_ton_in_vac: Number(vessel.tonInVac),
          vessel_load_liters_15_c: Number(vessel.volume15),
          vessel_load_liters_30_c: Number(vessel.volume30),
        }),
        ...(!isShipEmpty && {
          ship_received_ton_in_air: Number(ship.tonInAir),
          ship_received_ton_in_vac: Number(ship.tonInVac),
          ship_received_liters_15_c: Number(ship.volume15),
          ship_received_liters_30_c: Number(ship.volume30),
        }),
        measurment_volume_30_c: quantityReceivedDelivery.volume30,
        measurment_volume_15_c: quantityReceivedDelivery.volume15,
        measurment_metric_tons_in_air: quantityReceivedDelivery.tonInAir,
        measurment_metric_tons_in_vac: quantityReceivedDelivery.tonInVac,
        less_line_volume_30_c: quantityLessline.volume30,
        less_line_volume_15_c: quantityLessline.volume15,
        less_line_metric_tons_in_air: quantityLessline.tonInAir,
        less_line_metric_tons_in_vac: quantityLessline.tonInVac,
        final_volume_30_c: finalSummary.volume30,
        final_volume_15_c: finalSummary.volume15,
        final_metric_tons_in_air: finalSummary.tonInAir,
        final_metric_tons_in_vac: finalSummary.tonInVac,
      }
    )
  }
}
