import { withPermissionGuard } from '@lib/permissions'
import { ROLE_GROUP_SETTING } from '@lib/constants'

import { RoleGroupEditPage } from './RoleGroupEditPage'
import { withRoleGroupEditPage } from './withRoleGroupEditPage'

const ConnectedRoleGroupEditPage = withPermissionGuard(
  withRoleGroupEditPage(RoleGroupEditPage),
  ROLE_GROUP_SETTING,
  'write'
)
export { ConnectedRoleGroupEditPage as RoleGroupEditPage }
