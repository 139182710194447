import { CompanyType } from '@features/company/interfaces/CompanyType'
import { RoleGroupType } from '@features/role-group/interfaces/RoleGroupType'
import { Expose, Type } from 'class-transformer'

export class Profile {
  @Expose() id: number

  @Expose({ name: 'first_name' }) firstName: string

  @Expose({ name: 'last_name' }) lastName: string

  @Expose() username: string

  @Expose({ name: 'is_active' }) active: boolean

  @Expose() company: CompanyType

  @Expose({ name: 'role_group' })
  @Type(() => RoleGroupType)
  roleGroup: RoleGroupType

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}
