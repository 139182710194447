import { Outlet } from 'react-router-dom'

import { UserPage } from './pages/UserPage'
import { UserCreatePage } from './pages/UserCreatePage'
import { UserEditPage } from './pages/UserEditPage'

export const userRoutes = [
  {
    path: 'user',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <UserPage />,
      },
      {
        path: 'create',
        element: <UserCreatePage />,
      },
      {
        path: ':id/edit',
        element: <UserEditPage />,
      },
    ],
  },
]
