import { Page, View, Text } from '@react-pdf/renderer'

import { MonthlyProps } from '@features/pdf/interfaces/MonthlyType'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

import styles from './styles'
import { Summary } from './components/Summary'
import { CategoryGroup } from './components/CategoryGroup'
import { Note } from './components/Note'
import { Header } from './components/Header'

interface BoxProps extends FunctionComponentType {
  style?: any
}

function Box({ children, style = [] }: BoxProps) {
  return (
    <View
      style={[
        styles.w50,
        styles.borderT,
        styles.borderB,
        styles.borderL,
        styles.borderR,
        ...style,
      ]}
    >
      {children}
    </View>
  )
}

export function Gas({
  product,
  periodFrom,
  periodTo,
  date,
  stockLastMonth1,
  totalInputThisMonth1,
  totalOutputThisMonth1,
  stockThisMonth1,
  stockLastMonth2,
  totalInputThisMonth2,
  totalOutputThisMonth2,
  stockThisMonth2,
  stockLastMonth3,
  stockThisMonth3,
  stockLastMonth4,
  stockThisMonth4,
  lossGainByMass,
  lossGainTotalThroughputByMass,
  totalThroughputThisMonthByMass,
  totalThroughputByMass,
  lossGainByVolume,
  lossGainTotalThroughputByVolume,
  totalThroughputThisMonthByVolume,
  totalThroughputByVolume,
  diThroughputOfThisMonth1,
  diThroughputOfThisMonth2,
  diThroughputYearToDate1,
  diThroughputYearToDate2,
  deThroughputOfThisMonth1,
  deThroughputOfThisMonth2,
  deThroughputYearToDate1,
  deThroughputYearToDate2,
  imThroughputOfThisMonth1,
  imThroughputOfThisMonth2,
  imThroughputYearToDate1,
  imThroughputYearToDate2,
  exThroughputOfThisMonth1,
  exThroughputOfThisMonth2,
  exThroughputYearToDate1,
  exThroughputYearToDate2,
  soThroughputOfThisMonth1,
  soThroughputOfThisMonth2,
  soThroughputYearToDate1,
  soThroughputYearToDate2,
  reThroughputOfThisMonth1,
  reThroughputOfThisMonth2,
  reThroughputYearToDate1,
  reThroughputYearToDate2,
  flThroughputOfThisMonth1,
  flThroughputOfThisMonth2,
  flThroughputYearToDate1,
  flThroughputYearToDate2,
  itInThroughputOfThisMonth1,
  itInThroughputOfThisMonth2,
  itInThroughputYearToDate1,
  itInThroughputYearToDate2,
  itOutThroughputOfThisMonth1,
  itOutThroughputOfThisMonth2,
  itOutThroughputYearToDate1,
  itOutThroughputYearToDate2,
}: MonthlyProps) {
  return (
    <Page size='A4' style={styles.page} orientation='landscape'>
      <Header product={product} periodFrom={periodFrom} periodTo={periodTo} />

      <View style={[styles.dFlex, styles.flexRow, styles.mt]}>
        <View style={[styles.w30]} />
        <View style={styles.detailColumn2}>
          <View
            style={[
              styles.detailRow,
              styles.pl2,
              styles.pr2,
              styles.h20,
              styles.justifyCenter,
            ]}
          >
            <Text>Accounting stock</Text>
          </View>
          <View style={[styles.dFlex, styles.flexRow]}>
            <View style={[styles.w50, styles.textCenter]}>
              <Text>Quantity</Text>
              <Text>Metric Tons (in vac)</Text>
            </View>
            <View style={[styles.w50, styles.textCenter]}>
              <Text>Quantity</Text>
              <Text>Metric Tons (in air)</Text>
            </View>
          </View>
        </View>
        <View style={styles.detailColumn2}>
          <View
            style={[
              styles.detailRow,
              styles.pl2,
              styles.pr2,
              styles.h20,
              styles.justifyCenter,
            ]}
          >
            <Text>Actual stock*</Text>
          </View>
          <View style={[styles.dFlex, styles.flexRow]}>
            <View style={[styles.w50, styles.textCenter]}>
              <Text>Quantity</Text>
              <Text>Metric Tons (in vac)</Text>
            </View>
            <View style={[styles.w50, styles.textCenter]}>
              <Text>Quantity</Text>
              <Text>Metric Tons (in air)</Text>
            </View>
          </View>
        </View>
        <View style={styles.detailColumn1} />
      </View>

      <View style={[styles.dFlex, styles.flexRow]}>
        <View style={[styles.w30]}>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Stock at the end of last month</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total input in this month</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total output in this month</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Stock at the end of this month</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Loss(-)/gain(+) by mass (year to date)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>(Loss or Gain) / Total throughput (year to date)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total throughput of this month by mass</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total throughput (year to date)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Loss(-)/gain(+) by volume (year to date)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>(Loss or Gain) / Total throughput (year to date)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total throughput of this month by volume</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Total through (year to date)</Text>
          </View>
        </View>

        <View style={[styles.detailColumn2, styles.textRight, styles.mr]}>
          <View style={[styles.dFlex, styles.flexRow]}>
            <Box style={[styles.mr]}>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockLastMonth1}</Text>
              </View>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{totalInputThisMonth1}</Text>
              </View>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{totalOutputThisMonth1}</Text>
              </View>
              <View
                style={[
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockThisMonth1}</Text>
              </View>
            </Box>
            <Box style={[styles.ml]}>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockLastMonth2}</Text>
              </View>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{totalInputThisMonth2}</Text>
              </View>
              <View
                style={[
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{totalOutputThisMonth2}</Text>
              </View>
              <View
                style={[
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockThisMonth2}</Text>
              </View>
            </Box>
          </View>
        </View>

        <View style={[styles.detailColumn2, styles.textRight, styles.ml]}>
          <View style={[styles.dFlex, styles.flexRow]}>
            <View style={[styles.w50, styles.mr, styles.borderT]}>
              <View
                style={[
                  styles.borderB,
                  styles.borderL,
                  styles.borderR,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockLastMonth3}</Text>
              </View>
              <View style={[styles.h20, styles.detailRow]} />
              <View style={[styles.h20, styles.detailRow]} />
              <View
                style={[
                  styles.borderT,
                  styles.borderL,
                  styles.borderR,
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockThisMonth3}</Text>
              </View>
            </View>
            <View style={[styles.w50, styles.ml, styles.borderT]}>
              <View
                style={[
                  styles.borderB,
                  styles.borderL,
                  styles.borderR,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockLastMonth4}</Text>
              </View>
              <View style={[styles.h20, styles.detailRow]} />
              <View style={[styles.h20, styles.detailRow]} />
              <View
                style={[
                  styles.borderT,
                  styles.borderL,
                  styles.borderR,
                  styles.borderB,
                  styles.pl2,
                  styles.pr2,
                  styles.h20,
                  styles.detailRow,
                  styles.justifyEnd,
                ]}
              >
                <Text>{stockThisMonth4}</Text>
              </View>

              <View
                style={[
                  styles.mt,
                  styles.borderT,
                  styles.borderB,
                  styles.borderL,
                  styles.borderR,
                ]}
              >
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{lossGainByMass}</Text>
                </View>
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{lossGainTotalThroughputByMass}</Text>
                </View>
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{totalThroughputThisMonthByMass}</Text>
                </View>
                <View
                  style={[
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{totalThroughputByMass}</Text>
                </View>
              </View>

              <View
                style={[
                  styles.mt,
                  styles.borderT,
                  styles.borderB,
                  styles.borderL,
                  styles.borderR,
                ]}
              >
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{lossGainByVolume}</Text>
                </View>
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{lossGainTotalThroughputByVolume}</Text>
                </View>
                <View
                  style={[
                    styles.borderB,
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{totalThroughputThisMonthByVolume}</Text>
                </View>
                <View
                  style={[
                    styles.pl2,
                    styles.pr2,
                    styles.h20,
                    styles.detailRow,
                    styles.justifyEnd,
                  ]}
                >
                  <Text>{totalThroughputByVolume}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.detailColumn1]}>
          <View
            style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}
          />
          <View
            style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}
          />
          <View
            style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}
          />
          <View
            style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}
          />
          <View
            style={[
              styles.detailRow,
              styles.pl2,
              styles.pr2,
              styles.h20,

              { marginTop: 2 },
            ]}
          >
            <Text>Metric Tons (in air)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>(%)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Metric Tons (in air)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Metric Tons (in air)</Text>
          </View>
          <View
            style={[
              styles.detailRow,
              styles.pl2,
              styles.pr2,
              styles.h20,

              { marginTop: 3 },
            ]}
          >
            <Text>Metric Tons (in vac)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>(%)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Metric Tons (in vac)</Text>
          </View>
          <View style={[styles.detailRow, styles.pl2, styles.pr2, styles.h20]}>
            <Text>Metric Tons (in vac)</Text>
          </View>
        </View>
      </View>

      <Note />
      <Summary
        date={date}
        categoryTitle='Metric Tons (in vac)'
        diThroughputOfThisMonth1={diThroughputOfThisMonth1}
        diThroughputOfThisMonth2={diThroughputOfThisMonth2}
        diThroughputYearToDate1={diThroughputYearToDate1}
        diThroughputYearToDate2={diThroughputYearToDate2}
        deThroughputOfThisMonth1={deThroughputOfThisMonth1}
        deThroughputOfThisMonth2={deThroughputOfThisMonth2}
        deThroughputYearToDate1={deThroughputYearToDate1}
        deThroughputYearToDate2={deThroughputYearToDate2}
        imThroughputOfThisMonth1={imThroughputOfThisMonth1}
        imThroughputOfThisMonth2={imThroughputOfThisMonth2}
        imThroughputYearToDate1={imThroughputYearToDate1}
        imThroughputYearToDate2={imThroughputYearToDate2}
        exThroughputOfThisMonth1={exThroughputOfThisMonth1}
        exThroughputOfThisMonth2={exThroughputOfThisMonth2}
        exThroughputYearToDate1={exThroughputYearToDate1}
        exThroughputYearToDate2={exThroughputYearToDate2}
        soThroughputOfThisMonth1={soThroughputOfThisMonth1}
        soThroughputOfThisMonth2={soThroughputOfThisMonth2}
        soThroughputYearToDate1={soThroughputYearToDate1}
        soThroughputYearToDate2={soThroughputYearToDate2}
        reThroughputOfThisMonth1={reThroughputOfThisMonth1}
        reThroughputOfThisMonth2={reThroughputOfThisMonth2}
        reThroughputYearToDate1={reThroughputYearToDate1}
        reThroughputYearToDate2={reThroughputYearToDate2}
        flThroughputOfThisMonth1={flThroughputOfThisMonth1}
        flThroughputOfThisMonth2={flThroughputOfThisMonth2}
        flThroughputYearToDate1={flThroughputYearToDate1}
        flThroughputYearToDate2={flThroughputYearToDate2}
        itInThroughputOfThisMonth1={itInThroughputOfThisMonth1}
        itInThroughputOfThisMonth2={itInThroughputOfThisMonth2}
        itInThroughputYearToDate1={itInThroughputYearToDate1}
        itInThroughputYearToDate2={itInThroughputYearToDate2}
        itOutThroughputOfThisMonth1={itOutThroughputOfThisMonth1}
        itOutThroughputOfThisMonth2={itOutThroughputOfThisMonth2}
        itOutThroughputYearToDate1={itOutThroughputYearToDate1}
        itOutThroughputYearToDate2={itOutThroughputYearToDate2}
        position='Operation Planning Team Leader'
      />
      <CategoryGroup />
    </Page>
  )
}
