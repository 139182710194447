import hoist from 'hoist-non-react-statics'

import { useProfile } from '@features/profile/hooks/useProfile'

import { MonthlyReportPageProps } from '@features/monthly-report/interfaces/MonthlyReportType'
import { isBoardman, isAdmin } from '@lib/permissions'

export function withMonthlyReportPage(
  Component: React.FC<MonthlyReportPageProps>
) {
  function WithMonthlyReportPage() {
    const { data } = useProfile()
    const role = data?.roleGroup.reportRole || ''
    const roleAdmin = isAdmin(role) || isBoardman(role)

    return <Component isAdmin={roleAdmin} />
  }

  hoist(WithMonthlyReportPage, Component)

  WithMonthlyReportPage.displayName = `WithMonthlyReportPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithMonthlyReportPage
}
