import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient } from 'react-query'
import axios from 'axios'
import 'reflect-metadata'

import { Client } from '@lib/clients/Client'
import { API_URL } from '@lib/config'
import { Root } from './Root'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const appClient = new Client(axios.create({ baseURL: API_URL }))

ReactDOM.render(
  <React.StrictMode>
    <Root queryClient={queryClient} appClient={appClient} />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
