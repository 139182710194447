import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export interface TankProductListPropsType {
  productCode?: string
}

export function useTankProductList({
  productCode = '',
}: TankProductListPropsType = {}) {
  const client = useAppClient()
  const { data, isLoading } = useQuery(['tanks', productCode], () =>
    client?.tank.getTankProductList({ productCode }).then(response => response)
  )

  return {
    data: data || [],
    isLoading,
  }
}
