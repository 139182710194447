import { MeterDetailPropsType } from '@features/tracking-work/interfaces/MeterReportType'
import styles from '@features/tracking-work/pages/MeterMode/index.module.scss'

export function MeterDetail({
  header,
  volume30,
  volume15,
  tonInAir,
  tonInVac,
}: MeterDetailPropsType) {
  return (
    <>
      <div className={styles.meterReportCalculationHeader}>{header}</div>
      <div className={styles.meterReportCalculationLabel}>
        Volume @ 30 &#8451; (Liters)
      </div>
      <div>{volume30}</div>
      <div>Liters</div>

      <div className={styles.meterReportCalculationLabel}>
        Volume @ 15 &#8451; (Liters)
      </div>
      <div>{volume15}</div>
      <div>Liters</div>

      <div className={styles.meterReportCalculationLabel}>
        Metric Tons (in air)
      </div>
      <div>{tonInAir}</div>
      <div>Metric tons (in air)</div>

      <div className={styles.meterReportCalculationLabel}>
        Metric Tons (in vac)
      </div>
      <div>{tonInVac}</div>
      <div>Metric tons (in vac)</div>
    </>
  )
}
