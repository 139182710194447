import { useMemo } from 'react'
import hoist from 'hoist-non-react-statics'

import { ATGReportPropsType } from '@features/tracking-work/interfaces/ATGReportType'
import { useDeliveryReport } from '@features/tracking-work/hooks/useDeliveryReport'

export function withATGReport(Component: React.FC<ATGReportPropsType>) {
  function WithATGReport(props: ATGReportPropsType) {
    const { data: deliveryReport } = useDeliveryReport()

    const tankOptions = useMemo(
      () =>
        deliveryReport?.tanks?.map(tank => ({
          text: tank.code,
          value: tank.code,
        })) || [],
      [deliveryReport?.tanks]
    )

    const pageProps = {
      ...props,
      tankOptions,
    }

    return <Component {...pageProps} />
  }

  hoist(WithATGReport, Component)

  WithATGReport.displayName = `WithATGReport(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithATGReport
}
