import { ATGModeProps } from '@features/tracking-work/interfaces/ATGModeType'
import { blankFormProducts } from '@lib/constants'

import { ATGReport } from './ATGReport'
import { BlankForm } from './BlankForm'

export function ATGMode({ isBlankForm }: ATGModeProps) {
  if (isBlankForm) return <BlankForm />

  return <ATGReport />
}
