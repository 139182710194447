import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover, Button } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import { PermissionGuard } from '@lib/permissions'
import {
  BATCH_DOCUMENT_TRACKING,
  TRACKING_WORK,
  DRAFT_CALCULATION_PAGE,
} from '@lib/constants'

import styles from './index.module.scss'

export function DeliveryReportMenu() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'delivery-report-popover' : undefined

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function navigateTo(path: string) {
    navigate(path)
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        Delivery Report
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ className: styles.paper }}
      >
        <div className={styles.subMenu}>
          <PermissionGuard page={TRACKING_WORK} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/tracking-work')}
            >
              Tracking Work Request No.
            </div>
          </PermissionGuard>
          <PermissionGuard page={BATCH_DOCUMENT_TRACKING} mode='read'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/batch-document-tracking')}
            >
              Batch Document Tracking
            </div>
          </PermissionGuard>
          <PermissionGuard page={DRAFT_CALCULATION_PAGE} mode='write'>
            <div
              className={styles.subMenuItem}
              onClick={() => navigateTo('/draft')}
            >
              Draft Calculation
            </div>
          </PermissionGuard>
        </div>
      </Popover>
    </>
  )
}
