import { View, Text, Image } from '@react-pdf/renderer'

import signature from '@assets/images/sign_Chanud.png'
import styles from '@features/pdf/pages/MonthlyReport/styles'

interface SummaryProps {
  date: string
  categoryTitle: string
  diThroughputOfThisMonth1: string
  diThroughputOfThisMonth2: string
  diThroughputYearToDate1: string
  diThroughputYearToDate2: string
  deThroughputOfThisMonth1: string
  deThroughputOfThisMonth2: string
  deThroughputYearToDate1: string
  deThroughputYearToDate2: string
  imThroughputOfThisMonth1: string
  imThroughputOfThisMonth2: string
  imThroughputYearToDate1: string
  imThroughputYearToDate2: string
  exThroughputOfThisMonth1: string
  exThroughputOfThisMonth2: string
  exThroughputYearToDate1: string
  exThroughputYearToDate2: string
  soThroughputOfThisMonth1: string
  soThroughputOfThisMonth2: string
  soThroughputYearToDate1: string
  soThroughputYearToDate2: string
  reThroughputOfThisMonth1: string
  reThroughputOfThisMonth2: string
  reThroughputYearToDate1: string
  reThroughputYearToDate2: string
  flThroughputOfThisMonth1: string
  flThroughputOfThisMonth2: string
  flThroughputYearToDate1: string
  flThroughputYearToDate2: string
  itInThroughputOfThisMonth1: string
  itInThroughputOfThisMonth2: string
  itInThroughputYearToDate1: string
  itInThroughputYearToDate2: string
  itOutThroughputOfThisMonth1: string
  itOutThroughputOfThisMonth2: string
  itOutThroughputYearToDate1: string
  itOutThroughputYearToDate2: string
  position: string
}

function Box({ value, style = [] }: { value: string; style?: any }) {
  return (
    <View
      style={[
        ...style,
        styles.detailRow,
        styles.textRight,
        styles.h12,
        styles.pl2,
        styles.pr2,
        styles.justifyEnd,
      ]}
    >
      <Text>{value}</Text>
    </View>
  )
}

function Label({ text }: { text: string }) {
  return (
    <View
      style={[styles.detailRow, styles.spaceBetween, styles.pr2, styles.h12]}
    >
      <Text>{text}</Text>
      <Text>:</Text>
    </View>
  )
}

export function Summary({
  date,
  categoryTitle,
  diThroughputOfThisMonth1,
  diThroughputOfThisMonth2,
  diThroughputYearToDate1,
  diThroughputYearToDate2,
  deThroughputOfThisMonth1,
  deThroughputOfThisMonth2,
  deThroughputYearToDate1,
  deThroughputYearToDate2,
  imThroughputOfThisMonth1,
  imThroughputOfThisMonth2,
  imThroughputYearToDate1,
  imThroughputYearToDate2,
  exThroughputOfThisMonth1,
  exThroughputOfThisMonth2,
  exThroughputYearToDate1,
  exThroughputYearToDate2,
  soThroughputOfThisMonth1,
  soThroughputOfThisMonth2,
  soThroughputYearToDate1,
  soThroughputYearToDate2,
  reThroughputOfThisMonth1,
  reThroughputOfThisMonth2,
  reThroughputYearToDate1,
  reThroughputYearToDate2,
  flThroughputOfThisMonth1,
  flThroughputOfThisMonth2,
  flThroughputYearToDate1,
  flThroughputYearToDate2,
  itInThroughputOfThisMonth1,
  itInThroughputOfThisMonth2,
  itInThroughputYearToDate1,
  itInThroughputYearToDate2,
  itOutThroughputOfThisMonth1,
  itOutThroughputOfThisMonth2,
  itOutThroughputYearToDate1,
  itOutThroughputYearToDate2,
  position,
}: SummaryProps) {
  return (
    <View style={[styles.dFlex, styles.flexRow, { marginTop: 10 }]}>
      <View style={styles.w50}>
        <Text style={styles.strong}>Summary</Text>
        <View style={[styles.dFlex, styles.flexRow]}>
          <View style={{ flex: 1 }}>
            <View
              style={[
                styles.h20,
                styles.dFlex,
                styles.flexRow,
                { alignItems: 'flex-end' },
              ]}
            >
              <Text>Category</Text>
            </View>
            <Label text='DI' />
            <Label text='DE' />
            <Label text='IM' />
            <Label text='EX' />
            <Label text='SO' />
            <Label text='RE' />
            <Label text='FL' />
            <Label text='IT(in)' />
            <Label text='IT(out)' />
          </View>

          <View style={{ flex: 2 }}>
            <View style={styles.h20}>
              <View style={styles.textCenter}>
                <Text>Throughput of this month</Text>
              </View>
              <View style={[styles.dFlex, styles.flexRow, styles.textCenter]}>
                <View style={styles.w50}>
                  <Text>{categoryTitle}</Text>
                </View>
                <View style={styles.w50}>
                  <Text>Metric Tons (in air)</Text>
                </View>
              </View>
            </View>

            <View style={[styles.dFlex, styles.flexRow]}>
              <View
                style={[
                  styles.w50,
                  styles.mr,
                  styles.ml,
                  styles.borderL,
                  styles.borderR,
                  styles.borderT,
                  styles.borderB,
                ]}
              >
                <Box
                  value={diThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={deThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={imThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={exThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={soThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={reThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={flThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box
                  value={itInThroughputOfThisMonth1}
                  style={[styles.borderB]}
                />
                <Box value={itOutThroughputOfThisMonth1} />
              </View>
              <View
                style={[
                  styles.w50,
                  styles.mr,
                  styles.ml,
                  styles.borderL,
                  styles.borderR,
                  styles.borderT,
                  styles.borderB,
                ]}
              >
                <Box
                  value={diThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={deThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={imThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={exThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={soThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={reThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={flThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box
                  value={itInThroughputOfThisMonth2}
                  style={[styles.borderB]}
                />
                <Box value={itOutThroughputOfThisMonth2} />
              </View>
            </View>
          </View>

          <View style={{ flex: 2 }}>
            <View style={styles.h20}>
              <View style={styles.textCenter}>
                <Text>Throughput (year to date)</Text>
              </View>
              <View style={[styles.dFlex, styles.flexRow, styles.textCenter]}>
                <View style={styles.w50}>
                  <Text>{categoryTitle}</Text>
                </View>
                <View style={styles.w50}>
                  <Text>Metric Tons (in air)</Text>
                </View>
              </View>
            </View>

            <View style={[styles.dFlex, styles.flexRow]}>
              <View
                style={[
                  styles.w50,
                  styles.mr,
                  styles.ml,
                  styles.borderL,
                  styles.borderR,
                  styles.borderT,
                  styles.borderB,
                ]}
              >
                <Box value={diThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={deThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={imThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={exThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={soThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={reThroughputYearToDate1} style={[styles.borderB]} />
                <Box value={flThroughputYearToDate1} style={[styles.borderB]} />
                <Box
                  value={itInThroughputYearToDate1}
                  style={[styles.borderB]}
                />
                <Box value={itOutThroughputYearToDate1} />
              </View>
              <View
                style={[
                  styles.w50,
                  styles.mr,
                  styles.ml,
                  styles.borderL,
                  styles.borderR,
                  styles.borderT,
                  styles.borderB,
                ]}
              >
                <Box value={diThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={deThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={imThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={exThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={soThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={reThroughputYearToDate2} style={[styles.borderB]} />
                <Box value={flThroughputYearToDate2} style={[styles.borderB]} />
                <Box
                  value={itInThroughputYearToDate2}
                  style={[styles.borderB]}
                />
                <Box value={itOutThroughputYearToDate2} />
              </View>
            </View>
          </View>
        </View>
      </View>

      <View
        style={[
          styles.w50,
          styles.dFlex,
          {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
          },
        ]}
      >
        <View
          style={[
            styles.w50,
            styles.textCenter,
            styles.borderT,
            { marginLeft: 20, marginRight: 20 },
          ]}
        >
          <Text style={{ marginTop: 6 }}>CUSTOMER AUTHORIZED</Text>
          <Text style={{ marginTop: 3 }}>REPRESENTATIVE</Text>
          <View
            style={[
              styles.dFlex,
              styles.flexRow,
              { justifyContent: 'flex-start', marginTop: 10 },
            ]}
          >
            <View style={{ width: 20 }}>
              <Text>Date</Text>
            </View>
            <View style={[{ flex: 1 }, styles.borderB]} />
          </View>
        </View>

        <View
          style={[
            styles.w50,
            styles.textCenter,
            { marginLeft: 20, marginRight: 20 },
          ]}
        >
          <View style={styles.imageWrapper}>
          <Image src={signature} />
          </View>          
          <Text style={styles.borderT} />
          <Text style={{ marginTop: 6 }}>{position}</Text>
          <Text style={{ marginTop: 3 }}>MAPTAPHUT TANK TERMINAL CO.,LTD</Text>
          <View
            style={[
              styles.dFlex,
              styles.flexRow,
              { justifyContent: 'flex-start', marginTop: 10 },
            ]}
          >
            <View style={{ width: 20 }}>
              <Text>Date</Text>
            </View>
            <View style={[{ flex: 1 }, styles.borderB]}>
              <Text>{date}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
