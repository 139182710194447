import { createContext, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { useModal } from '@features/tracking-work/hooks/useModal'
import { MonthlyReportType } from '@features/monthly-report/interfaces/MonthlyReportType'
import { useAppClient } from '@lib/useAppClient'
import { getErrorMessage } from '@lib/utils'
import { useAlert } from '@lib/useAlert'

interface Response {
  data: MonthlyReportType[]
  queryParams: {
    month: string
    year: string
  }
  modalName: string
  payload: MonthlyReportType
  isLoading: boolean
  isSubmitting: boolean
  setQueryParams: (value: any) => void
  handleOpenApproveModal: (value: MonthlyReportType) => void
  handleApprove: () => void
  handleOpenRejectModal: (value: MonthlyReportType) => void
  handleReject: () => void
  closeModal: () => void
}

const AdminMonthlyReportContext = createContext<any>({})

export function AdminMonthlyReportProvider({
  children,
}: FunctionComponentType) {
  const queryClient = useQueryClient()
  const { error, success } = useAlert()
  const { modal, payload, closeModal, openModal } = useModal()
  const [searchParams] = useSearchParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [queryParams, setQueryParams] = useState(() => {
    const currentDatetime = DateTime.now().minus({ months: 1 })

    return {
      year: searchParams.get('year') || currentDatetime.toFormat('yyyy'),
      month: searchParams.get('month') || currentDatetime.toFormat('L'),
    }
  })

  const queryKey = ['admin-monthly-report', queryParams]
  const client = useAppClient()
  const { data = [], isLoading } = useQuery(
    queryKey,
    () =>
      client?.monthlyReport
        .getMonthlyReportList({
          year: queryParams.year,
          month: queryParams.month,
        })
        .then(response => {
          return response
        }),
    {
      enabled: queryParams.month !== '' && queryParams.year !== '',
    }
  )

  function handleOpenApproveModal(value: MonthlyReportType) {
    openModal({ modalName: 'approve', payload: value })
  }

  function handleApprove() {
    setSubmitting(true)
    client?.monthlyReport
      .approve({ id: payload.id })
      .then(res => {
        success({ message: `Approve ${payload.product} successful.` })
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return oldData.map((row: any) => {
            if (row.id === payload.id) {
              return res
            }

            return row
          })
        })
      })
      .catch(e => error({ message: getErrorMessage(e) }))
      .finally(() => {
        setSubmitting(false)
        closeModal()
      })
  }

  function handleOpenRejectModal(value: MonthlyReportType) {
    openModal({ modalName: 'reject', payload: value })
  }

  function handleReject() {
    setSubmitting(true)
    client?.monthlyReport
      .reject({ id: payload.id })
      .then(res => {
        success({ message: `Reject ${payload.product} successful.` })
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return oldData.map((row: any) => {
            if (row.id === payload.id) {
              return res
            }

            return row
          })
        })
      })
      .catch(e => error({ message: getErrorMessage(e) }))
      .finally(() => {
        setSubmitting(false)
        closeModal()
      })
  }

  const value = {
    data,
    modalName: modal,
    payload,
    queryParams,
    isLoading,
    isSubmitting,
    setQueryParams,
    handleOpenApproveModal,
    handleApprove,
    handleOpenRejectModal,
    handleReject,
    closeModal,
  }

  return (
    <AdminMonthlyReportContext.Provider value={value}>
      {children}
    </AdminMonthlyReportContext.Provider>
  )
}

export function useAdminMonthlyReport(): Response {
  return useContext(AdminMonthlyReportContext)
}
