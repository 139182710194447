import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  ReportHeader,
  ReportHeaderResponse,
} from '@features/report-header/interfaces/ReportHeaderType'

export class ReportHeaderClient {
  constructor(private client: AxiosInstance) {}

  async getReportHeaderList(): Promise<ReportHeaderResponse> {
    const response = await this.client.get('/api/report_headers')

    return response.data
      .map((tank: any) =>
        plainToClass(ReportHeader, tank, {
          excludeExtraneousValues: true,
        })
      )
      .reduce(
        (res: ReportHeaderResponse, data: ReportHeader) => {
          if (data.group === 'parameter') {
            return {
              ...res,
              parameters: [...res.parameters, data],
            }
          }

          if (data.type === 'alltank') {
            return {
              ...res,
              quantity: {
                ...res.quantity,
                alltanks: [...res.quantity.alltanks, data],
              },
            }
          }

          if (data.type === 'usable') {
            return {
              ...res,
              quantity: {
                ...res.quantity,
                usables: [...res.quantity.usables, data],
              },
            }
          }

          return {
            ...res,
            quantity: {
              ...res.quantity,
              avaliablespaces: [...res.quantity.avaliablespaces, data],
            },
          }
        },
        {
          parameters: [],
          quantity: {
            alltanks: [],
            usables: [],
            avaliablespaces: [],
          },
        }
      )
  }
}
