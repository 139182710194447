import { MONTHLY_REPORT } from '@lib/constants'
import { withPermissionGuard } from '@lib/permissions'
import { MonthlyReportPage } from './MonthlyReportPage'
import { withMonthlyReportPage } from './withMonthlyReportPage'

const ConnectedMonthlyReportPage = withPermissionGuard(
  withMonthlyReportPage(MonthlyReportPage),
  MONTHLY_REPORT,
  'read'
)

export { ConnectedMonthlyReportPage as MonthlyReportPage }
