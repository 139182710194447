import hoist from 'hoist-non-react-statics'

import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'

export function withATGMode(Component: React.FC<FunctionComponentType>) {
  function WithATGMode() {
    return <Component />
  }

  hoist(WithATGMode, Component)

  WithATGMode.displayName = `WithATGMode(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithATGMode
}
