import { memo } from 'react'
import { Formik, Form } from 'formik'
import { Button, InputAdornment, TextField } from '@material-ui/core'
import cx from 'classnames'
import * as yup from 'yup'

import { InputField } from '@components/fields/InputField'
import { Box } from '@features/tracking-work/components/Box'
import { ProrateDensityType } from '@features/tracking-work/interfaces/ProrateDensityType'
import styles from '@features/tracking-work/pages/ATGMode/index.module.scss'

const inputProps = {
  endAdornment: <InputAdornment position='end'>kg/liter</InputAdornment>,
}

const initialValues = {
  shoreBefore: '',
  shipBefore: '',
}

const validationSchema = yup.object().shape({
  shoreBefore: yup.number().required('this field is required'),
  shipBefore: yup.number().required('this field is required'),
})

export const ProrateDensity = memo(
  ({
    disabled,
    shoreAfter,
    isSubmitting,
    hideAction,
    handleSubmit,
  }: ProrateDensityType) => {
    return (
      <Box
        className={cx({ [styles.disabled]: disabled })}
        title='Prorate Density  @ 15 &#8451; in vac - For Import'
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting: formSubmitting }) => (
            <Form>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  Density at SHORE BEFORE discharge :
                </label>
                <InputField
                  type='number'
                  name='shoreBefore'
                  InputProps={inputProps}
                  disabled={disabled || hideAction}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  Density at SHIP BEFORE discharge :
                </label>
                <InputField
                  type='number'
                  name='shipBefore'
                  InputProps={inputProps}
                  disabled={disabled || hideAction}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.prorateLabel}>
                  Density at SHORE AFTER discharge :
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  value={shoreAfter || ''}
                  InputProps={inputProps}
                  disabled
                />
              </div>
              {!hideAction && (
                <div className={styles.calBtn}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isSubmitting || disabled || formSubmitting}
                  >
                    Cal
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    )
  }
)
