import { Outlet } from 'react-router-dom'

import { TrackingWorkPage } from './pages/TrackingWorkPage'
import { CalculationPage } from './pages/CalculationPage'
import { ATGMode } from './pages/ATGMode'
import { MeterMode } from './pages/MeterMode'

export const trackingWorkRoutes = [
  {
    path: 'tracking-work',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <TrackingWorkPage />,
      },
      {
        path: ':id/calculate',
        element: <CalculationPage />,
        children: [
          {
            path: '',
            element: <ATGMode />,
          },

          {
            path: 'meter',
            element: <MeterMode />,
          },
        ],
      },
    ],
  },
]
