import { useState, useEffect } from 'react'
import { useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'

import {
  MeterValueKey,
  MeterFormType,
  MeterReport,
  MeterValueType,
  VesselLoadFormType,
  SummaryFormType,
  ShipFormType,
} from '@features/tracking-work/interfaces/MeterReportType'
import { CheckListType } from '@features/tracking-work/interfaces/CheckListType'
import { useAppClient } from '@lib/useAppClient'
import {
  CalculateMode,
  CalculateType,
} from '@lib/clients/collections/ATGReportClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

interface UseMeterReportPropsType {
  deliveryId: string
  tankCode: string
  hasLessline: boolean
}

interface MeterReportReturnType {
  isLoading: boolean
  isSubmitting: boolean
  canCalVesselLoad: boolean
  data?: MeterReport
  methods: UseFormReturn<MeterFormType>
  vesselLoadMethods: UseFormReturn<VesselLoadFormType>
  shipMethods: UseFormReturn<ShipFormType>
  startDatetime: string
  stopLesslineDatetime: string
  stopDatetime: string
  summary: SummaryFormType
  setStartDatetime: (datetime: string) => void
  setStopLesslineDatetime: (datetime: string) => void
  setStopDatetime: (datetime: string) => void
  saveStart: (form: MeterValueType) => Promise<void>
  saveStopLessline: (form: MeterValueType) => Promise<void>
  saveStop: (form: MeterValueType) => Promise<void>
  handleRetrieveLastSave: () => void
}

const initialValues = {
  grossVolume: '',
  netVolume: '',
  massInAir: '',
  massInVac: '',
}

const initialVesselLoad = {
  volume30: '',
  volume15: '',
  tonInAir: '',
  tonInVac: '',
}

const initialSummaryValue = {
  volume30: 0,
  volume15: 0,
  tonInAir: 0,
  tonInVac: 0,
}

const initialSummary = {
  quantityReceivedDelivery: initialSummaryValue,
  difference: initialSummaryValue,
  quantityLessline: initialSummaryValue,
  finalSummary: initialSummaryValue,
}

export function useMeterReport({
  deliveryId,
  tankCode,
  hasLessline,
}: UseMeterReportPropsType): MeterReportReturnType {
  const client = useAppClient()
  const queryClient = useQueryClient()
  const { error, success } = useAlert()
  const [isSubmitting, setSubmitting] = useState(false)
  const [canCalVesselLoad, setCanCalVesselLoad] = useState(false)
  const queryKey = ['delivery', deliveryId, 'tanks', tankCode, 'meter-reports']

  const { data, isLoading, refetch } = useQuery(queryKey, () =>
    client?.meterReport
      .getMeterReport({
        deliveryId,
        tankCode,
      })
      .then(res => {
        setStartDatetime(res.report?.start?.time || '')
        setStopDatetime(res.report?.stop?.time || '')
        setStopLesslineDatetime(res.report?.stopLessline?.time || '')

        vesselLoadMethods.reset({
          volume15: res.report?.summaryReport?.vesselVolume15 || '',
          volume30: res.report?.summaryReport?.vesselVolume30 || '',
          tonInAir: res.report?.summaryReport?.vesselTonInAir || '',
          tonInVac: res.report?.summaryReport?.vesselTonInVac || '',
        })

        shipMethods.reset({
          volume15: res.report?.summaryReport?.shipVolume15 || '',
          volume30: res.report?.summaryReport?.shipVolume30 || '',
          tonInAir: res.report?.summaryReport?.shipTonInAir || '',
          tonInVac: res.report?.summaryReport?.shipTonInVac || '',
        })

        methods.reset({
          start: {
            grossVolume: res.report?.start?.grossVolume || '',
            netVolume: res.report?.start?.netVolume || '',
            massInAir: res.report?.start?.massInAir || '',
            massInVac: res.report?.start?.massInVac || '',
          },
          stopLessline: {
            grossVolume: res.report?.stopLessline?.grossVolume || '',
            netVolume: res.report?.stopLessline?.netVolume || '',
            massInAir: res.report?.stopLessline?.massInAir || '',
            massInVac: res.report?.stopLessline?.massInVac || '',
          },
          stop: {
            grossVolume: res.report?.stop?.grossVolume || '',
            netVolume: res.report?.stop?.netVolume || '',
            massInAir: res.report?.stop?.massInAir || '',
            massInVac: res.report?.stop?.massInVac || '',
          },
        })

        setCanCalVesselLoad(!!res.report?.stopLessline)

        return res
      })
  )

  const [startDatetime, setStartDatetime] = useState<string>('')
  const [stopLesslineDatetime, setStopLesslineDatetime] = useState<string>('')
  const [stopDatetime, setStopDatetime] = useState<string>('')
  const [summary, setSummary] = useState<SummaryFormType>(initialSummary)

  const methods = useForm<MeterFormType>({
    defaultValues: {
      start: initialValues,
      stopLessline: initialValues,
      stop: initialValues,
    },
  })

  const vesselLoadMethods = useForm<VesselLoadFormType>({
    defaultValues: initialVesselLoad,
  })

  const shipMethods = useForm<ShipFormType>({
    defaultValues: initialVesselLoad,
  })

  const start = useWatch({
    control: methods.control,
    name: [
      'start.grossVolume',
      'start.netVolume',
      'start.massInAir',
      'start.massInVac',
    ],
  })

  const stopLessline = useWatch({
    control: methods.control,
    name: [
      'stopLessline.grossVolume',
      'stopLessline.netVolume',
      'stopLessline.massInAir',
      'stopLessline.massInVac',
    ],
  })

  const stop = useWatch({
    control: methods.control,
    name: [
      'stop.grossVolume',
      'stop.netVolume',
      'stop.massInAir',
      'stop.massInVac',
    ],
  })

  const vesselLoad = useWatch({
    control: vesselLoadMethods.control,
    name: ['volume30', 'volume15', 'tonInAir', 'tonInVac'],
  })

  function updateChecklistStatus(type: CalculateType, mode: CalculateMode) {
    queryClient.setQueryData(
      ['delivery-report', deliveryId],
      (oldData: any) => {
        return {
          ...oldData,
          meterChecklist: oldData.meterChecklist.map((row: CheckListType) => ({
            ...row,
            active:
              (row.type === type &&
                row.mode === mode &&
                row.tankCode === tankCode) ||
              row.active,
          })),
        }
      }
    )
  }

  async function handleSave(
    type: CalculateType,
    mode: CalculateMode,
    datetime: string,
    form: MeterValueType
  ) {
    const valid =
      Object.keys(form).every(key => form[key as MeterValueKey]) && datetime

    if (valid) {
      await client?.meterReport.updateMeterReport({
        deliveryId,
        tankCode,
        datetime,
        type,
        mode,
        form,
      })
      const res = await client?.meterReport.getMeterReport({
        deliveryId,
        tankCode,
      })

      if (res) {
        queryClient.setQueryData(queryKey, (oldData: any) => {
          return {
            ...oldData,
            report: res.report,
            startState: res.startState,
            stopLesslineState: res.stopLesslineState,
            stopState: res.stopState,
          }
        })
      }

      if (type === 'stop' && mode === 'less_line') {
        setCanCalVesselLoad(true)
      }

      updateChecklistStatus(type, mode)
    } else {
      throw new Error('โปรดกรอกข้อมูลให้ครบก่อนการบันทึกข้อมูล')
    }
  }

  async function saveStart(form: MeterValueType) {
    await handleSave('start', 'normal', startDatetime, form)
  }

  async function saveStopLessline(form: MeterValueType) {
    await handleSave('stop', 'less_line', stopLesslineDatetime, form)
  }

  async function saveStop(form: MeterValueType) {
    await handleSave('stop', 'normal', stopDatetime, form)
  }

  function handleRetrieveLastSave() {
    setSubmitting(true)
    refetch()
      .then(() => {
        success({ message: 'Retrieve last save successful.' })
      })
      .catch(e => error({ message: getErrorMessage(e) }))
      .finally(() => setSubmitting(false))
  }

  function calQuantityLessline(
    fillIn: string,
    difference: number,
    decimalPoint: number
  ) {
    let ans = 0

    if (Number(fillIn) < Math.abs(difference))
      ans = Math.abs(difference) - Number(fillIn)

    return decimalPoint === 0 ? ans : Number(ans.toFixed(decimalPoint))
  }

  useEffect(() => {
    let newSummary: SummaryFormType = JSON.parse(JSON.stringify(summary))
    const [startGrossVolume, startNetVolume, startMassInAir, startMassInVac] =
      start
    const [
      stopLesslineGrossVolume,
      stopLesslineNetVolume,
      stopLesslineMassInAir,
      stopLesslineMassInVac,
    ] = stopLessline

    const [stopGrossVolume, stopNetVolume, stopMassInAir, stopMassInVac] = stop

    if (startDatetime && stopDatetime) {
      const quantityReceivedDeliveryVolume30 =
        (Number(stopGrossVolume) - Number(startGrossVolume)) * -1
      const quantityReceivedDeliveryVolume15 =
        (Number(stopNetVolume) - Number(startNetVolume)) * -1
      const quantityReceivedDeliveryTonInAir =
        (Number(stopMassInAir) - Number(startMassInAir)) * -1
      const quantityReceivedDeliveryTonInVac =
        (Number(stopMassInVac) - Number(startMassInVac)) * -1

      newSummary = {
        ...newSummary,
        quantityReceivedDelivery: {
          volume30: quantityReceivedDeliveryVolume30,
          volume15: quantityReceivedDeliveryVolume15,
          tonInAir: Number(quantityReceivedDeliveryTonInAir.toFixed(3)),
          tonInVac: Number(quantityReceivedDeliveryTonInVac.toFixed(3)),
        },
        finalSummary: {
          volume30:
            quantityReceivedDeliveryVolume30 +
            newSummary.quantityLessline.volume30,
          volume15:
            quantityReceivedDeliveryVolume15 +
            newSummary.quantityLessline.volume15,
          tonInAir: Number(
            (
              quantityReceivedDeliveryTonInAir +
              newSummary.quantityLessline.tonInAir
            ).toFixed(3)
          ),
          tonInVac: Number(
            (
              quantityReceivedDeliveryTonInVac +
              newSummary.quantityLessline.tonInVac
            ).toFixed(3)
          ),
        },
      }
    }

    if (startDatetime && stopLesslineDatetime) {
      const [vesselVolume30, vesselVolume15, vesselTonInAir, vesselTonInVac] =
        vesselLoad

      const differenceVolume30 = hasLessline
        ? Number(stopLesslineGrossVolume) - Number(startGrossVolume)
        : 0
      const differenceVolume15 = hasLessline
        ? Number(stopLesslineNetVolume) - Number(startNetVolume)
        : 0
      const differenceTonInAir = hasLessline
        ? Number(
            (Number(stopLesslineMassInAir) - Number(startMassInAir)).toFixed(3)
          )
        : 0
      const differenceTonInVac = hasLessline
        ? Number(
            (Number(stopLesslineMassInVac) - Number(startMassInVac)).toFixed(3)
          )
        : 0

      const quantityLesslineVolume30 = calQuantityLessline(
        vesselVolume30,
        differenceVolume30,
        0
      )
      const quantityLesslineVolume15 = calQuantityLessline(
        vesselVolume15,
        differenceVolume15,
        0
      )
      const quantityLesslineTonInAir = calQuantityLessline(
        vesselTonInAir,
        differenceTonInAir,
        3
      )
      const quantityLesslineTonInVac = calQuantityLessline(
        vesselTonInVac,
        differenceTonInVac,
        3
      )

      newSummary = {
        ...newSummary,
        difference: {
          volume30: differenceVolume30,
          volume15: differenceVolume15,
          tonInAir: differenceTonInAir,
          tonInVac: differenceTonInVac,
        },
        quantityLessline: {
          volume30: quantityLesslineVolume30,
          volume15: quantityLesslineVolume15,
          tonInAir: quantityLesslineTonInAir,
          tonInVac: quantityLesslineTonInVac,
        },
        finalSummary: {
          volume30:
            newSummary.quantityReceivedDelivery.volume30 +
            quantityLesslineVolume30,
          volume15:
            newSummary.quantityReceivedDelivery.volume15 +
            quantityLesslineVolume15,
          tonInAir: Number(
            (
              newSummary.quantityReceivedDelivery.tonInAir +
              quantityLesslineTonInAir
            ).toFixed(3)
          ),
          tonInVac: Number(
            (
              newSummary.quantityReceivedDelivery.tonInVac +
              quantityLesslineTonInVac
            ).toFixed(3)
          ),
        },
      }
    }

    setSummary(newSummary)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, stop, stopLessline, vesselLoad])

  return {
    isLoading,
    isSubmitting,
    canCalVesselLoad,
    data,
    methods,
    vesselLoadMethods,
    shipMethods,
    startDatetime,
    stopLesslineDatetime,
    stopDatetime,
    summary,
    setStartDatetime,
    setStopLesslineDatetime,
    setStopDatetime,
    saveStart,
    saveStopLessline,
    saveStop,
    handleRetrieveLastSave,
  }
}
