import { AxiosInstance } from 'axios'
import { plainToClass } from 'class-transformer'

import {
  CompanyType,
  CompanyFormType,
} from '@features/company/interfaces/CompanyType'

interface GetCompanyType {
  id: string
}

export class CompanyClient {
  constructor(private client: AxiosInstance) {}

  async getCompanyList(): Promise<CompanyType[]> {
    const response = await this.client.get('/api/companies')

    return response.data.map((company: any) =>
      plainToClass(CompanyType, company, {
        excludeExtraneousValues: true,
      })
    )
  }

  async getCompany({ id }: GetCompanyType): Promise<CompanyType | undefined> {
    if (id === 'undefined') return undefined

    const response = await this.client.get(`/api/companies/${id}`)

    return plainToClass(CompanyType, response.data, {
      excludeExtraneousValues: true,
    })
  }

  async createCompany({
    code,
    name,
    description,
    phoneNumber,
    active,
  }: CompanyFormType): Promise<void> {
    await this.client.post('/api/companies', {
      code,
      name,
      description,
      phone_number: phoneNumber,
      is_active: active === 'true',
    })
  }

  async editCompany({
    id,
    code,
    name,
    description,
    phoneNumber,
    active,
  }: CompanyFormType): Promise<void> {
    await this.client.patch(`/api/companies/${id}`, {
      code,
      name,
      description,
      phone_number: phoneNumber,
      is_active: active === 'true',
    })
  }
}
