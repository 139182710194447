import { useEffect } from 'react'
import hoist from 'hoist-non-react-statics'

import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'
import { PurityCorrectionPropsType } from '@features/tracking-work/interfaces/PurityCorrectionType'

export function withPurityCorrection(
  Component: React.FC<PurityCorrectionPropsType>
) {
  function WithPurityCorrection() {
    const {
      atgConfig,
      atgPurity,
      hideAction,
      setAtgPurity,
    } = useDraftDeliveryReport()

    const pageProps = {
      disabled: !atgConfig?.productPurity?.state,
      purity: atgPurity,
      hideAction,
      setPurity: setAtgPurity,
    }

    useEffect(() => {
      if (atgConfig?.productPurity?.value) {
        setAtgPurity(atgConfig?.productPurity?.value)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Component {...pageProps} />
  }

  hoist(WithPurityCorrection, Component)

  WithPurityCorrection.displayName = `WithPurityCorrection(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithPurityCorrection
}
