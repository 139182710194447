import { Outlet } from 'react-router-dom'

import { CompanyPage } from './pages/CompanyPage'
import { CompanyCreatePage } from './pages/CompanyCreatePage'
import { CompanyEditPage } from './pages/CompanyEditPage'

export const companyRoutes = [
  {
    path: 'company',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <CompanyPage />,
      },
      {
        path: 'create',
        element: <CompanyCreatePage />,
      },
      {
        path: ':id/edit',
        element: <CompanyEditPage />,
      },
    ],
  },
]
