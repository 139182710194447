import { useCallback, useState } from 'react'
import hoist from 'hoist-non-react-statics'
import { useParams } from 'react-router-dom'

import {
  ProrateMWForm,
  ProrateMWType,
} from '@features/tracking-work/interfaces/ProrateMWType'
import { useDraftDeliveryReport } from '@features/draft-calculation/hooks/useDraftDeliveryReport'
import { useAppClient } from '@lib/useAppClient'
import { useAlert } from '@lib/useAlert'
import { getErrorMessage } from '@lib/utils'

export function withProrateMW(Component: React.FC<ProrateMWType>) {
  function WithProrateMW() {
    const client = useAppClient()
    const { id } = useParams()
    const {
      atgConfig,
      selectedTankCode,
      isAtgSubmitting,
      hideAction,
    } = useDraftDeliveryReport()
    const { error, success } = useAlert()
    const [shoreAfter, setShoreAfter] = useState<number>()

    const handleSubmit = useCallback(
      async (form: ProrateMWForm) => {
        let isError = false

        const data = await client?.draft
          .calculateProrateMolecularWeight({
            deliveryId: id || '',
            tankCode: selectedTankCode,
            form,
          })
          .catch(e => {
            isError = true
            error({ message: getErrorMessage(e) })
          })

        if (!isError) {
          setShoreAfter(Number(data))
          success({ message: 'Prorate molecular weight cal successful.' })
        }
      },
      [selectedTankCode, id, client?.draft, error, success]
    )

    const pageProps = {
      disabled: !atgConfig?.molecularWeight?.state || hideAction,
      isSubmitting: isAtgSubmitting,
      shoreAfter,
      hideAction,
      handleSubmit,
    }

    return <Component {...pageProps} />
  }

  hoist(WithProrateMW, Component)

  WithProrateMW.displayName = `WithProrateMW(${
    Component.displayName ?? Component.name ?? 'Component'
  })`

  return WithProrateMW
}
