import { useQuery } from 'react-query'

import { useAppClient } from '@lib/useAppClient'

export function useActivityList({ productCode = '' }) {
  const client = useAppClient()
  const { data = [] } = useQuery(['activities', productCode], () =>
    client?.activity.getActivityList({ productCode }).then(response => response)
  )

  return {
    data,
  }
}
