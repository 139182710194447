import { useContext, createContext, useReducer, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { useAppClient } from '@lib/useAppClient'
import { getErrorMessage } from '@lib/utils'
import { FunctionComponentType } from '@interfaces/common/FunctionComponentType'
import { SignInForm } from '@features/authentication/interfaces/SignInType'

interface AuthenticationProviderType extends FunctionComponentType {
  isAuthenticated: boolean
}

interface AuthenticationContextType {
  isAuthenticated: boolean
  error?: string
}

interface ActionType {
  type: string
  error?: string
}

const AuthenticationContext = createContext<any>({
  isAuthenticated: false,
})

export function AuthenticationProvider({
  children,
  isAuthenticated: initIsAuthenticated,
}: AuthenticationProviderType) {
  const client = useAppClient()
  const queryClient = useQueryClient()

  const [{ isAuthenticated, error }, dispatch] = useReducer(
    (state: AuthenticationContextType, action: ActionType) => {
      switch (action.type) {
        case 'SIGN_IN': {
          return { ...state, isAuthenticated: true, error: '' }
        }
        case 'SIGN_OUT': {
          return { ...state, isAuthenticated: false, error: '' }
        }
        case 'SIGN_IN_FAIL': {
          return { ...state, error: action.error }
        }
        default: {
          return state
        }
      }
    },
    { isAuthenticated: initIsAuthenticated, error: '' }
  )

  async function signIn(params: SignInForm) {
    let isError = false
    await client?.auth.signIn(params).catch(e => {
      isError = true

      dispatch({ type: 'SIGN_IN_FAIL', error: getErrorMessage(e) })
    })

    if (!isError) dispatch({ type: 'SIGN_IN' })
  }

  async function signOut() {
    await client?.auth.signOut()
    queryClient.clear()
    dispatch({ type: 'SIGN_OUT' })
  }

  const value = {
    isAuthenticated,
    error,
    signIn,
    signOut,
  }

  useEffect(() => {
    client?.onAuthStateChange((token: string) => {
      if (token) return

      queryClient.clear()
      dispatch({ type: 'SIGN_OUT' })
    })
  }, [client, queryClient])

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication() {
  return useContext(AuthenticationContext)
}
