import { memo } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Column } from 'react-table'

import { TableReport } from '@components/TableReport'
import { DailyReport } from '@features/report/interfaces/DailyReportType'
import { useHeaderReport } from '@features/report/hooks/useHeaderReport'
import { getHeader } from '@lib/report'

import { DailyTablePropsType } from './interfaces'
import styles from './index.module.scss'

export const DailyTable = memo(
  ({ data, date, loading, companyId, editable }: DailyTablePropsType) => {
    const { data: headerReport } = useHeaderReport({
      companyId,
    })

    let columns: Column[] = headerReport
      ? [
          {
            Header: (
              <div className={cx(styles.noWrap, styles.box)}>Tank No</div>
            ),
            accessor: 'tankNo',
            Cell: ({ record }: { record: DailyReport }) => (
              <div>{record.roc.tankNo.value}</div>
            ),
          },
          {
            Header: (
              <div className={cx(styles.noWrap, styles.box)}>Product Name</div>
            ),
            accessor: 'productionName',
            Cell: ({ record }: { record: DailyReport }) => (
              <div>{record.roc.productionName.value}</div>
            ),
          },
          {
            Header: (
              <div className={cx(styles.noWrap, styles.box)}>Quantities</div>
            ),
            id: 'quantities',
            columns: [
              {
                Header: (
                  <div
                    className={cx(styles.noWrap, styles.box, styles.allTank)}
                  >
                    All tank
                  </div>
                ),
                id: 'all-tank',
                columns: headerReport?.alltank.map(row => ({
                  Header: (
                    <div
                      className={cx(styles.noWrap, styles.box, styles.allTank)}
                    >
                      {getHeader(row.name.replace('oC', '°C'))}
                    </div>
                  ),
                  accessor: `alltank_${row.key}`,
                  Cell: ({ record }: { record: DailyReport }) => (
                    <span
                      className={cx({
                        [styles.adjust]:
                          record.quantities.alltank[row.key]?.adjust,
                      })}
                    >
                      {record.quantities.alltank[row.key]?.value}
                    </span>
                  ),
                })),
              },
              {
                Header: (
                  <div className={cx(styles.noWrap, styles.box, styles.usable)}>
                    Usable (All tank - Dead stock)
                  </div>
                ),
                id: 'usable-all-tank-dead-stock',
                columns: headerReport?.usable.map(row => ({
                  Header: (
                    <div
                      className={cx(styles.noWrap, styles.box, styles.usable)}
                    >
                      {getHeader(row.name.replace('oC', '°C'))}
                    </div>
                  ),
                  accessor: `usable_${row.key}`,
                  Cell: ({ record }: { record: DailyReport }) => (
                    <span
                      className={cx({
                        [styles.adjust]:
                          record.quantities.usable[row.key]?.adjust,
                      })}
                    >
                      {record.quantities.usable[row.key]?.value}
                    </span>
                  ),
                })),
              },
              {
                Header: (
                  <div
                    className={cx(styles.noWrap, styles.box, styles.available)}
                  >
                    Available space (Max Capacity - All tank)
                  </div>
                ),
                id: 'available-space-max-capacity-all-tank',
                columns: headerReport?.avaliablespace.map(row => ({
                  Header: (
                    <div
                      className={cx(
                        styles.noWrap,
                        styles.box,
                        styles.available
                      )}
                    >
                      {getHeader(row.name.replace('oC', '°C'))}
                    </div>
                  ),
                  accessor: `avaliable_${row.key}`,
                  Cell: ({ record }: { record: DailyReport }) => (
                    <span
                      className={cx({
                        [styles.adjust]:
                          record.quantities.avaliablespace[row.key]?.adjust,
                      })}
                    >
                      {record.quantities.avaliablespace[row.key]?.value}
                    </span>
                  ),
                })),
              },
            ],
          },
          {
            Header: (
              <div
                className={cx(styles.setParameter, styles.noWrap, styles.box)}
              >
                Set Parameter
              </div>
            ),
            id: 'parameter',
            columns: headerReport.parameter.map(row => ({
              Header: (
                <div
                  className={cx(
                    styles.setParameter,
                    styles.noWrap,
                    styles.box,
                    styles.h56
                  )}
                >
                  {getHeader(row.name.replace('oC', '°C'))}
                </div>
              ),
              accessor: row.key,
              Cell: ({ record }: { record: DailyReport }) => (
                <span
                  className={cx({
                    [styles.adjust]: record.parameter[row.key]?.adjust,
                  })}
                >
                  {record.parameter[row.key]?.value}
                </span>
              ),
            })),
          },
        ]
      : []

    if (editable) {
      columns = [
        {
          Header: <div className={cx(styles.noWrap, styles.box)}>Tracking</div>,
          id: 'tracking',
          columns: [
            {
              Header: '',
              accessor: 'edit',
              Cell: ({ record }: { record: DailyReport }) => (
                <Link
                  to={`/inventory-daily-report/calculate?tc=${record.roc.tankNo.value}&d=${date}&p=${record.roc.productionName.value}`}
                >
                  Edit
                </Link>
              ),
            },
            {
              Header: (
                <div className={cx(styles.noWrap, styles.box)}>
                  Report Status
                </div>
              ),
              accessor: 'reportStatus',
              Cell: ({ record }: { record: DailyReport }) => (
                <div
                  className={cx(styles.reportStatus, {
                    [styles.verify]: record.reportStatus === 'Verify',
                  })}
                >
                  {record.reportStatus}
                </div>
              ),
            },
          ],
        },
        ...columns,
      ]
    }

    return (
      <TableReport
        columns={columns}
        data={data}
        loading={loading}
        type='daily'
      />
    )
  }
)
