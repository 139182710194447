import { Column } from 'react-table'
import { Expose } from 'class-transformer'

import { Screen } from '@lib/clients/interfaces/Screen'
import { ScreenPermission } from '@lib/clients/interfaces/ScreenPermission'
import { Option } from '@interfaces/common/SelectType'

export interface RoleGroupScreenType {
  write: boolean
  read: boolean
  id: number
}

export interface RoleGroupFormType {
  id?: string
  code: string
  name: string
  reportRole: string
  screens: RoleGroupScreenType[]
  active: string
}

export interface RoleGroupPageType {
  data: RoleGroupType[]
  columns: Column[]
  loading: boolean
  handleCreate: () => void
}

export interface RoleGroupFormProps {
  initialValues: RoleGroupFormType
  loading?: boolean
  handleSubmit: (form: RoleGroupFormType) => Promise<void>
}

export interface ComponentProps extends RoleGroupFormProps {
  screens: Screen[]
  screenLoading: boolean
  reportRoles: Option[]
}

export class RoleGroupType {
  @Expose() id: number

  @Expose() code: string

  @Expose() name: string

  @Expose() status: boolean

  @Expose({ name: 'report_role' }) reportRole: string

  @Expose() screens: ScreenPermission[]
}
