import { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { DateTime } from 'luxon'
import { pdf, Document } from '@react-pdf/renderer'

import { useAppClient } from '@lib/useAppClient'
import { PreviewButtonPropsType } from '@features/monthly-report/interfaces/AdminType'
import { Liquid } from '@features/pdf/pages/MonthlyReport/Liquid'
import { Gas } from '@features/pdf/pages/MonthlyReport/Gas'
import { ProductMovementDetail } from '@features/pdf/pages/ProductMovementDetail'

import styles from './index.module.scss'

export function PreviewButton({
  id,
  year,
  month,
  version,
}: PreviewButtonPropsType) {
  const client = useAppClient()
  const [loading, setLoading] = useState(false)

  async function handlePreview() {
    setLoading(true)
    const data = await client?.monthlyReport.getPreview({
      id,
    })

    if (data) {
      const { summary, movement } = data
      const date = DateTime.local(Number(year), Number(month))
      const startDate = date.startOf('month')
      const endDate = date.endOf('month')

      const fileName = `${version}_${startDate.toFormat(
        'dd_LL_yyyy'
      )}_to_${endDate.toFormat('dd_LL_yyyy')}`

      const blob = await pdf(
        <Document title={fileName}>
          {summary.type === 'LIQUID' ? (
            <Liquid
              product={summary.product}
              periodFrom={startDate.toFormat('dd/LL/yyyy')}
              periodTo={endDate.toFormat('dd/LL/yyyy')}
              date={DateTime.now().toFormat('dd/LL/yyyy')}
              stockLastMonth1={summary.stockLastMonth1}
              totalInputThisMonth1={summary.totalInputThisMonth1}
              totalOutputThisMonth1={summary.totalOutputThisMonth1}
              stockThisMonth1={summary.stockThisMonth1}
              stockLastMonth2={summary.stockLastMonth2}
              totalInputThisMonth2={summary.totalInputThisMonth2}
              totalOutputThisMonth2={summary.totalOutputThisMonth2}
              stockThisMonth2={summary.stockThisMonth2}
              stockLastMonth3={summary.stockLastMonth3}
              stockThisMonth3={summary.stockThisMonth3}
              stockLastMonth4={summary.stockLastMonth4}
              stockThisMonth4={summary.stockThisMonth4}
              lossGainByMass={summary.lossGainByMass}
              lossGainTotalThroughputByMass={
                summary.lossGainTotalThroughputByMass
              }
              totalThroughputThisMonthByMass={
                summary.totalThroughputThisMonthByMass
              }
              totalThroughputByMass={summary.totalThroughputByMass}
              lossGainByVolume={summary.lossGainByVolume}
              lossGainTotalThroughputByVolume={
                summary.lossGainTotalThroughputByVolume
              }
              totalThroughputThisMonthByVolume={
                summary.totalThroughputThisMonthByVolume
              }
              totalThroughputByVolume={summary.totalThroughputByVolume}
              diThroughputOfThisMonth1={summary.diThroughputOfThisMonth1}
              diThroughputOfThisMonth2={summary.diThroughputOfThisMonth2}
              diThroughputYearToDate1={summary.diThroughputYearToDate1}
              diThroughputYearToDate2={summary.diThroughputYearToDate2}
              deThroughputOfThisMonth1={summary.deThroughputOfThisMonth1}
              deThroughputOfThisMonth2={summary.deThroughputOfThisMonth2}
              deThroughputYearToDate1={summary.deThroughputYearToDate1}
              deThroughputYearToDate2={summary.deThroughputYearToDate2}
              imThroughputOfThisMonth1={summary.imThroughputOfThisMonth1}
              imThroughputOfThisMonth2={summary.imThroughputOfThisMonth2}
              imThroughputYearToDate1={summary.imThroughputYearToDate1}
              imThroughputYearToDate2={summary.imThroughputYearToDate2}
              exThroughputOfThisMonth1={summary.exThroughputOfThisMonth1}
              exThroughputOfThisMonth2={summary.exThroughputOfThisMonth2}
              exThroughputYearToDate1={summary.exThroughputYearToDate1}
              exThroughputYearToDate2={summary.exThroughputYearToDate2}
              soThroughputOfThisMonth1={summary.soThroughputOfThisMonth1}
              soThroughputOfThisMonth2={summary.soThroughputOfThisMonth2}
              soThroughputYearToDate1={summary.soThroughputYearToDate1}
              soThroughputYearToDate2={summary.soThroughputYearToDate2}
              reThroughputOfThisMonth1={summary.reThroughputOfThisMonth1}
              reThroughputOfThisMonth2={summary.reThroughputOfThisMonth2}
              reThroughputYearToDate1={summary.reThroughputYearToDate1}
              reThroughputYearToDate2={summary.reThroughputYearToDate2}
              flThroughputOfThisMonth1={summary.flThroughputOfThisMonth1}
              flThroughputOfThisMonth2={summary.flThroughputOfThisMonth2}
              flThroughputYearToDate1={summary.flThroughputYearToDate1}
              flThroughputYearToDate2={summary.flThroughputYearToDate2}
              itInThroughputOfThisMonth1={summary.itInThroughputOfThisMonth1}
              itInThroughputOfThisMonth2={summary.itInThroughputOfThisMonth2}
              itInThroughputYearToDate1={summary.itInThroughputYearToDate1}
              itInThroughputYearToDate2={summary.itInThroughputYearToDate2}
              itOutThroughputOfThisMonth1={summary.itOutThroughputOfThisMonth1}
              itOutThroughputOfThisMonth2={summary.itOutThroughputOfThisMonth2}
              itOutThroughputYearToDate1={summary.itOutThroughputYearToDate1}
              itOutThroughputYearToDate2={summary.itOutThroughputYearToDate2}
            />
          ) : (
            <Gas
              periodFrom={startDate.toFormat('dd/LL/yyyy')}
              periodTo={endDate.toFormat('dd/LL/yyyy')}
              date={DateTime.now().toFormat('dd/LL/yyyy')}
              product={summary.product}
              stockLastMonth1={summary.stockLastMonth1}
              totalInputThisMonth1={summary.totalInputThisMonth1}
              totalOutputThisMonth1={summary.totalOutputThisMonth1}
              stockThisMonth1={summary.stockThisMonth1}
              stockLastMonth2={summary.stockLastMonth2}
              totalInputThisMonth2={summary.totalInputThisMonth2}
              totalOutputThisMonth2={summary.totalOutputThisMonth2}
              stockThisMonth2={summary.stockThisMonth2}
              stockLastMonth3={summary.stockLastMonth3}
              stockThisMonth3={summary.stockThisMonth3}
              stockLastMonth4={summary.stockLastMonth4}
              stockThisMonth4={summary.stockThisMonth4}
              lossGainByMass={summary.lossGainByMass}
              lossGainTotalThroughputByMass={
                summary.lossGainTotalThroughputByMass
              }
              totalThroughputThisMonthByMass={
                summary.totalThroughputThisMonthByMass
              }
              totalThroughputByMass={summary.totalThroughputByMass}
              lossGainByVolume={summary.lossGainByVolume}
              lossGainTotalThroughputByVolume={
                summary.lossGainTotalThroughputByVolume
              }
              totalThroughputThisMonthByVolume={
                summary.totalThroughputThisMonthByVolume
              }
              totalThroughputByVolume={summary.totalThroughputByVolume}
              diThroughputOfThisMonth1={summary.diThroughputOfThisMonth1}
              diThroughputOfThisMonth2={summary.diThroughputOfThisMonth2}
              diThroughputYearToDate1={summary.diThroughputYearToDate1}
              diThroughputYearToDate2={summary.diThroughputYearToDate2}
              deThroughputOfThisMonth1={summary.deThroughputOfThisMonth1}
              deThroughputOfThisMonth2={summary.deThroughputOfThisMonth2}
              deThroughputYearToDate1={summary.deThroughputYearToDate1}
              deThroughputYearToDate2={summary.deThroughputYearToDate2}
              imThroughputOfThisMonth1={summary.imThroughputOfThisMonth1}
              imThroughputOfThisMonth2={summary.imThroughputOfThisMonth2}
              imThroughputYearToDate1={summary.imThroughputYearToDate1}
              imThroughputYearToDate2={summary.imThroughputYearToDate2}
              exThroughputOfThisMonth1={summary.exThroughputOfThisMonth1}
              exThroughputOfThisMonth2={summary.exThroughputOfThisMonth2}
              exThroughputYearToDate1={summary.exThroughputYearToDate1}
              exThroughputYearToDate2={summary.exThroughputYearToDate2}
              soThroughputOfThisMonth1={summary.soThroughputOfThisMonth1}
              soThroughputOfThisMonth2={summary.soThroughputOfThisMonth2}
              soThroughputYearToDate1={summary.soThroughputYearToDate1}
              soThroughputYearToDate2={summary.soThroughputYearToDate2}
              reThroughputOfThisMonth1={summary.reThroughputOfThisMonth1}
              reThroughputOfThisMonth2={summary.reThroughputOfThisMonth2}
              reThroughputYearToDate1={summary.reThroughputYearToDate1}
              reThroughputYearToDate2={summary.reThroughputYearToDate2}
              flThroughputOfThisMonth1={summary.flThroughputOfThisMonth1}
              flThroughputOfThisMonth2={summary.flThroughputOfThisMonth2}
              flThroughputYearToDate1={summary.flThroughputYearToDate1}
              flThroughputYearToDate2={summary.flThroughputYearToDate2}
              itInThroughputOfThisMonth1={summary.itInThroughputOfThisMonth1}
              itInThroughputOfThisMonth2={summary.itInThroughputOfThisMonth2}
              itInThroughputYearToDate1={summary.itInThroughputYearToDate1}
              itInThroughputYearToDate2={summary.itInThroughputYearToDate2}
              itOutThroughputOfThisMonth1={summary.itOutThroughputOfThisMonth1}
              itOutThroughputOfThisMonth2={summary.itOutThroughputOfThisMonth2}
              itOutThroughputYearToDate1={summary.itOutThroughputYearToDate1}
              itOutThroughputYearToDate2={summary.itOutThroughputYearToDate2}
            />
          )}
          <ProductMovementDetail
            type={summary.type}
            product={summary.product}
            date={date.toFormat('LLLL yyyy')}
            data={movement}
          />
        </Document>
      ).toBlob()

      const csvURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', `${fileName}.pdf`)
      tempLink.click()
    }

    setLoading(false)
  }

  return (
    <div className={styles.container}>
      <Button onClick={handlePreview} disabled={loading}>
        {loading ? <CircularProgress size={20} /> : 'Preview'}
      </Button>
    </div>
  )
}
