import { QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import LuxonUtils from '@date-io/luxon'
import luxon from 'luxon'
import { Font } from '@react-pdf/renderer'

import { Client } from '@lib/clients/Client'
import { AppClientProvider } from '@lib/useAppClient'
import { AlertProvider } from '@lib/useAlert'
import { AuthenticationProvider } from '@features/authentication/hooks/useAuthentication'
import { ProfileProvider } from '@features/profile/hooks/useProfile'
import font from '@assets/fonts/Roboto-Regular.ttf'
import fontBold from '@assets/fonts/Roboto-Bold.ttf'
import fontMedium from '@assets/fonts/Roboto-Medium.ttf'

import { App } from './App'

interface RootType {
  queryClient: QueryClient
  appClient: Client
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCE40',
      light: '#DB3D32E6',
      contrastText: '#000000',
    },
    secondary: {
      main: '#005ff9',
      contrastText: '#FEFEFE',
    },
  },
})

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fonts: [
    { src: font },
    { src: fontMedium, fontWeight: 500 },
    { src: fontBold, fontWeight: 700 },
  ],
})

export function Root({ queryClient, appClient }: RootType) {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppClientProvider client={appClient}>
          <AuthenticationProvider
            isAuthenticated={!!appClient.auth.accessToken}
          >
            <ProfileProvider>
              <AlertProvider>
                <MuiPickersUtilsProvider utils={LuxonUtils} libInstance={luxon}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </MuiPickersUtilsProvider>
              </AlertProvider>
            </ProfileProvider>
          </AuthenticationProvider>
        </AppClientProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
